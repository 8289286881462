<h3 class="sub-section">{{ 'WORKFLOW.AUDIT.LABEL.APPROVAL_HISTORY' | translate }}</h3>
<div id="depositTypeGrid" class="approval-history-row my-3 depositTypeGrid">
  <dx-data-grid
    #dataGrid
    appDatagridSort
    [wordWrapEnabled]="true"
    [dataSource]="approvalHistoryList"
    [remoteOperations]="{ paging: true }"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
  >
    <dxo-remote-operations [filtering]="false" [sorting]="true" [grouping]="false" [paging]="true" [summary]="false"> </dxo-remote-operations>
    <!-- <dxo-scrolling [useNative]="true"></dxo-scrolling> -->
    <dxo-paging [pageSize]="20"></dxo-paging>
    <dxi-column [width]="150" [calculateSortValue]="sortDateWithID" sortOrder="desc" dataField="modifiedDate" cellTemplate="actionDateCell" caption="Actioned date"></dxi-column>
    <div *dxTemplate="let data of 'actionDateCell'">
      {{ data.value | systemDateTimeFormat }}
    </div>
    <dxi-column dataField="action" caption="Action" dataField="action" cellTemplate="actionCell"></dxi-column>
    <div *dxTemplate="let data of 'actionCell'">
      {{ data.value | genericCategory: 'WORKFLOW.TRANSACTION_ACTION' | async }}
    </div>
    <dxi-column dataField="remarks" caption="Approval Comments" cellTemplate="remarkCell"></dxi-column>
    <div *dxTemplate="let data of 'remarkCell'">
      {{ data.value ? data.value : '-' }}
    </div>
    <dxi-column dataField="actionedBy" caption="Submitted By"></dxi-column>
  </dx-data-grid>
</div>
