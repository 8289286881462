import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Attachment, AttachmentRequest } from '@core/models/attachment';
import { ChangeRequestService, DialogData, DialogService, S3FileManagementService, untilDestroyed, UserService, WorkflowRequestAttachments } from '@app/@core';
import { AttachmentsListConstants } from './attachments-list.constant';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { lookup } from 'mime-types';
import { generateUuid } from '@app/@core/utils/uuid/generate-uuid';

@Component({
  selector: 'workflow-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss'],
})
export class AttachmentsListComponent implements OnInit, OnDestroy {
  isUploading = false;
  fileList: Attachment[] = [];
  currentUser: any = {} as any;
  sortOrder = '';

  public moment = moment;

  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  @Input() isAllowAttachmentChange = true;
  @Input() uploadedFileList: Attachment[];
  @Input() isFixHeight = true;
  @Input() isReceiptRelated = false;
  @Output() attachments = new EventEmitter<Attachment[]>();
  @Input() isDisableUploadBtn = false;

  constructor(private userService: UserService, private changeRequestService: ChangeRequestService, private fileService: S3FileManagementService, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.initAttachmentList();
  }

  ngOnDestroy() {}

  resetFileList() {
    this.fileList = this.fileList.filter((file) => !file.isUploaded);
    this.emitAttachmentList();
  }

  initAttachmentList() {
    if (this.uploadedFileList) {
      const uploadedFileList = cloneDeep(this.uploadedFileList);
      uploadedFileList.forEach((file) => {
        file.displayFileSize = this.consolidateFileSize(file.fileSize);
      });
      this.fileList = uploadedFileList;
      this.emitAttachmentList();
    }
  }

  onRemoveAdditionalAttachmentClick(e: any) {
    this.fileList = this.fileList.filter((file) => file.uuid !== e.data.uuid);
    this.emitAttachmentList();
  }

  onUploadClick() {
    const fileInput = this.fileUpload.nativeElement;
    fileInput.value = null;
    fileInput.onchange = () => {
      Object.keys(fileInput.files).forEach((key) => {
        const file = fileInput.files[key];

        let errorObj: { [error: string]: any } = null;
        const mimeType = lookup(file.name);
        const size = file.size;

        if (!mimeType || !AttachmentsListConstants.ALLOWED_FILE_TYPES.includes(mimeType)) {
          errorObj = { ...errorObj, 'Invalid file type.': true };
        }
        if (size > AttachmentsListConstants.MAX_FILE_SIZE) {
          const sizeErrMsg = `The file must be smaller than ${this.consolidateFileSize(AttachmentsListConstants.MAX_FILE_SIZE)}`;
          errorObj = { ...errorObj, [sizeErrMsg]: true };
        }

        if (errorObj) {
          const dialogData: DialogData = {
            title: 'Error',
            errorList: errorObj,
            width: '370px',
            yesCallback: (dialogRef) => {
              dialogRef.close();
            },
          };
          this.dialogService.showErrorDialog(dialogData).subscribe();
        } else {
          this.appendFile(file);
        }
      });
      // const file = fileInput.files[0];
      // this.appendFile(file);
    };
    fileInput.click();
  }

  appendFile(uploadedFile: any) {
    const splittedFileName = uploadedFile.name.split('.');
    const file: Attachment = {
      isUploaded: true,
      displayFileSize: this.consolidateFileSize(uploadedFile.size),
      fileSize: uploadedFile.size,
      uploadedOn: null,
      uploadedBy: this.getUserName(),
      fileName: splittedFileName[0],
      fileType: splittedFileName.splice(splittedFileName.length - 1, 1).toString(),
      displayedFileName: uploadedFile.name,
      file: uploadedFile,
      uuid: generateUuid(),
    };

    this.fileList.push(file);
    this.emitAttachmentList();
  }

  getUserName() {
    Object.assign(this.currentUser, this.userService.currentUserInfo);
    return `${this.currentUser.firstName || ''} ${this.currentUser.lastName || ''}`;
  }

  consolidateFileSize(value: number) {
    const sizeInKb = value / 1024;
    if (sizeInKb > 1024) {
      const sizeInMb = sizeInKb / 1024;
      return `${sizeInMb.toFixed(1)}MB`;
    } else return `${Math.ceil(sizeInKb)}KB`;
  }

  emitAttachmentList() {
    let attachments: any[] = cloneDeep(this.fileList);
    attachments = attachments
      .filter((attachment) => attachment.isUploaded)
      .map((attachment) => {
        delete attachment.uploadedOn;
        delete attachment.uploadedBy;
        delete attachment.displayFileSize;
        delete attachment.displayedFileName;
        if (attachment.uuid) delete attachment.uuid;
        if (attachment.isUploaded) delete attachment.isUploaded;
        return attachment;
      });
    this.attachments.next(attachments);
  }

  checkIsValidDate(value: any) {
    return this.moment(value).isValid();
  }

  onDownloadFileClick(e: any) {
    // * click the row to trigger a download job ( click the action column will not trigger )
    if (e.values && e.values[0]) {
      if (this.isReceiptRelated) {
        this.changeRequestService
          .downloadTransactionAttachment({ id: e.data.id })
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.fileService.downloadFile(res, e.data.displayedFileName);
          });
      } else {
        this.changeRequestService
          .downloadWorkflowAttachment({ id: e.data.id })
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.fileService.downloadFile(res, e.data.displayedFileName);
          });
      }
    }
  }

  resetAttachmentList() {
    this.fileList = [];
  }
}
