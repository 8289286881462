import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isChecked' })
export class isCheckedPipe implements PipeTransform {
  transform(id: string, selectedList?: Array<any>, selectedListLength?: number): boolean {
    console.log(id);
    if (!id) {
      return false;
    }
    if (selectedList?.length === 0) return false;
    else {
      return selectedList.indexOf(id) > -1;
    }
  }
}
