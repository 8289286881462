import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'systemCurrency',
})
export class SystemCurrencyPipe extends CurrencyPipe implements PipeTransform {
  // TODO: Set to env/app config
  transform(value: number | string, ...args: string[]): any {
    const strValue = String(value);
    if (value && strValue?.charAt(0) === '-') {
      return `HKD ${super.transform(value, 'HKD ', '', '1.2')}`;
    }
    return super.transform(value, 'HKD ', 'code', '1.2');
  }
}
