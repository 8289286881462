/*
 * Regex is used in permissionMapping keys to handle "URL" with variables/params
 * Use (.*) for the variable part of the URL
 * PermissionGuard will convert the permissionMapping keys into a RegExp object to match with the current URL for permission checking
 */

import { PermissionResources } from './permission-resources';

export const permissionMapping = {
  /** 1. Campaign Module */

  '/admin/campaign/list': [PermissionResources.LOYALTY_CAMPAIGN_LIST_PAGE],
  '/admin/campaign/create': [PermissionResources.LOYALTY_CAMPAIGN_EDIT_PAGE],
  '/admin/campaign/view/(.*)': [PermissionResources.LOYALTY_CAMPAIGN_LIST_PAGE],
  '/admin/campaign/edit/(.*)': [PermissionResources.LOYALTY_CAMPAIGN_EDIT_PAGE],

  '/admin/campaign/(.*)/promotion/view/(.*)': [PermissionResources.LOYALTY_PROMOTION_LIST_PAGE],
  '/admin/campaign/(.*)/promotion/edit/(.*)': [PermissionResources.LOYALTY_PROMOTION_EDIT_PAGE],
  '/admin/campaign/(.*)/promotion/create': [PermissionResources.LOYALTY_PROMOTION_EDIT_PAGE],
  '/admin/campaign/(.*)/promotion/clone': [PermissionResources.LOYALTY_PROMOTION_EDIT_PAGE],

  /** 2. Member Module */

  '/admin/members/list': [PermissionResources.LOYALTY_MEMBER_LIST_MEMBER_PAGE],
  '/admin/members/create': [PermissionResources.LOYALTY_MEMBER_CREATE_MEMBER_PAGE],
  '/admin/members/view/(.*)': [PermissionResources.LOYALTY_MEMBER_VIEW_MEMBER_PAGE],
  '/admin/members/edit/(.*)': [PermissionResources.LOYALTY_MEMBER_EDIT_MEMBER_PAGE],
  '/admin/members/upload-receipts/cs-upload/(.*)': [PermissionResources.LOYALTY_MEMBER_CS_UPLOAD_RECEIPT_PAGE],
  '/admin/members/upload-receipts/ge-upload/(.*)': [PermissionResources.LOYALTY_MEMBER_GE_UPLOAD_RECEIPT_PAGE],

  '/admin/members/invitation-link': [PermissionResources.LOYALTY_MEMBER_INVITATION_LINK_PAGE],

  /** 3. Receipt Transaction Module */

  '/admin/receipt-transaction/list': [PermissionResources.LOYALTY_TRANSACTION_LIST_PAGE],
  '/admin/receipt-transaction/view/(.*)': [PermissionResources.LOYALTY_TRANSACTION_EDIT_PAGE],

  /** 4. Gift Module */

  '/admin/gift/list': [PermissionResources.LOYALTY_GIFT_LIST_PAGE],
  '/admin/gift/create': [PermissionResources.LOYALTY_GIFT_EDIT_PAGE],
  '/admin/gift/view/(.*)': [PermissionResources.LOYALTY_GIFT_LIST_PAGE],
  '/admin/gift/edit/(.*)': [PermissionResources.LOYALTY_GIFT_EDIT_PAGE],

  /** 5. Report Module */

  '/admin/report/list': [PermissionResources.LOYALTY_REPORT_LIST_PAGE],
  // Respective report permission checking is handled in src\app\@core\guards\report-permission.guard.ts

  /** 6. Workflow Module */

  '/admin/workflow/request/list': [PermissionResources.LOYALTY_WORKFLOW_REQUEST_INBOX_PAGE],
  '/admin/workflow/request/view/(.*)': [PermissionResources.LOYALTY_WORKFLOW_REQUEST_DETAIL_PAGE],

  /** -- Uncategorized */

  '/admin/currency-conversion/list': [PermissionResources.LOYALTY_CURRENCY_CONVERSION_RATE_LIST_PAGE],
  '/admin/currency-conversion/view/(.*)': [PermissionResources.LOYALTY_CURRENCY_CONVERSION_RATE_LIST_PAGE],
  '/admin/currency-conversion/edit': [PermissionResources.LOYALTY_EDIT_CURRENCY_CONVERSION_RATE],
  '/admin/currency-conversion/create': [PermissionResources.LOYALTY_EDIT_CURRENCY_CONVERSION_RATE],

  '/admin/loyalty/overview': [PermissionResources.LOYALTY_PROGRAMME_OVERVIEW],
  '/admin/loyalty/tier/view': [PermissionResources.LOYALTY_VIEW_TIER],
  '/admin/loyalty/tier/edit': [PermissionResources.LOYALTY_EDIT_TIER],
  '/admin/loyalty/tier/create': [PermissionResources.LOYALTY_CREATE_TIER],

  '/admin/tenant/list': [PermissionResources.LOYALTY_TENANT_LIST_PAGE],
  '/admin/tenant/create': [PermissionResources.LOYALTY_TENANT_EDIT_PAGE],
  '/admin/tenant/view/(.*)': [PermissionResources.LOYALTY_TENANT_LIST_PAGE],
  '/admin/tenant/edit/(.*)': [PermissionResources.LOYALTY_TENANT_EDIT_PAGE],

  '/admin/partner/list': [PermissionResources.LOYALTY_PARTNER_LIST_PAGE],
  '/admin/partner/create': [PermissionResources.LOYALTY_PARTNER_EDIT_PAGE],
  '/admin/partner/view/(.*)': [PermissionResources.LOYALTY_PARTNER_LIST_PAGE],
  '/admin/partner/edit/(.*)': [PermissionResources.LOYALTY_PARTNER_EDIT_PAGE],

  '/admin/loyalty/loyalty-programme/list': [PermissionResources.LOYALTY_PROGRAMME_LIST_PAGE],
  '/admin/loyalty/loyalty-programme/create': [PermissionResources.LOYALTY_PROGRAMME_EDIT_PAGE],
  '/admin/loyalty/loyalty-programme/view/(.*)': [PermissionResources.LOYALTY_PROGRAMME_LIST_PAGE],
  '/admin/loyalty/loyalty-programme/edit/(.*)': [PermissionResources.LOYALTY_PROGRAMME_EDIT_PAGE],
};
