import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@env/environment';

export const OAUTH_CONFIG: AuthConfig = {
  clientId: environment.OAUTH_CLIENT_ID,
  issuer: `${environment.OAUTH_URL}`,
  redirectUri: window.location.origin,
  postLogoutRedirectUri: environment.APP_BASE_URL,
  responseType: 'code',
  scope: 'openid',
  sessionChecksEnabled: false,
  showDebugInformation: true,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  silentRefreshShowIFrame: false,
  skipIssuerCheck: true,
  strictDiscoveryDocumentValidation: false,
  silentRefreshTimeout: 2000,
  useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
  clearHashAfterLogin: true, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  useIdTokenHintForSilentRefresh: false,
  timeoutFactor: 0.5,
  fallbackAccessTokenExpirationTimeInSec: 900,
  oidc: false,
};
