<div class="property-container container-fluid">
  <div class="row full-width property-selector" [ngClass]="{ collapsed: !(sidenavService.isMenuOpenedSubject$ | async) }">
    <div class="icon-container">
      <img *ngIf="sidenavService.isMenuOpenedSubject$ | async" class="expand-logo" src="{{ (loyaltyService.currentProgramme$ | async).horizontalLogoImgPath }}" alt="programme logo" />
      <div class="collapsed-logo-wrapper" *ngIf="!(sidenavService.isMenuOpenedSubject$ | async)">
        <img class="collapsed-logo" src="{{ (loyaltyService.currentProgramme$ | async).squareLogoImgPath }}" alt="programme collapse ogo" />
      </div>
    </div>
    <div class="toggle-container" *ngIf="showProgSelection">
      <button mat-icon-button [matMenuTriggerFor]="beforeMenu">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before" class="loyalty-button-menu">
        <ng-container *ngFor="let programme of loyaltyService.loyaltyProgrammes$ | async">
          <button class="loyalty-button" mat-menu-item (click)="onSwitchProgramme(programme)">
            <span class="loyalty-label">{{ programme.name }}</span>
            <div class="loyalty-button-logo">
              <img src="{{ programme.horizontalLogoImgPath }}" alt="programme logo" />
            </div>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
  <!-- </div> -->
</div>
<mat-divider *ngIf="sidenavService.isMenuOpenedSubject$ | async"></mat-divider>
<!-- Menu Section Start -->
<app-admin-side-menu></app-admin-side-menu>
<!-- Menu Section End -->
