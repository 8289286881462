import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiptTransactionStatusPipe } from './receipt-transaction-status.pipe';
import { JoinBySeparatorPipe } from './join-by-separator.pipe';

@NgModule({
  declarations: [ReceiptTransactionStatusPipe,JoinBySeparatorPipe],
  exports: [ReceiptTransactionStatusPipe, JoinBySeparatorPipe],
  imports: [CommonModule],
})
export class ReceiptTransactionPipeModule {}
