import { Component, Input, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { checkDepositTransactionTenant } from '@app/@core';
import { DateTimeMaskConstants } from '@shared/masks';
import { UserService } from '@core/services';


@Component({
  selector: 'RPT-form',
  templateUrl: './RPT-form.component.html',
  styleUrls: ['./RPT-form.component.scss'],
})
export class RPTFormransactionComponent implements OnInit {
  @Input() data:any = null;
  @Input() tenantList:any[] = [];
  RptForm: UntypedFormGroup;
  DATE_TODAY = new Date();
  TIME_MASK = DateTimeMaskConstants.TIME;
  get shopId() {
    return this.RptForm.get('shopId');
  }
  get originalPaymentAmount() {
    return this.RptForm.get('originalPaymentAmount');
  }
  get haveDepositTransactionAccess(): boolean {
    return this.userService.hasPermission('ui#loyalty#transaction#depositTransaction');
  }
  constructor(
    private fb: UntypedFormBuilder,
    public userService: UserService,
  ) {
    this.initForm()
  }
  ngOnInit(): void {
      
  }
  initForm() {
    this.RptForm = this.fb.group({
        shopId: [null, [checkDepositTransactionTenant]],
        transactionDateTime: [null],
        transactionDate: [null],
        transactionTime: [null],
        originalPaymentAmount: [null],
        paidAmount: [null],
        excludedAmount: [null],
        paymentMethod: [null],
        acquirerRefNo: [null],
        remarks: [null],
        invoiceNumbers: [''],
    })
  }
}
