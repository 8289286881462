import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { consolidateValidationError } from '@app/@core/utils/validators/consolidate-validation-error';
import { FieldType } from '@ngx-formly/core';

// point可以存在无pointTo的情况
export function noEndpointRangeValidator(control: UntypedFormControl): ValidationErrors {
  if (control.value) {
    const pointFrom = control.value.pointFrom;
    const pointTo = control.value.pointTo;
    return (!pointFrom && !pointTo) || (pointFrom && !pointTo) || (pointFrom && pointTo && Number(pointTo) >= Number(pointFrom)) ? null : { invalidPointRange: true };
  } else return null;
}

export function pointRangeValidator(control: UntypedFormControl): ValidationErrors {
  if (control.value) {
    const pointFrom = control.value.pointFrom;
    const pointTo = control.value.pointTo;
    return (!pointFrom && !pointTo) || (pointFrom && pointTo && Number(pointTo) > Number(pointFrom)) ? null : { invalidPointRange: true };
  } else return null;
}

export function dateRangeRequiredValidator(control: UntypedFormControl): ValidationErrors {
  const { dateFrom, dateTo } = control.value;
  if (!dateFrom) control.get('transactionDateFrom').setErrors({ required: true });
  if (!dateTo) control.get('transactionDateTo').setErrors({ required: true });
  return !dateFrom || !dateTo ? { required: true } : null;
}

export function transactionDateRangeRequiredValidator(control: UntypedFormControl): ValidationErrors {
  const dateFrom = control.get('dateRange')?.get('dateFrom');
  const dateTo = control.get('dateRange')?.get('dateTo');
  const transactionDateFrom = control.get('transactionDateRange')?.get('transactionDateFrom');
  const transactionDateTo = control.get('transactionDateRange')?.get('transactionDateTo');
  const dateRangeIsInvalid = !dateFrom?.value && !dateTo?.value && !transactionDateFrom?.value && !transactionDateTo?.value;

  dateFrom.setErrors(consolidateValidationError(dateRangeIsInvalid, dateFrom.errors, 'requiredDateRange'));
  dateTo.setErrors(consolidateValidationError(dateRangeIsInvalid, dateTo.errors, 'requiredDateRange'));
  transactionDateFrom.setErrors(consolidateValidationError(dateRangeIsInvalid, transactionDateFrom.errors, 'requiredDateRange'));
  transactionDateTo.setErrors(consolidateValidationError(dateRangeIsInvalid, transactionDateTo.errors, 'requiredDateRange'));
  return null;
}

export function memberDetailReportDateRangeValidator(control: UntypedFormControl): ValidationErrors {
  const dateFrom = control.get('dateRange')?.get('dateFrom');
  const dateTo = control.get('dateRange')?.get('dateTo');

  const tierUpdateDateFrom = control.get('tierUpgradeDate')?.get('tierUpgradeDateFrom');
  const tierUpdateDateTo = control.get('tierUpgradeDate')?.get('tierUpgradeDateTo');

  const dateRangeIsInvalid = !dateFrom?.value && !dateTo?.value && !tierUpdateDateFrom?.value && !tierUpdateDateTo?.value;

  return dateRangeIsInvalid ? { memberDetailReportDateRange: true } : null;
}

export const formlyValidators = [
  { name: 'point-range', validation: pointRangeValidator },
  { name: 'date-range-required', validation: dateRangeRequiredValidator },
  { name: 'transaction-date-range-required', validation: transactionDateRangeRequiredValidator },
  // { name: 'memberDetailReportDateRange', validation: memberDetailReportDateRangeValidator },
];

export const formlyValidationMessage = [{ name: 'point-range', message: 'Invalid point range' }];

export function showError(field: FieldType) {
  return field.formControl && field.formControl.invalid && field.formControl.touched;
}
