import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { of, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MetadataResolver implements Resolve<any> {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const routeData = route.data;
    let childRoute = route;
    while (childRoute && !childRoute.data?.module) {
      childRoute = childRoute?.firstChild;
    }

    if (childRoute && childRoute.data?.module) {
      return from(import(`src/app/pages/${childRoute.data.module}/${childRoute.data?.module}.metadata`)).pipe(
        map((data) => {
          while (!childRoute.data?.metadata) {
            childRoute = childRoute?.firstChild;
            if (!childRoute) break;
          }

          if (!childRoute) return of(true);

          let rawMetadata = data.META_DATA;
          let extraParams = {};
          const metadataKey = childRoute.data?.metadata.split('.');

          if (rawMetadata) {
            metadataKey.forEach((key: string) => {
              if (rawMetadata[key]) {
                extraParams = Object.assign(extraParams, rawMetadata[key].extraParams);
                rawMetadata = rawMetadata[key].children;
              }
            });
          }
          return extraParams;
        }),
        catchError((err) => this.router.navigateByUrl(`/`))
      );
    } else {
      return of(null);
    }
  }
}
