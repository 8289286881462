import * as IMask from 'imask';

export abstract class GeneralConstants {
  public static NON_ZERO_INTEGER = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        signed: false, // disallow negative
        thousandsSeparator: ',', // any single char
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 1,
        max: 99999,
      },
    },
  };
  public static NON_ZERO_INTEGEROVER = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        signed: false, // disallow negative
        thousandsSeparator: ',', // any single char
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 1,
        max: 99999999999999,
      },
    },
  };

  public static INTEGER = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        signed: false, // disallow negative
        thousandsSeparator: ',', // any single char
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 0,
        max: 99999999999999,
      },
    },
  };
  public static ONLYTWODOTS = {
    mask:  /^\d+(\.\d{0,2})?$/,
  }
  

  public static CONTRACT_NO = {
    mask: /^[A-Za-z0-9]+$/,
  };
  public static CARPALTENUMBER_NO = {
    mask: /^[ABCDEFGHJKLMNPR-Zabcdefghjklmnpr-z0-9]+$/,
  };

  public static ADJUSTMENT_POINT = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        signed: true, // disallow negative
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        thousandsSeparator: ',', // any single char
        // min: 1,
      },
    },
  };

  public static ALL_INTEGER = {
    mask: [
      {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            signed: true, // disallow negative
            radix: '.', // fractional delimiter
            mapToRadix: ['.'], // symbols to process as radix
          },
        },
      },
    ],
  };

  public static QUANTITY = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        signed: false, // disallow negative
        thousandsSeparator: ',', // any single char
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 1,
        max: 99999,
      },
    },
    parse(value: any) {
      return Number.parseInt(value, 10);
    },
  };
  public static QUANTITY_WITHOT_SEPARATOR = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        signed: false, // disallow negative
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 1,
        max: 9999999999,
      },
    },
    parse(value: any) {
      return Number.parseInt(value, 10);
    },
  };

  public static QUANTITY_WITHOUT_SEPARATOR_MIN_ZERO = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        signed: false, // disallow negative
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 0,
        max: 9999999999,
      },
    },
    parse(value: any) {
      return Number.parseInt(value, 10);
    },
  };

  public static FLOAT = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        signed: false, // disallow negative
        thousandsSeparator: ',', // any single char
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 0,
        max: 99999999999999,
      },
    },
  };

  public static NON_ZERO_FLOAT = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        signed: false, // disallow negative
        thousandsSeparator: ',', // any single char
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 1,
        max: 99999999999999,
      },
    },
  };

  public static TEN_DIGIT_UNSIGNED_INTEGER = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        signed: false, // allow negative
        thousandsSeparator: ',', // any single char
        min: 0,
        max: 9999999999,
      },
    },
  };

  public static NON_ZERO_TEN_DIGIT_UNSIGNED_INTEGER = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        signed: false, // allow negative
        thousandsSeparator: ',', // any single char
        min: 1,
        max: 9999999999,
      },
    },
  };

  public static TEN_DIGIT_INTEGER = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        signed: true, // allow negative
        thousandsSeparator: ',', // any single char
        min: -999999999,
        max: 9999999999,
      },
    },
  };

  public static PROMO_TITLE = {
    mask: /^[a-z0-9\-]+$/,
  };

  public static POINT_RANGE = {
    mask: 'fr\\om FROM t\\o TO',
    autofix: false,
    lazy: true,
    overwrite: true,
    placeholderChar: ' ',

    blocks: {
      FROM: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ',', // any single char
            signed: true, // disallow negative
            radix: '.', // fractional delimiter
            mapToRadix: ['.'], // symbols to process as radix
            min: 0,
            max: 9999999,
          },
        },
      },

      TO: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ',', // any single char
            signed: true, // disallow negative
            radix: '.', // fractional delimiter
            mapToRadix: ['.'], // symbols to process as radix
            min: 0,
            max: 9999999,
          },
        },
      },
    },
    parse(value: any) {
      return `-${value}`;
    },
  };

  public static RECEIPT_AMOUNT = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        signed: false, // disallow negative
        thousandsSeparator: ',', // any single char
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 1,
      },
    },
  };
  public static MAX_VALUE = (max: number) => {
    return {
      mask: 'num',
      blocks: {
        num: {
          mask: Number,
          scale: 0,
          signed: false, // disallow negative
          thousandsSeparator: ',', // any single char
          radix: '.', // fractional delimiter
          mapToRadix: ['.'], // symbols to process as radix
          min: 1,
          max: max
        },
      },
    };
  };
  public static GIFT_FACE_VALUE = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        signed: false, // disallow negative
        thousandsSeparator: ',', // any single char
        radix: '.', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        min: 1,
        max: 9999999999,
      },
    },
  };

  public static ALPHABETIC = {
    mask: /^[A-Za-z\s]+$/,
  };

  public static CALCULATION_MASK = {
    // mask: /^[\d{}+.]+$/
    mask: (value: string) => {
      const mask = /^[-?\d{}+.]+$/;
      if (mask.test(value)) {
        const amounts: string[] = value.match(/-?\d+(\.\d+)?/g);
        if (amounts) {
          for (const amount of amounts) {
            const numericAmount = Number(amount);
            const countDecimal = Math.floor(numericAmount) === numericAmount ? 0 : numericAmount.toString().split('.')[1].length || 0;
            if (countDecimal > 2) {
              return false;
            }
          }
        }
        return true;
      }
      return false;
    },
  };

  public static POSITIVE_CALCULATION_MASK = {
    // mask: /^[\d{}+.]+$/
    mask: (value: string) => {
      const mask = /^[\d{}+.]+$/;
      if (mask.test(value)) {
        const amounts: string[] = value.match(/\d+(\.\d+)?/g);
        for (const amount of amounts) {
          const numericAmount = Number(amount);
          const countDecimal = Math.floor(numericAmount) === numericAmount ? 0 : numericAmount.toString().split('.')[1].length || 0;
          if (countDecimal > 2) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
  };

  public static MAX_LENGTH = (maxLength: number) => {
    return {
      mask: (value: string) => {
        return value.length <= maxLength;
      },
    };
  };

  public static CODE = (maxLength = 2) => {
    return {
      mask: new RegExp(`^[a-zA-Z0-9\-]{0,${maxLength}}$`),
    };
  };

  public static EMAIL_DOMAIN = {
    mask: /[0-9a-zA-Z\.]/,
  };
  public static ID_WIHT_NUMBER = {
    mask: /^[0-9]+$/,
  };
  public static REF_NUMBER = {
    mask: /^[0-9a-zA-Z\W]*$/,
    prepare: (value:any) => {
      return value.replace(/[,，\u4e00-\u9fa5]/g, '');
    }
  };
  public static TIME_RANGE = {
    mask: 'HH:MM - HH1:MM1',
    lazy: false, // make placeholder always visible

    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
      },

      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
      HH1: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
      },

      MM1: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
    },
  };
  public static MEMBER_ID_INPUT = {
    mask: /[0-9]{1,8}(-\d)?/,
  };
  public static PERCENTAGE_INPUT = {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        signed: false, // allow negative
        thousandsSeparator: ',', // any single char
        min: 1,
        max: 100,
        radix: '.',
      },
    },
  };
}
