import { Pipe, PipeTransform } from '@angular/core';
import { TransactionConstants } from '@app/@shared/components/receipt-transaction/receipt-transaction.constant';

@Pipe({
  name: 'receiptTransactionStatus',
})
export class ReceiptTransactionStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case TransactionConstants.STATUS_SUBMITTED:
        return 'RECEIPT_TRANSACTIONS.LABEL.STATUS_SUBMITTED';
      case TransactionConstants.STATUS_NOT_ACTIVATED:
        return 'RECEIPT_TRANSACTIONS.LABEL.STATUS_NOT_ACTIVATED';
      case TransactionConstants.STATUS_EXPIRED:
        return 'RECEIPT_TRANSACTIONS.LABEL.STATUS_EXPIRED';
      case TransactionConstants.STATUS_APPROVED:
        return 'RECEIPT_TRANSACTIONS.LABEL.STATUS_APPROVED';
      case TransactionConstants.STATUS_PENDING_FOR_APPROVAL:
        return 'RECEIPT_TRANSACTIONS.LABEL.STATUS_PENDING_FOR_APPROVAL';
      case TransactionConstants.STATUS_REJECTED:
        return 'RECEIPT_TRANSACTIONS.LABEL.STATUS_REJECTED';
      case TransactionConstants.STATUS_VOIDED:
        return 'RECEIPT_TRANSACTIONS.LABEL.STATUS_VOIDED';
      case TransactionConstants.STATUS_LOCKED:
        return 'RECEIPT_TRANSACTIONS.LABEL.STATUS_LOCKED';
      default:
        return value;
    }
  }
}
