<!-- <div class="upload-block" (dragover)="ondragover($event)" (drop)="ondrop($event)" (dragleave)="ondragleave($event)"> -->
<div class="upload-block">
  <div class="upload-block-inner row no-gutters" [ngClass]="{ dragover: isDragover && !disabled }">
    <div class="file-info-wrapper" [hidden]="isDragover">
      <div *ngIf="files.new || files.existing || files.error" class="col-auto">
        <img class="logo-thumbnail" [src]="newFileBlob || files.existing?.fileUrl" alt="logo thumbnail" />
      </div>
      <div *ngIf="files.new || files.existing || files.error" class="col">
        <div>{{ files.new ? files.new.fileName : files.error ? files.error.fileName : files.existing.fileName + '.' + files.existing.fileType }}</div>
        <div class="file-size">{{ files.new ? calculateSizeDisplay(files.new.file.size) : files.error ? calculateSizeDisplay(files.error.file.size) : calculateSizeDisplay(files.existing.fileSize) }}</div>
        <div class="error-msg" *ngIf="files.error && !isFileTypeValid">Invalid File Type</div>
        <div class="error-msg" *ngIf="files.error && !isFileSizeValid">File Size too big (> {{ calculateSizeDisplay(allowedSize) }})</div>
      </div>
      <div [hidden]="disabled || files.new || files.existing || files.error" class="col">
        <button mat-stroked-button [disabled]="disabled" (click)="fileInput.click()">{{ uploadButtonLabel }}{{ requiredUpload ? '*' : '' }}</button>
        <input #fileInput type="file" [accept]="acceptedFileTypesString" [hidden]="true" [disabled]="disabled" (change)="onFileChange($event)" />
      </div>
      <div *ngIf="!disabled && (files.new || files.error)" class="col-auto">
        <mat-icon (click)="clearSelection()">clear</mat-icon>
      </div>
      <div *ngIf="!disabled && files.existing && !files.error" class="col-auto">
        <mat-icon (click)="markDelete()">clear</mat-icon>
      </div>
      <div *ngIf="!disabled && files.toBeDeleted && !files.new && !files.error" class="col-auto">
        <mat-icon (click)="clearSelection('undo')">undo</mat-icon>
      </div>
    </div>
    <span [hidden]="!isDragover">Drag & drop file here to upload</span>
  </div>
</div>
