import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject } from 'rxjs';

/**
 * @description
 * Always focus the first text field in the host container after view init
 * @param autoFocus specify a field element to auto focus
 * @param triggeredBy observable to trigger auto focus
 */
@Directive({
  selector: '[autoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  private static readonly FIRST_INPUT_SELECTOR: string = [
    ...['text', 'email', 'color', 'date', 'datetime-local', 'month', 'number', 'password', 'search', 'tel', 'time', 'url', 'week'].map((elSelector) => `input[type="${elSelector}"]`),
    'input:not([type])',
    'textarea',
  ]
    .map((selector) => `${selector}:not([disabled]):not([hidden]):not([readonly])`)
    .join(',');
  afterViewInitSubject: Subject<null> = new Subject<null>();
  @Input() autoFocus: ElementRef;
  @Input() focusOn: Observable<null> = this.afterViewInitSubject;
  @Input() isMobileAutoFocus: boolean;

  constructor(private hostElementRef: ElementRef, private deviceDetectorService: DeviceDetectorService) {
    this.focusOn?.subscribe(this.performAutoFocus.bind(this));
  }

  get parentElement() {
    return this.hostElementRef.nativeElement.parentElement;
  }

  ngAfterViewInit() {
    this.afterViewInitSubject.next(null);
  }

  performAutoFocus(): void {
    if ((this.deviceDetectorService.isTablet() || !this.deviceDetectorService.isDesktop()) && !this.isMobileAutoFocus) return;

    const focusElement = this.autoFocus?.nativeElement || this.parentElement.querySelector(AutoFocusDirective.FIRST_INPUT_SELECTOR);

    focusElement?.focus({
      preventScroll: true,
    });
    focusElement?.click();
  }
}
