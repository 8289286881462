import { MenuSection } from '@core/models/application/menu-item';
import { environment } from '@env/environment';
import { PermissionResources } from '@shared/permission-resources';

const PERMISSION = PermissionResources;

export const ADMIN_MENU: MenuSection[] = [
  {
    sectionLabelId: '',
    isShowSectionLabel: false,
    children: [
      {
        labelId: 'MENU.LABEL.HOME',
        route: 'home',
        iconName: 'dashboard',
      },
      {
        labelId: 'MENU.REQUEST.LABEL.LIST',
        route: 'workflow/request',
        iconName: 'inbox',
        isBadgeVisible: true,
        resource: PERMISSION.LOYALTY_WORKFLOW_REQUEST_INBOX_PAGE,
      },
      {
        labelId: 'Programme',
        route: ['loyalty/overview', 'loyalty'],
        iconName: 'error',
        children: [
          {
            labelId: 'Overview',
            route: 'loyalty/overview',
            iconName: '',
            resource: PERMISSION.LOYALTY_PROGRAMME_OVERVIEW,
          },
          {
            labelId: 'Tier & Tier Rules',
            route: 'loyalty/tier',
            iconName: '',
            resource: PERMISSION.LOYALTY_VIEW_TIER,
          },
        ],
      },
      {
        labelId: 'MENU.LABEL.MEMBER_PROFILE',
        iconName: 'account_box',
        route: ['members'],
        children: [
          {
            labelId: 'Member List',
            route: 'members',
            iconName: '',
            resource: PERMISSION.LOYALTY_MEMBER_LIST_MEMBER_PAGE,
          },
          {
            labelId: 'Create Member',
            route: 'members/create',
            iconName: '',
            resource: PERMISSION.LOYALTY_MEMBER_CREATE_MEMBER_PAGE,
            // action: () => {window.open(environment.MEMBER_REGISTRATION_LINK);}
          },
          {
            labelId: 'Invitation Links',
            route: 'members/invitation-link',
            iconName: '',
            resource: PERMISSION.LOYALTY_MEMBER_INVITATION_LINK_PAGE,
          },
        ],
      },
      {
        labelId: 'Campaign',
        iconName: 'loyalty',
        route: ['campaign'],
        isDisableClick: true,
        children: [
          {
            labelId: 'MENU.CAMPAIGN.LABEL.LIST',
            route: 'campaign',
            iconName: '',
            resource: PERMISSION.LOYALTY_CAMPAIGN_LIST_PAGE,
          },
        ],
      },
      {
        labelId: 'MENU.TRANSACTION.LABEL.LIST',
        route: ['receipt-transaction/spotcheck','receipt-transaction'],
        iconName: 'receipt',
        children:[
          {
            labelId: 'Receipt Approval',
            route: 'receipt-transaction',
            iconName: '',
            resource: PERMISSION.LOYALTY_TRANSACTION_LIST_PAGE,
          },
          {
            labelId: 'Receipt Spot Check',
            route: 'receipt-transaction/spotcheck',
            iconName: '',
            resource: PERMISSION.LOYALTY_RECEIPT_SPOT_CHECK,
          },
        ]
      },
      {
        labelId: 'MENU.REIMBURSEMENT.LABEL.LIST',
        route: 'reimbursement',
        iconName: 'attach_money',
        resource: PERMISSION.LOYALTY_REIMBURSEMENT_LIST_PAGE,
      },
      {
        labelId: 'MENU.OTP.LABEL.OTP',
        route: 'otp',
        iconName: 'device_unknown',
        resource: PERMISSION.OTP_VIEW_PAGE,
      },
    ],
  },
  {
    sectionLabelId: '{{BUSINESS_UNIT_NAME}} Configuration',
    isShowSectionLabel: true,
    children: [
      {
        labelId: 'Business Unit Settings',
        iconName: 'shopping_bag',
        isDisableClick: true,
        route: ['tenant', 'partner', 'currency-conversion', 'loyalty/loyalty-programme', 'gift'],
        children: [
          {
            labelId: 'MENU.LABEL.TENANT',
            route: 'tenant',
            iconName: '',
            resource: PERMISSION.LOYALTY_TENANT_LIST_PAGE,
          },
          {
            labelId: 'MENU.LABEL.PARTNERS',
            route: 'partner',
            iconName: '',
            resource: PERMISSION.LOYALTY_PARTNER_LIST_PAGE,
          },
          {
            labelId: 'MENU.LABEL.CURRENCY_CONVERSION',
            route: 'currency-conversion',
            iconName: '',
            resource: PERMISSION.LOYALTY_CURRENCY_CONVERSION_RATE_LIST_PAGE,
          },
          {
            labelId: 'MENU.LABEL.GIFT',
            route: 'gift',
            iconName: '',
            resource: PERMISSION.LOYALTY_GIFT_LIST_PAGE,
          },
          {
            labelId: 'MENU.LABEL.OFFICE_WORKER_WHITELIST_DOMAIN_MAINTENANCE',
            route: 'white-list',
            iconName: '',
            resource: PERMISSION.LOYALTY_WHITE_LIST_PAGE,
          },
          {
            labelId: 'MENU.LABEL.REIMBURESEMENT_ACCOUNT_CODE_MANAGEMENT',
            route: 'account',
            iconName: '',
            resource: PERMISSION.LOYALTY_ACCOUNT_PAGE, // 权限
          },
          
        ],
      },
      {
        labelId: 'Reports',
        route: 'report',
        iconName: 'backup_table',
        resource: PERMISSION.LOYALTY_REPORT_LIST_PAGE,
      },
      {
        labelId: 'Approval Matrix Review',
        route: 'approval-matrix-review',
        iconName: 'dvr',
        resource: PERMISSION.APPROVAL_MATRIX_REVIEW,
      },
    ],
  },
];

export const CONFIG_MENU: MenuSection[] = [
  {
    sectionLabelId: 'Administration',
    isShowSectionLabel: true,
    children: [
      // {
      //   labelId: 'Tenant Category',
      //   iconName: 'shopping_bag',
      //   isDisableClick: true,
      //   route: 'tenant-category',
      // },
      {
        labelId: 'Business Unit',
        iconName: 'dashboard',
        route: 'business-unit/list',
        isDisableClick: false,
      },
      {
        labelId: 'MENU.LOYALTY_PROGRAM.LABEL.PROGRAMME',
        iconName: 'card_giftcard',
        route: 'programme/list',
        isDisableClick: false,
      },
      {
        labelId: 'MENU.LABEL.USER_ROLE',
        iconName: 'supervised_user_circle',
        route: ['user-role', 'approval-matrix'],
        isDisableClick: true,
        children: [
          {
            labelId: 'MENU.LABEL.USER',
            route: 'user-role/user/list',
            iconName: '',
          },
          {
            labelId: 'MENU.LABEL.ROLE',
            route: 'user-role/role/list',
            iconName: '',
          },
          {
            labelId: 'MENU.LABEL.APPROVAL_MATRIX',
            route: 'approval-matrix/edit',
            iconName: '',
          },
        ],
      },
    ],
  },
];
