import { AfterViewInit, Directive, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { NgResizeObserver, ngResizeObserverProviders } from 'ng-resize-observer';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { untilDestroyed } from '@app/@core';

// declare var ResizeObserver;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'dx-data-grid',
  providers: [ngResizeObserverProviders],
})
export class DatagridAutoResizeContainerDirective implements OnInit, OnDestroy {
  containerSize$ = this.resize$.pipe(
    debounceTime(500),
    map((entry: any) => entry)
  );

  constructor(private datagrid: DxDataGridComponent, private resize$: NgResizeObserver) {}

  ngOnInit() {
    this.containerSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.datagrid?.instance?.updateDimensions();
    });
  }

  ngOnDestroy() {}
}
