import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject, Subject, merge } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  ApiResponse,
  ParkingAvailability,
  ReceiptSelections,
  RedeemReservedECResponse,
  RedemptionActivity,
  RedemptionAvailabilityItem,
  RedemptionItem,
  RedemptionListItem,
  RedemptionRequest,
  ParkingRedeemAndActualizeRequest,
  RedemptionParkingRequest,
  ParkingPopUpRedemptionHistory,
  OutstandingPamentReference,
} from '@core/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { differenceInDays, parseISO, startOfDay } from 'date-fns';
import { AnyAaaaRecord, AnyARecord } from 'dns';
import { OutstandingPaymentReferenceDialogComponent } from '@app/pages/members/view-member/carpark/redemption-and-actualization-detail-dialog/outstanding-payment-reference-dialog/outstanding-payment-reference-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class RedemptionService {
  public openRedemptionDetailSubject: Subject<any> = new Subject<any>();
  myWalletCount = 0;
  giftUuidSubject = new BehaviorSubject<string>('');
  // redemptionListCount$ = (guestId: string, params?: any): Observable<number> => {
  //   return merge(this.getActualizableRedemptionListCount(guestId, params), this.updateMyWalletCountSubject.asObservable());
  // };
  giftSubject = new BehaviorSubject<{ giftUuid: string; guestGift: any }>(null);
  defaultHeaders = {
    'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
  };
  defaultHeadersPost = {
    'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  promotionTypes = {
    REDEMPTION_BY_LOYALTY_CURRENCY: 'REDEMPTION_BY_LOYALTY_CURRENCY',
    YEAR_ROUND_OFFER: 'YEAR_ROUND_OFFER',
    REWARD_BY_SPENDING: 'REWARD_BY_SPENDING',
    BENEFITS_AND_PRIVILEGES: 'BENEFITS_AND_PRIVILEGES',
    FREE_PARKING: 'FREE_PARKING',
  };
  transactionId: number = null;

  constructor(private httpClient: HttpClient) {}

  getRedemptionList(guestId: string, params?: any): Observable<RedemptionListItem[]> {
    return this.httpClient
      .get(`/campaign/promotion/redemption/list`, {
        headers: this.defaultHeaders,
        params: {
          ...params,
          guestId,
          expiredGift: true,
        },
      })
      .pipe(map((res: ApiResponse) => res.data)) as Observable<RedemptionListItem[]>;
  }

  getReservedEC(guestId: string, params?: any): Observable<RedemptionAvailabilityItem[]> {
    return this.httpClient
      .get(`/campaign/promotion/redemption/reservation/list`, {
        headers: this.defaultHeaders,
        params: {
          ...params,
          guestId,
        },
      })
      .pipe(map((res: ApiResponse) => res.data)) as Observable<RedemptionAvailabilityItem[]>;
  }

  redeemReservedEC(guestEarningCriteriaId: string) {
    return this.httpClient.post(`/campaign/promotion/redemption/reservation/redeem?guestEarningCriteriaId=${guestEarningCriteriaId}`, {}).pipe(map((res: ApiResponse) => res.data)) as Observable<RedeemReservedECResponse>;
  }

  refreshActualizableRedemptionListCount(guestId: string, params?: any) {
    this.getRedemptionList(guestId, params)
      .pipe(
        map((items) => {
          return items.filter((item) => {
            return (
              item.actualizableInCounter &&
              ((differenceInDays(startOfDay(new Date()), startOfDay(parseISO(item.validityStartDate))) > -1 && differenceInDays(startOfDay(new Date()), startOfDay(parseISO(item.expiryDate))) < 1) ||
                (!item.validityStartDate && differenceInDays(startOfDay(new Date()), startOfDay(parseISO(item.expiryDate))) < 1))
            );
          }).length;
        })
      )
      .subscribe((count) => (this.myWalletCount = count));
  }

  redeemSetOfGifts(body: RedemptionRequest): Observable<any[]> {
    return this.httpClient
      .post(`/campaign/promotion/redemption/redeem`, body, {
        headers: this.defaultHeaders,
      })
      .pipe(map((res: ApiResponse) => res.data)) as Observable<any[]>;
  }

  previewRedemption(body: RedemptionRequest): Observable<any[]> {
    return this.httpClient
      .post(`/campaign/promotion/redemption/previewRedemption`, body, {
        headers: this.defaultHeaders,
      })
      .pipe(map((res: ApiResponse) => res.data)) as Observable<any[]>;
  }

  getReceiptSelections(memberId: string, earningCriteriaId: number, params: { [props: string]: any } = {}): Observable<ReceiptSelections> {
    return this.httpClient
      .get(`/campaign/promotion/redemption/rewardBySpending/receiptSelections`, {
        headers: {
          'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
        },
        params: {
          memberId,
          earningCriteriaId: String(earningCriteriaId),
          ...params,
        },
      })
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      ) as Observable<ReceiptSelections>;
  }

  actualizeGift(actualizationRequest: { guestGiftWithSerialNumber: {}; qrCode: string; actualizationDateTime?: any } | null) {
    return this.httpClient
      .post(`/campaign/promotion/redemption/actualize`, actualizationRequest, {
        headers: {
          'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
        },
      })
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      ) as Observable<any>;
  }

  redeemGift(params?: any) {
    return this.httpClient
      .post(`/campaign/promotion/redemption/redeem`, params, {
        headers: {
          'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
        },
      })
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      ) as Observable<any>;
  }
  bnpRedeem(params: any) {
    return this.httpClient
      .post(`/campaign/promotion/redemption/bNp/redeem`, params, {
        headers: {
          'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
        },
      })
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      ) as Observable<any>;
  }

  getRedemptionActivities(guestId: string, params?: object): Observable<RedemptionActivity[]> {
    return this.httpClient
      .get(`/campaign/promotion/redemption/redemptionActivities`, {
        headers: this.defaultHeaders,
        params: {
          guestId,
          ...params,
        },
      })
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      );
  }
  parkingRedeemAndActualize(guestId: string, params: RedemptionParkingRequest): Observable<any> {
    return this.httpClient
      .post(`/campaign/promotion/redemption/parkingRedeemAndActualize?guestId=${guestId}`, params, {
        // headers: this.defaultHeaders,
      })
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      ) as Observable<any>;
  }

  retrieveActualizeQrCodeData(params?: any) {
    return this.httpClient
      .get('/codeScan/retrieveQrCodeActionByUuid', {
        headers: {
          'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
        },
        params,
      })
      .pipe(
        map((response: ApiResponse) => {
          return response;
        })
      );
  }

  getRedemptionDetailsByGuestEarningCriteriaId(guestEarningCriteriaId: number | string) {
    return this.httpClient.get(`/campaign/promotion/redemption/redemptionDetails/${guestEarningCriteriaId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  updateRedemptionDate(id: number | string, params: any) {
    return this.httpClient.put(`/campaign/promotion/redemption/redemptionDetails/${id}`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  updateRedemptionActualizedLocation(params: any): Observable<any> {
    return this.httpClient.put(`/campaign/promotion/redemption/updateActualizedLocation`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getActualizationDetailsByGuestEarningCriteriaId(guestGiftId: number | string, actualizationTransactionNumber: string) {
    return this.httpClient.get(`/campaign/promotion/redemption/actualizationDetails/${actualizationTransactionNumber}/${guestGiftId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  updatActualizationDate(guestGiftId: number | string, params: any) {
    return this.httpClient.put(`/campaign/promotion/redemption/actualizationDetails/${guestGiftId}`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  voidGuestEarningCriteriaByPromotionType(params: any): Observable<any> {
    return this.httpClient
      .put(
        `/campaign/promotion/redemption/redemption/void`,
        {},
        {
          headers: {
            'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
          },
          params,
        }
      )
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      );
  }

  voidActualization(params: any) {
    return this.httpClient
      .put(
        `/campaign/promotion/redemptions/void`,
        {},
        {
          headers: {
            'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
          },
          params,
        }
      )
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      );
  }

  /**
   * Fetch redemption by point gift
   */
  getRedemptionByPointAvailabilityList(guestId: string, params?: any): Observable<RedemptionAvailabilityItem[]> {
    return this.getGiftsByPromotionType(guestId, this.promotionTypes.REDEMPTION_BY_LOYALTY_CURRENCY, params);
  }

  getRewardBySpendingAvailabilityList(guestId: string, params?: any): Observable<RedemptionAvailabilityItem[]> {
    return this.getGiftsByPromotionType(guestId, this.promotionTypes.REWARD_BY_SPENDING, params);
  }

  getYearRoundOfferAvailabilityList(guestId: string, params?: any): Observable<any[]> {
    return this.getGiftsByPromotionType(guestId, this.promotionTypes.YEAR_ROUND_OFFER, params);
  }

  getBenefitsAndPrivileges(guestId: string, params?: any): Observable<any[]> {
    return this.getGiftsByPromotionType(guestId, this.promotionTypes.BENEFITS_AND_PRIVILEGES, params);
  }
  getFreeParkingGiftAvailabilityList(guestId: string, params?: any): Observable<RedemptionAvailabilityItem[]> {
    return this.getGiftsByPromotionType(guestId, this.promotionTypes.FREE_PARKING, params);
  }
  isEligibleForRewardBySpending(memberId: string): Observable<boolean> {
    return this.getRewardBySpendingAvailabilityList(memberId, { mode: 'ONLYELIGIBLE' }).pipe(map((redemptionItems: RedemptionAvailabilityItem[]) => redemptionItems.length > 0));
  }
  getEarliestActivePromotionForRewardBySpending() {
    return this.httpClient
      .get(`/campaign/promotion/earliestActivePromotion?promotionType=REWARD_BY_SPENDING`, {
        headers: this.defaultHeaders,
      })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        })
      );
  }

  getRedeemedGiftFullNames(guestId: string) {
    return this.httpClient
      .get(`/campaign/promotion/redemption/redeemedGiftFullNames`, {
        headers: this.defaultHeaders,
        params: { guestId },
      })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        })
      );
  }
  getRedeemedGiftType(guestId: string): Observable<string[]> {
    return this.httpClient
      .get(`/campaign/promotion/redemption/redeemedGiftTypes`, {
        headers: this.defaultHeaders,
        params: { guestId },
      })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        })
      );
  }
  getRedeemedPromotionNames(guestId: string) {
    return this.httpClient
      .get(`/campaign/promotion/redemption/redeemedPromotionNames`, {
        headers: this.defaultHeaders,
        params: { guestId },
      })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        })
      );
  }
  getParkingAvailablility(params: any): Observable<ParkingAvailability> {
    return this.httpClient.get(`/campaign/promotion/redemption/getParkingAvailability`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }
  getValetParkingAvailablility(guestId: string): Observable<ParkingAvailability> {
    return this.httpClient
      .get(`/valetParking/redemption/availability`, {
        headers: this.defaultHeaders,
        params: { guestId },
      })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        })
      );
  }
  getParkingPopUpRedemptionHistory(guestId: string, cpsRef: string): Observable<ParkingPopUpRedemptionHistory[]> {
    return this.httpClient
      .get(`/campaign/promotion/redemption/getParkingPopUpRedemptionHistory`, {
        headers: this.defaultHeaders,
        params: { guestId, cpsRef },
      })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        })
      );
  }

  getValetParkingRedemptionHistory(guestId: string, vpsRef: string): Observable<ParkingPopUpRedemptionHistory[]> {
    return this.httpClient
      .get(`/valetParking/valetParkingRedemptionHistory`, {
        headers: this.defaultHeaders,
        params: { guestId, vpsRef },
      })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        })
      );
  }

  addOutStandingPaymentReference(params: any): Observable<any> {
    let body: HttpParams = new HttpParams();
    body = body.append('paymentRemarks', params.paymentRemarks);
    body = body.append('txnReference', params.txnReference);
    return this.httpClient
      .post(`/campaign/promotion/redemption/parkingAddRemarks`, body, {
        headers: this.defaultHeaders,
      })
      .pipe(map((res: ApiResponse) => res.data)) as Observable<any[]>;
  }
  updateOutStandingPaymentReference(params: any): Observable<any> {
    let body: HttpParams = new HttpParams();
    body = body.append('paymentRemarks', params.paymentRemarks);
    body = body.append('txnReference', params.txnReference);
    return this.httpClient
      .post(`/campaign/promotion/redemption/parkingUpdateRemarks`, body, {
        headers: this.defaultHeaders,
      })
      .pipe(map((res: ApiResponse) => res.data)) as Observable<any[]>;
  }

  setGiftUuid(uuid: string) {
    this.giftUuidSubject.next(uuid);
  }

  setGift(giftInfo: { giftUuid: string; guestGift: any }) {
    this.giftSubject.next(giftInfo);
  }

  clearGiftUuid() {
    this.giftUuidSubject.next('');
  }

  clearGift() {
    this.giftSubject.next(null);
  }

  // updateMyWalletCount(count: number) {
  //   this.myWalletCount = count;
  // }

  get giftUuid() {
    const uuid = this.giftUuidSubject.value;
    return uuid;
  }

  get gift() {
    const gift = this.giftSubject.value;
    return gift;
  }

  /**
   * Get gifts item by promotion types (e.g. Year round offer, Welcome offer etc.)
   * @param guestId - Guest Id
   * @param params - search params
   */
  private getGiftsByPromotionType(guestId: string, promotionType: string, params?: any): Observable<RedemptionAvailabilityItem[]> {
    return this.httpClient
      .get(`/campaign/promotion/redemption/availabilityList`, {
        headers: this.defaultHeaders,
        params: {
          ...params,
          guestId,
          promotionType,
        },
      })
      .pipe(map((res: ApiResponse) => res.data));
  }

  settlePaymentByCash(params: any): Observable<any> {
    return this.httpClient.post(`/valetParking/payment/cash`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  settleValetParkingPayment(params: any): Observable<any> {
    return this.httpClient.post(`/valetParking/payment`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  settlePayment(params: any): Observable<any> {
    return this.httpClient.post(`/valetParking/settlement`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  valetParkingRedeemAndActualize(params: any): Observable<any> {
    return this.httpClient.post(`/valetParking/redeemAndActualize`, params, {}).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  actualizeEvoucherGift(params: any): Observable<any> {
    return this.httpClient.post(`/campaign/promotion/redemption/eVoucher/csPortal/actualize`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }
  getTransactionHistory(params: any) {
    return this.httpClient
      .get(`/campaign/promotion/redemption/redemption/history`, {
        headers: this.defaultHeaders,
        params,
      })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        })
      );
  }

  getTimeslots(params: any) {
    return this.httpClient
      .get(`/campaign/gift/timeslots`, {
        headers: this.defaultHeaders,
        params,
      })
      .pipe(
        map((res: ApiResponse) => {
          return res.data;
        })
      );
  }
}
