<div class="compare-rpt-receipts">
    <div class="dialog-container">
        <h1>Information inputted not match with Payment with Payment Terminal</h1>
        <div class="row">
            <div class="col-6">
                <div class="payment-terminal-record">
                    <h2>Payment Terminal Record</h2>
                    <div>
                    <form [formGroup]="paymentFormGroup">
                        <formly-form [options]="options" [model]="formParams" [fields]="filterFields" [form]="paymentFormGroup"></formly-form>
                        </form>
                    </div>
                </div>
            </div>  
            <div class="col-6">
                <div class="rpt-current-transaction">
                    <h2>Current Transaction</h2>
                    <div>
                    <form [formGroup]="currentFormGroup">
                        <formly-form [options]="currentOptions" [model]="currentFormParams" [fields]="currentFilterFields" [form]="currentFormGroup"></formly-form>
                        </form>
                    </div>
                </div>
            </div>  
        </div>
    </div>
    <div class="dialog-stick-bottom-bar">
        <div class="d-flex justify-content-end button-container no-right-padding box">
          <div class="align-right">
            <div class="d-flex">
              <div class="ml-auto button-container">
                <c-form-button [label]="'Cancel'" (click)="dialogRef.close()" [upperCaseLabel]="true" color="basic" class="grey"> </c-form-button>
                <button mat-stroked-button 
                color="primary" class="stroked-btn"
                (click)="data.noCallback(dialogRef)"
                >Remain & Save</button>
                <c-form-button
                  (click)="data.yesCallback(dialogRef)"
                  color="primary"
                  label="	
                  Replace by Payment Terminal info"
                  [upperCaseLabel]="false"
                >
                </c-form-button>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>