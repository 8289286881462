<carousel [height]="225" [cellWidth]="170" [margin]="15">
  <ng-container *ngIf="transformedImages.length">
  <div *ngFor="let image of transformedImages; let i = index" class="thumbnail-card carousel-cell" (click)="onSelectThumbnail(i)" [class.is-active-image]="image?.id === selectedImage?.id">
    <div class="fixed-image-card">
      <div>
        <img *ngIf="!isLocalImage" [src]="image.thumbnailUrl | authImage | async" alt="receipt image" />
        <img *ngIf="isLocalImage" [src]="image.thumbnailUrl" alt="receipt image" />
      </div>
    </div>
    <div class="caption-container">
      <input class="form-control caption-input" placeholder="Input captions here..." [(ngModel)]="image.caption" disabled />
    </div>
  </div>
</ng-container>
  <ng-container *ngIf="!transformedImages.length ">
    <div class="thumbnail-card carousel-cell">
      <div class="fixed-image-card fixed-image-card-noImg">
        {{isRpt?('REDEMPTION_ACTIVITIES.LABEL.RECEIPT_NO_IMG' | translate):''}}
      </div>
      <div class="caption-container">
        <input class="form-control caption-input" placeholder="Input captions here..." disabled />
      </div>
    </div>
  </ng-container>
</carousel>

<ng-template #imageViewerRef>
  <button mat-button [mat-dialog-close]="true" class="close" style="margin-right: -20px; margin-top: 5px">
    <mat-icon>close</mat-icon>
  </button>
  <div style="padding-top: 32px">
    <div style="padding-bottom: 25px">
      <strong>Uploaded Receipt</strong>
    </div>
    <div>
      <div class="row no-gutters image-action-bar">
        <div class="mr-auto">
          <button mat-raised-button class="action-button" (click)="onClickZoomIn()">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <button mat-raised-button class="action-button" (click)="onClickZoomOut()">
            <mat-icon>zoom_out</mat-icon>
          </button>
        </div>
      </div>
      <!-- Dirty solution , will move to common service -->
      <dx-gallery
        #imageSliderRef
        [dataSource]="transformedImages"
        [loop]="false"
        [showNavButtons]="true"
        [swipeEnabled]="false"
        [showIndicator]="false"
        [selectedIndex]="selectedIndex"
        (onSelectionChanged)="onCurrentViewChanged()"
      >
        <div *dxTemplate="let galleryItem of 'item'">
          <div class="image-viewer-container">
            <div *ngIf="!isLocalImage" class="primary-image-viewer">
              <pinch-zoom #pinchViewer [limit-zoom]="10" [autoHeight]="true" [disableZoomControl]="'disable'" [backgroundColor]="'#fff'">
                <img [ngStyle]="imageRotateStyle" [src]="galleryItem.originalUrl | authImage | async" alt="receipt image" />
              </pinch-zoom>
            </div>
            <div *ngIf="isLocalImage" class="primary-image-viewer">
              <pinch-zoom #pinchViewer [limit-zoom]="10" [autoHeight]="true" [disableZoomControl]="'disable'" [backgroundColor]="'#fff'">
                <img [ngStyle]="imageRotateStyle" [src]="galleryItem.originalUrl" alt="receipt image" />
              </pinch-zoom>
            </div>
          </div>
        </div>
      </dx-gallery>
      <div class="rotate-action">
        <button mat-flat-button class="rotateBtn left" style="margin-right: 22px" (click)="onClickRotateLeft()"><mat-icon>rotate_90_degrees_ccw</mat-icon><span class="rotateText">Rotate Left</span></button>
        <button mat-flat-button class="rotateBtn right" (click)="onClickRotateRight()">
          <mat-icon style="transform: scaleX(-1)">rotate_90_degrees_ccw</mat-icon>
          <span class="rotateText">Rotate Right</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
