<ng-container autoFocus>
  <div class="dialog-container">
    <div>
      <div class="header">
        <div class="row no-gutters title-div">
          <div class="col dialog-title">
            <span>Change Request</span>
          </div>
        </div>

        <div class="row no-gutters mt-3">
          <div class="col">
            <request-member-info [memberData]="memberData" [labels]="labels"></request-member-info>
          </div>
        </div>
      </div>

      <div class="input-container">
        <div class="request-form-container mt-4">
          <form [formGroup]="changeRequestForm">
            <div class="row no-gutters">
              <div class="col">
                <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
                  <mat-label>{{ 'REQUEST.LABEL.REQUEST_TYPE' | translate }}</mat-label>
                  <ng-select formControlName="requestType" ngSelectMat appendTo="body" [items]="requestTypeDropdownDataByProgram" [groupBy]="'translatedParent'" bindLabel="label" bindValue="value"></ng-select>
                </mat-form-field>
              </div>
            </div>
            <ng-container formGroupName="requestTypeFormGroup">
              <div [ngSwitch]="requestType.value">
                <ng-container *ngSwitchCase="ChangeRequestConstants.BIRTHDAY_UPDATE">
                  <div class="row" [class.mb-3]="!dayOfBirth.hasError('invalidDate')">
                    <div class="col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.DAY_OF_BIRTH' | translate }}</mat-label>
                        <mat-select formControlName="dayOfBirth">
                          <mat-option value="">-</mat-option>
                          <mat-option *ngFor="let day of dayOfMonth" [value]="day">
                            {{ day }}
                          </mat-option>
                        </mat-select>
                        <mat-hint>Original Value: {{ memberData.dayOfBirth || '-' }}</mat-hint>
                        <mat-error *ngIf="dayOfBirth.hasError('invalidDate')">Invalid date format</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.MONTH_OF_BIRTH' | translate }}</mat-label>
                        <mat-select [multiple]="false" formControlName="monthOfBirth">
                          <mat-option *ngFor="let month of months" [value]="month.value">
                            {{ month.label }}
                          </mat-option>
                        </mat-select>
                        <mat-hint>Original Value: {{ memberData.monthOfBirth ? convertMonthToLabel(memberData.dayOfBirth, memberData.monthOfBirth) : '-' }}</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row no-gutters">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <!-- * Mobile Update Fields -->
                <ng-container *ngSwitchCase="ChangeRequestConstants.MOBILE_UPDATE">
                  <div class="row">
                    <div class="col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.NEW_MOBILE_AREA_CODE' | translate }}</mat-label>
                        <mat-select formControlName="mobileNumberAreaCode">
                          <mat-option value=""> - </mat-option>
                          <mat-option *ngFor="let areaCode of dropdownData.GUEST.AREA_CODE" [value]="areaCode.value">
                            {{ areaCode.label }}
                          </mat-option>
                        </mat-select>
                        <mat-hint>Original Value: {{ memberData.mobileNumberAreaCode || '-' }}</mat-hint>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.NEW_MOBILE_NUMBER' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="mobilePhone" type="tel" input="number" [imask]="phoneMask" [unmask]="true" [attr.disabled]="isPhoneDisabled ? '' : null" />
                        <mat-hint>Original Value: {{ memberData.mobilePhone || '-' }}</mat-hint>
                        <mat-error *ngIf="mobilePhone.hasError('invalidPhoneFormat')">Invalid Format</mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row no-gutters mt-3">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <!-- * Email Update Fields -->
                <ng-container *ngSwitchCase="ChangeRequestConstants.EMAIL_UPDATE">
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.NEW_EMAIL' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="email" type="email" />
                        <mat-hint>Original Value: {{ memberData.email || '-' }}</mat-hint>
                        <mat-error *ngIf="email.hasError('invalidFormat')">{{ 'ERROR.MESSAGE.INVALID_EMAIL_FORMAT' | translate }}</mat-error>
                        <mat-error *ngIf="email.hasError('duplicated')">{{ 'ERROR.MESSAGE.EMAIL_USED' | translate }} </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row no-gutters mt-3">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <!-- * Member Status Update Fields -->
                <ng-container *ngSwitchCase="ChangeRequestConstants.MEMBER_STATUS_UPDATE">
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.NEW_MEMBER_STATUS' | translate }}</mat-label>
                        <mat-select formControlName="accountStatus">
                          <mat-option value="TERMINATED"> {{ 'MEMBER.LABEL.STATUS_TERMINATED' | translate }} </mat-option>
                        </mat-select>
                        <!-- <mat-hint>{{ (memberData.accountStatus | memberStatusFormat) || '-' }}</mat-hint> -->
                        <mat-hint>{{ (memberData.accountStatus | memberStatusFormat) || '-' }}</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row no-gutters mt-3">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <!-- * Blacklist Member Fields -->
                <ng-container *ngSwitchCase="ChangeRequestConstants.BLACKLIST_MEMBER">
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <!-- * Un-Blacklist Member Fields -->
                <ng-container *ngSwitchCase="ChangeRequestConstants.UNBLACKLIST_MEMBER">
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="ChangeRequestConstants.MANUAL_POINT_ADJUSTMENT">
                  <div class="row">
                    <div class="col-4">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.POINT_MOVEMENT_SUB_TYPE' | translate }}</mat-label>
                        <mat-select formControlName="pointTransactionType">
                          <mat-option *ngFor="let type of dropdownData.POINT_TRANSACTION.POINT_TYPE" [value]="type.value">
                            {{ type.label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-4">
                      <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
                        <mat-label>{{ 'REQUEST.LABEL.REQUEST_REASON' | translate }}</mat-label>
                        <ng-select formControlName="reasonCode" ngSelectMat appendTo="body" [items]="requestReasonByPointType" bindLabel="label" bindValue="value"> </ng-select>
                      </mat-form-field>
                    </div>

                    <div class="col-4">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.ADJUSTMENT_POINT' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="adjustmentPoint" input="number" [imask]="INTEGER_MASK" [unmask]="true" />
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row no-gutters">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="ChangeRequestConstants.REDEMPTION_POINT_EXPIRY_DATE_EXTENSION">
                  <div class="row">
                    <div class="col-5">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.NEW_EXPIRY_DATE' | translate }}</mat-label>
                        <app-masked-datepicker
                          placeholder="Expiry date (DD-MM-YYYY)"
                          formControlName="newExpiryDate"
                          [min]="momentCurrentRedemptionPointExpiryDate"
                          [matDatepickerFilter]="
                            currentProgramExpiryLogic === ChangeRequestConstants.EXPIRY_LOGIC_QUARTERLY ? changeRequestService.quarterlyDatePickerFilter : changeRequestService.monthlyMatDatepickerFilter
                          "
                        >
                        </app-masked-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="col-7">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REQUEST_REASON' | translate }}</mat-label>
                        <mat-select formControlName="reasonCode">
                          <mat-option *ngFor="let requestReason of dropdownDataByRequestType" [value]="requestReason.value">
                            {{ requestReason.label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row no-gutters">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="ChangeRequestConstants.TIER_EXPIRY_DATE_EXTENSION">
                  <div class="row">
                    <div class="col-5">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.NEW_EXPIRY_DATE' | translate }}</mat-label>
                        <app-masked-datepicker
                          placeholder="Expiry date (DD-MM-YYYY)"
                          formControlName="newExpiryDate"
                          [min]="momentCurrentTierPointExpiryDate"
                          [matDatepickerFilter]="
                            currentProgramExpiryLogic === ChangeRequestConstants.EXPIRY_LOGIC_QUARTERLY ? changeRequestService.quarterlyDatePickerFilter : changeRequestService.monthlyMatDatepickerFilter
                          "
                        >
                        </app-masked-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="col-7">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REQUEST_REASON' | translate }}</mat-label>
                        <mat-select formControlName="reasonCode">
                          <mat-option *ngFor="let requestReason of dropdownDataByRequestType" [value]="requestReason.value">
                            {{ requestReason.label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="ChangeRequestConstants.MANUAL_TIER_ADJUSTMENT">
                  <div class="row no-gutters">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.NEW_TIER' | translate }}</mat-label>
                        <mat-select formControlName="newTier">
                          <mat-option *ngFor="let tier of tierList" [value]="tier.code">
                            {{ tier.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="ChangeRequestConstants.OFFICE_WORKER_TERMINATION">
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.WORK_EMAIL' | translate }}</mat-label>
                        <mat-select formControlName="workEmail">
                          <mat-option *ngFor="let terminateOfficeWorkerEmail of terminateOfficeWorkerEmails" [value]="terminateOfficeWorkerEmail">
                            {{ terminateOfficeWorkerEmail }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="ChangeRequestConstants.OFFICE_WORKER_MANUAL_VERIFICATION">
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.OFFICE_WORKER_MANUAL_VERIFICATION_SUB_TYPE' | translate }}</mat-label>
                        <mat-select formControlName="reasonCode">
                          <mat-option *ngFor="let requestReason of dropdownDataByRequestType" [value]="requestReason.value">
                            {{ requestReason.label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="ChangeRequestConstants.OFFICE_WORKER">
                  <div class="row">
                    <div class="col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.WORK_EMAIL' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="workEmail" maxlength="500" placeholder="" />
                        <mat-error *ngIf="workEmail.hasError('invalidFormat')">{{ 'ERROR.MESSAGE.INVALID_EMAIL_FORMAT' | translate }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.COMPANY_NAME' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="companyName" maxlength="500" placeholder="" />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.OFFICE_LOCATION' | translate }}</mat-label>
                        <mat-select formControlName="officeLocation">
                          <mat-option *ngFor="let location of dropdownData?.OFFICE_WORKER.OFFICE_LOCATION" [value]="location.value">
                            {{ location.label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6" *ngIf="showOfficeLocationText">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.OFFICE_LOCATION_TEXT' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="officeLocationText" maxlength="500" placeholder="" />
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.JOB_TITLE' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="jobTitle" maxlength="500" placeholder="" />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col mb-2">
                      <span class="card-img-label">Business/ Staff Card Image</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <c-sync-form-uploader
                        #officeWorkerImage1
                        [uploadSpec]="uploadSpec"
                        uploadButtonLabel="Select Business Card Image"
                        formControlName="officeWorkerCard1"
                        [requiredUpload]="officeWorkerCard1.hasValidator(Validators.required)"
                      >
                      </c-sync-form-uploader>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <c-sync-form-uploader #officeWorkerImage2 [uploadSpec]="uploadSpec" uploadButtonLabel="Select Business Card Image" formControlName="officeWorkerCard2"> </c-sync-form-uploader>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="ChangeRequestConstants.REOMVE_CAR_PLATE">
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
                        <mat-label>Select Car Plate</mat-label>
                        <ng-select formControlName="removeCarplate" ngSelectMat appendTo="body" [items]="carPlateList" [groupBy]="'translatedParent'" bindLabel="plateNumber" bindValue="plateNumber"></ng-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'REQUEST.LABEL.REMARKS' | translate }}</mat-label>
                        <input matInput autocomplete="off" formControlName="remarks" maxlength="500" placeholder="Input Approval Comments" />
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </form>
        </div>
      </div>

      <div class="mb-2 attachment-container">
        <workflow-attachments-list (attachments)="attachmentOnChange($event)"></workflow-attachments-list>
      </div>
    </div>
  </div>

  <div class="dialog-stick-bottom-bar">
    <div class="d-flex justify-content-end button-container no-right-padding box">
      <div class="align-right">
        <div class="d-flex">
          <div class="ml-auto button-container">
            <c-form-button [disabled]="isSubmitting" label="COMMON.LABEL.CANCEL" color="basic" class="grey" [upperCaseLabel]="true" (click)="onCancelClick()"></c-form-button>
            <c-form-button label="COMMON.LABEL.SUBMIT" [upperCaseLabel]="true" (click)="onSubmitChangeClick()" [disabled]="changeRequestForm.invalid || isSubmitting" [isLoading]="isSubmitting"></c-form-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
