import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageService } from '@app/@core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { ImageInput, ImageMode } from '../model/receipt-image-processing-status';

@Component({
  selector: 'image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent {
  @ViewChild(ImageCropperComponent) cropper: ImageCropperComponent;
  public croppedImage: any = '';
  public isEditMode = true;
  public readonly: boolean;
  public rotateAngle = 0;
  public showCropper = false;
  public srcImage: File;
  public srcObject: string | ArrayBuffer;

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: ImageInput, private imageService: ImageService) {
    this.srcImage = data.image;
  }

  rotate(isRight: boolean): boolean {
    if (this.showCropper !== true) {
      return;
    }
    this.showCropper = false;
    if (isRight) {
      this.rotateAngle += 1;
    } else {
      this.rotateAngle -= 1;
    }
  }

  loadImageFailed(): void {
    console.log('Load failed');
  }

  onClickSaveImage(): void {
    this.dialogRef.close(this.imageService.decodeImageToFile(this.croppedImage));
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(): void {
    this.showCropper = true;
  }

  onClickCancelButton() {
    this.dialogRef.close();
  }

  onClickEditImage() {
    this.isEditMode = true;
  }

  onClickRetakePhotoButton() {
    this.dialogRef.close({
      retakePhoto: true,
    });
  }

  get backLabel(): string {
    return this.data.mode === ImageMode.capture ? 'Retake Photo' : 'Cancel';
  }

  get nextLabel(): string {
    return this.data.mode === ImageMode.capture ? 'Next' : 'Save';
  }
}
