<div role="group" [formGroup]="receiptAmountRangeForm" class="transaction-amount-range-input-container" [attr.aria-labelledby]="_formField?.getLabelId()">
  <input
    class="transaction-amount-input"
    formControlName="receiptAmountFrom"
    (keyup)="_handleInput(receiptAmountRangeForm.controls.receiptAmountFrom)"
    [imask]="HKD_MASK"
    [unmask]="true"
    aria-label="Receipt Amount From"
    placeholder="From"
    #receiptAmountFrom
  />
  <span class="transaction-amount-input-spacer">&ndash;</span>
  <input
    class="transaction-amount-input text-middle"
    formControlName="receiptAmountTo"
    (keyup)="_handleInput(receiptAmountRangeForm.controls.receiptAmountTo)"
    [imask]="HKD_MASK"
    [unmask]="true"
    aria-label="Receipt Amount To"
    placeholder="To"
    #receiptAmountTo
  />
</div>
