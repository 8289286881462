import { Injectable } from '@angular/core';
import { BasicFormInput } from '@core/models/application/basic-form-input';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormGeneratorService {
  constructor() {}

  generateFormGroup(fields: BasicFormInput<string>[]) {
    const group: any = {};
    fields.forEach((field) => {
      group[field.key] = field.validators ? new UntypedFormControl(field.value || '', field.validators) : new UntypedFormControl(field.value || '');
    });
    return new UntypedFormGroup(group);
  }
}
