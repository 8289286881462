import { Component, OnInit, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { MenuItem, MenuSection } from '@core/models/application/menu-item';
import { MenuService } from '@core/services/application/menu';
import { Router } from '@angular/router';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-config-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, AfterViewInit {
  @ViewChildren(MatExpansionPanel) sidenav: QueryList<MatExpansionPanel>;
  menuItemList: MenuSection[];
  isMenuOpened = true;
  constructor(private menuService: MenuService, private router: Router) {}

  ngOnInit(): void {
    this.menuItemList = this.menuService.getConfigMenuItems();
  }

  ngAfterViewInit() {
    // Expand selected item parent panel
    setTimeout(() => {
      this.sidenav.toArray().forEach((el) => {
        const panelHeader = document.getElementById(el._headerId);
        if (!el.hideToggle && panelHeader.classList.contains('is-active')) {
          el.open();
        }
      });
    }, 100);
  }
}
