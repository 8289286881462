import { ApiResponse } from '@core/models/application/api-response';
import { Gift, ReceiptCsv, RelatedPromotion, SerialNumber, GiftWithAllotment, GiftInventoryAdjustment, TempSerialNumber } from '@core/models/campaign/gift';
import { AggregatedMovement, InventoryMovement } from '@core/models/campaign/gift/inventory-movement';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, publishReplay, refCount, shareReplay } from 'rxjs/operators';
import { PaginatedResponse } from '@core/models';
import { AnimationStyleMetadata } from '@angular/animations';
import { DialogService, DialogData } from '@app/@core';
import { ErrorService } from '../application/error.service';
import { PreserveTabService } from '../application/preserve-tab.service';

interface PaginatedInventoryMovement extends PaginatedResponse {
  content: InventoryMovement[];
}
@Injectable({
  providedIn: 'root',
})
export class GiftService {
  public gifts: Observable<Gift[]> = null;
  public locations: Observable<any[]> = null;
  public actualizedLocation: string = '';
  private giftCache$: Observable<any>;

  constructor(private httpService: HttpClient, private dialog: MatDialog, private router: Router,
    private dialogService: DialogService,
    private preserveTabService: PreserveTabService
  ) {}

  getGifts(params?: any) {
    return this.httpService.get('/campaign/gift/list', { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<PaginatedResponse<Gift[]>>;
  }
  getAllGifts() {
    const params = { size: 999999, page: 0 }
    if(!this.giftCache$) {
      this.giftCache$ = this.httpService.get('/campaign/gift/list', { params }).pipe(
        map((response: ApiResponse) => {
          return response.data;
        }),
        shareReplay(1)
      ) as Observable<PaginatedResponse<Gift[]>>;
    }
    return this.giftCache$
  }
  resetGiftCache() {
    this.giftCache$ && (this.giftCache$ = null);
  }

  getGiftById(params?: any) {
    return this.httpService.get('/campaign/gift', { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<Gift>;
  }

  getGiftsWithAllotmentCount(params?: any) {
    return this.httpService.post('/campaign/gift/listWithAllotmentCount', params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<GiftWithAllotment[]>;
  }

  checkDuplicateGift(code?: any) {
    return this.httpService.get(`/campaign/gift/duplicateGiftCodes?giftCode=${code}`).pipe(
      map((response: ApiResponse) => {
        if (response.data.length === 0) {
          return true;
        } else {
          // throw new Error('Duplicated gift code');
          return false;
        }
      })
    ) as Observable<boolean>;
  }

  createGift(params?: any) {
    return this.httpService.post('/campaign/gift', params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<Gift>;
  }

  updateGift(params?: any) {
    return this.httpService.put('/campaign/gift', params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<Gift>;
  }

  deleteGift(params?: any) {
    return this.httpService.delete('/campaign/gift', { params });
  }

  uploadGiftSerialNumber(csv: ReceiptCsv, params?: any) {
    const formData = new FormData();
    formData.append('serialNumberCsv', csv.data, csv.data.name);
    return this.httpService.post(`/campaign/gift/serialNumber?giftId=${params.giftId}`, formData).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  listGiftSerialNumbers(giftId: number, params?: any) {
    return this.httpService.get(`/campaign/gift/serialNumber?giftId=${giftId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<SerialNumber[]>;
  }

  getSerialNumberHistory(giftId: number, serialNumber: string) {
    return this.httpService.get(`/campaign/gift/serialNumberHistory?giftId=${giftId}&serialNumber=${serialNumber}&order=ASC`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  downloadSerialNumberTemplate() {
    return this.httpService.get(`/campaign/gift/serialNumberTemplate`, { responseType: 'blob' as 'json' }).pipe(
      map((response: any) => {
        return response;
      })
    ) as Observable<any>;
  }
  downloadSerialNumberExcel(giftId: number) {
    return this.httpService.get(`/campaign/gift/giftSerialNumbersExcel?giftId=${giftId}`, { responseType: 'blob' as 'json' }).pipe(
      map((response: any) => {
        return response;
      })
    ) as Observable<any>;
  }

  deleteSerialNumber(giftId: number) {
    return this.httpService.delete(`/campaign/gift/serialNumber?giftId=${giftId}`).pipe(
      map((response: any) => {
        return response.data;
      })
    ) as Observable<boolean>;
  }

  obsoleteSerialNumber(params: any) {
    return this.httpService.request('delete', `/campaign/gift/serialNumber`, { body: params }).pipe(
      map((response: any) => {
        return response.data;
      })
    ) as Observable<boolean>;
  }

  retrievePreviewSerialNumber(batchId: string, params?: any) {
    return this.httpService.get(`/campaign/gift/serialNumber/temp?batchId=${batchId}`, { params }).pipe(
      map((response: any) => {
        // response.data.content.forEach((res: any) => (res.status = 'Available'));
        return response.data;
      })
    ) as Observable<TempSerialNumber[]>;
  }

  confirmUploadSerialNumber(batchId: string) {
    return this.httpService.put(`/campaign/gift/serialNumber/${batchId}`, {}).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  retrieveGiftListWithAllotmentCount(params?: any) {
    return this.httpService.post('/campaign/gift/listWithAllotmentCount', params).pipe(
      map((response: any) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  retrieveRelatedPromotions(giftId: number, params?: any) {
    return this.httpService.get(`/campaign/gift/relatedPromotions?giftId=${giftId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<RelatedPromotion[]>;
  }

  retrieveAggregatedInventoryMovement(giftId: number, params?: any) {
    return this.httpService.get(`/campaign/gift/aggregateMovement?giftId=${giftId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<AggregatedMovement>;
  }

  retrieveInventoryMovement(giftId: number, params?: any) {
    return this.httpService.get(`/campaign/gift/inventoryMovement?giftId=${giftId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<PaginatedInventoryMovement[]>;
  }

  adjustInventory(params?: any) {
    return this.httpService.post('/campaign/gift/inventoryAdjustment', params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<GiftInventoryAdjustment>;
  }

  getLocations() {
    if (!this.locations) {
      this.locations = this.httpService.get(`/campaign/gift/inventory/locations`).pipe(
        map((res: any) => {
          return res;
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.locations;
  }
  getInventoryQuantity(giftId: number) {
    return this.httpService.get(`/campaign/gift/inventoryQuantity?giftId=${giftId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }
  getSerialList(params?: any) {
    return this.httpService.get(`/campaign/gift/serialNumber/stockMovement`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  saveSerialList(params?: any) {
    return this.httpService.post('/campaign/gift/serialNumber/stockMovement', params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  getTimeSlotList(giftId: number) {
    return this.httpService.get(`/campaign/gift/timeslots?giftId=${giftId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  createTimeSlot(params?: any) {
    return this.httpService.post('/campaign/gift/timeslots', params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  updateTimeSlot(params?: any) {
    return this.httpService.put('/campaign/gift/timeslots', params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  deleteTimeSlot(id?: any) {
    return this.httpService.delete('/campaign/gift/timeslots', { params: { timeslotIds: id } }).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  generateVMGift(params?: any) {
    return this.httpService.put('/campaign/gift/vmGift/serialNumber', params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  generateEvCharger(params?: any) {
    return this.httpService.put('/campaign/gift/evCharger/serialNumber', params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  clearAllCaches() {
    this.locations = null;
  }

  getMerchantList(giftIds?: any) {
    return this.httpService.get(`/admin/merchant/actualizibleMerchant?giftIds=${giftIds}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  expiryDateListPost(params: any) {
    return this.httpService.post('/campaign/gift/expiry/date/list', params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  expiryDateListPut(params: any) {
    return this.httpService.put('/campaign/gift/extend/expiry/date', params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  expiryDateMember(params: any) {
    return this.httpService.put('/campaign/gift/expiry/date', params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  // 库存不足
  giftNotEnoughInventory(giftId:any, isRedmptionDetail?:any) {
    const dialogData: DialogData = {
      content: 'Not Enough Inventory. <p>Please add back inventory before extend gift expiry date.</p>',
      buttonTwoLabel: 'MEMBER.LABEL.EXTEND_GIFT_EXPIRY_GO_TO_GIFT',
      width: '370px',
      closeBtn: true,
      buttonTwoCallback: (dialogRef) => {
        dialogRef.close();
        isRedmptionDetail && this.dialogService.closeDialog('mat-redemption-detail');
        this.dialogService.closeDialog('redemption-expirt');
        this.router.navigate([`/admin/gift/view/${giftId}`], {
          fragment:     'tabIndex=1',
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      },
    };
    this.dialogService.showSuccessDialog(dialogData).subscribe();
  }
  // Not enough allotment but enough inventory handling
  giftNotEnoughAllotment(data:any,giftNum:any, promotionName:any, isRedmptionDetail?:any) {
    const dialogData: DialogData = {
      content: `Not Enough Allotment, system will move [${giftNum}] inventory to promotion [${promotionName}]`,
      yesLabel: 'COMMON.LABEL.CONFIRM',
      yesCallback: (dialogRef) => {
        this.expiryDateMember({...data, confirm: true}).subscribe((res:any)=> {
          dialogRef.close();
          this.giftExtendCompleteLinkPage(isRedmptionDetail)
        })
      },
      noLabel: 'COMMON.LABEL.CANCEL',
      noCallback: (dialogRef) => {
        dialogRef.close();
        
      },
    };
    this.dialogService.showConfirmationDialog(dialogData).subscribe();
  }

  giftExtendCompleteLinkPage(isRedmptionDetail?:any) {
    isRedmptionDetail && this.dialogService.closeDialog('mat-redemption-detail');
    this.dialogService.closeDialog('redemption-expirt');
    const dialogData: DialogData = {
      content: 'Gift Expiry Extended!',
      strokedButtonLabel: 'BACK TO MEMBER PROFILE',
      width: '370px',
      strokedButtonCallback: (dialogRef) => {
        dialogRef.close();
        this.preserveTabService.navigateSamePageTab('/admin/members/view', { tabIndex: 0 });
      },
    };

    this.dialogService.showSuccessDialog(dialogData).subscribe();
  }

  expiryExport(params: any) {
    return this.httpService.post('/campaign/gift/extend/expiry/date/export/csv', params, { responseType: 'blob' as 'json' }).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  availableMerchants(csv: { data: File }) {
    const formData = new FormData();
    formData.append('tenantsCsv', csv.data, csv.data.name);
    return this.httpService.post(`/campaign/gift/availableMerchants`, formData).pipe(
      map((response: any) => {
        return response;
      })
    ) as Observable<any>;
  }
}
