import { environment } from '@env/environment';

export const SHORTCUT_ITEM = (link: string | null) => {
  return [
    {
      title: 'MENU.LABEL.MEMBER_PROFILE',
      icon: 'domain',
      link: '/admin/members/list',
    },
    {
      title: 'MENU.LABEL.MEMBER_REGISTRATION',
      icon: 'account_box',
      // link: '/admin/members/create',
      action: () => {
        window.open(link || environment.MEMBER_REGISTRATION_LINK);
      },
    },
  ];
};
