import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Pipe({
  name: 'authImage',
})
export class AuthImagePipe implements PipeTransform {
  constructor(private http: HttpClient, private authStorage: OAuthStorage) {}
  async transform(src: string): Promise<string> {
    const token: string = this.authStorage.getItem('access_token'); // TODO get existing token
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    try {
      const imageBlob = await this.http.get(src, { headers, responseType: 'blob' }).toPromise();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch {
      return null;
    }
  }
}
