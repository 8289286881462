import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogInfoDisplayComponent } from './dialog-info-display.component';
import { DialogInfoDisplayCellComponent } from './dialog-info-display-cell/dialog-info-display-cell.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DialogInfoDisplayComponent, DialogInfoDisplayCellComponent],
  imports: [CommonModule, TranslateModule],
  exports: [DialogInfoDisplayComponent, DialogInfoDisplayCellComponent],
})
export class DialogInfoDisplayModule {}
