import { Routes, Route } from '@angular/router';

import { CsShellComponent } from './cs-shell.component';

/**
 * Provides helper methods to create routes.
 */
export class CsShell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: CsShellComponent,
      children: routes,
      // Reuse ShellComponent instance when navigating between child views
      data: {
        extraParams: {
          reuse: true,
        },
      },
    };
  }
}
