import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { untilDestroyed } from '@app/@core';
import { generateUuid } from '@app/@core/utils/uuid/generate-uuid';

import { DialogService, ImageService, ReceiptImageService, ReceiptTransactionService } from '@core/services';
import { DialogData } from '@core/models/application/dialog-data';
import { MatDialogRef } from '@angular/material/dialog';

import { Subject, timer } from 'rxjs';
import { repeat, timeout } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { UploadedReceiptDialogComponent } from './uploaded-receipt-dialog/uploaded-receipt-dialog.component';
import { ProcessedReceiptImage, ReceiptImageProcessingStatus } from './model/receipt-image-processing-status';

import ImageOcrStatus from './model/image-ocr-status';
import ImageProcessingStatus from './model/image-processing-status';

@Component({
  selector: 'c-receipt-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() maxFile: number;
  @Input() fileCategoryLabel: string; // Label for the uploader
  @Input() fileCategoryName: string; // Label for the uploader
  @Input() fileSizeLimit: number;
  @Input() isRequired = false;
  @Input() isAllowSkip = false;
  @Input() debugMode = false;
  @Input() loadTestConfig: any;
  @Input() isEnableOcr = false;
  @Input() transactionIndex = 0;
  @Input() isShowCapture = false;

  @Output() openImageViewer = new EventEmitter<any>();
  @Output() ocrFinishEvents: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSkipUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteImage: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeImage: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('captureFileInputRef') captureFileInputRef: ElementRef;
  @ViewChild('fileInputRef') fileInputRef: ElementRef;

  fileList: File[] = [];
  receiptImages: ProcessedReceiptImage[] = [];
  maxFileCount: number;
  isSubmitting: boolean;
  isEnableAlbumPicker = false;

  public isOptional = false;
  public isDisableOptionalCheckbox = false;
  private selectedIndex = 0;
  private fileQueue$: Subject<File[]> = new Subject();

  constructor(private dialogService: DialogService, private imageService: ImageService, private receiptImageService: ReceiptImageService, private receiptTransactionService: ReceiptTransactionService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileList) {
      this.cd.detectChanges();
      this.cd.markForCheck();
    }
  }

  /**
   * Select File Event
   * Temp Disable Multi Select Files
   * @parm event
   */
  onSelectFile(event: Event): void {
    Array.from((event.target as HTMLInputElement).files).forEach((file) => {
      this.addImageToFileQueue(file);
    });
    // Clear uploader for next file
    this.fileInputRef.nativeElement.value = '';
    this.onChangeImage.emit(true);
  }

  onClickThumbnail(index: number): void {
    this.openImageViewer.emit(index);
  }

  onClickDeleteImage(index: number): void {
    this.receiptImageService.deleteReceiptImage(this.receiptImages[index].id).subscribe(() => {
      const fileList = cloneDeep(this.fileList);
      fileList.splice(index, 1);
      const deletedImg = this.receiptImages.splice(index, 1);
      this.fileQueue$.next(fileList);

      this.onDeleteImage.emit(deletedImg);
      this.onChangeImage.emit(true);
      this.cd.markForCheck();
    });
  }

  onClickUploaderWrapper(event: any): void {
    if (event.target.type !== 'file') {
      if (event.target.innerHTML === 'collections') {
        this.isEnableAlbumPicker = true;
        setTimeout(() => {
          this.fileInputRef.nativeElement.click();
          this.isEnableAlbumPicker = false;
        }, 100);
      } else {
        this.fileInputRef.nativeElement.click();
      }
    }
  }

  onOpenImageViewer(index: number): void {
    this.selectedIndex = index;
    const data = {
      selectedIndex: index,
      processedImages: this.receiptImages,
    };
    this.receiptImageService.showUploadedReceiptDialog(data, UploadedReceiptDialogComponent).subscribe((dialogRef) => {
      dialogRef.afterClosed().subscribe((response: { isEditedImage: boolean; imageSrc?: any }) => {
        // If Image Edited
        if (response && response.isEditedImage) {
          // Replace current image
          this.onClickDeleteImage(index);
          this.addImageToFileQueue(response.imageSrc, true);
          dialogRef.close();
        }
      });
    });
  }

  uploaderWrapperDragOver(event: any, uploaderWrapper: any): void {
    event.preventDefault();
    uploaderWrapper.classList.add('dragging');
  }

  uploaderWrapperDragLeave(event: any, uploaderWrapper: any): void {
    event.preventDefault();
    uploaderWrapper.classList.remove('dragging');
  }

  uploaderWrapperDragEnd(event: any, uploaderWrapper: any): void {
    event.preventDefault();
    uploaderWrapper.classList.remove('dragging');
  }

  uploaderWrapperDrop(event: any, uploaderWrapper: any): void {
    event.preventDefault();
    uploaderWrapper.classList.remove('dragging');
    if (event.dataTransfer.files.length > this.maxFileCount) {
      return;
    }
    event.target.files = event.dataTransfer.files;
    this.onSelectFile(event);
  }

  onChangeSkipOption(event: any) {
    if (event.event && this.receiptImages.length < 1) {
      this.isOptional = event.value;
      this.onSkipUpload.emit({
        isSkip: this.isOptional,
      });
      if (event.autoSelect === true) {
        // this.isDisableOptionalCheckbox = true;
      } else {
        this.isDisableOptionalCheckbox = false;
      }
      return;
    }
    if (event.value && this.receiptImages.length > 0) {
      const dialogData: DialogData = {
        content: 'Are you sure want to skip this receipt image?',
        yesLabel: 'COMMON.LABEL.CONFIRM',
        yesCallback: (dialogRef) => {
          this.isOptional = true;
          this.resetFileList();
          this.onSkipUpload.emit({
            isSkip: this.isOptional,
          });
          dialogRef.close();
        },
        noLabel: 'COMMON.LABEL.CANCEL',
        noCallback: (dialogRef) => {
          this.isOptional = false;
          this.onSkipUpload.emit({
            isSkip: this.isOptional,
          });
          dialogRef.close();
        },
      };
      this.dialogService.showConfirmationDialog(dialogData).subscribe();
    }
  }

  trackByFn(el: any): number {
    return el.id;
  }

  resetFileList(): void {
    this.receiptImages = [];
    this.fileList = [];
    this.cd.markForCheck();
  }

  /**
   * Add Image to Pre-upload Queue
   */
  private addImageToFileQueue(file: File, isOverridePrimaryReceipt: boolean = false): void {
    // Compress Image
    const isPrimaryReceipt = !isOverridePrimaryReceipt && this.receiptImages.length === 0;
    this.imageService.compressImage(file).then(
      (compressedImage) => {
        this.fileList.push(compressedImage);
        this.uploadReceiptImage(compressedImage, isPrimaryReceipt);
      },
      (error: Error) => {
        // Error in compress image
        if (error.message === 'Oversize') {
          const dialogData: DialogData = {
            title: 'Error',
            content: `The ${this.fileCategoryLabel} file size is greater than 5MB`,
            yesCallback: (dialogRef) => {
              dialogRef.close();
            },
          };
          this.dialogService.showErrorDialog(dialogData).subscribe();
        } else if (error.message === 'dataURLtoImage(): dataURL is illegal') {
          const dialogData: DialogData = {
            title: 'Error',
            content: `The file format is not accepted.`,
            yesCallback: (dialogRef) => {
              dialogRef.close();
            },
          };
          this.dialogService.showErrorDialog(dialogData).subscribe();
        } else {
          const dialogData: DialogData = {
            title: 'Error',
            content: `The ${this.fileCategoryLabel} file hit unknown error.`,
            yesCallback: (dialogRef) => {
              dialogRef.close();
            },
          };
          this.dialogService.showErrorDialog(dialogData).subscribe();
        }
      }
    );
  }

  /**
   * TODO: Use constant / enum instead of string
   */
  private uploadReceiptImage(file: File, isPrimaryReceipt: boolean): void {
    // OCR Config for stress test START
    let sleepTime = 0;
    let repeatTime = 1;
    if (this.debugMode) {
      sleepTime = this.loadTestConfig.sleepTime;
      repeatTime = this.loadTestConfig.repeatTime;
    }
    // OCR Config for stress test END

    // XXX: Create new session id form each upload request - to map the image in client side
    const uploadSessionId = generateUuid();
    const newImageStatus = { name: file.name, uploadSessionId, fileCategoryName: this.fileCategoryName, src: file, remoteState: ImageProcessingStatus.New, isPrimaryReceipt };
    this.receiptImages.push(newImageStatus);
    this.receiptImageService.setImageProcessingStatus(newImageStatus, this.transactionIndex);
    // Upload Receipt to S3
    this.receiptImageService
      .uploadReceiptImage({ sourceFile: file, isPrimaryReceipt, receiptType: this.fileCategoryName })
      .pipe(untilDestroyed(this), repeat(repeatTime))
      .subscribe({
        next: (fileId: string) => {
          const imageUrl: string = this.receiptTransactionService.getReceiptImageUrl(fileId, 'thumbnail');
          if (fileId && this.isEnableOcr) {
            this.updateImageState(uploadSessionId, { id: fileId, ocrStatus: ImageOcrStatus.Pending, remoteState: ImageProcessingStatus.Successful }, imageUrl);
            timer(sleepTime).subscribe(() => {
              this.receiptImageService
                .retrieveOcrResult(fileId, this.fileCategoryName, this.transactionIndex, isPrimaryReceipt)
                .pipe(timeout(15000))
                .subscribe({
                  next: (ocrResult) => {
                    this.updateImageState(uploadSessionId, { ocrStatus: ImageOcrStatus.Successful });
                  },
                  error: (error) => {
                    if (error.ocrStatus === ImageOcrStatus.Interrupted) {
                      this.updateImageState(uploadSessionId, { ocrStatus: ImageOcrStatus.Interrupted });
                    } else {
                      this.updateImageState(uploadSessionId, { ocrStatus: ImageOcrStatus.Failed });
                    }
                  },
                });
            });
          } else {
            this.updateImageState(uploadSessionId, { id: fileId, src: file, ocrStatus: ImageOcrStatus.Unnecessary, remoteState: ImageProcessingStatus.Successful }, imageUrl);
          }
        },
        error: () => {
          // this.updateImageState(uploadSessionId, { ocrStatus: ImageOcrStatus.Failed });
          const fileList = cloneDeep(this.fileList);
          const index = this.fileList.lastIndexOf(file);
          fileList.splice(index, 1);
          const deletedImg = this.receiptImages.splice(index, 1);
          this.fileQueue$.next(fileList);

          this.onDeleteImage.emit(deletedImg);
          this.onChangeImage.emit(true);
          this.cd.markForCheck();
          // delete this.fileList[index];
          // const imageIndex = this.receiptImages.findIndex((image: any) => image.uploadSessionId === uploadSessionId);
          // delete this.receiptImages[imageIndex];
        },
      });
  }

  private updateImageState(uploadSessionId: string, status: ReceiptImageProcessingStatus, thumbnail?: string): void {
    const imageIndex = this.receiptImages.findIndex((image: any) => image.uploadSessionId === uploadSessionId);
    this.receiptImages[imageIndex] = { ...this.receiptImages[imageIndex], ...status };
    console.log(thumbnail);
    if (thumbnail != null) {
      console.log('using thumbnail : ' + thumbnail);
      this.receiptImages[imageIndex].thumbnail = thumbnail;
    } else {
      console.log('not using thumbnail');
    }
    this.receiptImageService.setImageProcessingStatus(this.receiptImages[imageIndex], imageIndex);
    this.cd.markForCheck();
  }
  
  onClickReceiptCapture(event: any): void {
    this.captureFileInputRef.nativeElement.click();
  }


  get receiptIdWithCaption() {
    return this.receiptImages.map((receipt, index) => {
      return { id: receipt.id, caption: receipt.caption ?? '' };
    });
  }

  get uploadStatus(): string {
    return `(${this.receiptImages.length})`;
  }

  get uploaderLabel(): string {
    return this.receiptImages.length > 0 ? 'Upload Image' : 'You have not uploaded any image yet';
  }

  get reachedMaxFileCount(): boolean {
    return this.receiptImages.length >= this.maxFileCount;
  }
}
