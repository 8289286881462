import { Component, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy, ViewChild, ViewContainerRef, ComponentFactoryResolver, TemplateRef } from '@angular/core';
import { ViewTransactionConstants } from '@app/pages/receipt-transaction/view-transaction/view-transaction.constants';
import { ReceiptTransaction, StackConfig, StackViewerService, TransactionDialogService, untilDestroyed } from '@core';
import { VerifyTransactionInfoDialogComponent } from '../receipt-transaction/dialogs/verify-transaction-info-dialog/verify-transaction-info-dialog.component';

@Component({
  selector: 'c-stack-viewer',
  templateUrl: './stack-viewer.component.html',
  styleUrls: ['./stack-viewer.component.scss'],
})
export class StackViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  dynamicComponent: any; // TODO
  panelTitle: string;
  stackConfig: StackConfig;
  transactionButtonTemplate: TemplateRef<any>;
  @ViewChild('container', { read: ViewContainerRef }) ctr: ViewContainerRef;
  transactionDetail: ReceiptTransaction;
  isFromTransactionHistory: boolean;

  constructor(private stackViewerService: StackViewerService, private resolve: ComponentFactoryResolver, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.stackViewerService.buttonTemplate$?.pipe(untilDestroyed(this)).subscribe((buttonTpl: TemplateRef<any>) => {
      this.transactionButtonTemplate = buttonTpl;
    });

    this.stackViewerService.viewerConfigDataSubject$?.pipe(untilDestroyed(this)).subscribe((stackConfig: StackConfig) => {
      this.panelTitle = stackConfig.panelTitle;
      this.stackConfig = stackConfig;
      this.transactionDetail = this.stackConfig.customData?.transactionDetail;
      this.isFromTransactionHistory = this.stackConfig.customData?.isFromTransactionHistory;
    });

    this.stackViewerService.isReloadViewer$?.pipe(untilDestroyed(this)).subscribe((isReload: boolean) => {
      this.loadComponent();
    });
  }

  ngOnDestroy(): void {
    this.stackViewerService.closePanel();
    this.transactionButtonTemplate = null;
  }

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  closeViewer(): void {
    this.stackViewerService.closePanel();
    this.transactionButtonTemplate = null;
  }

  loadComponent(): void {
    if (this.ctr) {
      this.ctr.clear();
    }
    const factory = this.resolve.resolveComponentFactory(this.stackConfig.component);
    const dynamicComponent = this.ctr.createComponent<any>(factory);
    dynamicComponent.instance.customData = this.stackConfig.customData;
    this.cdr.detectChanges();
  }

  get submittedWithWorkflow() {
    return (
      (this.transactionDetail.status === ViewTransactionConstants.STATUS_SUBMITTED ||
        this.transactionDetail.status === ViewTransactionConstants.STATUS_LOCKED ||
        this.transactionDetail.status === ViewTransactionConstants.STATUS_CONSIGNMENT_PEND_FOR_APPROVE) &&
      this.transactionDetail.withPendingForApprovalWorkflowRequest
    );
  }

  get approvedWithWorkflow() {
    return (
      (this.transactionDetail.status === ViewTransactionConstants.TRANSACTION_STATUS_APPROVED ||
        this.transactionDetail.status === ViewTransactionConstants.STATUS_CONSIGNMENT_PEND_FOR_COMBINE ||
        this.transactionDetail.status === ViewTransactionConstants.STATUS_CONSIGNMENT_COMBINED) &&
      this.transactionDetail.withPendingForApprovalWorkflowRequest
    );
  }

  get isShowTransactionVerificationStatus(): boolean {
    return this.transactionDetail.status === ViewTransactionConstants.TRANSACTION_STATUS_APPROVED || this.transactionDetail.status === ViewTransactionConstants.STATUS_CONSIGNMENT_COMBINED;
  }
}
