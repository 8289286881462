import { Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DialogService } from '@app/@core/services/application/dialog/dialog.service';
import { DxGalleryComponent } from 'devextreme-angular';
import { VerifyTransactionDialogComponent } from '../../receipt-transaction/dialogs/verify-transaction-dialog/verify-transaction-dialog.component';
import { ImageEditorComponent } from '../image-editor/image-editor.component';
import { PinchZoomComponent } from '@shared/components/pinch-zoom/pinch-zoom.component';

@Component({
  selector: 'app-uploaded-receipt-dialog',
  templateUrl: './uploaded-receipt-dialog.component.html',
  styleUrls: ['./uploaded-receipt-dialog.component.scss'],
})
export class UploadedReceiptDialogComponent implements OnInit {
  @ViewChildren('pinchViewer') pinchViewerRef: QueryList<PinchZoomComponent>;
  @ViewChild('imageSlider') imageSliderRef: DxGalleryComponent;

  imageRotateDeg = 0;
  imageRotateStyle = {};
  processedImages: any[] = [];
  selectedIndex = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<VerifyTransactionDialogComponent>, private domSanitizer: DomSanitizer, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.processedImages = this.data.processedImages;
    this.selectedIndex = this.data.selectedIndex;
  }

  onClickCloseButton(): void {
    this.dialogRef.close();
  }

  onClickEditImage(): void {
    this.openImageEditor(this.processedImages[this.selectedIndex].src);
  }

  onCurrentViewChanged(): void {
    this.resetImageRotation();
  }

  // Image viewer Control
  onClickZoomIn(): void {
    const currentPinchViewerRef = this.pinchViewerRef.filter((element, index) => index === this.imageSliderRef.selectedIndex)[0];

    if (currentPinchViewerRef) {
      let newScale = currentPinchViewerRef.scale + currentPinchViewerRef.properties.zoomControlScale;
      currentPinchViewerRef.pinchZoom.setZoom({ scale: newScale > currentPinchViewerRef.maxScale ? currentPinchViewerRef.maxScale : newScale });
    }
  }

  onClickZoomOut(): void {
    const currentPinchViewerRef = this.pinchViewerRef.filter((element, index) => index === this.imageSliderRef.selectedIndex)[0];

    if (currentPinchViewerRef) {
      const newScale = currentPinchViewerRef.scale - currentPinchViewerRef.properties.zoomControlScale;
      currentPinchViewerRef.pinchZoom.setZoom({ scale: newScale > 1 ? newScale : 1 });
    }
  }

  onClickRotateLeft(): void {
    this.imageRotateDeg -= 90;
    this.imageRotateStyle = { transform: `rotate(${this.imageRotateDeg}deg)` };
  }

  onClickRotateRight(): void {
    this.imageRotateDeg += 90;
    this.imageRotateStyle = { transform: `rotate(${this.imageRotateDeg}deg)` };
  }

  resetImageRotation(): void {
    this.imageRotateDeg = 0;
    this.imageRotateStyle = { transform: `rotate(${this.imageRotateDeg}deg)` };
  }

  showBlobImage(sourceBlob: Blob): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(sourceBlob));
  }

  private openImageEditor(file: File): void {
    const dialogData = {
      disableClose: true,
      data: { image: file },
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '100%',
      height: '100%',
      backdropClass: 'backdrop',
      id: 'photo-confirmation-dialog',
    };
    this.dialogService.showDialog(dialogData, ImageEditorComponent).subscribe((dialogRef: MatDialogRef<any>) => {
      dialogRef.afterClosed().subscribe((image) => {
        if (image) {
          this.dialogRef.close({ isEditedImage: true, imageSrc: image });
        } else {
          // this.dialogRef.close({ isEditedImage: false });
        }
      });
    });
  }
}
