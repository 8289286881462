<div class="d-flex align-items-center">
    <mat-icon class="RPTinfo-icon" id="rptInfo" *ngIf="['NORMAL_TRANSACTION','SPLIT_PAYMENT_MAIN_TRANSACTION','CONSIGNMENT_CHILD_TRANSACTION'].includes(ocrTransactionDetail?.type) && ocrTransactionDetail?.rptReceipt"> info </mat-icon>
    <dx-popover target="#rptInfo" hideEvent="mouseleave" showEvent="click" position="top" [width]="300">
        <div style="color:#204568">RPT Transaction ID: {{ocrTransactionDetail?.rptTransactionId}}</div>
    </dx-popover>
    <ng-container>
        <svg-icon *ngIf="ocrTransactionDetail?.type=='SPLIT_PAYMENT_TRANSACTION'"
            src="/assets/images/icons/transactions/split-payment.svg" alt=""></svg-icon>
        <svg-icon *ngIf="ocrTransactionDetail?.type=='CONSIGNMENT_CHILD_TRANSACTION'"
             src="/assets/images/icons/transactions/consignment.svg" alt=""></svg-icon>
    </ng-container>
</div>