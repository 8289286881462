import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tenant } from '@core/models/business-unit/tenant';
import { publishReplay, refCount, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse } from '../models';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { LoyaltyService } from './loyalty';

interface TenantResponse extends PaginatedResponse {
  content: Tenant[];
}

@Injectable({
  providedIn: 'root',
})
export class TenantService implements Resolve<any> {
  public tenants: Observable<Tenant[]> = null;
  public activeTenants: Tenant[] = null;
  public newTenants: Observable<Tenant[]> = null;
  public partners: Observable<Tenant[]> = null;
  public allRecords: Observable<Tenant[]> = null;
  public allTenants: Observable<Tenant[]> = null;

  constructor(private httpService: HttpClient, private router: Router, private loyaltyService: LoyaltyService) {}

  

  tenantSearch(params?: any) {
    return this.httpService
      .get(`/admin/merchant/search`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      ) as Observable<TenantResponse>;
  }

  getTenants(params?: any) {
    return this.httpService
      .get(`/admin/merchant/list?type=TENANT`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      ) as Observable<TenantResponse>;
  }

  getPartners(params?: any) {
    return this.httpService
      .get(`/admin/merchant/list?type=PARTNER`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      ) as Observable<TenantResponse>;
  }

  getAllRecords(params?: any) {
    return this.httpService
      .get(`/admin/merchant/list`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res.data?.content;
        })
      ) as Observable<Tenant[]>;
  }

  createTenant(tenant: Tenant) {
    this.clearAllCaches();
    return this.httpService.post(`/admin/merchant`, tenant);
  }

  updateTenant(tenant: Tenant) {
    this.clearAllCaches();
    return this.httpService.put(`/admin/merchant`, tenant);
  }

  deleteTenant(params?: any) {
    this.clearAllCaches();
    return this.httpService.delete(`/admin/merchant`, { params });
  }

  clearAllCaches() {
    this.tenants = null;
    this.partners = null;
    this.allRecords = null;
    this.allTenants = null;
    this.newTenants = null;
  }

  getTenantList(isActiveOnly = false, includeDeleted = false) {
    let searchParams = {
      type: 'TENANT',
      size: '9999',
      orderBy: 'name',
      businessUnit: this.loyaltyService.currentProgramme.businessUnit,
    };
    this.tenants = null;
    if (isActiveOnly) {
      searchParams = { ...searchParams, ...{ listType: 'ACTIVE' } };
    }
    if (includeDeleted) {
      searchParams = { ...searchParams, ...{ includeDeleted: true } };
    }
    if (this.tenants) {
      return this.tenants;
    }
    return (this.tenants = this.httpService.get(`/admin/merchant/list`, { params: searchParams }).pipe(
      map((res: any) => {
        return res.data?.content;
      }),
      publishReplay(1),
      refCount(),
      catchError((err, caught) => {
        this.tenants = null;
        return caught;
      })
    ) as Observable<Tenant[]>);
  }

  getActiveTenantList(): Observable<Tenant[]> {
    return this.getTenantList(true).pipe(
      map((tenants) => {
        this.activeTenants = tenants;
        return tenants;
      })
    );
  }

  getMerchantList(params?: any) {
    if (this.newTenants) {
      return this.newTenants;
    }
    this.newTenants = this.httpService.get(`/admin/merchant/list?size=999999&orderBy=name`, { params }).pipe(
      map((res: any) => {
        return res.data?.content;
      }),
      publishReplay(1),
      refCount(),
      catchError((err, caught) => {
        this.newTenants = null;
        return caught;
      })
    ) as Observable<Tenant[]>;

    return this.newTenants;
  }

  getAllTenants() {
    if (!this.allTenants) {
      this.allTenants = this.httpService.get(`/admin/merchant/list?type=TENANT`).pipe(
        map((res: any) => {
          return res?.data?.content;
        }),
        publishReplay(1),
        refCount()
      ) as Observable<Tenant[]>;
    }
    return this.allTenants;
  }

  getTenantNameById(tenantId: number): Observable<string> {
    return this.tenants ? this.tenants.pipe(map((tenant) => tenant.find((item) => item.id === tenantId).name)) : null;
  }

  getTenantById(tenantId: number): Observable<Tenant> {
    return this.httpService.get(`/admin/merchant`, { params: { id: tenantId.toString() } }).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<Tenant>;
  }

  getTenantPartnerList() {
    let searchParams = {
      // type: ['TENANT', 'PARTNER'],
      size: '9999',
      orderBy: 'name',
      listType: 'ACTIVE',
    };
    searchParams = { ...searchParams, ...{ listType: 'ACTIVE' } };
    return (this.tenants = this.httpService.get(`/admin/merchant/list`, { params: searchParams }).pipe(
      map((res: any) => {
        return res.data?.content;
      })
    ) as Observable<Tenant[]>);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getAllRecords().pipe(
      map((tenants) => {
        return tenants.sort((t1, t2) => {
          return t1.name.localeCompare(t2.name);
        });
      }),
      catchError((err) => this.router.navigateByUrl(`/`))
    );
  }

  // Tenant Related Utils
  isActiveTenant(tenantId: number): boolean {
    return this.activeTenants?.findIndex((item) => item.id === tenantId) !== -1;
    // return this.activeTenants.pipe(map((tenant) => { return  }));
  }

  getLatestOPT(tenantId: any): Observable<any> {
    return this.httpService.get(`/admin/merchant/latestOtp`, { params: { id: tenantId } }).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<any>;
  }
  getApproverGroup() {
    return this.httpService.get(`/admin/reimbursementSummaryApproverGroup/list`).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<any>;
  }

  visibilityTransform(value: string): string {
    if (value?.length >= 4) {
      return value.replace(/.(?=....)/g, '*');
    } else {
      return value;
    }
  }
}
