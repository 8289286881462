<form [formGroup]="RptForm" class="rpt-form">
    <!-- tenant -->
    <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
        <mat-label>{{ 'RECEIPT_TRANSACTIONS.LABEL.TENANT' | translate }}</mat-label>
        <ng-select formControlName="shopId" ngSelectMat [items]="tenantList" bindLabel="name"
            bindValue="id"></ng-select>
        <mat-error *ngIf="shopId.hasError('notEligibleToEarnPoints')">{{
            'RECEIPT_TRANSACTIONS.MESSAGE.TENANT_NOT_ELIGIBLE' | translate }}</mat-error>
        <mat-error
            *ngIf="shopId.hasError('depositTransactionTenantInvalid') && !shopId.hasError('notEligibleToEarnPoints')">
            {{ 'RECEIPT_TRANSACTIONS.MESSAGE.DEPOSIT_TRANSACTION_MISMATCH' | translate }}
        </mat-error>
    </mat-form-field>

    <!-- Transaction date and time -->
    <mat-form-field class="full-width">
        <mat-label>{{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_DATE' | translate }}</mat-label>
        <app-masked-datepicker placeholder="(DD-MM-YYYY)" formControlName="transactionDate"
            [max]="DATE_TODAY"></app-masked-datepicker>
    </mat-form-field>

    <mat-form-field class="full-width">
        <mat-label>{{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_TIME' | translate }}</mat-label>
        <input matInput autocomplete="off" inputmode="numeric" formControlName="transactionTime" [imask]="TIME_MASK"
            [unmask]="false" />
    </mat-form-field>

    <!-- Total transaction amount -->
    <mat-form-field class="full-width flex-input calculation">
        <mat-label>{{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_AMOUNT' | translate }}</mat-label>
        <span>HKD&nbsp;</span>
        <calculation-input formControlName="originalPaymentAmount"></calculation-input>
        <mat-hint class="reminder reminder-Rpt" align="start">
            <div class="d-flex align-items-center">
                <span style="color: red;">Point Earned: 1,222</span><mat-icon style="color: #333;cursor: pointer;" class="RPTinfo-icon mx-2" id="isPointEarnformula"> info </mat-icon>
            </div>
           <dx-popover target="#isPointEarnformula" hideEvent="mouseleave" showEvent="click" position="top" [width]="400">
            <h4 style="color:#204568;margin-bottom: 8px;">Remarks</h4>
            <div style="color:#888c92;">
                <!--[Transaction Amount] - [Excluded Amount] - [Actualized Mall Dollar / eVoucher Amount]) < [Minimum Spending] -->
                Point Earn = [Transaction Amount] - [Excluded Amount] - [Actualized Mall Dollar / eVoucher Amount]) < [Minimum Spending]
            </div>
        </dx-popover>

        </mat-hint>
    </mat-form-field>

    <!-- Electronic receipt amount -->
    <!-- [allowNegativeAmount]="haveDepositTransactionAccess" -->
    <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
        <mat-label>Electronic Receipt Amount</mat-label>
        <span>HKD&nbsp;</span>
        <calculation-input formControlName="paidAmount"></calculation-input>
    </mat-form-field>

    <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
        <mat-label>Excluded Amount</mat-label>
        <span>HKD&nbsp;</span>
        <calculation-input formControlName="excludedAmount"></calculation-input>
    </mat-form-field>

    <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
        <mat-label>Actualized Mall Dollar / eVoucher amount</mat-label>
        <span>HKD&nbsp;</span>
        <calculation-input formControlName="excludedAmount"></calculation-input>
    </mat-form-field>

    <!-- dropdownData?.RECEIPT_TRANSACTION.PAYMENT_METHOD -->
    <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
        <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.PAYMENT_METHOD</mat-label>
        <ng-select formControlName="paymentMethod" ngSelectMat>
            <ng-option [disabled]="item.disabled" *ngFor="let item of []" bindLabel="label" [value]="item.value">
                {{ item.label }}
            </ng-option>
        </ng-select>
    </mat-form-field>

    <!-- Invoice number -->
    <mat-form-field class="full-width">
        <mat-label>{{ 'RECEIPT_TRANSACTIONS.LABEL.INVOICE_NUMBER' | translate }}</mat-label>
        <app-chip-input formControlName="invoiceNumbers"></app-chip-input>
    </mat-form-field>

    <!-- Trace ID -->
    <mat-form-field class="full-width">
        <mat-label>Trace ID</mat-label>
        <mat-select multiple formControlName="acquirerRefNo">
            <mat-option *ngFor="let type of []" [value]="type.value">
                {{ type.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Transaction remarks -->
    <mat-form-field class="full-width">
        <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_REMARKS</mat-label>
        <textarea rows="5" matInput formControlName="remarks"></textarea>
    </mat-form-field>

</form>