export abstract class AttachmentsListConstants {
  public static MAX_FILE_SIZE = 6 * 1024 * 1024; // 10MB
  public static ALLOWED_FILE_TYPES = [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
  ]; // Document file type only
}
