import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-point-range-form-field',
  templateUrl: './point-range-form-field.component.html',
  styleUrls: ['./point-range-form-field.component.scss'],
})
export class PointRangeFormFieldComponent extends FieldType implements OnInit {
  ngOnInit() {}
}
