import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputComponent } from './chip-input.component';
import { MatIconModule } from '@angular/material/icon';
import { IMaskModule } from 'angular-imask';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  imports: [CommonModule, MatIconModule, IMaskModule, MatInputModule, FormsModule, MatChipsModule],
  exports: [ChipInputComponent],
  declarations: [ChipInputComponent],
})
export class ChipInputModule {}
