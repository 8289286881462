import { Routes } from '@angular/router';
import { PermissionGuard } from '@core/guards/permission.guard';

export function addPermissionGuardToRoute(routes: Routes) {
  const authRoutes = routes.map((route) => {
    if (!route.redirectTo) {
      route.canActivate = route.canActivate ? [...route.canActivate, PermissionGuard] : [PermissionGuard];
    }
    return route;
  });
  return authRoutes;
}
