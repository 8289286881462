import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({
  name: 'carparkHour',
})
export class CarparkHourPipe implements PipeTransform {
  transform(value: number, ...args: any): string {
    let parkingHourStr = '0 Hour';
    if (!isNumber(value)) return parkingHourStr;

    var y = String(value).indexOf('.') + 1;
    if (y > 0) {
      value = parseFloat(value.toFixed(2));
      const remain = value - Math.trunc(value);
      const mins = remain * 60;
      if (value < 1) {
        parkingHourStr = `0h ${mins}mins`;
      } else {
        parkingHourStr = `${Math.trunc(value)}h  ${mins}mins`;
      }
    } else {
      parkingHourStr = value > 0 ? String(value) + ' Hour(s)' : '0 Hour(s)';
    }
    return parkingHourStr;
  }
}
