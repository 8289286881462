import { DialogData } from '@core/models';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { Subject, Subscription, BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { GeneralDialogComponent } from '@app/@shared/components/dialog/general-dialog/general-dialog.component';
import { ConfirmationDialogComponent } from '@app/@shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from '@app/@shared/components/dialog/success-dialog/success-dialog.component';
import { ErrorDialogComponent } from '@app/@shared/components/dialog/error-dialog/error-dialog.component';

class DialogMeta {
  public subs: Subscription[] = [];

  constructor(public ref: MatDialogRef<any>) {}
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  static defaultDialogConfig: MatDialogConfig<DialogData> = {
    id: 'mat-dialog',
    width: '370px',
    disableClose: true,
    hasBackdrop: true,
    direction: 'ltr',
  };

  static defaultConfirmationDialogConfig: MatDialogConfig<DialogData> = {
    id: 'mat-confirm-dialog',
    disableClose: true,
  };

  static defaultSuccessDialogConfig: MatDialogConfig<DialogData> = {
    id: 'mat-success-dialog',
    panelClass: 'p-0-container',
    minWidth: '395px',
    disableClose: true,
    closeOnNavigation: true,
  };

  static defaultErrorDialogConfig: MatDialogConfig<DialogData> = {
    id: 'mat-error-dialog',
    width: '370px',
    disableClose: true,
    closeOnNavigation: true,
  };

  static errorMsgs = {
    UNKNOWN_DIALOG_ID: 'Unknown dialog id.',
  };

  dialogMetaList: { [name: string]: DialogMeta } = {};
  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  constructor(private dialog: MatDialog) {}

  saveDialogMeta(id: string, meta: DialogMeta) {
    this.dialogMetaList[id] = meta;
  }

  removeDialogMeta(id: string) {
    const dialogMeta = this.dialogMetaList[id];
    delete this.dialogMetaList[id];
  }

  clearDialog(meta: DialogMeta) {
    if (meta) {
      for (const sub of meta?.subs) {
        sub.unsubscribe();
      }
      meta?.subs.splice(0);
    }
  }

  saveDialog(id: string, dialogRef: MatDialogRef<any>): void {
    const meta = new DialogMeta(dialogRef);

    this.saveDialogMeta(id, meta);

    const sub = dialogRef?.afterClosed().subscribe((result) => {
      this.removeDialogMeta(id);
      this.clearDialog(meta);
    });

    meta.subs.push(sub);
  }

  closeDialog(id: string): void {
    const dialogMeta = this.getDialogMeta(id);
    if (dialogMeta) {
      this.clearDialog(dialogMeta);
      this.removeDialogMeta(id);
      dialogMeta.ref.close();
    } else {
      throw new Error(DialogService.errorMsgs.UNKNOWN_DIALOG_ID);
    }
  }

  // TODO: Testing
  closeAllDialogs(): void {
    this.dialog.closeAll();
  }

  closeExistingDialog(id: string, dialogFunction?: () => void) {
    const existingDialogMeta = this.getDialogMeta(id);
    if (existingDialogMeta) {
      this.clearDialog(existingDialogMeta);
      const sub: Subscription = existingDialogMeta?.ref?.afterClosed().subscribe(() => {
        this.removeDialogMeta(id);
        this.clearDialog(existingDialogMeta);
        if (dialogFunction) {
          dialogFunction();
        }
      });
      existingDialogMeta?.subs.push(sub);
      existingDialogMeta?.ref.close();
    } else {
      if (dialogFunction) {
        dialogFunction();
      }
    }
  }

  showDialog(dialogData: DialogData, dialogComponent: any = GeneralDialogComponent): Observable<MatDialogRef<any>> {
    const dialogConfig = {
      ...DialogService.defaultDialogConfig,
      data: dialogData,
      ...dialogData,
    };

    return new Observable((subscriber) => {
      this.closeExistingDialog(dialogConfig.id, () => {
        const dialogRef = this.dialog.open(dialogComponent, dialogConfig);

        this.saveDialog(dialogConfig.id, dialogRef);
        subscriber.next(dialogRef);
        subscriber.complete();
      });
    });
  }

  hideDialog(dialogId: string) {
    const backdropElements = document.getElementsByClassName('cdk-overlay-backdrop') as HTMLCollectionOf<HTMLElement>;
    Array.from(backdropElements).forEach((element) => {
      element.style.display = 'none';
    });
    this.getDialogMeta(dialogId)?.ref.addPanelClass('hidden-dialog');
  }

  resumeDialog(dialogId: string) {
    const backdropElements = document.getElementsByClassName('cdk-overlay-backdrop') as HTMLCollectionOf<HTMLElement>;
    Array.from(backdropElements).forEach((element) => {
      element.style.display = 'block';
    });
    this.getDialogMeta(dialogId)?.ref.removePanelClass('hidden-dialog');
  }

  showConfirmationDialog(dialogData: DialogData): Observable<MatDialogRef<ConfirmationDialogComponent>> {
    return this.showDialog(
      {
        ...DialogService.defaultConfirmationDialogConfig,
        ...dialogData,
      } as DialogData,
      ConfirmationDialogComponent
    );
  }

  showSuccessDialog(dialogData: DialogData): Observable<MatDialogRef<SuccessDialogComponent>> {
    return this.showDialog(
      {
        ...DialogService.defaultSuccessDialogConfig,
        ...dialogData,
      } as DialogData,
      SuccessDialogComponent
    );
  }

  showErrorDialog(dialogData: DialogData): Observable<MatDialogRef<ErrorDialogComponent>> {
    return this.showDialog(
      {
        ...DialogService.defaultErrorDialogConfig,
        ...dialogData,
      } as DialogData,
      ErrorDialogComponent
    );
  }

  closeAllDialog(): void {
    for (const id of Object.keys(this.dialogMetaList)) {
      this.closeDialog(id);
    }
  }

  checkExistDialog(id: string) {
    return !!this.getDialogMeta(id);
  }

  getDialogMeta(id: string): DialogMeta {
    return this.dialogMetaList[id];
  }
}
