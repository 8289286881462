import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { AdvancedSearchParams, ControlKeyMappingConstants, DataTableService, DepositTransactionDialogData, DialogData, DialogService, DropdownDictionary, ReceiptTransactionService, Tenant } from '@app/@core';
import { removeEmptyAttributes } from '@app/@core/utils/object';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface TransactionDateParams {
  createdDateStart: string;
  createdDateEnd: string;
  transactionDateStart: string;
  transactionDateEnd: string;
  transactionAmountFrom: string;
  transactionAmountTo: string;
  receiptAmountFrom: string;
  receiptAmountTo: string;
}
@Component({
  selector: 'app-link-deposit-transaction-dialog',
  templateUrl: './link-deposit-transaction-dialog.component.html',
  styleUrls: ['./link-deposit-transaction-dialog.component.scss'],
})
export class LinkDepositTransactionDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  dataSource: any = {};
  dropdownData: DropdownDictionary;
  membershipTierList: any;
  transactionsData: any;
  linkedPickupSlipTransactionId: string;
  currentUser: any;
  currentBu: any;
  minDate: Date;
  maxDate: Date;
  searchTransactionForm: UntypedFormGroup;
  listTransactionForm: UntypedFormGroup;
  isSubmitting = false;
  checkboxSelectedList: Array<string> = [];
  controlKeyMapping: AdvancedSearchParams = ControlKeyMappingConstants.CONTROL_KEY_MAPPING.LIST_DEPOSIT_TRANSACTION;
  transactionId: string;
  tenantId: number | string;
  selectedList: Array<any> = [];
  guestId: string = '';
  dropDownDepositTypes = [
    { label: 'Full', value: 'FULL' },
    { label: 'Partial', value: 'PARTIAL' },
  ];
  // selectedDepositType: string = ''
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: DepositTransactionDialogData,
    private dataTableService: DataTableService,
    private dialogRef: MatDialogRef<LinkDepositTransactionDialogComponent>,
    private receiptTransactionService: ReceiptTransactionService,
    private fb: UntypedFormBuilder,
    protected route: ActivatedRoute
  ) {
    this.dropdownData = this.data.dropdownData;

    this.tenantId = this.data.tenantId;
    this.guestId = this.data.guestId;
    this.linkedPickupSlipTransactionId = this.data.linkedPickupSlipTransactionId;
    this.selectedList = [...this.data.selectedList];
    if (this.selectedList?.length) {
      this.checkboxSelectedList = this.selectedList.map((data) => {
        return data.id;
      });
    }
  }
  ngAfterViewInit(): void {
    if (this.tenantId) {
      this.onSearchFormSearchClick('');
    }
  }
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.initSearchForm();
  }
  onSearchFormSearchClick(searchKeyword: any) {
    this.getTransactions(searchKeyword);
    this.dataGrid.instance.pageIndex(0);
  }
  getTransactions(searchKeyword: any) {
    let customParams = {
      tenantId: this.tenantId,
      guestId: this.guestId,
      ...this.searchTransactionForm.value,
      searchValue: searchKeyword ? searchKeyword : null,
      searchFields: ['invoiceNumbers', 'transactionUploadSource', 'paymentMethod', 'id', 'lookupTenantName'].join(),
    };

    if (this.linkedPickupSlipTransactionId) customParams.linkedPickupSlipTransactionId = this.linkedPickupSlipTransactionId;
    customParams = { ...customParams, ...this.formattedDate(customParams) };

    if (customParams.transactionAmountRange) delete customParams.transactionAmountRange;
    if (customParams.receiptAmountRange) delete customParams.receiptAmountRange;
    if (!customParams.depositType) delete customParams.depositType;
    if (!customParams.paymentMethod) delete customParams.paymentMethod;
    this.dataSource = this.dataTableService.getPaginationObject(
      'id',
      (params: any) => {
        try {
          return this.receiptTransactionService.getDepositTransaction(params).pipe(
            map((transactions) => {
              transactions.content = transactions.content.map((transaction) => {
                transaction.createdByDisplay = transaction.createdBy === transaction.uploadedBy ? `${transaction.createdBy}` : `${transaction.uploadedBy} (${transaction.createdBy})`;
                return transaction;
              });
              return transactions;
            }),
            catchError((error: any) => {
              // Clear result when getting error
              this.dataSource = [];
              return throwError(error);
            })
          );
        } catch {
          console.error('Error!');
        }
      },
      removeEmptyAttributes(customParams)
    );
  }
  onSearchFormResetClick() {
    this.searchTransactionForm.reset();
    this.checkboxSelectedList = [];
    this.selectedList = [...this.data.selectedList];
    if (this.selectedList?.length) {
      this.checkboxSelectedList = this.selectedList.map((data) => {
        return data.id;
      });
    }

    this.getTransactions(null);
  }
  forSettingMemberIdAsCheckboxValue(rowData: any) {
    return {
      id: rowData.id,
      depositType: rowData.depositType,
    };
  }
  formattedDate(customParams: Params): TransactionDateParams {
    let tempParams: TransactionDateParams = {
      createdDateStart: null,
      createdDateEnd: null,
      transactionDateStart: null,
      transactionDateEnd: null,
      transactionAmountFrom: null,
      transactionAmountTo: null,
      receiptAmountFrom: null,
      receiptAmountTo: null,
    };
    if (customParams.createdDateStart) {
      tempParams.createdDateStart = moment(customParams.createdDateStart).set({ h: 0, m: 0, s: 0 }).format('YYYY-MM-DD HH:mm:ss'); // ToDO - replace by env config
    }
    if (customParams.createdDateEnd) {
      (tempParams.createdDateEnd as string) = moment(customParams.createdDateEnd).set({ h: 23, m: 59, s: 59 }).format('YYYY-MM-DD HH:mm:ss'); // ToDO - replace by env config
    }
    if (customParams.transactionDateStart) {
      (tempParams.transactionDateStart as string) = moment(customParams.transactionDateStart).set({ h: 0, m: 0, s: 0 }).format('YYYY-MM-DD HH:mm:ss'); // ToDO - replace by env config
    }
    if (customParams.transactionDateEnd) {
      (tempParams.transactionDateEnd as string) = moment(customParams.transactionDateEnd).set({ h: 23, m: 59, s: 59 }).format('YYYY-MM-DD HH:mm:ss'); // ToDO - replace by env config
    }

    if (customParams.transactionAmountRange) {
      (tempParams.transactionAmountFrom as string) = customParams.transactionAmountRange.transactionAmountFrom || null;
      (tempParams.transactionAmountTo as string) = customParams.transactionAmountRange.transactionAmountTo || null;
    }
    if (customParams.receiptAmountRange) {
      (tempParams.receiptAmountFrom as string) = customParams.receiptAmountRange.receiptAmountFrom || null;
      (tempParams.receiptAmountTo as string) = customParams.receiptAmountRange.receiptAmountTo || null;
    }
    return tempParams;
  }

  calculateExpired(rowData: any) {
    return {
      createdDate: rowData.createdDate,
      soonToBeExpired: rowData.soonToBeExpired,
      overdueTransaction: rowData.overdueTransaction,
    };
  }

  onRowClick(rowData: any) {
    const id = rowData.data.id;
    let index = this.checkboxSelectedList.indexOf(id);
    if (index > -1) {
      this.checkboxSelectedList.splice(index, 1);
      this.selectedList.splice(index, 1);
    } else {
      this.checkboxSelectedList.push(id);
      this.selectedList.push(rowData.data);
    }
  }

  onDialogSubmitClick() {
    this.submitRequest();
  }
  submitRequest() {
    this.isSubmitting = false;

    this.dialogRef.close(this.selectedList);
  }
  onCancelClick() {
    this.dialogRef.close();
  }

  private initSearchForm(): void {
    this.searchTransactionForm = this.fb.group({
      invoiceNumbers: [null],
      paymentMethod: [null],
      transactionDateEnd: [null],
      transactionDateStart: [null],
      transactionUploadSource: [null], //Upload Channel
      createdDateEnd: [null],
      createdDateStart: [null],
      transactionAmount: [null],
      transactionAmountRange: [null],
      receiptAmountRange: [null],
      id: [null],
      depositType: [null],
    });
  }
}
