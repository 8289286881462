import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, filter, map, publishReplay, refCount } from 'rxjs/operators';
import { BusinessUnit } from '@core/models/business-unit/business-unit';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ApiResponse } from '@app/@core';
import { cloneDeep, isEqual } from 'lodash';

interface BusinessUnitResponse extends ApiResponse {
  content: BusinessUnit[];
}

@Injectable({
  providedIn: 'root',
})
export class BusinessUnitService implements Resolve<any> {
  public businessUnits: Observable<BusinessUnitResponse> = null;
  private lastBUParams: any;

  constructor(private httpService: HttpClient, private router: Router) {}

  getBusinessUnits(params?: any) {
    if (!this.businessUnits || !isEqual(params, this.lastBUParams)) {
      this.lastBUParams = cloneDeep(params);

      this.businessUnits = this.httpService.get(`/admin/businessUnit`, { params }).pipe(
        map((res: any) => {
          return res.data;
        }),
        publishReplay(1),
        refCount()
      ) as Observable<BusinessUnitResponse>;
    }
    return this.businessUnits;
  }

  getBusinessUnitByCode(code: string): Observable<BusinessUnit> {
    return this.getBusinessUnits().pipe(
      map((businessUnits) => {
        return businessUnits.content.filter((bu) => bu.code === code)[0] ?? null;
      })
    );
  }

  createBusinessUnit(businessUnit: BusinessUnit): Observable<BusinessUnit> {
    this.clearCache();
    return this.httpService.post(`/admin/businessUnit`, businessUnit).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateBusinessUnit(businessUnit: BusinessUnit): Observable<BusinessUnit> {
    this.clearCache();
    return this.httpService.put(`/admin/businessUnit`, businessUnit).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getBusinessUnits().pipe(
      map((res) => res.content),
      catchError((err) => this.router.navigateByUrl('/'))
    );
  }

  private clearCache() {
    this.businessUnits = null;
    this.lastBUParams = null;
  }
}
