import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExternalLinkService {
  constructor() {}

  /**
   * TODO: Will add some logger and interaction
   * @param link URL
   */
  openLink(link: string) {
    window.open(link);
  }
}
