export abstract class CurrencyMaskConstants {
  public static HKD = {
    // mask: 'HKD num',
    // blocks: {
    //   num: {
    mask: Number,
    scale: 2, // digits after point, 0 for integers
    signed: false, // disallow negative
    thousandsSeparator: ',', // any single char
    padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
    normalizeZeros: true, // appends or removes zeros at ends
    radix: '.', // fractional delimiter
    mapToRadix: ['.'], // symbols to process as radix
    min: 0,
    max: 99999999999999,
    //   },
    // },
  };

  public static HKD_WITH_NEGATIVE = {
    // mask: 'HKD num',
    // blocks: {
    //   num: {
    mask: Number,
    scale: 2, // digits after point, 0 for integers
    signed: true, // disallow negative
    thousandsSeparator: ',', // any single char
    padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
    normalizeZeros: true, // appends or removes zeros at ends
    radix: '.', // fractional delimiter
    mapToRadix: ['.'], // symbols to process as radix
    min: -99999999999999,
    max: 99999999999999,
    //   },
    // },
  };

  public static HKD_INT = {
    // mask: 'HKD num',
    // blocks: {
    //   num: {
    mask: Number,
    scale: 0, // digits after point, 0 for integers
    signed: false, // disallow negative
    thousandsSeparator: ',', // any single char
    radix: '.', // fractional delimiter
    mapToRadix: ['.'], // symbols to process as radix
    min: 0,
    max: 99999999999999,
    //   },
    // },
  };

  public static NON_ZERO_HKD_INT = {
    // mask: 'HKD num',
    // blocks: {
    //   num: {
    mask: Number,
    scale: 0, // digits after point, 0 for integers
    signed: false, // disallow negative
    thousandsSeparator: ',', // any single char
    radix: '.', // fractional delimiter
    mapToRadix: ['.'], // symbols to process as radix
    min: 1,
    max: 99999999999999,
    //   },
    // },
  };

  public static ACCOUNT_CODE = {
    mask: '000000.000000.00',
  }
}
