import { Component, Input, OnInit } from '@angular/core';
import { TransactionConstants } from '../receipt-transaction.constant';
import { keyBy } from 'lodash';

@Component({
  selector: 'share-transaction-status',
  templateUrl: './transaction-status.component.html',
  styleUrls: ['./transaction-status.component.scss'],
})
export class TransactionStatusComponent implements OnInit {
  @Input() status: string;
  @Input() pointEarned: string;
  @Input() dataStatusAll:any;
  rptStatus = ['CONSIGNMENT_COMBINED','CONSIGNMENT_PEND_FOR_APPROVE','CONSIGNMENT_PEND_FOR_COMBINE','REJECTED_CONSIGNMENT','REJECTED_SPLIT_PAYMENT','SPLIT_PAYMENT','VOIDED_CONSIGNMENT','VOIDED_SPLIT_PAYMENT','NO_POINT_EARN']
  redColor = ['REJECTED_SPLIT_PAYMENT','REJECTED_CONSIGNMENT','VOIDED_CONSIGNMENT','VOIDED_SPLIT_PAYMENT']
  TransactionConstants = TransactionConstants;
  constructor() {}

  ngOnInit() {}
}
