import { CommonModule } from '@angular/common';
import { CsShellComponent } from './cs-shell.component';
import { HeaderComponent } from './header/header.component';
import { I18nModule } from '@app/i18n';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule, I18nModule, RouterModule, SharedModule],
  declarations: [HeaderComponent, CsShellComponent],
})
export class CsShellModule {}
