<ng-template #selectAllTpl let-selectOptions="selectOptions" let-filteredSource="filteredSource">
  <div class="select-all">
    <mat-checkbox class="mat-option" color="primary" [(ngModel)]="selectAll" [ngModelOptions]="{ standalone: true }" (change)="toggleAllSelection($event, filteredSource)">Select All</mat-checkbox>
  </div>
</ng-template>

<mat-select
  #matSelect
  ngxMatSelect
  [multiple]="true"
  [hasSearchBox]="true"
  [(ngModel)]="_value"
  [searcher]="searcher"
  [lazyLoad]="true"
  [disabled]="disabled"
  [source]="field.templateOptions.options | formlySelectOptions: field | async"
  [displayMember]="'label'"
  [valueMember]="'value'"
  (selectionChange)="onSelectionChange($event)"
  (filteredSourceChange)='onFilteredSourceChange($event)'
  [placeholder]="placeholder | translate"
  [maximumResultForShow]='99999'
  #sf="ngxMatSelect"
>
  <mat-select-trigger>
    <span *ngIf="selectAll; else listingTmpl">All</span>
    <ng-template #listingTmpl>
      <span>{{ selectionStringArray.length > 0 ? selectionStringArray.join(',') : '' }}</span>
    </ng-template>
  </mat-select-trigger>
  <ng-container *ngIf="field.templateOptions.options | formlySelectOptions: field | async as selectOptions">
    <ng-container [ngTemplateOutlet]="selectAllTpl" [ngTemplateOutletContext]="{ selectOptions: selectOptions, filteredSource: sf.filteredSource }"> </ng-container>
    <ng-container *ngFor="let item of sf.filteredSource">
      <mat-option [value]="item.value" [disabled]="item.disabled" (mousedown)="getMousedownPosition($event)">{{ item.label }}</mat-option>
    </ng-container>
  </ng-container>
</mat-select>
