import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Logger, untilDestroyed } from '@core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { merge } from 'rxjs';
// Services
import { I18nService } from '@app/i18n';
import { AuthIdleService, BreadcrumbService, DialogService, RequestService, UrlService, UserService } from '@core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private previousUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private breadcrumbService: BreadcrumbService,
    private authIdleService: AuthIdleService,
    private userService: UserService,
    private requestService: RequestService,
    private urlService: UrlService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    // Setup application logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change breadcrumb on navigation
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event: any) => {
        this.breadcrumbService.loadPageMetadata(event);
      });

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    onNavigationEnd
      .pipe(
        map((value) => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(
          (route) =>
            route.outlet === 'primary' &&
            route.snapshot.fragment !== 'changeLP' && // check fragment when swapping LP to prevent pre-mature calling with old LP header
            this.userService.hasPermission('loyalty#guest#workflow#workflowRequest#listInbox')
        ),
        untilDestroyed(this)
      )
      .subscribe((event: ActivatedRoute) => {
        // this.requestService.retrieveSideMenuInputNumber(false).subscribe();
      });

    // Save Previous URL
    onNavigationEnd.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.urlService.setPreviousUrl(this.previousUrl);
      this.previousUrl = event.url;
    });
    // Set Idle Watcher
    this.authIdleService.setIdleWatcher();
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
