import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoyaltyService } from '@core/services/loyalty';
import { MembershipTierService } from './membership-tier.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MembershipTierResolverService {
  constructor(private router: Router, private membershipTierService: MembershipTierService, private loyaltyService: LoyaltyService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.loyaltyService.currentProgramme.id) {
      this.router.navigate(['/admin/home']);
    }

    return this.membershipTierService
      .getTierList({
        businessUnit: this.loyaltyService.currentProgramme.businessUnit,
        loyaltyProgram: this.loyaltyService.currentProgramme.code,
        orderBy: 'level',
      })
      .pipe(catchError((err) => this.router.navigateByUrl('/')));
  }
}
