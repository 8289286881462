import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { AfterContentChecked, AfterViewInit, ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDateRangeInput } from '@angular/material/datepicker';
import { MatFormField } from '@angular/material/form-field';
import { MaskedDatepickerComponent } from '@app/@shared/components/masked-datepicker/masked-datepicker.component';

@Directive({
  selector: 'mat-form-field',
})
export class DeleteInputFormFieldDirective implements OnDestroy, AfterViewInit, AfterContentChecked {
  @Input() isRemoveDeleteIcon = false;
  private buttonFactory: ComponentFactory<MatButton>;
  private button: ComponentRef<MatButton>;
  private focused = false;

  get nativeElement(): HTMLElement {
    return this.eleRef.nativeElement;
  }

  get buttonElement(): HTMLElement {
    return this.button.instance._elementRef.nativeElement;
  }

  get control() {
    return this.matFormField._control.ngControl?.control;
  }

  get textArea() {
    return this.eleRef.nativeElement.querySelector('textarea');
  }

  get isMaskedDatepickerComponent() {
    return this.matFormField._control instanceof MaskedDatepickerComponent;
  }

  get MatDateRangeInput() {
    return this.matFormField._control as MatDateRangeInput<any>;
  }

  constructor(
    private fm: FocusMonitor,
    private eleRef: ElementRef<any>,
    private matFormField: MatFormField,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.buttonFactory = this.componentFactoryResolver.resolveComponentFactory(MatButton);
  }

  ngAfterContentChecked() {}

  ngAfterViewInit() {
    if (!this.isRemoveDeleteIcon) {
      this.fm.monitor(this.eleRef, true).subscribe((origin: FocusOrigin) => {
        this.focused = !!origin;
        if ((this.control || this.MatDateRangeInput) && !this.control?.disabled) {
          if (this.focused) {
            if (!this.button) {
              if (!this.MatDateRangeInput.rangePicker || (this.MatDateRangeInput.rangePicker && this.MatDateRangeInput.rangePicker?.opened)) this.createDeleteInputButton();
            }
          } else {
            this.destroyDeleteInputIcon();
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.isRemoveDeleteIcon) this.fm.stopMonitoring(this.nativeElement);
  }

  private createDeleteInputButton() {
    const matSelectArrowWrapper = this.nativeElement.querySelector('.mat-select-arrow-wrapper');
    const matFormFieldSuffix = this.nativeElement.querySelector('.mat-form-field-suffix');
    const matDatepickerToggle = this.nativeElement.querySelector('mat-datepicker-toggle');

    if (!this.button) {
      this.button = this.viewContainerRef.createComponent(this.buttonFactory);
      this.button.instance.color = 'primary';
      this.renderer.addClass(this.buttonElement, 'mat-icon-button');
      this.buttonElement.addEventListener('click', this.clearInput.bind(this));
      this.renderer.setAttribute(this.buttonElement, 'type', 'button');
      this.renderer.addClass(this.buttonElement, 'delete-input-btn');
      this.renderer.addClass(this.buttonElement, this.isMaskedDatepickerComponent ? 'datepicker' : matSelectArrowWrapper ? 'select' : 'normal');
      this.renderer.appendChild(this.buttonElement.querySelector('.mat-button-wrapper'), this.createDeleteIcon());

      const focusDiv = this.renderer.createElement('div');
      this.renderer.setAttribute(focusDiv, 'tabindex', '-1');
      this.renderer.appendChild(focusDiv, this.buttonElement);
      this.renderer.addClass(focusDiv, 'focus-input');

      if (this.isMaskedDatepickerComponent || this.nativeElement.querySelector('.mat-datepicker-toggle')) {
        this.renderer.setStyle(focusDiv, 'display', 'inline');
        this.renderer.insertBefore(this.nativeElement.querySelector('.masked-datepicker') || matFormFieldSuffix, focusDiv, matDatepickerToggle);
      } else if (this.matFormField._control instanceof MatDateRangeInput) {
        this.renderer.setStyle(focusDiv, 'display', 'inline');
        matDatepickerToggle && matFormFieldSuffix.hasChildNodes() ? this.renderer.insertBefore(matFormFieldSuffix, focusDiv, matDatepickerToggle) : this.renderer.appendChild(matFormFieldSuffix, focusDiv);
      } else if (matSelectArrowWrapper) {
        const iconDiv = this.renderer.createElement('div');
        this.renderer.addClass(iconDiv, 'mat-select-delete-icon-wrapper');
        this.renderer.appendChild(iconDiv, focusDiv);
        this.renderer.insertBefore(matSelectArrowWrapper.parentElement, iconDiv, matSelectArrowWrapper);
      } else {
        const iconDiv = this.renderer.createElement('div');
        this.renderer.addClass(iconDiv, this.textArea ? 'delete-text-area-btn-container' : 'mat-form-field-suffix');
        this.renderer.appendChild(iconDiv, focusDiv);
        this.renderer.appendChild(this.nativeElement.querySelector(this.textArea ? '.mat-form-field-wrapper' : '.mat-form-field-flex'), iconDiv);
      }
    }
  }

  private createDeleteIcon() {
    const matSelectArrowWrapper = this.nativeElement.querySelector('.mat-select-arrow-wrapper');
    const icon = this.renderer.createElement('mat-icon');
    const iconHeight = this.textArea ? '24px' : '14px';
    const iconWidth = '14px';
    const iconLineHeight = matSelectArrowWrapper || this.isMaskedDatepickerComponent ? '14px' : null;

    this.renderer.setStyle(icon, 'width', iconWidth);
    this.renderer.setStyle(icon, 'height', iconHeight);
    if (iconLineHeight) this.renderer.setStyle(icon, 'line-height', iconLineHeight);
    if (this.textArea) this.renderer.setStyle(icon, 'line-height', 1);
    this.renderer.setStyle(icon, 'font-size', '14px');
    this.renderer.appendChild(icon, this.renderer.createText('close'));
    this.renderer.addClass(icon, 'mat-icon');
    this.renderer.addClass(icon, 'material-icons');
    return icon;
  }

  private destroyDeleteInputIcon() {
    const suffixDiv = this.nativeElement.querySelector(this.textArea ? '.delete-text-area-btn-container' : '.mat-form-field-suffix');
    const matDatePickerToggle = this.nativeElement.querySelector('mat-datepicker-toggle');
    const focusInput = this.nativeElement.querySelector('.focus-input');
    const matSelectDeleteWrapper = this.nativeElement.querySelector('.mat-select-delete-icon-wrapper');
    if (suffixDiv && !suffixDiv.contains(matDatePickerToggle)) {
      if (!suffixDiv.hasChildNodes()) {
        this.renderer.removeChild(this.nativeElement.querySelector(this.textArea ? '.mat-form-field-wrapper' : '.mat-form-field-flex'), suffixDiv);
      } else {
        if (focusInput) {
          this.renderer.removeChild(suffixDiv, focusInput);
        }
      }
    }

    if (matSelectDeleteWrapper) {
      this.renderer.removeChild(matSelectDeleteWrapper.parentElement, matSelectDeleteWrapper);
    }

    if (this.button) {
      this.button.destroy();
      this.button = null;
    }
  }

  private clearInput(e: any) {
    e.stopPropagation();

    if (this.control) {
      this.control.setValue(null);
    } else if (this.matFormField._control instanceof MatDateRangeInput) {
      this.MatDateRangeInput._startInput.ngControl?.control.setValue(null);
      this.MatDateRangeInput._endInput.ngControl?.control.setValue(null);
    }
  }
}
