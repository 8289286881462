import { CommonModule } from '@angular/common';
import { ConfigShellComponent } from './config-shell.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { I18nModule } from '@app/i18n';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [SharedModule, CommonModule, TranslateModule, I18nModule, RouterModule],
  declarations: [HeaderComponent, ConfigShellComponent, SidenavComponent, FooterComponent, SideMenuComponent],
})
export class ConfigShellModule {}
