import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent extends FieldType implements OnInit, OnDestroy {
  dateFromKey: string;
  dateToKey: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.dateFromKey = this.to.dateFromKey || 'dateFrom';
    this.dateToKey = this.to.dateToKey || 'dateTo';
    const validators = [];
    if (this.field.validators?.validation.length > 0) {
      validators.push(this.field.validators.validation[0]);
    }

    validators.push(this.dateRangeValidator.bind(this));
    this.formControl.setValidators(validators);
  }

  ngOnDestroy() {}

  dateRangeValidator(fg: UntypedFormGroup) {
    const dateFrom = this.hasUniqueDateKey ? fg.value[this.to.dateFromKey] : fg.value.dateFrom;
    const dateTo = this.hasUniqueDateKey ? fg.value[this.to.dateToKey] : fg.value.dateTo;
    const dateFromFormControl = fg.get(this.dateFromKey);
    const dateToFormControl = fg.get(this.dateToKey);
    if (this.to.required) {
      if (!dateFrom) dateFromFormControl.setErrors({ required: true });
      if (!dateTo) dateToFormControl.setErrors({ required: true });

      return !dateFrom || !dateTo ? { bothRequired: true } : null;
    } else {
      const dateRangeIsInvalid = (!dateFrom && dateTo) || (dateFrom && !dateTo);
      if (dateRangeIsInvalid) {
        if (!dateFrom) dateFromFormControl.setErrors({ required: true });
        if (!dateTo) dateToFormControl.setErrors({ required: true });
      } else {
        dateFromFormControl.setErrors(null);
        dateToFormControl.setErrors(null);
      }
      return (!dateFrom && dateTo) || (dateFrom && !dateTo) ? { bothRequired: true } : null;
    }
  }

  get dateFrom() {
    return this.formControl.get('dateFrom');
  }

  get dateTo() {
    return this.formControl.get('dateTo');
  }

  get hasUniqueDateKey() {
    return this.to.dateFromKey && this.to.dateToKey;
  }
}
