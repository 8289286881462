import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
/**
 * Append UUID into request header transaction tracing
 */
export class ApiTracerInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers = request.headers;

    // Set APP Channel
    headers = headers?.set('X-SPL-TRACEID', uuidv4());

    return next.handle(
      request.clone({
        headers,
      })
    );
  }
}
