import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  public isMenuOpenedSubject$ = new BehaviorSubject<boolean>(localStorage.getItem('isMenuOpened') === 'true' ? true : false);
  private isHideContainerPaddingSubject$ = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isMenuOpened$ = this.isMenuOpenedSubject$.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isHideContainerPadding$ = this.isHideContainerPaddingSubject$.asObservable();

  private sidenav: MatSidenav;

  constructor() {}

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public open() {
    return this.sidenav.open();
  }

  public close() {
    return this.sidenav.close();
  }

  toggleSidenav() {
    this.isMenuOpened$.pipe(take(1)).subscribe((isOpen) => {
      localStorage.setItem('isMenuOpened', (!isOpen).toString());
      this.isMenuOpenedSubject$.next(!isOpen);
    });
  }

  setContainerPadding(isHidePadding: boolean) {
    this.isHideContainerPaddingSubject$.next(isHidePadding);
  }
}
