<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="close-button has-dialog-title">
    <h2>Verified Transaction info</h2>
    <button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12 pb-3">
      <label class="view-label-blue">Failure Reason</label>
      <ng-container *ngFor="let verifyFailureReason of verifyFailureReasons; let last = last; let first = first">
        <span *ngIf="!first">,</span>
        <span>
          {{ verifyFailureReason | replaceEmptyValue | genericCategory: 'RECEIPT_TRANSACTION.VERIFY_FAILURE_REASON' | async }}
        </span>
      </ng-container>
    </div>
    <div class="col-12 pb-3">
      <label class="view-label-blue"
        >Tenant Name <span class="ocr-label">Original: {{ data.transactionDetail.tenantId | merchant | async }}</span>
      </label>
      <span> {{ data.transactionDetail.verifiedTenantId | merchant | async }}</span>
    </div>
    <div class="col-12 pb-3">
      <label class="view-label-blue"
        >Transaction Date <span class="ocr-label">Original: {{ data.transactionDetail.transactionDate | replaceEmptyValue | systemDateFormat }}</span></label
      >
      <span> {{ data.transactionDetail.verifiedTransactionDate | replaceEmptyValue | systemDateFormat }}</span>
    </div>
    <div class="col-12 pb-3">
      <label class="view-label-blue"
        >Transaction Amount <span class="ocr-label">Original: {{ data.transactionDetail.transactionAmount | systemCurrency }}</span></label
      >
      <span> {{ data.transactionDetail.verifiedTransactionAmount | systemCurrency }}</span>
    </div>
    <div class="col-12 pb-3">
      <label class="view-label-blue"
        >Electron Receipt Amount <span class="ocr-label">Original: {{ data.transactionDetail.receiptAmount | systemCurrency }}</span></label
      >
      <span> {{ data.transactionDetail.verifiedReceiptAmount | systemCurrency }}</span>
    </div>
    <div class="col-12 pb-3">
      <label class="view-label-blue"
        >Invoice Number <span class="ocr-label">Original: {{ data.transactionDetail.invoiceNumbers }}</span></label
      >
      <span> {{ data.transactionDetail.verifiedInvoiceNumbers }}</span>
    </div>
    <div class="col-12 pb-3">
      <label class="view-label-blue">Comment</label>
      <span> {{ data.transactionDetail.verifiedRemarks | replaceEmptyValue }}</span>
    </div>
    <div class="col-12 pb-3">
      <label class="view-label-blue">Actioned by</label>
      <span> {{ data.transactionDetail.verifiedBy | replaceEmptyValue }}</span>
    </div>
    <div class="col-12 pb-3">
      <label class="view-label-blue">Actioned Datetime</label>
      <span> {{ data.transactionDetail.verifiedDate | systemDateTimeFormat }}</span>
    </div>
  </div>
</div>
