import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Input } from '@angular/core';
import { Directive } from '@angular/core';
import { MembersConstants } from '@app/pages/members/members.constants';
import { ViewTransactionConstants } from '@app/pages/receipt-transaction/view-transaction/view-transaction.constants';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[transactionStatus]',
})
export class TransactionStatusDirective implements OnInit {
  @Input('transactionStatus') status: string;
  @Input('submittedWithOngoingWorkFlow') submittedWithOngoingWorkFlow: boolean = false;
  @Input('approvedWithOngoingWorkFlow') approvedWithOngoingWorkFlow: boolean = false;

  domElement: HTMLElement;

  constructor(private renderer: Renderer2, private elementRef: ElementRef, translate: TranslateService) {
    this.domElement = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    const requiredClasses: string[] = ['badge-text'];
    switch (this.status) {
      case ViewTransactionConstants.STATUS_SUBMITTED:
        let text = requiredClasses.push('grey');
        this.domElement.innerText = this.submittedWithOngoingWorkFlow ? 'RECEIPT_TRANSACTIONS.LABEL.STATUS_SUBMITTED_WITH_WORKFLOW' : 'RECEIPT_TRANSACTIONS.LABEL.STATUS_SUBMITTED';
        break;
      case ViewTransactionConstants.TRANSACTION_STATUS_EXPIRED:
        requiredClasses.push('grey');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_EXPIRED';
        break;
      case ViewTransactionConstants.STATUS_PENDING_FOR_APPROVAL:
        requiredClasses.push('black');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.PENDING_FOR_APPROVAL';
        break;
      case ViewTransactionConstants.TRANSACTION_STATUS_APPROVED:
        requiredClasses.push('green');
        this.domElement.innerText = this.approvedWithOngoingWorkFlow ? 'RECEIPT_TRANSACTIONS.LABEL.STATUS_APPROVED_WITH_WORKFLOW' : 'RECEIPT_TRANSACTIONS.LABEL.STATUS_APPROVED';
        break;
      case ViewTransactionConstants.TRANSACTION_STATUS_REJECTED:
        requiredClasses.push('red');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_REJECTED';
        break;
      case ViewTransactionConstants.TRANSACTION_STATUS_VOIDED:
        requiredClasses.push('red');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_VOIDED';
        break;
      case ViewTransactionConstants.STATUS_LOCKED:
        requiredClasses.push('grey');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_LOCKED';
        break;
      case ViewTransactionConstants.STATUS_NON_ACTIVATED:
        requiredClasses.push('grey');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_NON_ACTIVATED';
        break;
      case ViewTransactionConstants.STATUS_CONSIGNMENT_PEND_FOR_APPROVE:
        requiredClasses.push('grey');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_CONSIGNMENT_PEND_FOR_APPROVE';
        break;
      case ViewTransactionConstants.STATUS_CONSIGNMENT_PEND_FOR_COMBINE:
        requiredClasses.push('grey');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_CONSIGNMENT_PEND_FOR_COMBINE';
        break;
      case ViewTransactionConstants.STATUS_CONSIGNMENT_COMBINED:
        requiredClasses.push('grey');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_CONSIGNMENT_COMBINED';
        break;
      case ViewTransactionConstants.STATUS_SPLIT_PAYMENT:
        requiredClasses.push('grey');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_SPLIT_PAYMENT';
        break;
      case ViewTransactionConstants.STATUS_NO_POINT_EARN:
        requiredClasses.push('grey');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_NO_POINT_EARN';
        break;
      case ViewTransactionConstants.STATUS_REJECTED_CONSIGNMENT:
        requiredClasses.push('red');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_REJECTED_CONSIGNMENT';
        break;
      case ViewTransactionConstants.STATUS_REJECTED_SPLIT_PAYMENT:
        requiredClasses.push('red');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_REJECTED_SPLIT_PAYMENT';
        break;
      case ViewTransactionConstants.STATUS_VOIDED_CONSIGNMENT:
        requiredClasses.push('red');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.STATUS_VOIDED_CONSIGNMENT';
        break;
      case ViewTransactionConstants.VOIDED_SPLIT_PAYMENT:
        requiredClasses.push('red');
        this.domElement.innerText = 'RECEIPT_TRANSACTIONS.LABEL.VOIDED_SPLIT_PAYMENT';
        break;
      default:
        requiredClasses.push('black');
        this.domElement.innerText = this.status;
    }
    requiredClasses.forEach((newClass) => {
      this.renderer.addClass(this.domElement, `${newClass}`);
    });
  }
}
