export abstract class RequestConstants {
  public static WORKFLOW_STATUS_APPROVED = 'APPROVED';
  public static WORKFLOW_STATUS_REJECTED = 'REJECTED';
  public static WORKFLOW_STATUS_SUBMITTED = 'SUBMITTED';
  public static WORKFLOW_STATUS_CANCELLED = 'CANCELLED';
  public static WORKFLOW_ACTION_REVERT = 'REVERT';
  public static WORKFLOW_ACTION_SUBMIT = 'SUBMIT';
  public static WORKFLOW_STATUS_LOCKED = 'LOCKED';
  public static REJECT_BTN_STYLE = { color: '#ff2032', border: '1px solid #ff2032' };
  public static REVERT_BTN_STYLE = { color: '#1b4266', border: '1px solid #1b4266' };
  public static WORKFLOW_APPROVAL_HISTORY_REVERTED = 'REVERTED';
  public static POINT_ADJUSTMENT_REQUEST_POINT_TYPE_REDEMPTION = 'REDEMPTION';
  public static POINT_ADJUSTMENT_REQUEST_POINT_TYPE_TIER = 'TIER';
  public static REQUEST_VIEW_SOURCE_HK1CRM_WEB = 'HK1CRM_WEB';
  public static REQUEST_MOBILE_AREA_CODE_OTHERS = 'Others';
}
