<app-cs-header></app-cs-header>
<mat-sidenav-container class="cs-content-container">
  <mat-sidenav-content>
    <div class="sticky">
      <app-breadcrumb></app-breadcrumb>
    </div>
    <div>
      <c-stack-viewer *ngIf="isShowStackViewer"></c-stack-viewer>
    </div>
    <div [style.display]="isShowStackViewer ? 'none' : null">
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
