import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/application/api-response';
import {
  WorkflowRequestAttachments,
  ChangeRequestResponse,
  ExtendRedemptionPointExpiryRequest,
  ExtendTierExpiryRequest,
  WorkflowRequestApproveRejectResponse,
  WorkflowRequest,
  WorkflowConfig,
} from '@core/models/workflow/request/change-request';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ChangeRequestService {
  headers = {
    headers: {
      'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
    },
  };

  private rawWorkflowConfig: Observable<any[]> = null;

  constructor(private httpService: HttpClient) {}

  changeRequest(requestType: string, params?: ExtendRedemptionPointExpiryRequest | ExtendTierExpiryRequest) {
    return this.httpService.post(`/changeRequest/${requestType}`, params, this.headers).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<ChangeRequestResponse>;
  }

  memberUpdateChangeRequest(requestType: string, params?: any) {
    // if(params.dayOfBirth===''){params.dayOfBirth='-'}
    return this.httpService.post(`/changeRequest/MEMBER_UPDATE/${requestType}`, params, this.headers).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<ChangeRequestResponse>;
  }

  uploadAttachment(attachment: File, bu?: string, lp?: string, isReturnFileInfo: boolean = false) {
    const formData = new FormData();
    formData.append('attachment', attachment);
    if (bu) formData.append('bu', bu);
    if (lp) formData.append('lp', lp);

    return this.httpService.post(`/workflow/attachment`, formData).pipe(
      map((response: ApiResponse) => {
        if (isReturnFileInfo) {
          return {
            fileName: attachment.name,
            filePath: response.data,
            fileSize: attachment.size,
            fileType: attachment.type,
          };
        } else {
          return response.data;
        }
      })
    );
  }

  retrieveRawWorkflowConfig() {
    if (!this.rawWorkflowConfig) {
      this.rawWorkflowConfig = this.httpService.get('/workflow/workflowConfigList').pipe(
        map((res: any) => {
          return res.data;
        })
      ) as Observable<any[]>;
    }

    return this.rawWorkflowConfig;
  }

  retrieveWorkflowConfig() {
    return this.httpService.get('/workflow/workflowConfigList').pipe(
      map((res: any) => {
        const filteredRes = res.data.map((d: any) => {
          return {
            type: d.type,
            reason: d.reason,
          };
        });
        return filteredRes;
      })
    ) as Observable<WorkflowConfig[]>;
  }

  retrieveWorkflowRequest(requestId: string | number) {
    return this.httpService.get(`/workflow/${requestId}`).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<WorkflowRequest>;
  }

  approveRejectWorkflowRequest(params: any, requestType: string) {
    return this.httpService.put(`/changeRequest/${requestType}`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<WorkflowRequestApproveRejectResponse>;
  }

  downloadWorkflowAttachment(params?: any) {
    return this.httpService.get('/workflow/attachment', { params, responseType: 'blob' as 'json' }).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  downloadTransactionAttachment(params?: any) {
    return this.httpService.get('/transaction/attachment', { params, responseType: 'blob' as 'json' }).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    ) as Observable<any>;
  }

  convertWorkflowAttachment(attachments: WorkflowRequestAttachments[]) {
    return attachments.map(({ fileName, fileType, filePath, fileSize, uploadedBy, modifiedDate, id }) => {
      return {
        fileName,
        filePath,
        fileSize,
        fileType,
        isUploaded: false,
        uploadedBy,
        id,
        displayedFileName: `${fileName}.${fileType}`,
        uploadedOn: modifiedDate,
      };
    });
  }

  getWorkflowRequestByTxnId(transactionId: number | string) {
    return this.httpService.get(`/workflow/RECEIPT_APPROVAL?receiptTransactionId=${transactionId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  revertWorkflowRequest(params: any, requestType: string) {
    return this.httpService.put(`/changeRequest/revert/${requestType}`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<WorkflowRequestApproveRejectResponse>;
  }

  lockWorkflowRequestById(id: number | string) {
    return this.httpService.post(`/workflow/lock`, { id }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  workflowMapIsOrdering(a: any, b: any) {
    const aStepId = parseInt(a.key, 10);
    const bStepId = parseInt(b.key, 10);
    return aStepId > bStepId ? 1 : bStepId > aStepId ? -1 : 0;
  }

  unlockWorkflowRequestById(id: number | string) {
    return this.httpService.post(`/workflow/unlock`, { id }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  cancelWorkflowRequest(requestType: string, params: any): Observable<any> {
    return this.httpService.put(`/changeRequest/cancel/${requestType}`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any>;
  }

  monthlyMatDatepickerFilter(date: moment.Moment) {
    if (date) {
      const currentDate = date.toDate();
      const endDate = moment(date).endOf('month').toDate();
      return currentDate.getMonth() === endDate.getMonth() && currentDate.getDate() === endDate.getDate();
    } else return false;
  }

  quarterlyDatePickerFilter(date: moment.Moment) {
    if (date) {
      const currentDate = date.toDate();
      const endDate = moment(date).endOf('month').toDate();
      return [2, 5, 8, 11].includes(currentDate.getMonth()) && currentDate.getMonth() === endDate.getMonth() && currentDate.getDate() === endDate.getDate();
    } else return false;
  }
}
