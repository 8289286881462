import { Directive, Input, OnChanges, OnInit, SimpleChanges, ComponentFactoryResolver, ViewContainerRef, Renderer2, ComponentFactory, ComponentRef, ElementRef, Injector } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector: '[appLoadingButton]',
})
export class LoadingButtonDirective implements OnInit, OnChanges {
  @Input('appLoadingButton') isLoading = false;
  @Input() disabled: boolean;

  private spinnerFactory: ComponentFactory<MatProgressSpinner>;
  private spinner: ComponentRef<MatProgressSpinner>;

  get nativeElement(): HTMLElement {
    return this.eleRef.nativeElement;
  }

  private instanceRef: MatButton | MatAnchor;

  constructor(private eleRef: ElementRef<any>, private injector: Injector, private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, private renderer: Renderer2) {
    this.spinnerFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
    this.instanceRef = this.eleRef.nativeElement.tagName === 'BUTTON' ? this.injector.get(MatButton) : this.injector.get(MatAnchor);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.isLoading) {
      return;
    }

    if (changes.isLoading.currentValue) {
      this.nativeElement.classList.add('mat-loading');
      this.instanceRef.disabled = true;
      this.createSpinner();
    } else if (!changes.isLoading.firstChange) {
      this.nativeElement.classList.remove('mat-loading');
      this.instanceRef.disabled = this.disabled;
      this.destroySpinner();
    }
  }

  private createSpinner(): void {
    if (!this.spinner) {
      this.spinner = this.viewContainerRef.createComponent(this.spinnerFactory);
      this.spinner.instance.diameter = 20;
      this.spinner.instance.mode = 'indeterminate';
      this.spinner.instance.color = 'accent';
      this.renderer.appendChild(this.nativeElement, this.spinner.instance._elementRef.nativeElement);
    }
  }

  private destroySpinner(): void {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }
}
