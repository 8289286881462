import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MemberService } from '@core/services/member/member.service';
import { QrCodeConstants } from './qr-code-prefix.constants';
import { QrCodeScannerComponent } from '@app/@shared/components/qrcode-scanner/qrcode-scanner.component';
import { RedemptionService } from '@core/services/campaigns/redemption.service';
import { Router } from '@angular/router';
import { validate as isValidUuid } from 'uuid';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class QrCodeScannerService {
  qrDialogRef: MatDialog;
  constructor(private dialog: MatDialog, private qrCodeConstants: QrCodeConstants, private memberService: MemberService, private redemptionService: RedemptionService, private router: Router) {}

  scanQrCode(dialogData: any) {
    const scannerDialogRef = this.dialog.open(QrCodeScannerComponent, {
      width: '100vw',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100%',
      disableClose: false,
      panelClass: 'qr-code-scanner',
      data: dialogData,
    });
    scannerDialogRef.afterClosed().subscribe((result: any) => {
      // Returned scanned result
      if (result) {
        dialogData.successCallback(result);
      }
    });
  }

  processQrCode(qrCodeResponse: string) {
    if (isValidUuid(qrCodeResponse)) {
      this.redirectToActualizeGift(qrCodeResponse);
    } else {
      this.memberService.getIdFromQrCode(qrCodeResponse).subscribe((memberIdRes) => {
        this.memberService.getMemberDetail(memberIdRes).subscribe(() => {
          this.router.navigate([`admin/members/view/${memberIdRes}`]);
        });
      });
    }
  }

  private redirectToActualizeGift(giftUuid: string) {
    this.redemptionService
      .retrieveActualizeQrCodeData({ uuid: giftUuid })
      .pipe(take(1))
      .subscribe((guestGift: any) => {
        // if (!this.router.url.includes('members/view')) {
        this.router.navigate([`/admin/members/view/${guestGift.guest}`], { fragment: 'tabIndex=3' }).then(() => {
          this.redemptionService.setGift({ giftUuid, guestGift });
        });
        // }
      });
  }
}
