import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../models';

@Injectable({
    providedIn: 'root',
})
export class AccountCodeService {

    constructor(private httpService: HttpClient) { }
    // add Code
    addAccountCodes(params?: any) {
        return this.httpService.post('/reimbursement/accountCodes', params).pipe(
            map((response: any) => {
            return response.data;
            })
        ) as Observable<any>;
    }
    // list 
    accountCodesList(params?: any) {
        return this.httpService.get('/reimbursement/accountCode/list', {params}).pipe(
            map((response: any) => {
            return response.data;
            })
        ) as Observable<any>;
    }
}
