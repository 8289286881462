import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FormlySelectOptionsPipe } from '../formly-select-options.pipe';

@Component({
  selector: 'formly-select-all',
  templateUrl: './formly-select-options.component.html',
  styleUrls: ['./formly-select-options.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FormlySelectOptionsComponent }],
})
export class FormlySelectOptionsComponent implements OnInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;
  @ViewChild('matSelect', { static: false }) matSelect: MatSelect;
  @Input() optionList: { label: any; value: any }[];
  @Input() fieldLabel: string;
  @Input() field: FormlyFieldConfig;
  @Input() isReturnAllOption = false;
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return this.value ? this.value.length === 0 : true;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  @Input()
  get value(): any[] {
    return this._value || [];
  }
  set value(options: any[]) {
    this._value = options || [];
    this.stateChanges.next();
  }

  get errorState() {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  stateChanges = new Subject<void>();
  @HostBinding() id = `select-all-dropdown-${FormlySelectOptionsComponent.nextId++}`;

  public selectionStringArray: string[] = [];

  public touched: boolean;
  public selectAll: boolean;

  focused = false;
  scrollPosition: any = {};

  public _value: any[];
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  private formlySelectOptionsPipe = new FormlySelectOptionsPipe();

  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin && !this.disabled;
      this.stateChanges.next();
    });
  }

  onSelectionChange(e: MatSelectChange) {
    let optionListFullLength: number;
    if (!(this.field.templateOptions.options instanceof Observable)) {
      optionListFullLength = this.field.templateOptions.options.length;
      this.selectionStringArray = this.transformOptions(this.field.templateOptions.options)
        .filter((option) => e.value.includes(option.value))
        .map((option) => option.label);
    } else {
      this.field.templateOptions.options
        .pipe(
          tap((options: any[]) => {
            optionListFullLength = options.length;
            this.selectionStringArray = this.transformOptions(options)
              .filter((option) => e.value.includes(option.value))
              .map((option) => option.label);
          })
        )
        .subscribe();
    }
    this.selectAll = optionListFullLength === e.value.length;
    this.value = e.value;
    this.onChange(this.selectAll && !this.isReturnAllOption ? ['ALL'] : e.value);

    if (this.matSelect?.panel) {
      this.matSelect.panel.nativeElement.scrollTop = this.getOptionScrollPosition(
        this.scrollPosition.offsetTop,
        this.scrollPosition.offsetHeight,
        this.scrollPosition.panelScrollTop,
        this.scrollPosition.panelOffsetHeight
      );
    }
    this.stateChanges.next();
  }

  onFilteredSourceChange(event: any) {
    this.cd.detectChanges();
  }

  getMousedownPosition($event: any) {
    const panel: HTMLElement = this.matSelect.panel ? this.matSelect.panel.nativeElement : null;

    if (panel) {
      this.scrollPosition.offsetTop = $event.currentTarget ? $event.currentTarget.offsetTop : 0;
      this.scrollPosition.offsetHeight = $event.currentTarget ? $event.currentTarget.offsetHeight : 0;
      this.scrollPosition.panelScrollTop = panel.scrollTop;
      this.scrollPosition.panelOffsetHeight = panel.offsetHeight;
    }
  }

  getOptionScrollPosition(optionOffset: number, optionHeight: number, currentScrollPosition: number, panelHeight: number) {
    if (optionOffset < currentScrollPosition) {
      return optionOffset;
    }

    if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
      return Math.max(0, optionOffset - panelHeight + optionHeight);
    }

    return currentScrollPosition;
  }

  onSelectAllOuterDivClick(e: any, options: any[]) {
    e.preventDefault();
    this.selectAll = !this.selectAll;
    this.toggleAllSelection({ checked: this.selectAll }, options);
  }

  toggleAllSelection(e: any, options: any[]) {
    this.value = e.checked ? options.map((option) => option.value) : [];
    this.onChange(e.checked ? (this.isReturnAllOption ? options.map((option) => option.value) : ['ALL']) : null);
    this.stateChanges.next();
  }

  ngOnInit(): void {
    if (this.field.templateOptions.isDefaultSelectAll) {
      this.defaultSelectAll();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  writeValue(obj: any): void {
    if (!obj) {
      if (this.field.templateOptions.isDefaultSelectAll) {
        this.defaultSelectAll();
      } else {
        this.value = obj;
        this.selectionStringArray = [];
        this.selectAll = false;
      }
    } else if(this.field?.templateOptions?.isDefaultSelectValue) {
      this.onSelectionChange({value:obj} as any)
    } else if (obj[0]?.notSelectAll) {
      this.value = [];
      this.selectionStringArray = [];
      this.selectAll = false;
      this.onChange([]);
    } else if (obj[0] !== 'ALL') {
      this.value = obj.map((option: any) => option.value);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(delta: any) {}

  onTouched() {
    this.touched = true;
    this.stateChanges.next();
  }

  onContainerClick(event: MouseEvent): void {
    this.onTouched();
  }

  setDescribedByIds(ids: string[]) {}

  transformOptions(options: any[]) {
    return options.map((option) => {
      return {
        label: option[this.field.templateOptions.labelProp],
        value: option[this.field.templateOptions.valueProp],
      };
    });
  }

  defaultSelectAll() {
    if (this.field.templateOptions.options instanceof Observable) {
      this.field.templateOptions.options
        .pipe(
          tap((options: any[]) => {
            const dropdownOptions = this.transformOptions(options);
            this.value = dropdownOptions.map((option: any) => option.value);
            this.selectAll = true;
            this.onChange(this.isReturnAllOption ? dropdownOptions.map((option) => option.value) : ['ALL']);
            this.selectionStringArray = dropdownOptions.map((option) => option.label);
          })
        )
        .subscribe((options) => {});
    } else {
      const dropdownOptions = this.transformOptions(this.field.templateOptions.options);
      this.selectionStringArray = dropdownOptions.map((option) => option.label);
      this.value = dropdownOptions.map((option: any) => option.value);
      this.selectAll = true;
      this.onChange(this.isReturnAllOption ? dropdownOptions.map((option) => option.value) : ['ALL']);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  searcher = (search: string): Observable<any[]> => {
    if(!search || search == "") return this.formlySelectOptionsPipe.transform(this.field.templateOptions.options, this.field);

    return this.formlySelectOptionsPipe.transform(this.field.templateOptions.options, this.field).pipe(
      map((options) => {
        return options.filter((option: any) => {
          return option.label.toLowerCase().includes(search.toLowerCase());
        });
      })
    );
  }
}
