import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoyaltyService } from '@core/services/loyalty';
import { environment } from '@env/environment';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { DebugService } from '@core/services/application/debug.service';
import { UserService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthInterceptor implements HttpInterceptor {
  constructor(private oAuthStorage: OAuthStorage, private loyaltyService: LoyaltyService, private debugService: DebugService, private oAuthService: OAuthService, private userService: UserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const loginToken: string = this.oAuthStorage.getItem('access_token');
    let headers = request.headers;
    if (!request.url.includes('/assets/')) {
      // Set loyalty code to header
      if (this.loyaltyService.currentProgramme.code) {
        headers = headers?.set('X-SPL-LP', this.loyaltyService.currentProgramme.code);
      }
      // Set loyalty BU to header
      if (this.loyaltyService.currentProgramme.businessUnit) {
        headers = headers?.set('X-SPL-BU', this.loyaltyService.currentProgramme.businessUnit);
      }
      // Set SID
      const sessionId = this.oAuthStorage.getItem('spl.sid');
      if (sessionId) {
        headers = headers?.set('X-SPL-UUID', sessionId);
      }
      // Set Debug flag
      const debug = this.debugService.debug;
      if (debug) {
        headers = headers?.set('X-SPL-DEBUG', 'true');
      }

      // Set APP Channel
      headers = headers?.set('X-SPL-CHANNEL', environment.APP_CHANNEL_NAME);

      // Set access token
      if (loginToken) {
        if (this.oAuthService.hasValidAccessToken()) {
          headers = headers?.set('Authorization', `Bearer ${loginToken}`);
        } else {
          this.userService.logout(false);
        }
      }
    }

    return next.handle(
      request.clone({
        headers,
      })
    );
  }
}
