import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '@core/services/auth/user.service';
import { LoyaltyService, LpCheckRecordType } from '@core/services/loyalty/loyalty.service';
import { permissionMapping } from '@shared/permission-mapping';
import { DialogService } from '../services/application/dialog/dialog.service';
import { DialogData } from '..';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(private userService: UserService, private loyaltyService: LoyaltyService, private router: Router, private dialogService: DialogService) {}
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UrlTree | boolean> {
    if (!history.state || history.state.navigationId === 1) {
      let recordType: LpCheckRecordType = null;
      let recordId: string = null;
      if (next.params.campaign_id) {
        recordType = 'CAMPAIGN';
        recordId = next.params.campaign_id;
      } else if (next.params.membershipNumber) {
        recordType = 'GUEST';
        recordId = next.params.membershipNumber;
      }

      if (recordType) {
        const isLpCheckFailed = await this.loyaltyService
          .getRecordLoyaltyProgramme(recordType, recordId)
          .pipe(
            map(async (res) => {
              const userProgramme = this.loyaltyService.currentProgramme;
              if (res.loyaltyProgram === userProgramme.code && res.businessUnit === userProgramme.businessUnit) return true;

              const canAccessProgramme = this.loyaltyService.loyaltyProgrammesSubject.value.find((lp) => {
                return res.loyaltyProgram === lp.code && res.businessUnit === lp.businessUnit;
              });
              if (canAccessProgramme) {
                await this.loyaltyService.switchLoyaltyProgramme(res.businessUnit, res.loyaltyProgram);
                await this.userService.refreshUserAccess();
                return null;
              } else {
                const dialogData: DialogData = {
                  title: 'Error',
                  content: 'ERROR.MESSAGE.ACCESS_DENIED',
                  width: '370px',
                  yesCallback: (dialogRef) => {
                    dialogRef.close();
                  },
                };
                this.dialogService.showErrorDialog(dialogData).subscribe();
                return this.router.createUrlTree(['/admin/home']);
              }
            })
          )
          .toPromise();

        if (isLpCheckFailed) return isLpCheckFailed;
      }
    }

    const userPermissions = this.userService.permissions;
    const permissionRequired = Object.keys(permissionMapping)
      .filter((url, index) => {
        const urlRegexStr = url.replace(/\//g, '\\/');
        const urlRegex = new RegExp(urlRegexStr);
        return urlRegex.test(state.url);
      })
      .flatMap((url) => {
        return permissionMapping[url];
      });

    if (permissionRequired.length > 0) {
      const matchedPermissions = permissionRequired.filter((permission) => {
        return userPermissions.includes(permission);
      });
      if (matchedPermissions.length !== permissionRequired.length) {
        if (history.state && history.state.navigationId > 1) {
          // page navigation
        } else {
          // direct link
          this.router.navigateByUrl('/admin/home');
        }

        const dialogData: DialogData = {
          title: 'Error',
          content: 'ERROR.MESSAGE.ACCESS_DENIED',
          width: '370px',
          yesCallback: (dialogRef) => {
            dialogRef.close();
          },
        };
        this.dialogService.showErrorDialog(dialogData).subscribe();
        return false;
      }
    }
    return true;
  }
}
