import { AbstractControl } from '@angular/forms';
import { MemberService } from '@core/services/member/member.service';
import { UserRoleService } from '@core/services/user-role';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export class EmailValidator {
  static checkDuplicatedEmail(memberService: MemberService) {
    return (control: AbstractControl) => {
      const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (control && EMAIL_REGEXP.test(control.value) && !control.pristine && control.value && control.value.trim() !== '') {
        return memberService.checkDuplicatedEmail(control.value).pipe(
          map((isValid) => null),
          catchError(() => of({ error: true, duplicated: true }))
        );
      } else {
        return of(null);
      }
    };
  }

  static checkExistingUserEmail(userRoleService: UserRoleService) {
    return (control: AbstractControl) => {
      const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (control && EMAIL_REGEXP.test(control.value) && !control.pristine && control.value && control.value.trim() !== '') {
        return userRoleService.findUserInfoByEmail(control.value).pipe(
          map((res) => null),
          catchError(() => of({ error: true, nonExist: true }))
        );
      } else {
        return of(null);
      }
    };
  }
}
