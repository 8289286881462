import { CommonModule } from '@angular/common';
import { DateTimePickerComponent } from './date-time.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { IMaskModule } from 'angular-imask';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
// import { NgxMatTimepickerComponent } from "./time-picker/timepicker.component";
import { DxDateBoxModule } from 'devextreme-angular';

@NgModule({
  imports: [CommonModule, MatDatepickerModule, MatIconModule, IMaskModule, MatInputModule, FormsModule, ReactiveFormsModule, MatButtonModule, DxDateBoxModule],
  exports: [DateTimePickerComponent],
  declarations: [DateTimePickerComponent],
})
export class DateTimePickerModule {}
