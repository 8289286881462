import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SyncFormUploaderComponent } from './';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [SyncFormUploaderComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, TranslateModule],
  exports: [SyncFormUploaderComponent, MatButtonModule],
})
export class SyncFormUploaderModule {}
