import { Inject, Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ImageCompressorConfig, IMAGE_COMPRESSOR_CONFIG } from './image/image-compressor.config';
// Third Party Libs
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import { extension } from 'mime-types';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(@Inject(IMAGE_COMPRESSOR_CONFIG) private imageCompressorConfig: ImageCompressorConfig) {}

  /**
   * Compress Image
   * @param file
   */
  compressImage(file: File, fileSizeLimit: number = 5000000): Promise<File> {
    if (file && file.size * this.imageCompressorConfig.compressRatio > fileSizeLimit) {
      return new Promise((resolve, reject) => {
        reject('Oversize');
      });
    } else {
      return imageCompression(file, { maxSizeMB: 5, initialQuality: this.imageCompressorConfig.compressRatio }).then((res: Blob) => {
        const compressedImage = new File([res], `${uuidv4()}.${extension(file.type)}`, { type: 'image/jpg' });
        if (compressedImage.size > fileSizeLimit) {
          throw new Error('Oversize');
        }
        return compressedImage;
      });
    }
  }

  /**
   * Convert Base64 to File
   * @param dataUrl Base64 Date
   * @param filename Target file name
   */

  decodeImageToFile(dataUrl: ArrayBuffer | SafeUrl, filename?: string): File {
    const base64String = dataUrl as string;
    const arr = base64String?.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], `${filename}`, { type: mime });
  }
}
