import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2, Optional, OnDestroy, Self } from '@angular/core';
import { AbstractControl, NgControl, NgModel } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: 'input[matInput]',
})
export class InputMaxLengthDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() appInputMaxLength: number;
  private div: HTMLDivElement;
  private destroyed$ = new Subject();

  constructor(private el: ElementRef, private renderer: Renderer2, @Optional() private ngModel: NgModel, private matFormField: MatFormField) {}

  @HostListener('input', ['$event']) onChange(event: any) {
    // if (!this.ngModel) {
    //   this.update(event.target.value.length);
    // }
  }

  get defaultInputMaxLength(): string {
    return environment.APP_CONFIG.DEFAULT_INPUT_MAX_LENGTH.toString();
  }

  ngOnInit() {
    if (this.matFormField && !this.el.nativeElement.getAttribute('maxLength')) {
      this.renderer.setAttribute(this.el.nativeElement, 'maxLength', this.appInputMaxLength?.toString() || this.defaultInputMaxLength);
    }

    // if (this.ngModel) {
    //   // this.ngModel.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
    //   //   this.update(value.length);
    //   // })
    // }
  }

  ngAfterViewInit() {
    // this.div = this.renderer.createElement('span');
    // this.div.classList.add('count');
    // this.renderer.insertBefore(this.el.nativeElement.parentNode, this.div, this.el.nativeElement.nextSibling);
    // this.update(this.el.nativeElement.value.length);
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
    if (this.div) {
      this.div.remove();
    }
  }

  private update(length: number) {
    this.renderer.setProperty(this.div, 'innerText', `${length}/${this.appInputMaxLength}`);
  }
}
