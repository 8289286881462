import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { DropdownDictionary, RequestReasonApprovalMatrix } from '@app/@core/models';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { UserService } from '../auth';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  triggerRetrieveTotalRequest = new BehaviorSubject<number>(0);

  // Request Count for Workflow Tab
  private pendingForApprovalRequestCount$ = new BehaviorSubject<number>(0);
  private enrouteRequestCount$ = new BehaviorSubject<number>(0);
  private allRequestCount$ = new BehaviorSubject<number>(0);

  constructor(private httpClient: HttpClient, private userService: UserService) {}

  get pendingForApprovalRequestRequestCount() {
    return this.pendingForApprovalRequestCount$;
  }

  get enrouteRequestCount() {
    return this.enrouteRequestCount$;
  }

  get allRequestCount() {
    return this.allRequestCount$;
  }

  updatePendingForApprovalRequestCount(totalCount: number) {
    this.pendingForApprovalRequestCount$.next(totalCount);
  }

  updateEnrouteRequestCount(totalCount: number) {
    this.enrouteRequestCount$.next(totalCount);
  }

  updateAllRequestCount(totalCount: number) {
    this.allRequestCount$.next(totalCount);
  }

  searchApprovalRequests(params?: any, isSetTotalCount = false): Observable<any> {
    let urlSuffix = '';
    const listType = params.listType;
    switch (listType) {
      case 'ENROUTE':
        urlSuffix = 'listEnroute';
        break;
      case 'ALL':
        urlSuffix = 'listAll';
        break;
      case 'PENDING':
      default:
        urlSuffix = 'listPending';
        break;
    }
    delete params.listType;

    return this.httpClient.get(`/workflow/${urlSuffix}`, { params }).pipe(
      map((response: any) => {
        const totalElementsCount = response.data.totalElements;
        if (!isSetTotalCount) {
          if (listType === 'PENDING') {
            this.updatePendingForApprovalRequestCount(totalElementsCount);
          } else if (listType === 'ENROUTE') {
            this.updateEnrouteRequestCount(totalElementsCount);
          } else if (listType === 'ALL') {
            this.updateAllRequestCount(totalElementsCount);
          }
        }
        return response.data;
      })
    );
  }

  /**
   * Get both pending and enroute requests count
   */
  getAllRequestCount(params?: any) {
    return forkJoin([this.searchApprovalRequests({ ...params, listType: 'PENDING' })]);
  }

  retrieveTotalRequest(isRequestDetailPage?: boolean) {
    const requestParams = { listType: 'PENDING' };
    return this.searchApprovalRequests(requestParams, true).pipe(
      map((response: any) => {
        isRequestDetailPage ? this.triggerRetrieveTotalRequest.next(response.totalElements + 1) : this.triggerRetrieveTotalRequest.next(response.totalElements);
        return response.totalElements;
      })
    );
  }

  getNextPendingRequest(): Observable<any> {
    return this.httpClient.get(`/workflow/nextPendingWorkflowRequest`).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  forShowLockIconIfLockedAndReverted(rowData: any) {
    let toBeReturn: any;

    toBeReturn = {
      id: rowData.id,
      isLocked: rowData.status === 'LOCKED' ? true : false,
      revertedToCurrentUser: rowData.revertedToCurrentUser,
    };

    return toBeReturn;
  }

  getApprovalMatrix(params?: any) {
    return this.httpClient.get(`/workflow/approvalMatrix`, { params }).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  upsertApprovalMatrix(params: RequestReasonApprovalMatrix[]) {
    return this.httpClient.put('/workflow/approvalMatrix', params).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<any>;
  }

  retrieveSideMenuInputNumber(isRequestDetailPage?: boolean) {
    return this.userService.isAuthenticated.pipe(
      take(1),
      map((isAuth) => isAuth),
      tap((isAuth) => {
        if (isAuth && this.userService.hasPermission('loyalty#guest#workflow#workflowRequest#listInbox')) {
          this.retrieveTotalRequest(isRequestDetailPage).subscribe();
        } else {
          return;
        }
      })
    );
  }

  forCastReasonBaseOnType(rowData: any) {
    // TODO: Move to constants
    let toBeReturn: any;
    if (rowData.approvalRequestType === 'RECEIPT_APPROVAL' || rowData.approvalRequestType === 'RECEIPT_VOID') {
      toBeReturn = {
        isMsgIsCurrency: true,
        isMsgIsDate: false,
        isMsgIsFromTo: false,
        isReasonNeedConcat: true,
        msg: rowData.valueFrom,
        msgFrom: '',
        msgTo: '',
        reason: rowData.requestReason,
      };
    } else if (rowData.approvalRequestType === 'MANUAL_POINT_ADJUSTMENT') {
      toBeReturn = {
        isMsgIsCurrency: false,
        isMsgIsDate: false,
        isMsgIsFromTo: false,
        isReasonNeedConcat: true,
        msg: (Number(rowData.valueTo) > 0 ? '+' : '') + Number(rowData.valueTo).toLocaleString(),
        msgFrom: '',
        msgTo: '',
        reason: rowData.requestReason,
      };
    } else if (
      rowData.approvalRequestType === 'BIRTHDAY_UPDATE' ||
      rowData.approvalRequestType === 'EMAIL_UPDATE' ||
      rowData.approvalRequestType === 'MANUAL_TIER_ADJUSTMENT' ||
      rowData.approvalRequestType === 'MEMBER_STATUS_UPDATE' ||
      rowData.approvalRequestType === 'MOBILE_UPDATE'
    ) {
      toBeReturn = {
        isMsgIsCurrency: false,
        isMsgIsDate: false,
        isMsgIsFromTo: true,
        isReasonNeedConcat: true,
        msg: '',
        msgFrom: rowData.valueFrom,
        msgTo: rowData.valueTo,
        reason: rowData.requestReason,
      };
    } else if (rowData.approvalRequestType === 'TIER_EXPIRY_DATE_EXTENSION' || rowData.approvalRequestType === 'REDEMPTION_POINT_EXPIRY_DATE_EXTENSION') {
      toBeReturn = {
        isMsgIsCurrency: false,
        isMsgIsDate: true,
        isMsgIsFromTo: true,
        isReasonNeedConcat: true,
        msg: '',
        msgFrom: moment(rowData.valueFrom).subtract(1, 'd').format('YYYY-MM-DD'),
        msgTo: moment(rowData.valueTo).subtract(1, 'd').format('YYYY-MM-DD'),
        reason: rowData.requestReason,
      };
    } else {
      toBeReturn = {
        isMsgIsCurrency: false,
        isMsgIsDate: false,
        isMsgIsFromTo: false,
        isReasonNeedConcat: false,
        msg: '',
        msgFrom: '',
        msgTo: '',
        reason: rowData.requestReason,
      };
    }

    return toBeReturn;
  }

  sortApprovalMatrixRequestReason(genericCategory: DropdownDictionary, type: string, approvalMatrixConfigs: RequestReasonApprovalMatrix[]) {
    const sorting = genericCategory.WORKFLOW.CONFIG_REQUEST_REASON.filter((category) => category.parent === type).sort((a, b) => {
      return a.displayOrder - b.displayOrder;
    });
    const sortedConfigs: RequestReasonApprovalMatrix[] = [];
    sorting.forEach((key) => {
      const filteredConfig = approvalMatrixConfigs.find((config) => {
        return config.reason === key.value;
      });
      if (filteredConfig) sortedConfigs.push(filteredConfig);
    });

    return sortedConfigs;
  }

  getRequestListByGuestId(guestId: string) {
    return this.httpClient.get(`/workflow/listAll`, { params: { guestId: guestId } }).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  getWorkflowConfigWhitelist(params?: any) {
    return this.httpClient.get(`/workflowConfigWhitelist`, { params }).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  enableWorkflowConfigWhitelist(whitelist: any) {
    return this.httpClient.put(`/workflowConfigWhitelist/activate`, whitelist).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  disableWorkflowConfigWhitelist(whitelist: any) {
    return this.httpClient.put(`/workflowConfigWhitelist/deactivate`, whitelist).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  createWorkflowConfigWhitelist(whitelist: any) {
    return this.httpClient.post(`/workflowConfigWhitelist`, whitelist).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }
}
