import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService, DropdownService } from '@app/@core/services';
import { DialogData, DropdownDictionary } from '@app/@core/models';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-reject-transaction-dialog',
  templateUrl: './reject-transaction-dialog.component.html',
  styleUrls: ['./reject-transaction-dialog.component.scss'],
})
export class RejectTransactionDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public approvalReasons: any[];
  public dropdownData: DropdownDictionary = null;
  public rejectFormGroup: UntypedFormGroup;
  public selectedReason: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RejectTransactionDialogComponent>,
    private dialogService: DialogService,
    private fb: UntypedFormBuilder,
    private dropdownService: DropdownService
  ) {
    // form builder
    this.rejectFormGroup = this.fb.group({
      rejectedRemarks: [null, []],
      rejectReason: [null, [Validators.required]],
      transactionModifyReason: [null],
    });

    if (data.isModified) {
      this.rejectFormGroup.get('transactionModifyReason').setValidators([Validators.required]);
    } else {
      this.rejectFormGroup.get('transactionModifyReason').clearValidators();
    }

    // set dropdown data
    this.dropdownService
      .getDropdown()
      .pipe(take(1))
      .subscribe(
        (dropdown) => {
          this.dropdownData = dropdown;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  onCancelClick() {
    this.dialogRef.close(); // do not pass data back
  }

  onSubmitClick() {
    if (!this.rejectFormGroup.get('rejectReason')?.value || this.rejectFormGroup.get('rejectReason')?.value.length < 1) {
      this.displayErrDialog('Please enter reject reason');
    } else {
      this.dialogRef.close(this.rejectFormGroup.getRawValue());
    }
  }

  displayErrDialog(content: string) {
    const dialogData: DialogData = {
      title: 'COMMON.LABEL.ERROR',
      content,
      width: '370px',
      yesCallback: (dialogRef) => {
        dialogRef.close();
        // switch form state to end-submitting and enable form edit
      },
    };
    this.dialogService.showErrorDialog(dialogData).subscribe();
  }

  get isModified() {
    return this.data.isModified;
  }
}
