<div class="stack-viewer">
  <!-- Header -->
  <div class="sticky">
    <div class="header-container">
      <div class="title-header d-flex" [class.has-badge]="isFromTransactionHistory">
        <div class="mr-auto compare-transaction-title">
          <span translate>{{ panelTitle }}</span>
          <span *ngIf="isFromTransactionHistory"> - {{ transactionDetail?.tenantId | merchant | async }}</span>
          <span *ngIf="isFromTransactionHistory"> / {{ transactionDetail?.receiptAmount | systemCurrency }}</span>
        </div>
        <div *ngIf="transactionButtonTemplate" class="mx-2">
          <ng-container *ngTemplateOutlet="transactionButtonTemplate"></ng-container>
        </div>
        <span class="badge status-badge approval-status" *ngIf="isFromTransactionHistory">
          <span> {{ 'MEMBER.LABEL.APPROVAL_STATUS' | translate }}: </span>
          <span translate [transactionStatus]="transactionDetail?.status" class="status" [submittedWithOngoingWorkFlow]="submittedWithWorkflow" [approvedWithOngoingWorkFlow]="approvedWithWorkflow"></span>
          <span *ngIf="isShowTransactionVerificationStatus"> ({{ 'RECEIPT_TRANSACTIONS.RECEIPT_VERIFICATION.LABEL.' + transactionDetail.verifyStatus | translate }}) </span>
        </span>
        <span class="close" (click)="closeViewer()">
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </div>
  </div>
  <div class="content-container">
    <ng-container #container></ng-container>
  </div>
</div>
