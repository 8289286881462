import * as moment from 'moment';

export function combineDateTime(date: any, time: string): string {
  if (date && time) {
    return moment(date)
      .set({ hour: +time.slice(0, 2), minute: +time.slice(3, 5), second: 0, millisecond: 0 })
      .toISOString();
  } else {
    return date;
  }
}
