<shell-admin-header></shell-admin-header>
<ngx-spinner name="full-screen-spinner" type="ball-clip-rotate"></ngx-spinner>
<mat-sidenav-container class="admin-content-container" autosize>
  <mat-sidenav [@slideInOut]="menuState" #sidenav mode="side" opened class="admin-sidenav">
    <admin-shell-sidenav></admin-shell-sidenav>
  </mat-sidenav>
  <mat-sidenav-content #contentContainer [@marginLeftInOut]="menuState" [class.collapsed]="menuState === 'in'" [class.has-fixed-height-container]="!isSetAutoOverflow">
    <div class="sticky">
      <app-breadcrumb></app-breadcrumb>
    </div>
    <div>
      <!-- TODO -->
      <c-stack-viewer *ngIf="isShowStackViewer"></c-stack-viewer>
    </div>
    <div
      [style.display]="isShowStackViewer ? 'none' : null"
      [class.admin-shell-content]="!isHideContainerPadding"
      [class.has-breadcrumb]="isBreadcrumbVisible"
      [class.has-breadcrumb1]="!isBreadcrumbVisible"
      [class.fixed-height-container]="!isSetAutoOverflow"
    >
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
