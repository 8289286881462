import { Component, OnInit, ViewChild, AfterContentInit, Pipe, OnDestroy, PipeTransform, NgZone } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuService, SidenavService, LoyaltyService, UserService, BreadcrumbService, RequestService } from '@core/services';
import { MenuItem } from '@core/models/application/menu-item';
import { zip } from 'rxjs';
import { LoyaltyProgramme } from '@core/models';
import { Router } from '@angular/router';
import { untilDestroyed } from '@app/@core/until-destroyed';
import { NgxSpinnerService } from 'ngx-spinner';
import { TenantService } from '../../../@core/services/tenant.service';
import { GiftService } from '../../../@core/services/campaigns/gift.service';

@Component({
  selector: 'admin-shell-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy, AfterContentInit {
  menuItemList: MenuItem[];
  showProgSelection: boolean;

  constructor(
    private menuService: MenuService,
    public sidenavService: SidenavService,
    public loyaltyService: LoyaltyService,
    public userService: UserService,
    private router: Router,
    private ngZone: NgZone,
    private spinnerService: NgxSpinnerService,
    private requestService: RequestService,
    private tenantService: TenantService,
    private giftService:GiftService
  ) {}

  ngOnInit(): void {
    // Get program list and current program info
    zip(this.loyaltyService.loyaltyProgrammes$, this.loyaltyService.currentProgramme$)
      .pipe(untilDestroyed(this))
      .subscribe(([programmes, currentProg]) => {
        const currProgIndex = programmes.findIndex((programme) => programme.id === currentProg.id);
        const filteredProgrammes = programmes.filter((programme, index) => index !== currProgIndex);
        this.showProgSelection = filteredProgrammes.length > 0;
      });
  }

  ngAfterContentInit() {}

  ngOnDestroy(): void {}

  onSwitchProgramme(programme: LoyaltyProgramme) {
    this.giftService.resetGiftCache()
    const currentRoute = this.router.routerState.snapshot.url;
    this.router.navigate(['/admin/home'], { fragment: 'changeLP' }).then((navigate) => {
      if (navigate || currentRoute === '/admin/reimbursement/list') {
        this.spinnerService.show('full-screen-spinner');
        this.loyaltyService.switchLoyaltyProgramme(programme.businessUnit, programme.code).then(
          () => {
            this.userService.refreshUserAccess().then(
              () => {
                this.ngZone.run(() => {
                  this.menuService.generateAdminMenuItems();
                  // if (this.userService.hasPermission('loyalty#guest#workflow#workflowRequest#listInbox')) {
                  //   this.requestService.retrieveSideMenuInputNumber().subscribe(() => this.spinnerService.hide('full-screen-spinner'));
                  // } else {
                  this.spinnerService.hide('full-screen-spinner');
                  this.tenantService.newTenants = null as any;
                  // }
                });
              },
              (error) => {
                this.spinnerService.hide('full-screen-spinner');
              }
            );
          },
          (error) => {
            this.spinnerService.hide('full-screen-spinner');
          }
        );
      }
    });
  }
}

@Pipe({
  name: 'filterCurrentProgramme',
  pure: false,
})
export class FilterCurrentProgrammePipe implements PipeTransform {
  constructor(public loyaltyService: LoyaltyService) {}
  transform(programmes: LoyaltyProgramme[]) {
    const currentProgramme = this.loyaltyService.currentProgrammeSubject.value;
    return programmes.filter((programme) => {
      return !(programme.businessUnit === currentProgramme.businessUnit && programme.code === currentProgramme.code);
    });
  }
}
