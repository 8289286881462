import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';

import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

import { AdvancedSearchParams } from '@app/@core/models/application/data-search';
import { isEmptyObject, removeEmptyAttributes } from '@app/@core/utils/object';

@Component({
  selector: 'c-data-table-server-search',
  templateUrl: './data-table-server-search.component.html',
  styleUrls: ['./data-table-server-search.component.scss'],
  animations: [
    trigger('inOutAnimation', [transition(':enter', [transition('void <=> *', []), transition('* <=> *', [style({ height: '{{startHeight}}px', opacity: 0 }), animate('.5s ease')], { params: { startHeight: 0 } })])]),
  ],
})
export class DataTableServerSearchComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() clickSearch = new EventEmitter<string>();
  @Output() clickReset = new EventEmitter<string>();
  @Output() clickExpend = new EventEmitter<boolean>();
  @Input() isExpandable: boolean;
  @Input() isLoading: BehaviorSubject<boolean>;
  @Input() tableTplRef: DxDataGridComponent;
  @Input() formTpl: TemplateRef<any>;
  @Input() customTitleTpl: TemplateRef<any>;
  @Input() isShowSearchHelper = true;
  @Input() isAutoTogglePanel = true;
  @Input() controlKeyMapping: AdvancedSearchParams;
  @Input() searchCardTitle: string;
  @Input() placeholder: string = 'Search...';
  @Input() queryParams: Params;
  @Input() ClickSearchCallback: (keyword: string) => void;
  @Input() searchFormGroup: UntypedFormGroup;
  @Input() hasLightAdvancedSearch = false;
  keywords: string;
  startHeight: number;
  isExpended = false;
  advancedSearchParams: any[] = [];
  @HostBinding('@inOutAnimation') get grow() {
    return { value: this.formTpl, params: { startHeight: this.startHeight } };
  }
  constructor(private route: ActivatedRoute, private element: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.route.snapshot.data.state && this.route.snapshot.data.state.table?.searchText) {
      this.keywords = this.route.snapshot.data.state.table.searchText;
    }
    if (this.hasLightAdvancedSearch === true) {
    }
  }

  onReset() {
    this.keywords = '';
    this.clickReset.emit(this.keywords);
  }

  onSearch() {
    if (!this.keywords) this.keywords = '';
    if (this.isExpended && this.isAutoTogglePanel) {
      this.togglePanel();
    }
    this.clickSearch.emit(this.keywords);
  }

  togglePanel() {
    this.isExpended = !this.isExpended;
    this.clickExpend.emit(this.isExpended);
  }

  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setStartHeight();
    if (changes.queryParams?.currentValue) {
      if (JSON.stringify(changes.queryParams?.currentValue) !== JSON.stringify(changes.queryParams?.previousValue)) {
        const params = changes.queryParams.currentValue;
        const advancedSearchParams = [];
        for (const control of Object.keys(removeEmptyAttributes(params))) {
          if (this.controlKeyMapping[control]) {
            let controlValue;
            if (this.controlKeyMapping[control].mutipleDisplayValueHandling) {
              controlValue = this.controlKeyMapping[control].mutipleDisplayValueHandling(this.controlKeyMapping[control].options, params[control], 'id', 'name');
            } else if (this.controlKeyMapping[control].displayValueHandling) {
              controlValue = this.controlKeyMapping[control].displayValueHandling(params[control]);
            } else if (moment(params[control], 'DD-MM-YYYY').isValid() && control.toLowerCase().includes('date')) {
              controlValue = moment(params[control]).format('DD-MM-YYYY');
            } else {
              controlValue = this.controlKeyMapping[control].options ? this.controlKeyMapping[control].options[typeof params[control] === 'boolean' ? params[control].toString() : params[control]] : params[control];
            }
            advancedSearchParams.push({
              control,
              controlLabel: this.controlKeyMapping[control].label,
              controlValue,
            });
          }
        }
        this.advancedSearchParams = advancedSearchParams;
      }
    }
  }

  onEnter(e: any) {
    this.searchInput.nativeElement.blur();
    this.onSearch();
  }

  removeParams(param: any) {
    this.searchFormGroup.get(param.control).setValue('');
    this.searchFormGroup.get(param.control).markAsTouched();
    this.searchFormGroup.get(param.control).updateValueAndValidity();
    if (this.searchFormGroup.invalid) {
      this.togglePanel();
      return;
    }
    this.ClickSearchCallback(this.keywords);
  }

  get freeTextSearchFields() {
    const freeTextSearchFields = {};
    if (this.controlKeyMapping) {
      for (const key of Object.keys(removeEmptyAttributes(this.controlKeyMapping))) {
        if (this.controlKeyMapping[key]?.isFreeTextSearchable) {
          freeTextSearchFields[key] = this.controlKeyMapping[key].label;
        }
      }
      return !isEmptyObject(freeTextSearchFields) ? freeTextSearchFields : null;
    }

    return null;
  }
}
