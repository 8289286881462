import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 's-data-table-advanced-search',
  templateUrl: './data-table-advanced-search.component.html',
  styleUrls: ['./data-table-advanced-search.component.scss'],
  animations: [
    trigger('inOutAnimation', [transition(':enter', [transition('void <=> *', []), transition('* <=> *', [style({ height: '{{startHeight}}px', opacity: 0 }), animate('.5s ease')], { params: { startHeight: 0 } })])]),
  ],
})
export class DataTableAdvancedSearchComponent implements OnInit, AfterViewInit, OnChanges {
  accordionData: any[] = [''];
  keywords: string;
  isExpended = false;
  startHeight: number;
  @Input() tableTplRef: DxDataGridComponent;
  @Input() formTpl: TemplateRef<any>;

  @HostBinding('@inOutAnimation') get grow() {
    return { value: this.formTpl, params: { startHeight: this.startHeight } };
  }

  constructor(private element: ElementRef, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  onInputKeywords() {
    this.tableTplRef.instance.searchByText(this.keywords);
  }

  togglePanel() {
    this.isExpended = !this.isExpended;
  }

  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges() {
    this.setStartHeight();
  }
}
