import { Component, OnInit, Input, OnDestroy, ViewChild, ViewEncapsulation, forwardRef, ElementRef, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { S3FileManagementService } from '@core/services';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { ReplaySubject, of, zip, Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { UploadDetail } from '@core/models/application/upload-detail';
import { first } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CommonConstants } from '@app/@core/services/application/common.constant';

export interface FileContent {
  existing: { fileName: string; fileUrl: string; fileType: string; fileSize: number };
  new: { fileName: string; file: File };
  error: { fileName: string; file: File };
  toBeDeleted: { fileName: string; fileUrl: string; fileType: string; fileSize: number };
}

@Component({
  selector: 'c-sync-form-uploader',
  templateUrl: './sync-form-uploader.component.html',
  styleUrls: ['./sync-form-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SyncFormUploaderComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SyncFormUploaderComponent),
      multi: true,
    },
  ],
})
export class SyncFormUploaderComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() allowedTypes: Array<string> = CommonConstants.MIME_TYPE_GROUP.IMAGE;
  @Input() allowedSize: number = 5 * CommonConstants.ONE_MB;
  @Input() uploadButtonLabel = 'SIMPLE_UPLOADER.LABEL.UPLOAD_BUTTON';
  @Input() uploadSpec: UploadDetail;
  @Input() requiredUpload = false;

  @ViewChild('fileInput', { static: false }) public fileInput: ElementRef;

  public fileToUpload: { filename: string; size: string };
  public isFileTypeValid = true;
  public isFileSizeValid = true;

  public touched = false;
  public files: FileContent = {
    existing: null,
    new: null,
    error: null,
    toBeDeleted: null,
  };
  public disabled = false;
  public isDragover = false;
  public uploadObservable: ReplaySubject<{ fileName: string; fileUrl: string; fileType: string; fileSize: number }> = new ReplaySubject();
  public acceptedFileTypesString = this.allowedTypes.join(', ');
  public newFileBlob: SafeUrl = null;

  private entryTarget: any;

  constructor(private s3FileManagementService: S3FileManagementService, private domSanitizer: DomSanitizer) {}

  onChange = (delta: any) => {};
  onTouched = () => {
    this.touched = true;
  };
  onValidationChange = () => {};

  writeValue(obj: any): void {
    this.files.existing = obj;
    this.files.new = null;
    this.files.error = null;
    this.files.toBeDeleted = null;
    this.newFileBlob = null;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.onChange(this.files.existing);
  }

  onSelectFile(fileList: FileList) {
    this.onTouched();
    if (fileList.length === 1) {
      const file = fileList[0];

      this.isFileTypeValid = this.allowedTypes.includes(file.type);
      this.isFileSizeValid = this.allowedSize ? this.allowedSize > file.size : true;
      this.onValidationChange();

      if (this.isFileTypeValid && this.isFileSizeValid) {
        this.files.error = null;
      } else {
        this.files.error = { fileName: file.name, file };
      }

      this.files.new = { fileName: file.name, file };
      this.onChange(this.files.new);

      if (this.isFileTypeValid) {
        this.newFileBlob = this.domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      }
    }
  }

  clearSelection(mode: string = null) {
    this.onTouched();

    this.files.new = null;
    this.files.error = null;

    this.files.existing = mode === 'undo' ? this.files.toBeDeleted : null;
    this.files.toBeDeleted = null;
    this.newFileBlob = null;
    this.fileInput.nativeElement.value = null;

    if (mode === 'undo') {
      this.onChange(this.files.existing);
    } else {
      this.onChange(null);
    }
  }

  markDelete() {
    this.onTouched();

    this.files.toBeDeleted = this.files.existing;
    this.files.existing = null;

    this.onChange(this.files.existing);
  }

  public uploadFile() {
    const fileHandlingObservable: Observable<any>[] = [];
    if (this.files.toBeDeleted) {
      fileHandlingObservable.push(this.s3FileManagementService.deleteFile({ url: this.files.toBeDeleted.fileUrl }));
    } else {
      fileHandlingObservable.push(of(null));
    }
    if (this.files.new) {
      const uploadDetail = cloneDeep(this.uploadSpec);
      uploadDetail.multipartFile = this.files.new.file;
      fileHandlingObservable.push(this.s3FileManagementService.uploadFile(uploadDetail));
    }
    if (fileHandlingObservable.length === 0) fileHandlingObservable.push(of(null));

    zip(...fileHandlingObservable)
      .pipe(first())
      .subscribe((responses) => {
        if (responses[1]) {
          const fileNameArray = this.files.new.fileName.split('.');
          const fileType = fileNameArray.splice(fileNameArray.length - 1, 1).toString();
          const fileName = fileNameArray.join('.');
          const fileSize = this.files.new.file.size;

          this.uploadObservable.next({ fileUrl: responses[1].data, fileName, fileType, fileSize });
        } else {
          this.uploadObservable.next({ fileUrl: null, fileName: null, fileType: null, fileSize: null });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allowedTypes) {
      this.acceptedFileTypesString = changes.allowedTypes.currentValue.join(', ');
    }
  }

  onFileChange(event: InputEvent) {
    this.onSelectFile(this.fileInput.nativeElement.files);
  }

  @HostListener('dragover', ['$event'])
  ondragover(event: DragEvent) {
    if (this.disabled) return false;

    event.stopPropagation();
    event.preventDefault();
    if (!this.entryTarget) {
      this.entryTarget = event.target;
    }
    this.isDragover = true;
  }

  @HostListener('drop', ['$event'])
  ondrop(event: DragEvent) {
    if (this.disabled) return false;

    event.preventDefault();
    this.entryTarget = null;
    this.isDragover = false;

    if (this.files.existing) {
      this.markDelete();
    }

    const file = event.dataTransfer.files;
    this.onSelectFile(file);
  }

  @HostListener('dragleave', ['$event'])
  ondragleave(event: DragEvent) {
    if (this.disabled) return false;

    if (this.entryTarget && this.entryTarget === event.target) {
      event.stopPropagation();
      event.preventDefault();
      this.isDragover = false;
      this.entryTarget = null;
    }
  }

  ngOnDestroy() {}

  validate(control: AbstractControl): ValidationErrors | null {
    const error: any = {};
    if (!this.isFileSizeValid) error.invalidFileSize = true;
    if (!this.isFileTypeValid) error.invalidFileType = true;
    return !this.isFileSizeValid || !this.isFileTypeValid ? error : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  public calculateSizeDisplay(byte: number) {
    if (byte < 1024) {
      return `${byte} bytes`;
    } else if (byte < 1024 * 1024) {
      return `${Math.round(byte / 1024)} KB`;
    } else {
      return `${Math.round(byte / 1024 / 1024)} MB`;
    }
  }
}
