import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, GuestProfile, MemberService, PreserveTabService, ReceiptTransaction, StackViewerService } from '@app/@core';

@Component({
  selector: 'transaction-guest-info',
  templateUrl: './transaction-guest-info.component.html',
  styleUrls: ['./transaction-guest-info.component.scss'],
})
export class TransactionGuestInfoComponent implements OnInit {
  @Input() guestData: GuestProfile;
  @Input() transactionData: ReceiptTransaction;
  @Input() isSamePageNavigation = false;
  guestNameOnCreditCard: string;

  constructor(private router: Router, private dialogService: DialogService, private memberService: MemberService, private stackViewerService: StackViewerService, private preserveTabService: PreserveTabService) {}

  ngOnInit(): void {
    this.memberService.getGuestNameOnCreditCard(this.guestData.id).subscribe((guestNameOnCreditCard) => {
      if (guestNameOnCreditCard) {
        this.guestNameOnCreditCard = guestNameOnCreditCard;
      }
    });
  }

  onClickGuestId(e: any) {
    this.dialogService.closeAllDialogs();
    this.stackViewerService.closePanel();
    if (this.isSamePageNavigation) {
      this.preserveTabService.navigateSamePageTab('/admin/members/view', { tabIndex: 0 });
    } else {
      this.router.navigate(['/admin/members/view', this.guestData?.id]);
    }
    e.preventDefault();
  }
}
