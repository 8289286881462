import { Injectable } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

interface MemberProfileTabNavigationData {
  tabIndex: number;
  subTabIndex?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PreserveTabService {
  tabNavigationSubjectMapping: { [route: string]: BehaviorSubject<MemberProfileTabNavigationData> } = {};
  tabNavigationObservableMapping: { [route: string]: Observable<MemberProfileTabNavigationData> } = {};

  constructor(private router: Router, private route: ActivatedRoute) {}

  addTabChangeFragment(matTabGroup: MatTabGroup, route: ActivatedRoute, tabIndex?: number, subTabIndex?: number, subMatTabGroup?: MatTabGroup) {
    const queryParams: any = {};

    if (subTabIndex !== null) {
      matTabGroup?.selectedIndex !== 0 && (queryParams.tabIndex = matTabGroup?.selectedIndex);
      subTabIndex !== 0 && (queryParams.subTabIndex = subTabIndex);
    }
    if (tabIndex !== null) {
      tabIndex !== 0 && (queryParams.tabIndex = tabIndex);
    }

    const queryString = Object.keys(queryParams)
      .map((key) => key + '=' + queryParams[key])
      .join('&');

    if (queryString) {
      if (route?.snapshot.fragment !== queryString) {
        this.router.navigate(['.'], {
          relativeTo: route,
          fragment: queryString,
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }
    } else {
      this.router.navigate(['.'], {
        relativeTo: route,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }

  retrieveIndexFromFragment(fragment: string) {
    const tabIndex = Number(new URLSearchParams(fragment).get('tabIndex'));
    const subTabIndex = Number(new URLSearchParams(fragment).get('subTabIndex'));
    return {
      tabIndex,
      subTabIndex,
    };
  }

  navigateSamePageTab(route: string, data: MemberProfileTabNavigationData) {
    if (!this.tabNavigationSubjectMapping[route]) {
      this.addSamePageTabNavigationSubject(route);
    }
    this.tabNavigationSubjectMapping[route].next(data);
  }

  getSamePageTabNavigationObservable(route: string) {
    if (!this.tabNavigationObservableMapping[route]) {
      this.addSamePageTabNavigationSubject(route);
    }
    return this.tabNavigationObservableMapping[route];
  }

  clearSamePageTabNavigation(route: string) {
    if (this.tabNavigationSubjectMapping[route] && this.tabNavigationObservableMapping[route]) {
      delete this.tabNavigationSubjectMapping[route];
      delete this.tabNavigationObservableMapping[route];
    }
  }

  private addSamePageTabNavigationSubject(route: string) {
    this.tabNavigationSubjectMapping[route] = new BehaviorSubject<MemberProfileTabNavigationData>(null);
    this.tabNavigationObservableMapping[route] = this.tabNavigationSubjectMapping[route].asObservable();
  }
}
