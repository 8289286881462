import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-transaction-info-dialog',
  templateUrl: './verify-transaction-info-dialog.component.html',
  styleUrls: ['./verify-transaction-info-dialog.component.scss'],
})
export class VerifyTransactionInfoDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<VerifyTransactionInfoDialogComponent>) {}

  ngOnInit(): void {}

  get verifyFailureReasons(): string[] {
    return this.data.transactionDetail.verifyFailureReasons.map((item: any) => item.verifyFailureReason);
  }
}
