import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList, AfterViewInit, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { ReceiptTransaction, ReceiptTransactionService, ImageListItem, ReimburseImage } from '@core';
import { ReceiptImageService } from '@app/@core/services/receipt-transaction/receipt-image.service';
import { DxGalleryComponent } from 'devextreme-angular';
import { findIndex } from 'lodash';
import { SimpleChanges } from '@angular/core';
import { AuthImagePipe } from '@shared';
import { PinchZoomComponent } from '@shared/components/pinch-zoom/pinch-zoom.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'c-receipt-image-viewer',
  templateUrl: './receipt-image-viewer.component.html',
  styleUrls: ['./receipt-image-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiptImageViewerComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChildren('pinchViewer') pinchViewerRef: QueryList<PinchZoomComponent>;
  @ViewChild('imageSlider') imageSliderRef: DxGalleryComponent;
  @Input() transactionDetail: ReceiptTransaction;
  @Input() imageViewerId: string;
  @Input() isReimbursement: boolean = false;
  @Input() reimbursementImages: ReimburseImage[];
  @Input() noImageText: string = 'Skipped';
  @Input() isRpt = false;
  transformedImages: any[] = [];
  selectedImage: ImageListItem;
  imageRotateDeg = 0;
  imageRotateStyle = {};
  show = true;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.onClickZoomOut();
  }

  constructor(
    private receiptTransactionService: ReceiptTransactionService,
    private viewTransactionImageService: ReceiptImageService,
    private cd: ChangeDetectorRef,
    private authImage: AuthImagePipe,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.viewTransactionImageService.selectedImage.subscribe((selectedImage) => {
      if (selectedImage.imageSrc && this.imageViewerId === selectedImage.imageViewerId) {
        this.goToSelectedItem(selectedImage.imageSrc);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.transactionDetail || changes.reimbursementImages) {
      this.transformImagesUrl().then(() => {
        this.onCurrentViewChanged();
      });
    }
  }

  async transformImagesUrl(): Promise<any> {
    let allImages;
    if (this.imageViewerId === 'salesReceiptViewer') {
      allImages = [...this.transactionDetail.salesReceipts];
    } else if (this.imageViewerId === 'electronicReceiptAndMemberIdViewer') {
      allImages = [...this.transactionDetail.electronicReceipts, ...this.transactionDetail.memberIdentity];
    } else if (this.isReimbursement) {
      allImages = this.reimbursementImages;
    } else {
      allImages = [...this.transactionDetail.salesReceipts, ...this.transactionDetail.electronicReceipts, ...this.transactionDetail.memberIdentity];
    }
    this.transformedImages = [];
    console.log('transformedImages');
    for await (const image of allImages) {
      if (this.isReimbursement) {
        const base64String = image.imgPath;
        this.transformedImages.push({
          thumbnailUrl: image.thumbnailPath,
          originalUrl: image.imgPath,
          originalImage: base64String,
          caption: '',
          id: image.uuid,
        });
      } else {
        const base64String = await this.authImage.transform(this.receiptTransactionService.getReceiptImageUrl(image.id, 'original'));
        this.transformedImages.push({
          thumbnailUrl: this.receiptTransactionService.getReceiptImageUrl(image.id, 'thumbnail'),
          originalUrl: this.receiptTransactionService.getReceiptImageUrl(image.id, 'original'),
          originalImage: base64String,
          caption: image.caption,
          id: image.id,
        });
      }
      this.cd.markForCheck();
    }
  }

  goToSelectedItem(selectedImage: ImageListItem): void {
    if (this.imageSliderRef) {
      const selectedItemIndex = findIndex(this.transformedImages, { id: selectedImage.id });
      this.imageSliderRef.instance.goToItem(selectedItemIndex, false);
      this.resetImageRotation();
    }
  }

  onCurrentViewChanged(): void {
    let selectedImageItem;
    if (this.imageSliderRef) {
      selectedImageItem = this.transformedImages[this.imageSliderRef.selectedIndex];
    } else {
      selectedImageItem = this.transformedImages[0];
    }
    this.viewTransactionImageService.setSelectedViewerImage(selectedImageItem, this.imageViewerId);
    this.resetImageRotation();
  }

  onClickZoomIn(): void {
    const currentPinchViewerRef = this.pinchViewerRef.filter((element, index) => index === this.imageSliderRef.selectedIndex)[0];

    if (currentPinchViewerRef) {
      let newScale = currentPinchViewerRef.scale + currentPinchViewerRef.properties.zoomControlScale;
      currentPinchViewerRef.pinchZoom.setZoom({ scale: newScale > currentPinchViewerRef.maxScale ? currentPinchViewerRef.maxScale : newScale });
    }
  }

  onClickZoomOut(): void {
    const currentPinchViewerRef = this.pinchViewerRef.filter((element, index) => index === this.imageSliderRef.selectedIndex)[0];

    if (currentPinchViewerRef) {
      const newScale = currentPinchViewerRef.scale - currentPinchViewerRef.properties.zoomControlScale;
      currentPinchViewerRef.pinchZoom.setZoom({ scale: newScale > 1 ? newScale : 1 });
    }
  }

  onClickRotateLeft(): void {
    const currentPinchViewerRef = this.pinchViewerRef.filter((element, index) => index === this.imageSliderRef.selectedIndex)[0];

    this.imageRotateDeg -= 90;
    this.imageRotateStyle = { transform: `rotate(${this.imageRotateDeg}deg)` };
    currentPinchViewerRef.initPinchZoom();

    // let selectedImageItem;
    // if (this.imageSliderRef) {
    //   selectedImageItem = this.transformedImages[this.imageSliderRef.selectedIndex];
    // } else {
    //   selectedImageItem = this.transformedImages[0];
    // }
    //
    // const baseImage = new Image();
    // baseImage.src = selectedImageItem.originalImage;
    //
    // const canvas = document.createElement('canvas');
    // canvas.width = baseImage.naturalHeight;
    // canvas.height = baseImage.naturalWidth;
    //
    // const context = canvas.getContext('2d');
    // context.translate(canvas.width / 2, canvas.height / 2);
    // context.rotate(-90 * Math.PI / 180);
    // context.drawImage(baseImage, -baseImage.naturalHeight / 2 , -baseImage.naturalWidth / 2, baseImage.naturalWidth, baseImage.naturalHeight);
    // selectedImageItem.originalImage = canvas.toDataURL();

    this.cd.markForCheck();
  }

  onClickRotateRight(): void {
    this.imageRotateDeg += 90;
    this.imageRotateStyle = { transform: `rotate(${this.imageRotateDeg}deg)` };
    this.cd.markForCheck();
  }

  resetImageRotation(): void {
    this.imageRotateDeg = 0;
    this.imageRotateStyle = { transform: `rotate(${this.imageRotateDeg}deg)` };
    this.cd.markForCheck();
  }

  get width() {
    const element = document.getElementById('image-viewer-section');
    return element?.getBoundingClientRect().width - 33;
  }
}
