import { Injectable, TemplateRef } from '@angular/core';
import { ReplaySubject, from, EMPTY } from 'rxjs';
import { Breadcrumb } from '@core/models/application/breadcrumb';
import { LoyaltyService } from '@core/services/loyalty';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public onBreadcrumbUpdate$: ReplaySubject<Breadcrumb[]> = new ReplaySubject();
  public onBreadcrumbActionUpdate$: ReplaySubject<TemplateRef<any>> = new ReplaySubject();
  public onBreadcrumbTplUpdate$: ReplaySubject<TemplateRef<any>> = new ReplaySubject();
  public onBreadcrumbParamsUpdate$: ReplaySubject<{}> = new ReplaySubject();
  public breadcrumbExtraRow$: ReplaySubject<TemplateRef<any>> = new ReplaySubject();

  componentFactory: any;
  constructor(private loyaltyService: LoyaltyService) {}

  public updateBreadcrumbRightContainer(dynamicComponent: any) {
    this.onBreadcrumbActionUpdate$.next(dynamicComponent);
  }

  public updateBreadcrumbLeftContainer(dynamicComponent: any) {
    this.onBreadcrumbTplUpdate$.next(dynamicComponent);
  }

  public updateBreadcrumbExtraRowContainer(dynamicComponent: any) {
    this.breadcrumbExtraRow$.next(dynamicComponent);
  }

  public updateCustomParams(params: {}) {
    this.onBreadcrumbParamsUpdate$.next(params);
  }

  public loadPageMetadata(event: any): void {
    this.loyaltyService.currentProgramme$.subscribe(() => {
      this.updateBreadcrumb([]);

      if (event.module) {
        import(`src/app/pages/${event.module}/${event.module}.metadata`).then((data) => {
          let metaData = data.META_DATA;
          const breadcrumbKey = event.metadata.split('.');
          const breadcrumbData: Breadcrumb[] = [];

          if (metaData.parentLabel) {
            breadcrumbData.push({
              icon: metaData.parentIcon,
              label: metaData.parentLabel,
            });
          }

          breadcrumbKey.forEach((key: string) => {
            breadcrumbData.push({
              icon: metaData[key].icon,
              label: metaData[key].label,
              link: metaData[key].link && metaData[key].link !== '' ? metaData[key].link : null,
              hideBreadcrumb: metaData[key].extraParams?.hideBreadcrumb,
              showRightContainer: metaData[key].extraParams?.showRightContainer,
              isListingPage: metaData[key].extraParams?.isListingPage,
              overrideLeftContainer: metaData[key].extraParams?.overrideLeftContainer,
              hideContainerPadding: metaData[key].extraParams?.hideContainerPadding,
              showExtraRowContainer: metaData[key].extraParams?.showExtraRowContainer,
            });
            metaData = metaData[key].children;
          });
          this.updateBreadcrumb(breadcrumbData);
        });
      } else {
        this.updateBreadcrumb(null);
      }
    });
  }

  updateBreadcrumb(metadata: Breadcrumb[]) {
    this.onBreadcrumbUpdate$.next(metadata);
  }
}
