export abstract class TransactionConstants {
  // Transaction Status
  public static STATUS_APPROVED = 'APPROVED';
  public static STATUS_LOCKED = 'LOCKED';
  public static STATUS_REJECTED = 'REJECTED';
  public static STATUS_SUBMITTED = 'SUBMITTED';
  public static STATUS_NOT_ACTIVATED = 'NON_ACTIVATED';
  public static STATUS_EXPIRED = 'EXPIRED';
  public static STATUS_VOIDED = 'VOIDED';
  public static STATUS_PROCESSED = 'PROCESSED';
  public static STATUS_PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL';
  public static STATUS_CONSIGNMENT_COMBINED = 'CONSIGNMENT_COMBINED';
  public static STATUS_SPLIT_PAYMENT = 'SPLIT_PAYMENT';
  public static STATUS_REJECTED_CONSIGNMENT = 'REJECTED_CONSIGNMENT';
  public static STATUS_REJECTED_SPLIT_PAYMENT = 'REJECTED_SPLIT_PAYMENT';
  public static STATUS_VOIDED_CONSIGNMENT = 'VOIDED_CONSIGNMENT';
  public static STATUS_VOIDED_SPLIT_PAYMENT = 'VOIDED_SPLIT_PAYMENT';
  public static STATUS_CONSIGNMENT_PEND_FOR_COMBINE = 'CONSIGNMENT_PEND_FOR_COMBINE';
  public static STATUS_CONSIGNMENT_PEND_FOR_APPROVE = 'CONSIGNMENT_PEND_FOR_APPROVE';
  public static STATUS_NO_POINT_EARN = 'NO_POINT_EARN';

  // Point status
  public static POINT_EARNED_PROCESSED = 'PROCESSED';

  // Transaction Verification Status
  public static VER_STATUS_VERIFIED_WITH_ISSUE = 'VERIFIED_WITH_ISSUE';
  public static VER_STATUS_VERIFIED_WITHOUT_ISSUE = 'VERIFIED_WITHOUT_ISSUE';
  public static VER_STATUS_NOT_YET_VERIFIED = 'NOT_YET_VERIFIED';

  // verify reason mismatched
  public static WRONG_ELECTRONIC_RECEIPT_DATA_INPUT = 'WRONG_ELECTRONIC_RECEIPT_DATA_INPUT';
  public static WRONG_INVOICE_NUMBER_DATA_INPUT = 'WRONG_INVOICE_NUMBER_DATA_INPUT';
  public static WRONG_TENANT_DATA_INPUT = 'WRONG_TENANT_DATA_INPUT';
  public static WRONG_TRANSACTION_AMOUNT_DATA_INPUT = 'WRONG_TRANSACTION_AMOUNT_DATA_INPUT';
  public static WRONG_TRANSACTION_DATE_DATA_INPUT = 'WRONG_TRANSACTION_DATE_DATA_INPUT';
  public static VERIFY_REASON_MISMATCHED = [
    'WRONG_ELECTRONIC_RECEIPT_DATA_INPUT',
    'WRONG_INVOICE_NUMBER_DATA_INPUT',
    'WRONG_TENANT_DATA_INPUT',
    'WRONG_TRANSACTION_AMOUNT_DATA_INPUT',
    'WRONG_TRANSACTION_DATE_DATA_INPUT',
  ];
  public static VERIFY_REASON_MISMATCHED_MAP = {
    WRONG_ELECTRONIC_RECEIPT_DATA_INPUT: 'verifiedReceiptAmount',
    WRONG_INVOICE_NUMBER_DATA_INPUT: 'verifiedInvoiceNumbers',
    WRONG_TENANT_DATA_INPUT: 'verifiedTenantId',
    WRONG_TRANSACTION_AMOUNT_DATA_INPUT: 'verifiedTransactionAmount',
    WRONG_TRANSACTION_DATE_DATA_INPUT: 'verifiedTransactionDate',
  };

  public static rejectableTransactionStatuses: string[] = [
    TransactionConstants.STATUS_SUBMITTED,
    TransactionConstants.STATUS_LOCKED,
    TransactionConstants.STATUS_CONSIGNMENT_PEND_FOR_COMBINE,
    TransactionConstants.STATUS_CONSIGNMENT_PEND_FOR_APPROVE,
  ];
  public static approvableTransactionStatuses: string[] = [TransactionConstants.STATUS_SUBMITTED, TransactionConstants.STATUS_LOCKED, TransactionConstants.STATUS_CONSIGNMENT_PEND_FOR_APPROVE];
  public static voidableTransactionStatuses: string[] = [
    TransactionConstants.STATUS_CONSIGNMENT_COMBINED,
    TransactionConstants.STATUS_NOT_ACTIVATED,
    TransactionConstants.STATUS_APPROVED,
    TransactionConstants.STATUS_PROCESSED,
  ];
  
  public static notVoidTransactionType: string[] = ['CONSIGNMENT_MAIN_TRANSACTION'];

  public static noActionTransactionStatuses: string[] = [
    TransactionConstants.STATUS_SPLIT_PAYMENT,
    TransactionConstants.STATUS_REJECTED_CONSIGNMENT,
    TransactionConstants.STATUS_REJECTED_SPLIT_PAYMENT,
    TransactionConstants.STATUS_VOIDED_CONSIGNMENT,
    TransactionConstants.STATUS_VOIDED_SPLIT_PAYMENT,
    TransactionConstants.STATUS_NO_POINT_EARN,
    TransactionConstants.STATUS_REJECTED,
    TransactionConstants.STATUS_EXPIRED,
    TransactionConstants.STATUS_VOIDED,
  ];

  public static splitPaymentTransaction = [
    TransactionConstants.STATUS_SPLIT_PAYMENT,
    TransactionConstants.STATUS_VOIDED_SPLIT_PAYMENT
  ]

  public static consignmentTransaction = [
    TransactionConstants.STATUS_CONSIGNMENT_PEND_FOR_APPROVE,
    TransactionConstants.STATUS_CONSIGNMENT_PEND_FOR_COMBINE,
    TransactionConstants.STATUS_REJECTED_CONSIGNMENT,
    TransactionConstants.STATUS_VOIDED_CONSIGNMENT,
    TransactionConstants.STATUS_CONSIGNMENT_COMBINED
  ]
}
