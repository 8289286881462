export abstract class RedemptionConstants {
  public static PROMO_TYPE_REDEMPTION_BY_LOYALTY_CURRENCY = 'REDEMPTION_BY_LOYALTY_CURRENCY';
  public static PROMO_TYPE_YEAR_ROUND_OFFER = 'YEAR_ROUND_OFFER';
  public static PROMO_TYPE_REWARD_BY_SPENDING = 'REWARD_BY_SPENDING';
  public static PROMO_TYPE_BENEFITS_AND_PRIVILEGES = 'BENEFITS_AND_PRIVILEGES';
  public static PROMO_TYPE_REDEMPTION_BY_POINT = 'REDEMPTION_BY_LOYALTY_CURRENCY';
  public static PROMO_TYPE_REWARD_BY_ACTIVITIES = 'REWARD_BY_ACTIVITIES';
  public static PROMO_STATUS_ACTIVE = 'ACTIVE';
  public static PROMO_STATUS_INACTIVE = 'INACTIVE';

  public static REDEMPTION_STATUS_VOIDED = 'VOIDED';
  public static REDEMPTION_STATUS_REDEEMED = 'REDEEMED';
  public static REDEMPTION_STATUS_ACTUALIZED = 'ACTUALIZED';
  public static REDEMPTION_STATUS_EXPIRED = 'EXPIRED';
  public static REDEMPTION_STATUS_RESERVED = 'RESERVED';
  public static REDEMPTION_STATUS_REJECTED = 'REJECTED';
  public static REDEMPTION_STATUS_CANCELLED = 'CANCELLED';
  public static REDEMPTION_STATUS_VOIDED_CONSIGNMENT = 'VOIDED_CONSIGNMENT';
  public static REDEMPTION_STATUS_REJECTED_CONSIGNMENT = "REJECTED_CONSIGNMENT";
 public static REDEMPTION_STATUS_CONSIGNMENT_COMBINED = "CONSIGNMENT_COMBINED"
}
