<app-config-header></app-config-header>
<mat-sidenav-container class="config-content-container">
  <mat-sidenav #sidenav mode="side" opened class="config-sidenav">
    <app-config-sidenav></app-config-sidenav>
  </mat-sidenav>
  <mat-sidenav-content [class.has-fixed-height-container]="!isSetAutoOverflow">
    <div class="sticky">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="config-shell-content" [class.fixed-height-container]="!isSetAutoOverflow">
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
