<div class="container search-container main-container">
  <div class="row">
    <div class="ml-auto">
      <div class="expandable-icon" (click)="togglePanel()">
        <mat-icon [style.transform]="isExpended ? 'rotate(180deg)' : 'rotate(0deg)'">keyboard_arrow_down</mat-icon>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <c-data-table-server-search (clickSearch)="togglePanel()"></c-data-table-server-search>
      <!-- <c-data-table-client-search [tableTplRef]="tableTplRef"></c-data-table-client-search> -->
    </div>
  </div>
  <div [@inOutAnimation] class="row" *ngIf="isExpended" style="padding-top: 20px">
    <div class="col-12">
      <ng-container [ngTemplateOutlet]="formTpl"></ng-container>
    </div>
  </div>
</div>
