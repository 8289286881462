import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { MatIconModule } from '@angular/material/icon';
import { DirectiveModule } from '@app/@shared/directives/directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaskInputComponent } from './mask-input/mask-input.component';
import { IMaskModule } from 'angular-imask';
import { MatInputModule } from '@angular/material/input';
import { PointRangeInputComponent } from './point-range-form-field/point-range-input/point-range-input.component';
import { PointRangeFormFieldComponent } from './point-range-form-field/point-range-form-field.component';
import { SelectAllFormFieldComponent } from './select-all-form-field/select-all-form-field.component';
import { MatSelectModule } from '@angular/material/select';
import { FormlySelectOptionsPipe } from './select-all-form-field/formly-select-options.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormlySelectOptionsComponent } from './select-all-form-field/formly-select-options/formly-select-options.component';
import { NgSelectFieldComponent } from './ng-select-field/ng-select-field.component';
import { MultipleInputComponent } from './multiple-input-field/multiple-input-field.component'
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMatSelectModule } from 'ngx-mat-select';
import { SharedModule } from '@app/@shared/shared.module';
import { MatFormFieldWrapperComponent } from './mat-form-field-wrapper/mat-form-field-wrapper.component';
import { YearMonthPickerComponent } from './year-month-picker/year-month-picker.component';
import { ChipInputModule } from '../../components/chip-input/chip-input.module'

@NgModule({
  declarations: [
    DateRangePickerComponent,
    MaskInputComponent,
    PointRangeInputComponent,
    PointRangeFormFieldComponent,
    SelectAllFormFieldComponent,
    FormlySelectOptionsPipe,
    FormlySelectOptionsComponent,
    NgSelectFieldComponent,
    MultipleInputComponent,
    MatFormFieldWrapperComponent,
    YearMonthPickerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatIconModule,
    DirectiveModule,
    FormlyModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    IMaskModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    NgSelectModule,
    ChipInputModule,
    NgxMatSelectModule.forRoot(),
  ],
  exports: [DateRangePickerComponent, MaskInputComponent, PointRangeInputComponent, NgSelectFieldComponent],
})
export class FormFieldModule {}
