import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  public debug = false;
  constructor() {
    this.debug = !environment.production && localStorage.getItem('debug') === 'true' ? true : false;
  }

  public setDebugMode(debug: boolean) {
    this.debug = debug;
    debug ? localStorage.setItem('debug', 'true') : localStorage.setItem('debug', 'false');
  }
}
