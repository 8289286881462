import { Component, OnInit} from '@angular/core';


@Component({
  selector: 'RPT-no-img',
  templateUrl: './RPT-no-img.component.html',
  styleUrls: ['./RPT-no-img.component.scss'],
})
export class RPTNoImgTransactionComponent implements OnInit {
  dataSource:any[] = [];
  constructor() {}
  ngOnInit(): void {
      
  }
}
