import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

export interface ComponentLoader {
  loadChildren: () => Promise<any>;
}
@Injectable({
  providedIn: 'root',
})
export class StackViewerService {
  stackConfig: StackConfig;

  public viewerConfigDataSubject$: ReplaySubject<StackConfig> = new ReplaySubject();
  private isShowStackViewerSubject$ = new BehaviorSubject<boolean>(false);

  private buttonTemplateSubject = new Subject<TemplateRef<any>>();
  public buttonTemplate$ = this.buttonTemplateSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isShowStackViewer$ = this.isShowStackViewerSubject$.asObservable();

  private isReloadViewSubject = new Subject<boolean>();
  public isReloadViewer$ = this.isReloadViewSubject.asObservable();

  constructor() {}

  openPanel() {
    this.isShowStackViewerSubject$.next(true);
  }

  closePanel() {
    if (this.stackConfig && this.stackConfig.closeEvent) {
      this.stackConfig.closeEvent();
    }
    this.isShowStackViewerSubject$.next(false);
    this.buttonTemplateSubject.next(null);
  }

  reloadViewer() {
    this.isReloadViewSubject.next(true);
  }

  addButtonTemplate(buttonTemplate: TemplateRef<any>) {
    this.buttonTemplateSubject.next(buttonTemplate);
  }

  openViewer(data: any, component: any) {
    this.viewerConfigDataSubject$ = new ReplaySubject();
    this.viewerConfigDataSubject$.next({ ...data, component });
    this.stackConfig = data;
    this.openPanel();
    if (data.preOpenEvent) {
      data.preOpenEvent();
    }
  }
}

export interface StackConfig {
  panelTitle: string;
  templateRef?: any;
  component?: any;
  customData?: any;
  preOpenEvent?: () => void;
  closeEvent?: () => void;
}
