import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'info-display-cell',
  templateUrl: './dialog-info-display-cell.component.html',
  styleUrls: ['./dialog-info-display-cell.component.scss'],
})
export class DialogInfoDisplayCellComponent implements OnInit {
  @Input() fieldName: string;
  constructor() {}

  ngOnInit(): void {}
}
