import { Breadcrumb } from '@core/models/application/breadcrumb';
import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, TemplateRef, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SidenavService, BreadcrumbService } from '@core/services';
import { untilDestroyed } from '@core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('rightContainer', { read: ViewContainerRef }) rightContainer: ViewContainerRef;

  public breadcrumbArr: Breadcrumb[] = [];

  public isBreadcrumbVisible = true;
  public isOverrideLeftSection = false;
  public isRightSectionVisible: boolean;
  public isShowExtraRowContainer: boolean;

  // Templates
  public currentTemplate: TemplateRef<any>;
  public leftContainerTemplate: TemplateRef<any>;
  public extraRowTemplate: TemplateRef<any>;

  constructor(private breadcrumbService: BreadcrumbService, private sidenavService: SidenavService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.breadcrumbService.onBreadcrumbUpdate$.pipe(untilDestroyed(this)).subscribe((breadcrumbs) => {
      if (breadcrumbs) {
        breadcrumbs.forEach((breadcrumb: Breadcrumb) => {
          if (breadcrumb.hideBreadcrumb) {
            this.isBreadcrumbVisible = false;
            this.cd.markForCheck();
          } else {
            this.isBreadcrumbVisible = true;
            this.cd.markForCheck();
          }
          if (breadcrumb.showRightContainer) {
            this.isRightSectionVisible = true;
          } else {
            this.isRightSectionVisible = false;
          }
          if (breadcrumb.overrideLeftContainer) {
            this.isOverrideLeftSection = true;
          } else {
            this.isOverrideLeftSection = false;
          }
          if (breadcrumb.hideContainerPadding) {
            this.sidenavService.setContainerPadding(true);
          } else {
            this.sidenavService.setContainerPadding(false);
          }
          if (breadcrumb.showExtraRowContainer) {
            this.isShowExtraRowContainer = true;
          } else {
            this.isShowExtraRowContainer = false;
          }
          this.cd.markForCheck();
        });
        this.breadcrumbArr = breadcrumbs;
        this.cd.markForCheck();
      } else {
        this.isBreadcrumbVisible = false;
        this.isRightSectionVisible = false;
        this.cd.markForCheck();
      }
    });

    this.breadcrumbService.onBreadcrumbActionUpdate$.pipe(untilDestroyed(this)).subscribe((actionSection) => {
      this.currentTemplate = actionSection;
      this.cd.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.breadcrumbService.onBreadcrumbTplUpdate$.pipe(untilDestroyed(this)).subscribe((leftSection) => {
      this.leftContainerTemplate = leftSection;
      this.cd.markForCheck();
    });

    this.breadcrumbService.breadcrumbExtraRow$.pipe(untilDestroyed(this)).subscribe((extraRowTemplate) => {
      this.extraRowTemplate = extraRowTemplate;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}
}
