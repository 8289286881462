<div mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="inner-p-20">
  <div translate>RECEIPT_TRANSACTIONS.MESSAGE.VERIFY_TRANSACTION</div>
  <form [formGroup]="verifyTransactionFormGroup">
    <div class="row pt-3">
      <div class="col-12">
        <mat-checkbox class="mat-option" color="primary" formControlName="isVerifyWithoutIssue">
          {{ 'RECEIPT_TRANSACTIONS.LABEL.VERIFY_WITHOUT_ISSUE' | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field [requiredOverrideValue]="!isDisableFailureReason" class="full-width multiple-select">
          <mat-label>{{ 'RECEIPT_TRANSACTIONS.LABEL.VERIFY_FAILURE_REASON' | translate }}</mat-label>
          <mat-select multiple formControlName="verifyFailureReasons">
            <mat-option *ngFor="let option of dropdownData?.RECEIPT_TRANSACTION.VERIFY_FAILURE_REASON" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="isShowTransactionDetailSection" class="row">
      <div class="col-12">
        <div class="ocr-label-wrapper">
          <span [ngClass]="{'required-input-invalid':requiredInputInvalid('verifiedTenantId')}" class="label" translate>RECEIPT_TRANSACTIONS.LABEL.TENANT <span *ngIf="isRequired('verifiedTenantId')">*</span></span>
        </div>
        <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
          <ng-select formControlName="verifiedTenantId" ngSelectMat [items]="this.tenantList" bindLabel="name" bindValue="id"> </ng-select>
        </mat-form-field>

        <!-- Transaction date and time -->
        <!-- <div class="ocr-label-wrapper">
          <span class="label" translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_DATE</span>
        </div> -->
        <mat-form-field class="full-width">
          <mat-label>{{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_DATE' | translate }} <span *ngIf="isRequired('verifiedTransactionDate')">*</span></mat-label>
          <app-masked-datepicker placeholder="(DD-MM-YYYY)" formControlName="verifiedTransactionDate" [max]="DATE_TODAY"> </app-masked-datepicker>
        </mat-form-field>
        <!-- Total transaction amount -->
        <div class="ocr-label-wrapper">
          <span [invalidInput]="verifyTransactionFormGroup.get('verifiedTransactionAmount').invalid && verifyTransactionFormGroup.get('verifiedTransactionAmount').touched" class="label" translate
            >RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_AMOUNT <span *ngIf="isRequired('verifiedTransactionAmount')">*</span></span
          >
        </div>
        <mat-form-field class="full-width flex-input calculation">
          <span>HKD&nbsp;</span>
          <!-- <input matInput autocomplete="off" inputmode="numeric" formControlName="verifiedTransactionAmount" [imask]="HKD_MASK" unmask="true" /> -->
          <calculation-input [allowNegativeAmount]="haveDepositTransactionAccess" formControlName="verifiedTransactionAmount"></calculation-input>
          <mat-error *ngIf="verifiedTransactionAmount.hasError('invalidInput')">Invalid amount input</mat-error>
        </mat-form-field>

        <!-- Electronic receipt amount -->
        <div class="ocr-label-wrapper">
          <span [invalidInput]="verifyTransactionFormGroup.get('verifiedReceiptAmount').invalid && verifyTransactionFormGroup.get('verifiedReceiptAmount').touched" class="label" translate
            >RECEIPT_TRANSACTIONS.LABEL.ELEC_AMOUNT <span *ngIf="isRequired('verifiedReceiptAmount')">*</span></span
          >
        </div>
        <mat-form-field class="full-width flex-input calculation">
          <span>HKD&nbsp;</span>
          <!-- <input matInput inputmode="numeric" formControlName="verifiedReceiptAmount" [imask]="HKD_MASK" unmask="true" /> -->
          <calculation-input [allowNegativeAmount]="haveDepositTransactionAccess" formControlName="verifiedReceiptAmount"></calculation-input>
          <mat-error *ngIf="verifiedReceiptAmount.hasError('invalidInput')">Invalid amount input</mat-error>
        </mat-form-field>
        <!-- Invoice number -->
        <div class="ocr-label-wrapper">
          <span [ngClass]="{'required-input-invalid':requiredInputInvalid('verifiedInvoiceNumbers')}" class="label" translate>RECEIPT_TRANSACTIONS.LABEL.INVOICE_NUMBER <span *ngIf="isRequired('verifiedInvoiceNumbers')">*</span></span>
        </div>
        <mat-form-field class="full-width" floatLabel="never">
          <app-chip-input formControlName="verifiedInvoiceNumbers"></app-chip-input>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="full-width">
          <mat-label>{{ 'RECEIPT_TRANSACTIONS.LABEL.VERIFY_COMMENT' | translate }}</mat-label>
          <!-- <input #remarks matInput name="remarks" autocomplete="off" formControlName="verifiedRemarks" maxlength="500" placeholder="Input {{ 'RECEIPT_TRANSACTIONS.LABEL.VERIFY_COMMENT' | translate }}" /> -->
          <textarea #remarks matInput name="remarks" autocomplete="off" formControlName="verifiedRemarks" maxlength="65535" placeholder="Input {{ 'RECEIPT_TRANSACTIONS.LABEL.VERIFY_COMMENT' | translate }}"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions class="action-wrapper">
  <c-form-button color="basic" class="grey" label="COMMON.LABEL.CANCEL" (click)="onClickCloseButton()" [upperCaseLabel]="true" [disabled]="verifyButtonDisable"></c-form-button>
  <c-form-button color="primary" label="COMMON.LABEL.CONFIRM" (click)="submitVerifyApprovedTransactionRequest()" [upperCaseLabel]="true" [disabled]="verifyTransactionFormGroup.invalid || verifyButtonDisable"></c-form-button>
</div>
