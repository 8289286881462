<div class="side-menu">
  <div *ngFor="let item of menuItemList">
    <ul [style.marginBottom.px]="!isMenuOpened ? 0 : null">
      <div *ngIf="isMenuOpened && item.sectionLabelId" class="parent-title">
        <span translate>
          {{ item.sectionLabelId | dynamicBreadcrumb }}
        </span>
      </div>
      <mat-accordion>
        <ng-container *ngFor="let menuItem of item.children">
          <mat-expansion-panel #panelWithSubMenu [hideToggle]="!isMenuOpened" [disabled]="!isMenuOpened" *ngIf="menuItem.children && menuItem.children.length > 0; else buttonTmpl">
            <mat-expansion-panel-header
              [routerLinkActive]="['is-active']"
              [routerLinkActiveOptions]="{ exact: false }"
              [class.panel-expand]="isMenuOpened"
              [class.panel-collapsed]="!isMenuOpened"
              [class.panel-expand-arrow]="panelWithSubMenu.expanded"
              [class.panel-collapsed-arrow]="!panelWithSubMenu.expanded"
            >
              <mat-panel-title>
                <!-- Temp Solution for skip navigation -->
                <span *ngFor="let hiddenMenuItem of menuItem.route" style="display: none" [routerLink]="hiddenMenuItem"></span>
                <span class="material-icons">
                  {{ menuItem.iconName }}
                </span>
                <span *ngIf="isMenuOpened" class="menu-title" translate>
                  {{ menuItem.labelId | dynamicBreadcrumb }}
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul *ngIf="isMenuOpened && menuItem.children && menuItem.children.length > 0" class="sub-menu">
              <li *ngFor="let subMenuItem of menuItem.children" [routerLink]="subMenuItem.route" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{ exact: true }">
                <a>
                  <span *ngIf="subMenuItem.iconName && subMenuItem.iconName !== ''" class="material-icons">
                    {{ subMenuItem.iconName }}
                  </span>
                  <span class="menu-title" translate>
                    {{ subMenuItem.labelId | dynamicBreadcrumb }}
                  </span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
          <ng-template #buttonTmpl>
            <mat-expansion-panel [disabled]="true" [hideToggle]="true">
              <mat-expansion-panel-header
                [routerLink]="menuItem.route"
                [routerLinkActive]="['is-active']"
                [routerLinkActiveOptions]="{ exact: false }"
                [class.panel-expand]="isMenuOpened"
                [class.panel-collapsed]="!isMenuOpened"
              >
                <mat-panel-title>
                  <span class="material-icons">
                    {{ menuItem.iconName }}
                  </span>
                  <span *ngIf="isMenuOpened" class="menu-title" translate>
                    {{ menuItem.labelId | dynamicBreadcrumb }}
                  </span>
                  <!-- </a> -->
                </mat-panel-title>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </ng-template>
        </ng-container>
      </mat-accordion>
    </ul>
  </div>
</div>
