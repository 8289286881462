export abstract class ViewTransactionConstants {
  public static STATUS_SUBMITTED = 'SUBMITTED';
  public static STATUS_PROCESSED = 'PROCESSED';
  public static STATUS_PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL';
  public static STATUS_CONSIGNMENT_PEND_FOR_COMBINE = 'CONSIGNMENT_PEND_FOR_COMBINE';
  public static STATUS_CONSIGNMENT_PEND_FOR_APPROVE = 'CONSIGNMENT_PEND_FOR_APPROVE';
  public static STATUS_NO_POINT_EARN = 'NO_POINT_EARN';

  public static TRANSACTION_STATUS_APPROVED = 'APPROVED';
  public static TRANSACTION_STATUS_REJECTED = 'REJECTED';
  public static TRANSACTION_STATUS_VOIDED = 'VOIDED';
  public static TRANSACTION_STATUS_EXPIRED = 'EXPIRED';
  public static STATUS_LOCKED = 'LOCKED';
  public static STATUS_NON_ACTIVATED = 'NON_ACTIVATED';
  public static VERIFIED_WITH_ISSUE = 'VERIFIED_WITH_ISSUE';
  public static VERIFIED_WITHOUT_ISSUE = 'VERIFIED_WITHOUT_ISSUE';

  public static STATUS_CONSIGNMENT_COMBINED = 'CONSIGNMENT_COMBINED';
  public static STATUS_SPLIT_PAYMENT = 'SPLIT_PAYMENT';
  public static STATUS_REJECTED_CONSIGNMENT = 'REJECTED_CONSIGNMENT';
  public static STATUS_REJECTED_SPLIT_PAYMENT = 'REJECTED_SPLIT_PAYMENT';
  public static STATUS_VOIDED_CONSIGNMENT = 'VOIDED_CONSIGNMENT';
  public static VOIDED_SPLIT_PAYMENT = 'VOIDED_SPLIT_PAYMENT';

  public static noDispalyOcrPaymentMethod: string[] = ["BANK_TRANSFER","OTHER","CHEQUE","TENANT_PAYMENT_BY_LINK"];
}

