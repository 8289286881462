export function consolidateValidationError(validationIsInvalid: boolean, existingError: any, errorKey: string) {
  if (validationIsInvalid) {
    const onAppendedError = {};
    onAppendedError[errorKey] = true;
    return existingError ? { ...existingError, ...onAppendedError } : onAppendedError;
  } else {
    if (existingError) {
      if (existingError[errorKey]) {
        if (Object.keys(existingError).length > 1) {
          delete existingError[errorKey];
          return existingError;
        }
        return null;
      }
      return existingError;
    } else return null;
  }
}
