import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentsListComponent } from './attachments-list.component';
import { MatButtonModule } from '@angular/material/button';
import { DxDataGridModule } from 'devextreme-angular';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '@shared/directives/directive.module';
import { SystemPipeModule } from '@app/@shared/pipes/system/system-pipe.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [AttachmentsListComponent],
  imports: [CommonModule, MatButtonModule, DxDataGridModule, TranslateModule, DirectiveModule, SystemPipeModule, MatIconModule],
  exports: [AttachmentsListComponent],
})
export class AttachmentsListModule {}
