import { Component, OnInit } from '@angular/core';
import { UserService, ConfigSidenavService } from '@core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isMenuOpen = false;
  currentUser: any = {} as any;
  username: string;
  constructor(private configSidenavService: ConfigSidenavService, private userService: UserService, private router: Router) {}

  ngOnInit() {
    Object.assign(this.currentUser, this.userService.currentUserInfo);
    this.username = this.currentUser.given_name ? `${this.currentUser.given_name || ''} ${this.currentUser.family_name || ''}` : '';
  }

  closeConfigView() {
    const redirectUrl = this.configSidenavService.redirectUrlFromConfig;
    this.configSidenavService.redirectUrlFromConfig = null;
    this.router.navigateByUrl(redirectUrl || '/admin/home');
  }

  onClickLogout() {
    this.userService.manualLogout();
  }
}
