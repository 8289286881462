import { Observable, of } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { Tenant } from '@core/models/business-unit/tenant';
import { TenantService } from '@core/services/tenant.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'merchant',
})
export class MerchantPipe implements PipeTransform {
  constructor(private tenantService: TenantService) {}

  transform(id: number | string, ...args: string[]): Observable<string> {
    let listType;
    if (args[0] === 'ACTIVE') {
      listType = { listType: 'ACTIVE' };
    } else {
      listType = {};
    }
    if(args && (args[0] as any) instanceof Array) {
      const findData =  (args[0] as any).find((tenant: Tenant) => tenant.id === id);
      return of((findData && findData.name) || '')
    }
    return this.tenantService.getMerchantList().pipe(
      map((tenantList: Tenant[]) => {
        const foundMerchant = tenantList.find((tenant: Tenant) => tenant.id === id);
        return (foundMerchant && (foundMerchant.name as string)) || '';
      })
    );
  }
}
