import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculationInputComponent } from './calculation-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IMaskModule } from 'angular-imask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CalculationInputComponent],
  imports: [CommonModule, TranslateModule, MatIconModule, MatInputModule, IMaskModule, FormsModule, ReactiveFormsModule],
  exports: [CalculationInputComponent],
})
export class CalculationInputModule {}
