import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '@env/environment';

@Pipe({
  name: 'systemDateFormat',
})
export class SystemDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (Object.keys(environment.SYSTEM_DATE_FORMAT).indexOf('SYSTEM_DATE_FORMAT') >= 0) {
      return super.transform(value, environment.SYSTEM_DATE_FORMAT);
    }
    if(args){
      return super.transform(value, args);
    }
    return super.transform(value, 'dd-MM-yyyy');
  }
}
