import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidenavService, UserService, ConfigSidenavService, QrCodeScannerService, DebugService, DialogService } from '@core/services';
import { QrCodeConstants } from '@core/services/application/barcode-scanner';
import { Router } from '@angular/router';
import { DialogData, untilDestroyed, User } from '@core';
import { environment } from '@env/environment';
@Component({
  selector: 'shell-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: User = {} as User;
  username: string;
  userTitle: string;
  envVersion: string;

  public environment = environment;

  constructor(
    private configSidenavService: ConfigSidenavService,
    private dialogService: DialogService,
    private qrCodeScannerService: QrCodeScannerService,
    private router: Router,
    private sidenavService: SidenavService,
    public userService: UserService,
    public qrCodeConstants: QrCodeConstants,
    public debugService: DebugService
  ) {}

  ngOnInit() {
    Object.assign(this.currentUser, this.userService.currentUserInfo);
    this.username = `${this.currentUser.firstName || ''} ${this.currentUser.lastName || ''}`;
    this.envVersion = environment.version;
  }

  ngOnDestroy() {}

  public toggleMenu() {
    this.sidenavService.toggleSidenav();
  }

  public onClickSetting() {
    this.configSidenavService.redirectUrlFromConfig = this.router.url;
    this.router.navigateByUrl('config');
  }

  public onClickLogout() {
    const dialogData: DialogData = {
      content: `COMMON.MESSAGE.CONFIRM_LOGOUT`,
      id: 'confirm-logout-dialog',
      yesLabel: 'COMMON.LABEL.CONFIRM',
      noLabel: 'COMMON.LABEL.CANCEL',
      yesCallback: (dialogRef) => {
        this.userService.manualLogout();
        dialogRef.close();
      },
      noCallback: (dialogRef) => {
        dialogRef.close();
      },
    };
    this.dialogService.showConfirmationDialog(dialogData).pipe(untilDestroyed(this)).subscribe();
  }

  public onClickScanQRCode() {
    this.qrCodeScannerService.scanQrCode({
      successCallback: (response: string) => {
        this.qrCodeScannerService.processQrCode(response);
      },
      title: 'Scan QR code',
    });
  }

  get usernameInitial() {
    return this.username[0];
  }
}
