import { Component, AfterContentInit, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreadcrumbService, SidenavService, StackViewerService } from '@core/services';
import { animate, style, transition, trigger, state } from '@angular/animations';
import { untilDestroyed } from '@core/until-destroyed';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin-shell',
  templateUrl: './admin-shell.component.html',
  styleUrls: ['./admin-shell.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          width: '66px',
        })
      ),
      state(
        'out',
        style({
          width: '260px',
        })
      ),
      transition('in => out', animate('150ms ease-in-out')),
      transition('out => in', animate('150ms ease-in-out')),
    ]),

    trigger('marginLeftInOut', [
      state(
        'in',
        style({
          marginLeft: '66px',
        })
      ),
      state(
        'out',
        style({
          marginLeft: '260px',
        })
      ),
      transition('in => out', animate('150ms ease-in-out')),
      transition('out => in', animate('150ms ease-in-out')),
    ]),
  ],
})
export class AdminShellComponent implements AfterContentInit, OnDestroy {
  public sidenav: MatSidenav;
  isMenuOpened = false;
  menuState = 'in';
  isHideContainerPadding = false;
  isShowStackViewer = false;
  isBreadcrumbVisible = false;
  isSetAutoOverflow = true;
  constructor(private sidenavService: SidenavService, private stackViewerService: StackViewerService, private breadcrumbService: BreadcrumbService) {
    this.stackViewerService.isShowStackViewer$.subscribe((isShowPanel) => {
      this.isShowStackViewer = isShowPanel;
    });

    this.breadcrumbService.onBreadcrumbUpdate$.pipe(untilDestroyed(this)).subscribe((breadcrumbs) => {
      if (breadcrumbs && breadcrumbs?.length && !breadcrumbs[breadcrumbs?.length - 1]?.hideBreadcrumb) {
        this.isBreadcrumbVisible = true;
      } else {
        this.isBreadcrumbVisible = false;
      }
      if (breadcrumbs[breadcrumbs?.length - 1]?.isListingPage) {
        this.isSetAutoOverflow = false;
      } else {
        this.isSetAutoOverflow = true;
      }
    });
  }

  ngAfterContentInit() {
    this.sidenavService.isMenuOpened$.subscribe((isOpen) => {
      this.isMenuOpened = isOpen;
      this.menuState = this.isMenuOpened ? 'out' : 'in';
    });
    this.sidenavService.isHideContainerPadding$.subscribe((isHideContainerPadding) => {
      this.isHideContainerPadding = isHideContainerPadding;
    });
  }

  ngOnDestroy(): void {}
}
