import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-tooltip',
  templateUrl: './search-tooltip.component.html',
  styleUrls: ['./search-tooltip.component.scss'],
})
export class SearchTooltipComponent implements OnInit {
  @Input() searchFields: { key: string };
  @Input() tooltipMsg: string;
  searchFieldDisplay: any[] = [];
  constructor() {}

  ngOnInit(): void {
    this.searchFieldDisplay = this.transformDisplayFields(Object.values(this.searchFields));
  }

  transformDisplayFields(list: string[]) {
    let matrix;
    const half = Math.floor(list.length / 2);
    const firstHalf = list.slice(0, half);
    const secondHalf = list.slice(half);
    matrix = [secondHalf, firstHalf];
    return matrix;
  }
}
