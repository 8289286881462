import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@core/services/auth/user.service';
import { DialogService } from '@core/services/application/dialog/dialog.service';
import { DialogData } from '..';

@Injectable({
  providedIn: 'root',
})
export class SystemAdminGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(private userService: UserService, private router: Router, private dialogService: DialogService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userService.systemAdminSubject?.value) {
      if (history.state && history.state.navigationId > 1) {
        // page navigation
      } else {
        // direct link
        this.router.navigateByUrl('/admin/home');
      }

      const dialogData: DialogData = {
        title: 'Error',
        content: 'ERROR.MESSAGE.ACCESS_DENIED',
        width: '370px',
        yesCallback: (dialogRef) => {
          dialogRef.close();
        },
      };
      this.dialogService.showErrorDialog(dialogData).subscribe();
      return false;
    }
    return true;
  }
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userService.systemAdminSubject?.value) {
      if (history.state && history.state.navigationId > 1) {
        // page navigation
        if (location.pathname.indexOf('/config/') !== -1) {
          this.router.navigateByUrl('/admin/home');
          return false;
        }
      } else {
        // direct link
        this.router.navigateByUrl('/admin/home');
      }

      const dialogData: DialogData = {
        title: 'Error',
        content: 'ERROR.MESSAGE.ACCESS_DENIED',
        width: '370px',
        yesCallback: (dialogRef) => {
          dialogRef.close();
        },
      };
      this.dialogService.showErrorDialog(dialogData).subscribe();
      return false;
    }
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
