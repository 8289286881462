import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { CurrencyConversionService } from '../currency-conversion.service';
import { LoyaltyService } from '../loyalty';

@Injectable({
  providedIn: 'root',
})
export class CurrencyConversionRateResolverService {
  constructor(private router: Router, private currencyConversionService: CurrencyConversionService, private loyaltyService: LoyaltyService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.currencyConversionService.getConversionRates().pipe(catchError((err) => this.router.navigateByUrl('/')));
  }
}
