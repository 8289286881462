import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnInit, ViewChild, ElementRef, Input, Inject, Optional, Self, HostBinding, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl, ValidatorFn } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import * as moment from 'moment';
import IMask from 'imask';
import { Subject, timer } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { take } from 'rxjs/operators';
import { untilDestroyed } from '@app/@core';

@Component({
  selector: 'app-masked-datepicker',
  templateUrl: './masked-datepicker.component.html',
  styleUrls: ['./masked-datepicker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: MaskedDatepickerComponent }],
})
export class MaskedDatepickerComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, MatFormFieldControl<string> {
  static nextId = 0;

  @ViewChild('datePickerInput', { static: false }) datePickerInput: ElementRef;
  @ViewChild('picker', { static: false }) picker: MatDatepicker<any>;

  @Input() min: moment.Moment = moment('01-01-1900', 'DD-MM-YYYY');
  @Input() max: moment.Moment = moment('31-12-9999', 'DD-MM-YYYY');
  @Input() matDatepickerFilter: (date: Date) => void;
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return !this.formGroup.get('inputValue').value?.length;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): string {
    return this.formGroup.get('datepickerValue').value && moment(this.formGroup.get('datepickerValue').value).isValid() ? moment(this.formGroup.get('datepickerValue').value).format() : null;
  }
  set value(date: string) {
    this.formGroup.get('datepickerValue').setValue(moment(date));
    this.stateChanges.next();
  }

  get errorState() {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  public touched: boolean;

  stateChanges = new Subject<void>();
  @HostBinding() id = `masked-datepicker-${MaskedDatepickerComponent.nextId++}`;
  name = `masked-datepicker-${MaskedDatepickerComponent.nextId++}`;

  focused = false;
  // controlType?: string;
  // autofilled?: boolean;
  // userAriaDescribedBy?: string;

  DATE_MASK = {
    mask: Date,
    pattern: 'd-`m-`Y', // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      Y: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
      },
    },
    // just make sure format ( parse (yourInputDateString) ) === yourInputDateString
    // define date -> str convertion
    format(date: any) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return [day, month, year].join('-');
    },
    // define str -> date convertion
    parse(str: any) {
      return moment(str, 'DD-MM-YYYY').toDate();
    },
    autofix: false,
    lazy: true,
    overwrite: true,
  };

  // public _value: moment.Moment;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  public formGroup: UntypedFormGroup;

  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional()
    @Inject(MAT_FORM_FIELD)
    public _formField: MatFormField,
    @Optional()
    @Self()
    public ngControl: NgControl,
    private fb: UntypedFormBuilder
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin && !this.formGroup.disabled;
      this.stateChanges.next();
    });

    this.formGroup = this.fb.group({
      inputValue: [null],
      datepickerValue: [moment()],
    });

    this.formGroup
      .get('datepickerValue')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.formGroup.get('inputValue').setValue(val.isValid() ? val.format('DD-MM-YYYY') : null, { emitEvent: false });
        this.onChange(this.value);
        this.onTouched();
        this.stateChanges.next();
      });

    this.formGroup
      .get('inputValue')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((val) => {
        if (val?.length === 8) {
          const date = moment(val, 'DDMMYYYY');
          if (!date.isValid() || moment(this.min).isAfter(date) || moment(this.max).isBefore(date)) {
            this.formGroup.get('inputValue').setValue(null, { emitEvent: false });
            this.formGroup.get('datepickerValue').setValue(null, { emitEvent: false });
          } else {
            this.formGroup.get('datepickerValue').setValue(date, { emitEvent: false });
          }
        }
        this.onChange(this.value);
        this.onTouched();
        this.stateChanges.next();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  setDescribedByIds(ids: string[]): void {
    // throw new Error('Method not implemented.');
  }
  onContainerClick(event: MouseEvent): void {
    this.onTouched();
  }
  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  onChange = (delta: any) => {};
  onTouched = () => {
    this.touched = true;
    this.stateChanges.next();
  };

  ngOnInit(): void {}

  // prompt the datepicker once the input is clicked, while move the focus to the input so user could input the date at the same time
  onDatePickerInputClicked(datePicker: MatDatepicker<any>) {
    if (!datePicker.opened) {
      datePicker.open();
    }
  }

  opened(datePickerInput: HTMLInputElement) {
    timer(1)
      .pipe(take(1))
      .subscribe(() => {
        datePickerInput.focus();
      });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
}
