import { DatePipe } from '@angular/common';
import { EventEmitter, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ReferenceTransaction } from '@app/@core/models/receipt-transaction';
import { DialogService, TransactionDialogService, UrlService, ChangeRequestService, ReceiptTransactionService } from '@app/@core/services';
import { VoidTransactionDialogComponent } from '../dialogs/void-transaction-dialog/void-transaction-dialog.component';
import { DialogData, untilDestroyed, StackViewerService } from '@app/@core';
import { AttachmentRequest } from '@core/models';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RedemptionConstants } from '@app/@core/services/campaigns/redemption.constants';
import { RejectTransactionDialogComponent } from '@app/@shared/components/receipt-transaction/dialogs/reject-transaction-dialog/reject-transaction-dialog.component';
import { Observable, of, zip } from 'rxjs';
import { cloneDeep } from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewTransactionConstants } from '@app/pages/receipt-transaction/view-transaction/view-transaction.constants';
import { TransactionConstants } from '@shared/components/receipt-transaction/receipt-transaction.constant';
import { FeatureFlagService } from '@core/services/feature-flag.service';

@Component({
  selector: 'receipt-transaction-compare-receipts',
  templateUrl: './compare-receipts.component.html',
  styleUrls: ['./compare-receipts.component.scss'],
  providers: [DatePipe],
})
export class CompareReceiptsComponent implements OnInit {
  referencedTransactionDetail: ReferenceTransaction;
  originalTransactionData: ReferenceTransaction;
  previousTransactionAuditPortalPath: string;
  memberProfile: any; // TODO
  isRpt = false;
  isCompareRpt = false;
  rejectObj: any = null;

  @Input('customData') customData: any;
  constructor(
    private datePipe: DatePipe,
    private transactionDialogService: TransactionDialogService,
    private dialogService: DialogService,
    private urlService: UrlService,
    private router: Router,
    private changeRequestService: ChangeRequestService,
    private receiptTransactionService: ReceiptTransactionService,
    private stackViewerService: StackViewerService,
    public featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.referencedTransactionDetail = this.customData.referencedTransactionData;
    this.isRpt = this.featureFlagService.getFeatureFlag('RPT') && this.referencedTransactionDetail.transactionDetail?.rptReceipt;//this.receiptTransactionService.isRptReceipt(this.referencedTransactionDetail.transactionDetail, this.customData?.tenantList);
    if (this.isRpt) {
      this.referencedTransactionDetail.transactionDetail.actualizedTransactionAmount = this.referencedTransactionDetail.transactionDetail?.actualizedTransactionAmount ?? 0;
      this.referencedTransactionDetail.transactionDetail.rptExcludedAmount = this.referencedTransactionDetail.transactionDetail?.rptExcludedAmount ?? 0
    }

    this.originalTransactionData = this.customData.originalTransactionData;

    this.isCompareRpt = this.featureFlagService.getFeatureFlag('RPT') && this.receiptTransactionService.isRptReceipt(this.originalTransactionData.transactionDetail, this.customData?.tenantList);
    if (this.isCompareRpt) {
      this.originalTransactionData.transactionDetail.actualizedTransactionAmount = this.originalTransactionData.transactionDetail?.actualizedTransactionAmount ?? 0;
      this.originalTransactionData.transactionDetail.rptExcludedAmount = this.originalTransactionData.transactionDetail?.rptExcludedAmount ?? 0
    }
    this.urlService.previousUrl$.pipe(take(1)).subscribe((url: string) => {
      if (url?.includes('receipt-transaction/list')) {
        this.previousTransactionAuditPortalPath = url;
      }
    });
  }
  onClickRejectButton() {
    // reject
    this.transactionDialogService
      .showRejectDialog(
        {
          isModified: false,
        },
        RejectTransactionDialogComponent
      )
      .subscribe((dialogRef) => {
        dialogRef.afterClosed().subscribe((dialogResponse) => {
          if (dialogResponse && dialogResponse.hasOwnProperty('rejectReason')) {
            this.rejectObj = dialogResponse;

            this.rejectTransaction();
          }
        });
      });
  }
  uploadWorkflowAttachments(): Observable<AttachmentRequest[]> {
    if (this.customData.attachments.length > 0) {
      const fileHandlingObservable: Observable<any>[] = [];
      this.customData.attachments.forEach((attachment: any) => {
        fileHandlingObservable.push(this.changeRequestService.uploadAttachment(attachment.file, null, null, true));
      });
      return zip(...fileHandlingObservable).pipe(
        map((response: any[]) => {
          return response;
        })
      );
    } else {
      return of(null);
    }
  }
  rejectTransaction() {
    this.uploadWorkflowAttachments().subscribe((attachmentList) => {
      const requestBody = {
        attachments: cloneDeep(this.customData.attachments),
        ...this.referencedTransactionDetail?.transactionDetail,
        rejectedReason: this.rejectObj?.rejectReason,
        rejectedRemarks: this.rejectObj?.rejectedRemarks,
        transactionModifyReason: this.rejectObj?.transactionModifyReason,
      };

      if (attachmentList?.length > 0) {
        requestBody.attachments = requestBody.attachments.map((attachment: any, index: number) => {
          attachment.filePath = attachmentList[index].filePath;
          delete attachment.file;
          return attachment;
        });
      }
      this.receiptTransactionService.rejectTransaction(requestBody, false).subscribe(
        () => {
          const dialogData: DialogData = {
            content: `Transaction ${this.referencedTransactionDetail?.transactionDetail.id} of ${this.referencedTransactionDetail?.memberProfile?.id} has been rejected.`,
            buttonOneLabel: 'GO TO TRANSACTION AUDIT PORTAL',
            strokedButtonLabel: 'NEXT PENDING RECEIPT',
            strokedButtonTwoLabel: 'GO TO MEMBER LIST', // TODO:
            buttonOneCallback: (dialogRef) => {
              dialogRef.close();
              this.dialogService.closeExistingDialog('duplicated-receipts-confirmation-dialog');
              const path = this.previousTransactionAuditPortalPath ?? `admin/receipt-transaction/list`;
              this.router.navigateByUrl(path);
            },
            strokedButtonCallback: (dialogRef) => {
              dialogRef.close();
              this.receiptTransactionService.closeCompareReceiptesSubject$.next(this.referencedTransactionDetail?.transactionDetail.id);
              this.stackViewerService.closePanel();
            },
            strokedButtonTwoCallback: (dialogRef) => {
              dialogRef.close();
              this.dialogService.closeExistingDialog('duplicated-receipts-confirmation-dialog');
              this.router.navigate([`admin/members/list`]);
            },
          };
          this.dialogService.showSuccessDialog(dialogData).subscribe();
        },
        (err) => {
          // switch form state to end-submitting and enable form edit
        }
      );
    });
  }
  onClickVoidReceipt() {
    const transactionDetail = {
      guestId: this.referencedTransactionDetail.transactionDetail.guestId,
      receiptTransactionId: this.referencedTransactionDetail.transactionDetail.id,
    };
    this.transactionDialogService.openVoidTransactionDialog(transactionDetail, VoidTransactionDialogComponent).subscribe((dialogRef) => {
      dialogRef.afterClosed().subscribe((response: { isVoided: boolean }) => {
        if (response?.isVoided) {
          this.openSuccessfulDialog();
        }
      });
    });
  }

  openSuccessfulDialog() {
    const dialogData: DialogData = {
      content: `Request Submitted`,
      buttonOneLabel: 'RECEIPT_TRANSACTIONS.LABEL.GO_TO_TRANSACTION_AUDIT_PORTAL',
      strokedButtonTwoLabel: 'GO TO MEMBER LIST', // TODO:
      buttonOneCallback: (successfulDialogRef) => {
        successfulDialogRef.close();
        const path = this.previousTransactionAuditPortalPath ?? `admin/receipt-transaction/list`;
        this.router.navigateByUrl(path);
        this.stackViewerService.closePanel();
        this.dialogService.closeAllDialogs();
      },
      strokedButtonTwoCallback: (dialogRef) => {
        dialogRef.close();
        this.stackViewerService.closePanel();
        this.dialogService.closeAllDialogs();
        this.router.navigate([`admin/members/list`]);
      },
    };
    this.dialogService.showSuccessDialog(dialogData).subscribe();
  }

  get isFromLocalTransaction() {
    return this.originalTransactionData?.transactionDetail?.id ? false : true;
  }

  /**
   * TODO: dirty solution zzz
   */
  get originTransactionTime() {
    return this.originalTransactionData?.transactionDetail?.transactionTime ?? null;
  }

  get referencedTransactionTime() {
    return this.referencedTransactionDetail?.transactionDetail?.withTransactionTime ? this.datePipe.transform(this.referencedTransactionDetail?.transactionDetail?.transactionDate as string, 'HH:mm') : null;
  }

  protected readonly TransactionConstants = TransactionConstants;
}
