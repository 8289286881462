import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/application/api-response';
import { Report } from '@app/@core/models';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  headers = {
    headers: {
      'X-SPL-CHANNEL': environment.APP_CHANNEL_NAME,
    },
  };

  constructor(private httpService: HttpClient) {}

  retrieveReportList(params?: any) {
    return this.httpService.get('/report/list', { params }).pipe(
      map((response: any) => {
        return response.data;
      })
    ) as Observable<Report[]>;
  }

  retrieveAsyncReportList(params?: any) {
    return this.httpService.get('/report/async/list', { params }).pipe(
      map((response: any) => {
        return response.data;
      })
    ) as Observable<Report[]>;
  }

  exportReport(params: any, exportReportApiPath: string) {
    return this.httpService.post(`/report/async/${exportReportApiPath}`, params).pipe(
      map((response: any) => {
        return response;
      })
    ) as Observable<any>;
  }
  downloadReport(params: any) {
    return this.httpService.get('/report/async/download', { params }).pipe(
      map((response: any) => {
        return response.data;
      })
    ) as Observable<any>;
  }
  genericCategory(params: any) {
    return this.httpService.get('/admin/genericCategory', { params }).pipe(
      map((response: any) => {
        const getData = response.data as any[]
        return getData.map(item=> {
          return {
            value: item.code,
            label: item.name,
          }
        });
      })
    ) as Observable<any>;
  }
}
