import { Directive, HostBinding, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { timer } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[requiredInput]',
})
export class RequiredInputDirective implements OnInit, OnChanges {
  @Input() isRequired = true;
  @HostBinding('class.required-input') requiredInput: boolean;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.requiredInput = this.isRequired;
  }

  ngOnChanges() {
    this.requiredInput = this.isRequired;
  }
}
