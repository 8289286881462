import { Component, SimpleChanges, OnChanges } from '@angular/core';
import { BasicList, DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'app-custom-list-box',
  templateUrl: './custom-list-box.component.html',
  styleUrls: ['./custom-list-box.component.scss'],
})
export class CustomListBoxComponent extends DualListComponent {
  public height = '260px';

  onFilter(source: BasicList) {
    super.onFilter(source);
    if (source.name === 'confirmed') {
      let sift = this.confirmed.sift.filter((item) => this.source.find((sourceItem) => sourceItem.id === item._id));
      this.confirmed.sift = sift;
    }
  }

  addAll() {
    this.selectAll(this.available);
    this.moveItem(this.available, this.confirmed);
  }

  removeAll() {
    this.selectAll(this.confirmed);
    this.moveItem(this.confirmed, this.available);
  }
}
