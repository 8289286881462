<ng-container autoFocus>
  <div class="dialog-container">
    <div>
      <!-- * Searching field -->
      <div class="main-container search-field">
        <!-- Search Section Start -->
        <c-data-table-server-search
          #parentSearch
          [searchFormGroup]="searchTransactionForm"
          [controlKeyMapping]="controlKeyMapping"
          searchCardTitle="RECEIPT_TRANSACTIONS.LABEL.LINK_DEPOSIT_TRANSACTION.CAPTION.LINK_DEPOSIT_TRANSACTION"
          (clickReset)="onSearchFormResetClick()"
          (clickSearch)="onSearchFormSearchClick($event)"
          [tableTplRef]="dataGrid"
          [formTpl]="searchFormTpl"
          [isExpandable]="true"
        >
          <ng-template #searchFormTpl>
            <form [formGroup]="searchTransactionForm">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-xs-6 col-sm-6">
                  <mat-form-field class="full-width">
                    <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_ID</mat-label>
                    <input matInput formControlName="id" placeholder="Input {{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_ID' | translate }}" />
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-6 col-sm-6">
                  <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
                    <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.LINK_DEPOSIT_TRANSACTION.DEPOSIT_TYPE</mat-label>
                    <!-- <ng-select formControlName="depositType" ngSelectMat bindLabel="label"
                                            bindValue="value" appendTo="body"  placeholder="Select {{ 'RECEIPT_TRANSACTIONS.LABEL.LINK_DEPOSIT_TRANSACTION.DEPOSIT_TYPE' | translate }}">
                                            <ng-option [value]="''">All</ng-option>
                                            <ng-option *ngFor="let item of dropDownDepositTypes" [value]="item.value">
                                                {{ item.label }}
                                            </ng-option>
                                        </ng-select> -->
                    <mat-select placeholder="Select {{ 'RECEIPT_TRANSACTIONS.LABEL.LINK_DEPOSIT_TRANSACTION.DEPOSIT_TYPE' | translate }}" formControlName="depositType">
                      <mat-option [value]="''">All</mat-option>
                      <mat-option *ngFor="let item of dropDownDepositTypes" [value]="item.value">
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-6 col-sm-6">
                  <mat-form-field class="full-width">
                    <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_DATE</mat-label>
                    <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
                      <input matStartDate formControlName="transactionDateStart" placeholder="Start date" (focus)="picker.open()" />
                      <input matEndDate formControlName="transactionDateEnd" placeholder="End date" (focus)="picker.open()" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-6 col-sm-6">
                  <mat-form-field class="full-width">
                    <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_UPLOAD_SOURCE </mat-label>
                    <mat-select placeholder="Select {{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_UPLOAD_SOURCE' | translate }}" formControlName="transactionUploadSource">
                      <mat-option [value]="''">All</mat-option>
                      <mat-option *ngFor="let item of dropdownData?.PROMOTION.CHANNEL" [value]="item.value">
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-6 col-sm-6">
                  <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
                    <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.PAYMENT_METHOD</mat-label>
                    <mat-select placeholder="Select {{ 'RECEIPT_TRANSACTIONS.LABEL.PAYMENT_METHOD' | translate }}" formControlName="paymentMethod">
                      <mat-option [value]="''">All</mat-option>
                      <mat-option *ngFor="let item of dropdownData?.RECEIPT_TRANSACTION.PAYMENT_METHOD" [value]="item.value">
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-6 col-sm-6">
                  <mat-form-field class="full-width">
                    <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.UPLOADED_DATE</mat-label>
                    <mat-date-range-input [rangePicker]="uploadDatePicker" [max]="maxDate">
                      <input matStartDate formControlName="createdDateStart" placeholder="Start date" (focus)="uploadDatePicker.open()" />
                      <input matEndDate formControlName="createdDateEnd" placeholder="End date" (focus)="uploadDatePicker.open()" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="uploadDatePicker"> </mat-datepicker-toggle>
                    <mat-date-range-picker #uploadDatePicker></mat-date-range-picker>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-6 col-sm-6">
                  <mat-form-field class="full-width">
                    <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.INVOICE_NUMBERS</mat-label>
                    <input matInput placeholder="Input invoice numbers" formControlName="invoiceNumbers" />
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-6 col-sm-6">
                  <mat-form-field class="full-width">
                    <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_AMOUNT </mat-label>
                    <transaction-amount-range-input formControlName="transactionAmountRange"></transaction-amount-range-input>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-6 col-sm-6">
                  <mat-form-field class="full-width">
                    <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.ELECTRONIC_RECEIPT_AMOUNT </mat-label>
                    <receipt-amount-range-input formControlName="receiptAmountRange"></receipt-amount-range-input>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </ng-template>
        </c-data-table-server-search>
        <!-- Search Section End -->
      </div>

      <!-- * Title: link deposit transaction Search Result -->
      <div>
        <h3 class="dialog-title">
          {{ 'RECEIPT_TRANSACTIONS.LABEL.LINK_DEPOSIT_TRANSACTION.CAPTION.RECEIPT_LIST' | translate }}
        </h3>
      </div>

      <div class="input-container">
        <div class="link-deposit-form-container">
          <div class="outer-container">
            <dx-data-grid
              #dataGrid
              appDatagridSort
              [dataSource]="dataSource"
              [remoteOperations]="{ paging: true }"
              [hoverStateEnabled]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [wordWrapEnabled]="true"
              (onRowClick)="onRowClick($event)"
              class="grid-height"
            >
              <dxo-remote-operations [filtering]="false" [sorting]="true" [grouping]="false" [paging]="true" [summary]="false"> </dxo-remote-operations>
              <dxo-paging [pageSize]="32"></dxo-paging>
              <dxo-scrolling mode="infinite"></dxo-scrolling>
              <!-- * Radio Selector -->
              <dxi-column [width]="40" dataField="" caption="" [calculateCellValue]="forSettingMemberIdAsCheckboxValue" cellTemplate="checkboxSelectorCell"></dxi-column>
              <div *dxTemplate="let cellData of 'checkboxSelectorCell'">
                <mat-checkbox class="checkbox" color="primary" [checked]="cellData.value.id | isChecked: checkboxSelectedList:checkboxSelectedList?.length"></mat-checkbox>
              </div>
              <dxi-column [width]="110" dataField="id" cellTemplate="idCell" [calculateCellValue]="forSettingMemberIdAsCheckboxValue" caption="Transaction ID"></dxi-column>
              <div *dxTemplate="let cellData of 'idCell'" class="cell-icon-row">
                <div>{{ cellData.value.id }}</div>
                <div *ngIf="cellData.value.depositType == 'FULL'"><svg-icon src="/assets/images/icons/transactions/shape-full.svg" alt=""></svg-icon></div>
                <div *ngIf="cellData.value.depositType == 'PARTIAL'"><svg-icon src="/assets/images/icons/transactions/shape-partial.svg" alt=""></svg-icon></div>
                <!-- <div> <svg-icon src="/assets/images/icons/transactions/shape-full.svg" alt=""></svg-icon></div> -->
              </div>
              <dxi-column [width]="160" dataField="lookupTenantName" dataType="string" cellTemplate="tenantCell" [caption]="'RECEIPT_TRANSACTIONS.LABEL.TENANT' | translate"></dxi-column>
              <div *dxTemplate="let cellData of 'tenantCell'">
                {{ cellData.value }}
              </div>
              <dxi-column [width]="150" dataField="transactionDate" caption="Transaction Date" cellTemplate="transactionDateCell"></dxi-column>
              <div *dxTemplate="let cellData of 'transactionDateCell'">
                {{ cellData.data.withTransactionTime ? (cellData.value | systemDateTimeFormat) : (cellData.value | systemDateFormat) }}
              </div>
              <dxi-column [width]="100" dataField="transactionUploadSource" caption="Upload Channel" cellTemplate="transactionUploadSourceCell"></dxi-column>
              <div *dxTemplate="let cellData of 'transactionUploadSourceCell'">
                <span *ngIf="cellData.value"> {{ cellData.value | genericCategory: 'PROMOTION.CHANNEL' | async }} </span>
              </div>
              <dxi-column [width]="180" dataField="paymentMethod" caption="Payment Method" cellTemplate="paymentMethodCell"> </dxi-column>
              <div *dxTemplate="let cellData of 'paymentMethodCell'">
                <span *ngIf="cellData.value"> {{ cellData.value | genericCategory: 'RECEIPT_TRANSACTION.PAYMENT_METHOD' | async }} </span>
              </div>
              <dxi-column [calculateCellValue]="calculateExpired" dataField="createdDate" sortOrder="desc" [width]="180" dataType="date" cellTemplate="dateCell" caption="Uploaded Date & Time"></dxi-column>
              <div *dxTemplate="let cellData of 'dateCell'">
                <div class="uploaded-time">
                  {{ cellData.value.createdDate | systemDateTimeFormat }}
                  <span *ngIf="cellData.value.soonToBeExpired === true" class="material-icons material-icons-bule"> timer </span>
                  <span *ngIf="cellData.value.overdueTransaction === true" class="material-icons material-icons-red"> timer </span>
                </div>
              </div>
              <dxi-column [width]="200" dataField="invoiceNumbers" caption="Invoice Number"> </dxi-column>
              <dxi-column [width]="170" dataField="transactionAmount" dataType="string" cellTemplate="transactionAmountCell" caption="Transaction Amount"></dxi-column>
              <div *dxTemplate="let cellData of 'transactionAmountCell'">
                <span *ngIf="cellData.value"> {{ cellData.value | systemCurrency }} </span>
              </div>
              <dxi-column [width]="170" dataField="receiptAmount" dataType="string" cellTemplate="receiptAmountCell" caption="Electronic Receipt Amount"></dxi-column>
              <div *dxTemplate="let cellData of 'receiptAmountCell'">
                <span *ngIf="cellData.value"> {{ cellData.value | systemCurrency }} </span>
              </div>

              <dxo-load-panel [showPane]="true" [shading]="false" indicatorSrc="/assets/images/loading-circle.gif"> </dxo-load-panel>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- * Bottom sticky btn Cancel and Submit -->
  <div class="dialog-stick-bottom-bar">
    <div class="d-flex justify-content-end button-container no-right-padding box">
      <div class="align-right">
        <div class="d-flex">
          <div class="ml-auto button-container">
            <c-form-button [label]="'Cancel'" [upperCaseLabel]="true" color="basic" class="grey" [disabled]="isSubmitting" [isLoading]="isSubmitting" (click)="onCancelClick()"> </c-form-button>
            <c-form-button
              label="RECEIPT_TRANSACTIONS.LABEL.LINK_DEPOSIT_TRANSACTION.LINK_TRANSACTION"
              [upperCaseLabel]="true"
              (click)="onDialogSubmitClick()"
              [disabled]="isSubmitting || checkboxSelectedList.length == 0"
              [isLoading]="isSubmitting"
            >
            </c-form-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
