<ng-container [formGroup]="inputsGroup">
  <input
    formControlName="calculatedResult"
    #maskInput
    (focus)="onFocusChange($event)"
    [class.hidden]="isFocus"
    matInput
    autocomplete="off"
    inputmode="numeric"
    input="number"
    [imask]="HKD_MASK"
    unmask="true"
    [placeholder]="placeholder"
    [readonly]="readOnly"
  />
  <input
    formControlName="equationInput"
    #calculationInput
    (focusout)="onFocusChange($event)"
    [class.hidden]="!isFocus"
    matInput
    autocomplete="off"
    inputmode="numeric"
    input="number"
    [imask]="CALCULATION_MASK"
    unmask="true"
    [placeholder]="placeholder"
    [readonly]="readOnly"
  />
</ng-container>
