import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
/**
 * deepTrim the body every post request
 */
@Injectable({
  providedIn: 'root',
})
export class ApiTrimInterceptor implements HttpInterceptor {
  // deepTrim function
  deepTrim(object: any) {
    if (typeof object === 'string') {
      let obj = object.trim();
      if (obj.length < 1) {
        return null;
      }
      return obj;
    } else if (typeof object === 'object') {
      for (var key in object) {
        // if(key!=='dayOfBirth'){
        object[key] = this.deepTrim(object[key]);
        // }
      }
    }

    return object;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * for post handling
     */
    if (request.body !== null && (request.method === 'POST' || request.method === 'PUT')) {
      // replace body
      request.clone({ body: this.deepTrim(request.body) });
    }
    /**
     * for get handling
     */
    if (request.method === 'GET') {
      if (request.params.keys().length > 0) {
        let params: HttpParams = new HttpParams();
        request.params.keys().forEach((key) => {
          let paramValue = request.params.get(key);
          if (typeof paramValue === 'string') {
            // try {
            // paramValue = encodeURIComponent(decodeURIComponent(paramValue).trim());
            // } catch (e: any) {
            paramValue = encodeURIComponent(paramValue.trim());
            // }
          } 
          // else {
          //   paramValue = paramValue;
          // }
          params = params.set(key, paramValue);
        });
        request = request.clone({ params });
      }
    }

    return next.handle(request);
  }
}
