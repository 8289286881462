import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableServerSearchComponent } from './data-table-server-search.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { SearchTooltipModule } from '../search-tooltip/search-tooltip.module';
import { DirectiveModule } from '@app/@shared/directives/directive.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DataTableServerSearchComponent],
  imports: [CommonModule, TranslateModule, MatIconModule, MatButtonModule, MatChipsModule, SearchTooltipModule, DirectiveModule, ReactiveFormsModule, FormsModule],
  exports: [DataTableServerSearchComponent],
})
export class DataTableServerSearchModule {}
