<ng-container *ngIf="routerLink; else noLinkTmpl">
  <a
    *ngIf="buttonType === 'flat'; else strokeButtonTmpl"
    mat-flat-button
    [ngStyle]="!isButtonDisabled ? style : null"
    [ngClass]="classList"
    [color]="buttonColor"
    [style.width]="buttonWidth + 'px'"
    [class.label-uppercase]="isUpperCase"
    [disabled]="isSubmitting || isButtonDisabled"
    [appLoadingButton]="isSubmitting"
    [routerLink]="routerLink"
  >
    <mat-icon *ngIf="buttonIcon"> {{ buttonIcon }}</mat-icon>
    <span>{{ buttonLabel | translate }}</span>
  </a>

  <ng-template #strokeButtonTmpl>
    <a
      mat-stroked-button
      [ngStyle]="!isButtonDisabled ? style : null"
      [ngClass]="classList"
      [color]="buttonColor"
      [style.width]="buttonWidth + 'px'"
      [class.label-uppercase]="isUpperCase"
      [disabled]="isSubmitting || isButtonDisabled"
      [appLoadingButton]="isSubmitting"
      [routerLink]="routerLink"
    >
      <mat-icon *ngIf="buttonIcon"> {{ buttonIcon }}</mat-icon>
      <span>{{ buttonLabel | translate }}</span>
    </a>
  </ng-template>
</ng-container>
<ng-template #noLinkTmpl>
  <button
    type="button"
    *ngIf="buttonType === 'flat'; else unlinkedStrokeButtonTmpl"
    mat-flat-button
    [ngStyle]="!isButtonDisabled ? style : null"
    [ngClass]="classList"
    [color]="buttonColor"
    [style.width]="buttonWidth + 'px'"
    [class.label-uppercase]="isUpperCase"
    [disabled]="isSubmitting || isButtonDisabled"
    [appLoadingButton]="isSubmitting"
  >
    <mat-icon *ngIf="buttonIcon"> {{ buttonIcon }}</mat-icon>
    <span>{{ buttonLabel | translate }}</span>
  </button>

  <ng-template #unlinkedStrokeButtonTmpl>
    <button
      type="button"
      mat-stroked-button
      [ngStyle]="!isButtonDisabled ? style : null"
      [ngClass]="classList"
      [color]="buttonColor"
      [style.width]="buttonWidth + 'px'"
      [class.label-uppercase]="isUpperCase"
      [disabled]="isSubmitting || isButtonDisabled"
      [appLoadingButton]="isSubmitting"
    >
      <mat-icon *ngIf="buttonIcon"> {{ buttonIcon }}</mat-icon>
      <span>{{ buttonLabel | translate }}</span>
    </button>
  </ng-template>
</ng-template>
