import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ng-select-field',
  templateUrl: './ng-select-field.component.html',
  styleUrls: ['./ng-select-field.component.scss'],
})
export class NgSelectFieldComponent extends FieldType implements OnInit {
  ngOnInit(): void {
    // if (this.to.options instanceof Observable) {
    //   this.to.options.subscribe(options => console.log(options));
    // }
  }
}
