<div class="thumbnail-card">
  <div class="fixed-image-card">
    <!-- <span *ngIf="isPendingOcrResult" class="ocr-loading-block animated-spinner">
      <i class="material-icons ocr-loading-spinner">autorenew</i>
    </span> -->
    <span *ngIf="!isPendingOcrResult" class="delete-button" (click)="onClickDeleteImage()">
      <mat-icon>delete</mat-icon>
    </span>
    <div (click)="onClickThumbnail()">
      <img [src]="thumbnail | authImage | async" alt="thumbnail image" />
    </div>
  </div>
  <div class="caption-container">
    <input class="form-control caption-input" maxlength="100" [placeholder]="'RECEIPT_TRANSACTIONS.LABEL.INPUT_CAPTION' | translate" [(ngModel)]="source.caption" />
  </div>
</div>
