import { ChangeDetectorRef, Component, Optional, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { isMoment } from 'moment';

import { setAllValueToNull } from '@app/@core/utils/object/set-all-value-to-null';

import { DataTableServerSearchComponent } from '../../data-table-server-search/data-table-server-search.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'listing-base',
  template: ``,
})
export class ListingBaseComponent {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild(DataTableServerSearchComponent) dataTableSearchPanel: DataTableServerSearchComponent;

  isSearchFormTouched = false;
  private _queryParams: Params;

  get queryParams() {
    return this._queryParams;
  }

  set queryParams(queryParams: Params) {
    // TODO: May have some massage
    this._queryParams = queryParams;
  }

  constructor(protected route: ActivatedRoute, protected router: Router, @Optional() protected cd: ChangeDetectorRef = null) {}

  /**
   * Preserve Data Table State (e.g. Sorting, Page etc.)
   */
  getTableState(): object {
    return { table: this.dataGrid?.instance?.state() };
  }

  /**
   *
   */
  initDataTableAndSearchPanelState(searchForm?: UntypedFormGroup, defaultQueryParams?: Params, isClientSearch: boolean = false, exceptionalParams: string[] = []) {
    this.isSearchFormTouched = false;
    if (this.route.snapshot.data.state) {
      this.dataGrid.instance.state(this.route.snapshot.data.state.table);
    }
    // Set form value when URL have params
    const queryParams = this.route.snapshot.queryParamMap; // TODO: To model
    // Public Query Params
    if(defaultQueryParams && ['ENDORSED','PENDING_ENDORSEMENT'].includes(defaultQueryParams?.status)){
      let queryParamsObject = Object.keys(queryParams['params']).length === 0 && defaultQueryParams ? defaultQueryParams : queryParams['params'];
      queryParamsObject = cloneDeep(queryParamsObject);
      if(queryParamsObject?.reimbursementSummaryPmsStatus){
        queryParamsObject.reimbursementSummaryPmsStatus = null;
      }
      this.queryParams = queryParamsObject;
    }else{
      this.queryParams = Object.keys(queryParams['params']).length === 0 && defaultQueryParams ? defaultQueryParams : queryParams['params'];
    }
    this.cd?.detectChanges();
    console.log('test');
    if (Object.keys(queryParams['params']).length > 0 && this.dataTableSearchPanel) {
      this.dataTableSearchPanel.keywords = queryParams.get('searchValue');
      if (isClientSearch) {
        this.dataGrid.instance.searchByText(queryParams.get('searchValue'));
      }
      if (searchForm) {
        const searchParam = JSON.parse(JSON.stringify(queryParams['params']));
        console.log('search param', searchParam);
        searchForm.patchValue(searchParam);
      }
    }
    // TODO: Temp - Handle expendable search panel
    const searchParam = queryParams['params'];
    let { searchValue, ...searchParamWithoutSearchValue } = searchParam;
    let { searchFields, ...advancedSearchParams } = searchParamWithoutSearchValue;
    if (
      Object.keys(searchParam).filter((keyName, keyValue) => keyName !== 'searchValue' && keyName !== 'searchFields' && keyName !== 'tab' && keyName !== 'sub_tab' && exceptionalParams.indexOf(keyName) === -1).length >
        0 &&
      JSON.stringify(defaultQueryParams) !== JSON.stringify(advancedSearchParams)
    ) {
      this.dataTableSearchPanel.isExpended = true;
    }
  }

  onClickSearch(searchKeyword: string, querySource?: UntypedFormGroup | string[], searchFieldExclusion: string[] = []): boolean | void {
    this.isSearchFormTouched = true;
    const queryParams: any = {};
    const searchFieldExclusionList = ['tab', 'sub_tab', 'giftUuid', ...searchFieldExclusion];
    queryParams.searchValue = searchKeyword || null;
    if (querySource && querySource instanceof UntypedFormGroup) {
      if (querySource.invalid) return false;
      queryParams.searchFields = searchKeyword
        ? Object.keys(querySource.controls)
            .filter((name) => !searchFieldExclusionList.includes(name))
            .join()
        : null;
      Object.keys(querySource.controls).forEach((key) => {
        if (querySource.get(key).value !== null && querySource.get(key).value !== '') {
          if (isMoment(querySource.get(key).value)) {
            queryParams[key] = moment(querySource.get(key).value).format('YYYY-MM-DD HH:mm:ss');
          } else if (Array.isArray(querySource.get(key).value)) {
            queryParams[key] = querySource.get(key).value.length > 0 ? querySource.get(key).value.join() : null;
          } else if (typeof querySource.get(key).value === 'object') {
            queryParams[key] = JSON.stringify(querySource.get(key).value);
          } else {
            queryParams[key] = querySource.get(key).value.toString();
          }
        } else {
          queryParams[key] = null;
        }
      });
    } else if (querySource && querySource instanceof Array) {
      queryParams.searchFields = searchKeyword ? querySource.join() : null;
    }

    this.queryParams = queryParams;

    if (this.route.snapshot.fragment) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: this.queryParams,
        queryParamsHandling: 'merge',
        fragment: this.route.snapshot.fragment,
      });
    } else {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: this.queryParams,
        queryParamsHandling: 'merge',
      });
    }

    this.dataGrid.instance.pageIndex(0);
    this.dataGrid.instance.getScrollable()?.scrollTo(0);
    return true;
  }

  onClickClientSearch(searchKeyword: string) {
    this.isSearchFormTouched = true;
    const queryParams: any = {};
    queryParams.searchValue = searchKeyword || null;
    this.queryParams = queryParams;
    this.dataGrid.instance.searchByText(searchKeyword);
    if (this.route.snapshot.fragment) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: this.queryParams,
        queryParamsHandling: 'merge',
        fragment: this.route.snapshot.fragment,
      });
    } else {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: this.queryParams,
        queryParamsHandling: 'merge',
      });
    }

    this.dataGrid.instance.pageIndex(0);
  }

  onClickReset(searchForm?: UntypedFormGroup, defaultQueryParams?: Params, isClientReset?: boolean) {
    this.isSearchFormTouched = true;
    if (searchForm) {
      searchForm.reset();
    }
    if (this.route.snapshot.fragment) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: setAllValueToNull(this.queryParams),
        queryParamsHandling: 'merge',
        fragment: this.route.snapshot.fragment,
      });
    } else {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: setAllValueToNull(this.queryParams),
        queryParamsHandling: 'merge',
      });
    }

    this.queryParams = defaultQueryParams || {};
    this.dataGrid?.instance.pageIndex(0);
    if (isClientReset) this.dataGrid?.instance.searchByText(null);
  }
}
