import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Injectable } from '@angular/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UserService } from '@core/services/auth/user.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserAccess } from '@app/@core';

/**
 * @description Auto Force Logout in 1 hr - ALL ENV
 */
let IDLE_CONFIG = 0;
const TIMEOUT_CONFIG = 600;

@Injectable({
  providedIn: 'root',
})
export class AuthIdleService {
  constructor(private idle: Idle, private keepAlive: Keepalive, private userService: UserService) {}

  setIdleWatcher() {
    this.userService.isAuthenticated.pipe(distinctUntilChanged()).subscribe((isAuth) => {
      if (isAuth) {
        // TODO: Get config from OpenId configuration
        IDLE_CONFIG = this.userService.userAccess.roles.filter((role) => role.role.includes('Jockey'))?.length ? 3000 : 600;
        this.idle.setIdle(IDLE_CONFIG);
        this.idle.setTimeout(TIMEOUT_CONFIG);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleEnd.subscribe(() => {
          this.idle.watch();
        });
        this.idle.onTimeout.subscribe(() => {
          this.userService.manualLogout(true);
        });
        this.keepAlive.interval(30);
        this.idle.watch();
      }
    });
  }
}
