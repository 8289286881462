import { Component, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@core/models/application/dialog-data';

@Component({
  selector: 'dialog-general',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.scss'],
})
export class GeneralDialogComponent {
  isShowContentTpl = false;
  constructor(public dialogRef: MatDialogRef<GeneralDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (data.content instanceof TemplateRef) this.isShowContentTpl = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
