<div class="dialog-nav">
  <button type="button" class="back-button" mat-button (click)="onClickCancelButton()">
    {{ 'COMMON.LABEL.CANCEL' | translate }}
  </button>
  <div class="nav-header">Rotate and Crop</div>
  <button type="button" class="next-button" mat-button (click)="onClickSaveImage()">Save</button>
</div>
<div class="loader-div" *ngIf="!showCropper">
  <mat-spinner mode="indeterminate"></mat-spinner>
</div>
<div class="editor-container">
  <ng-container>
    <image-cropper
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      [backgroundColor]="'F0F0F0'"
      [canvasRotation]="rotateAngle"
      [imageFile]="srcImage"
      [imageQuality]="50"
      [maintainAspectRatio]="false"
      [resizeToWidth]="1440"
      format="png"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </ng-container>

  <div class="image-editor-actions">
    <div class="rotate-action">
      <button mat-raised-button class="rotateBtn left" style="margin-right: 22px" (click)="rotate(false)"><mat-icon>rotate_90_degrees_ccw</mat-icon><span class="rotateText">Rotate Left</span></button>
      <button mat-raised-button class="rotateBtn right" (click)="rotate(true)"><mat-icon style="transform: rotate(180deg)">rotate_90_degrees_ccw</mat-icon><span class="rotateText">Rotate Right</span></button>
    </div>
  </div>
</div>
