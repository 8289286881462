import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@core/services/auth/user.service';
import { firstCharToLowerCase } from '@app/@core/utils/string/first-char-to-lower-case';

@Injectable({
  providedIn: 'root',
})
export class ReportPermissionGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userPermissions = this.userService.permissions;
    const reportCode = firstCharToLowerCase(next.params?.code);

    const permissionRequired = `ui#loyalty#report#${reportCode}#viewPage`;
    console.log(permissionRequired);
    if (!userPermissions.includes(permissionRequired)) {
      this.router.navigateByUrl('/forbidden');
      return false;
    }
    return true;
  }
}
