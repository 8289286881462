import { DropdownService } from '@core/services/application/generic-category';
import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'genericCategory',
})
export class GenericCategoryPipe implements PipeTransform {
  constructor(private dropdownService: DropdownService) {}

  transform(value: any, categoryName: string, toBeReplaces?: any): Observable<string> {
    const splitListName = categoryName?.split('.', 2);
    const splitValueList: string[] = value?.split(',') ?? [];
    if (this.dropdownService.dropdown && splitListName.length === 2) {
      return this.dropdownService.dropdown.pipe(
        map((dropdownItem) => {
          const returnArray: string[] = [];
          splitValueList.forEach((splitValue) => {
            const selectedValue = dropdownItem[splitListName[0]][splitListName[1]].find((item: any) => item.value === splitValue);
            if (toBeReplaces) {
              returnArray.push(selectedValue ? this.replaceAllLabelValue(selectedValue.label, toBeReplaces) : splitValue);
            } else {
              returnArray.push(selectedValue ? selectedValue.label : splitValue);
            }
          });
          return returnArray.join(', ');
        })
      );
    } else {
      // throw new Error('Unknown list value.');
      return value;
    }
  }

  private replaceAllLabelValue(str: string, mapObj: {}): string {
    const replacedMap = Object.keys(mapObj).map((key) => {
      const newKey = `{{${key}}}` || key;
      return {
        key: newKey,
        value: mapObj[key],
      };
    });
    const regexPattern = new RegExp(replacedMap.map((e) => e.key).join('|'), 'gi');
    return str.replace(regexPattern, (matched) => {
      return replacedMap.find((obj) => {
        return obj.key === matched;
      }).value;
    });
  }
}
