import { Directive, ElementRef, Inject, Input, OnInit, Optional, Renderer2 } from '@angular/core';
import { UserService } from '@app/@core';

@Directive({
  selector: '[appMaskField]',
})
export class MaskFieldDirective implements OnInit {
  @Input('appMaskField') permission: string;
  domElement: HTMLElement;
  parentNode: Node & ParentNode;
  constructor(protected renderer: Renderer2, protected userService: UserService, protected elementRef: ElementRef) {}

  ngOnInit(): void {
    this.maskFieldWithAccess();
  }

  maskFieldWithAccess(permission?: string) {
    this.domElement = this.elementRef.nativeElement;
    this.parentNode = this.domElement.parentNode;

    if (!this.userService.permissions.includes(this.permission || permission)) {
      if (this.domElement.parentNode?.querySelector('a')) {
        this.renderer.removeChild(this.parentNode, this.domElement);
        const span = this.renderer.createElement('span');
        this.renderer.appendChild(span, this.renderer.createText('****'));
        this.renderer.appendChild(this.parentNode, span);
      } else {
        this.renderer.removeAttribute(this.domElement, 'innerText');
        this.renderer.setProperty(this.domElement, 'innerText', '****');
      }
    }
  }

  isAccessible(permission?: string) {
    return this.userService.permissions.includes(this.permission || permission);
  }
}
