<div class="zoom-viewer" *ngIf="transformedImages.length > 0">
  <div class="row no-gutters image-action-bar">
    <div class="mr-auto">
      <button mat-raised-button class="action-button" (click)="onClickZoomIn()">
        <mat-icon>zoom_in</mat-icon>
      </button>
      <button mat-raised-button class="action-button" (click)="onClickZoomOut()">
        <mat-icon>zoom_out</mat-icon>
      </button>
    </div>
    <div class="ml-auto">
      <button mat-raised-button class="action-button" (click)="onClickRotateLeft()">
        <mat-icon>rotate_90_degrees_ccw</mat-icon>
      </button>
      <button mat-raised-button class="action-button" (click)="onClickRotateRight()">
        <mat-icon style="transform: scaleX(-1)">rotate_90_degrees_ccw</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <dx-gallery
      #imageSlider
      [dataSource]="transformedImages"
      height="100%"
      [width]="width"
      [loop]="false"
      [showNavButtons]="true"
      [swipeEnabled]="false"
      [showIndicator]="true"
      [stretchImages]="true"
      (onSelectionChanged)="onCurrentViewChanged()"
    >
      <div class="image-viewer-container">
        <div class="primary-image-viewer">
          <div *dxTemplate="let galleryItem of 'item'">
            <!-- <img [ngStyle]="imageRotateStyle" [src]="galleryItem.originalUrl | authImage | async" /> -->
            <pinch-zoom #pinchViewer [limit-zoom]="10" [limitPan]="false" [minPanScale]="1" [disableZoomControl]="'disable'" [autoHeight]="true" [overflow]="'visible'" [backgroundColor]="'#fff'">
              <img [ngStyle]="imageRotateStyle" [src]="galleryItem.originalImage" alt="receipt image" />
            </pinch-zoom>
            <div class="primary-image-caption" *ngIf="!isReimbursement">
              <input [value]="galleryItem.caption || ''" disabled />
            </div>
          </div>
        </div>
      </div>
    </dx-gallery>
  </div>
</div>
<div class="zoom-viewer is-skip-images" style="max-width: 390px;margin: 15px auto;" *ngIf="transformedImages.length === 0">{{ isRpt?'No Receipt Image for Payment Terminal': noImageText }}</div>