import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Application
import { CanAccessDirective, MainContainerClassDirective } from './application';

// Data Grid
import { DatagridLoadingPanelDirective } from './datagrid/datagrid-loading-panel.directive';
import { DatagridSortDirective } from './datagrid/datagrid-sort.directive';
import { LoadingButtonDirective } from './form/loading-button.directive';
import { RemovablePanelDirective } from './datagrid/removable-panel.directive';
import { TableNoDataButtonDirective } from './datagrid/table-no-data-button.directive';

// Badge
import { RequestStatusDirective } from './badges/request-status.directive';
import { RedemptionStatusDirective } from './badges/redemption-status.directive';
import { MemberStatusDirective } from './badges/member-status.directive';
import { InvitationLinkDirective } from './badges/invitation-link-status.directive';
import { TransactionStatusDirective } from './badges/transaction-status.directive';
import { AssociatedStatusDirective } from './badges/associated-status.directive';
import { SerialNumberHistoryStatusDirective } from './badges/serialNumber-history-status.directive';
import { AsyncReportStatusDirective } from './badges/async-report-status.directive';
import { StatusBadgeDirective } from './badges/status-badge.directive';
// Form
import { AutoHideFooterDirective } from './form/auto-hide-footer.directive';
import { InputMaxLengthDirective } from './form/input-max-length.directive';
import { NgSelectFormFieldControlDirective } from './form/ng-select.directive';

// From
import { RequiredInputDirective } from './form/required-input.directive';
import { AutoFocusDirective } from './form/auto-focus.directive';
import { RequiredFormFieldDirective } from './form/required-form-field.directive';

// Image
import { ImageDefaultDirective } from './image/image-default.directive';

// Badge
import { UserRoleStatusDirective } from './badges/user-role-status.directive';
import { DatagridAutoResizeContainerDirective } from './datagrid/datagrid-auto-resize-container.directive';
import { MaskFieldDirective } from './application/access-control/mask-field.directive';
import { ResizableElementDirective } from './application/resizable-element.directive';
import { MatSelectFixDirective } from './application/mat-select-fix.directive';

import { DatagridUseNativeDirective } from './datagrid/datagrid-use-native.directive';
import { DeleteInputFormFieldDirective } from './form/delete-input-form-field.directive';
import { DialogInfoDisplayCellDirective } from './application/dialog-info-display-cell.directive';
import { InvalidInputDirective } from './form/invalid-input.directive';
import { CarParkStatusDirective } from './badges/car-park-status.directive';
import { ReimbursementStatusDirective } from './badges/reimbursement-status.directive';
import { PmsStatusDirective } from './badges/pms-status.directive';
import { ApprovalStatusDirective } from './badges/approval-status.directive';
import { TrimWhitespaceDirective } from './form/remove-input-space.directive';

@NgModule({
  declarations: [
    CanAccessDirective,
    MainContainerClassDirective,
    TableNoDataButtonDirective,
    RequiredInputDirective,
    RemovablePanelDirective,
    NgSelectFormFieldControlDirective,
    DatagridSortDirective,
    DatagridLoadingPanelDirective,
    LoadingButtonDirective,
    AutoFocusDirective,
    RequestStatusDirective,
    StatusBadgeDirective,
    RedemptionStatusDirective,
    MemberStatusDirective,
    SerialNumberHistoryStatusDirective,
    InvitationLinkDirective,
    TransactionStatusDirective,
    AssociatedStatusDirective,
    RequiredFormFieldDirective,
    ImageDefaultDirective,
    UserRoleStatusDirective,
    AutoHideFooterDirective,
    InputMaxLengthDirective,
    DatagridAutoResizeContainerDirective,
    DatagridUseNativeDirective,
    MaskFieldDirective,
    ResizableElementDirective,
    MatSelectFixDirective,
    DeleteInputFormFieldDirective,
    DialogInfoDisplayCellDirective,
    InvalidInputDirective,
    AsyncReportStatusDirective,
    CarParkStatusDirective,
    ReimbursementStatusDirective,
    PmsStatusDirective,
    ApprovalStatusDirective,
    TrimWhitespaceDirective
  ],
  exports: [
    CanAccessDirective,
    MainContainerClassDirective,
    TableNoDataButtonDirective,
    RequiredInputDirective,
    RemovablePanelDirective,
    NgSelectFormFieldControlDirective,
    DatagridSortDirective,
    DatagridLoadingPanelDirective,
    LoadingButtonDirective,
    AutoFocusDirective,
    RequestStatusDirective,
    StatusBadgeDirective,
    RedemptionStatusDirective,
    MemberStatusDirective,
    SerialNumberHistoryStatusDirective,
    InvitationLinkDirective,
    TransactionStatusDirective,
    AssociatedStatusDirective,
    RequiredFormFieldDirective,
    ImageDefaultDirective,
    UserRoleStatusDirective,
    AutoHideFooterDirective,
    InputMaxLengthDirective,
    DatagridAutoResizeContainerDirective,
    DatagridUseNativeDirective,
    MaskFieldDirective,
    ResizableElementDirective,
    MatSelectFixDirective,
    DeleteInputFormFieldDirective,
    DialogInfoDisplayCellDirective,
    InvalidInputDirective,
    AsyncReportStatusDirective,
    CarParkStatusDirective,
    ReimbursementStatusDirective,
    PmsStatusDirective,
    ApprovalStatusDirective,
    TrimWhitespaceDirective
  ],
  imports: [CommonModule],
})
export class DirectiveModule {}
