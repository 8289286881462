<mat-form-field *ngIf="field" [requiredOverrideValue]="to.required" [floatLabel]="'always'" class="full-width">
  <mat-label
    >{{ to.label }}
    <span *ngIf="to.required" aria-hidden="true" class="mat-form-field-required-marker ng-star-inserted">*</span>
  </mat-label>
  <mat-date-range-input [rangePicker]="picker" separator="to" [max]="to.maxDate">
    <input matStartDate autocomplete="off" [formControl]="formControl.get(dateFromKey)" [formlyAttributes]="field.fieldGroup[0]" />
    <input matEndDate autocomplete="off" [formControl]="formControl.get(dateToKey)" [formlyAttributes]="field.fieldGroup[1]" />
  </mat-date-range-input>
  <mat-datepicker-toggle class="formly-datepicker-toggle" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
