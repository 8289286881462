import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestFullNamePipe } from './guest-full-name.pipe';
import { MemberStatusPipe } from './member-status.pipe';
import { CarparkHourPipe } from './carpark-hour.pipe';

@NgModule({
  declarations: [GuestFullNamePipe, MemberStatusPipe, CarparkHourPipe],
  exports: [GuestFullNamePipe, MemberStatusPipe, CarparkHourPipe],
  imports: [CommonModule],
  providers: [GuestFullNamePipe, MemberStatusPipe, CarparkHourPipe],
})
export class GuestPipeModule {}
