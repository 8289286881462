import { Pipe, PipeTransform } from '@angular/core';
import { GiftConstants } from '@app/pages/gift/gift.constants';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'serialNumberStatus',
})
export class SerialNumberStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, args?: any): any {
    let text;
    switch (value) {
      case GiftConstants.SERIAL_NUMBER_STATUS.ACTUALIZED:
        text = this.translateService.instant('SERIAL_NUMBER.STATUS.LABEL.ACTUALIZED');
        break;
      case GiftConstants.SERIAL_NUMBER_STATUS.AVAILABLE:
        text = this.translateService.instant('SERIAL_NUMBER.STATUS.LABEL.AVAILABLE');
        break;
      case GiftConstants.SERIAL_NUMBER_STATUS.REDEEMED:
        text = this.translateService.instant('SERIAL_NUMBER.STATUS.LABEL.REDEEMED');
        break;
      case GiftConstants.SERIAL_NUMBER_STATUS.VOIDED:
        text = this.translateService.instant('SERIAL_NUMBER.STATUS.LABEL.VOIDED');
        break;
      case GiftConstants.SERIAL_NUMBER_STATUS.OBSOLETE:
        text = this.translateService.instant('SERIAL_NUMBER.STATUS.LABEL.OBSOLETE');
        break;
      default:
        text = value;
    }
    return text;
  }
}
