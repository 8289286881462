import { PromotionType } from '@app/@core/models/campaign/promotion';

export abstract class PromotionConstants {
  public static STATUS_DRAFT = 'DRAFT';
  public static STATUS_ACTIVE = 'ACTIVE';
  public static STATUS_ACTIVE_PENDING = 'PENDING';
  public static STATUS_ACTIVE_IN_PROGRESS = 'IN_PROGRESS';
  public static STATUS_INACTIVE = 'INACTIVE';
  public static STATUS_ACTIVE_COMPLETED = 'COMPLETED';
  public static STATUS_EXPIRED = 'EXPIRED';
  public static ALLOWED_ELIGIBILITY_IMPORT_TYPE = [
    'text/plain',
    'application/vnd.ms-excel',
    'text/x-csv',
    'application/csv',
    'application/x-csv',
    'text/csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
    'text/tab-separated-values',
  ];
  public static DOWNLOAD_ELIGIBILITY_PERMISSION = 'ui#loyalty#campaign#downloadEligibleMemberList';

  public static TYPE_BENEFITS_AND_PRIVILEGES: PromotionType = 'BENEFITS_AND_PRIVILEGES';
  public static TYPE_BONUS_POINT_REWARD: PromotionType = 'BONUS_POINT_REWARD';
  public static TYPE_REDEMPTION_BY_LOYALTY_CURRENCY: PromotionType = 'REDEMPTION_BY_LOYALTY_CURRENCY';
  public static TYPE_REWARD_BY_SPENDING: PromotionType = 'REWARD_BY_SPENDING';
  public static TYPE_EXCEPTIONAL_MOVEMENT_EXTENSION: PromotionType = 'EXCEPTIONAL_MOVEMENT_EXTENSION';
  public static TYPE_MEMBER_GET_MEMBER: PromotionType = 'MEMBER_GET_MEMBER';
  public static TYPE_YEAR_ROUND_OFFER: PromotionType = 'YEAR_ROUND_OFFER';
  public static TYPE_REWARD_BY_ACTIVITIES: PromotionType = 'REWARD_BY_ACTIVITIES';
  public static TYPE_MEMBER_INTEREST = 'MEMBER_INTEREST';
  public static TYPE_REDEMPTION = 'REDEMPTION_BY_POINTS';
  public static TYPE_RECEIPT_UPLOAD = 'RECEIPT_UPLOAD';
  public static TYPE_ENRICH_MEMBER_PROFILE = 'ENRICH_MEMBER_PROFILE';
  public static TYPE_QR_CODE_CHECK_IN = 'QR_CODE_CHECK_IN';
  public static TYPE_MINI_GAME = 'MINI_GAME';

  public static TYPE_ENRICH_OFFICE_WORKER_PROFILE = 'ENRICH_OFFICE_WORKER_PROFILE';
}
