import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { GenericCategoryPipe } from '@app/@shared/pipes/system/generic-category.pipe';
import { Logger } from '@core/logger.service';
import { Observer, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const log = new Logger('Error Service');

const readFile = (blob: Blob): Observable<string> =>
  new Observable((obs: Observer<any>) => {
    const reader = new FileReader();

    reader.onerror = (err) => obs.error(err);
    reader.onabort = (err) => obs.error(err);
    reader.onload = () => obs.next(reader.result);
    reader.onloadend = () => obs.complete();

    return reader.readAsText(blob);
  });

@Injectable()
export class ErrorService {
  constructor() {}

  /**
   *  Massage API Error
   * @param errorResponse API Response
   */
  handleApiError(errorResponse: any, request?: HttpRequest<any>): Observable<ApiError> {
    let apiError: ApiError;
    if (errorResponse instanceof HttpErrorResponse) {
      if (errorResponse.error) {
        if (errorResponse.error instanceof Blob) {
          return readFile(errorResponse.error).pipe(
            switchMap((errorJson: string) => {
              const errorObj = JSON.parse(errorJson);
              const errorCode = errorObj.error;
              apiError = { errorCode, errorDetail: errorObj.error?.message };
              return of(apiError);
            })
          );
        } else {
          const errorCode = errorResponse.error.error;
          apiError = { errorCode, errorDetail: errorResponse.error?.message };
          return of(apiError);
        }
      } else {
        const errorCode = errorResponse.error;
        // return checkDefaultMessage(errorCode, errorResponse.error.message);
        apiError = { errorCode, errorDetail: errorResponse.error?.message };
        return of(apiError);
      }
    }
  }
}

interface ApiError {
  errorCode: string;
  errorDetail: string;
}
