import { ImageModule } from '@app/@shared/pipes/image/image.module';
import { CommonModule } from '@angular/common';
import { DxCheckBoxModule, DxFileUploaderModule, DxGalleryModule } from 'devextreme-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { ImageThumbnailComponent } from './image-thumbnail/image-thumbnail.component';
import { ImageUploaderComponent } from './image-uploader.component';
import { IvyCarouselModule } from '../common-carousel/carousel.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DirectiveModule } from '@app/@shared/directives/directive.module';
import { OcrStatusBlockComponent } from './ocr-status-block/ocr-status-block.component';
import { UploadedReceiptDialogComponent } from './uploaded-receipt-dialog/uploaded-receipt-dialog.component';
import { PinchZoomModule } from '@shared/components/pinch-zoom/pinch-zoom.module';

@NgModule({
  declarations: [ImageUploaderComponent, ImageThumbnailComponent, ImageEditorComponent, OcrStatusBlockComponent, UploadedReceiptDialogComponent],
  exports: [ImageUploaderComponent, OcrStatusBlockComponent, IvyCarouselModule, MatButtonModule, FormsModule, PinchZoomModule],
  imports: [
    CommonModule,
    DxFileUploaderModule,
    ImageCropperModule,
    LazyLoadImageModule,
    IvyCarouselModule,
    MatIconModule,
    FormsModule,
    DxGalleryModule,
    PinchZoomModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    DirectiveModule,
    DxCheckBoxModule,
    MatProgressSpinnerModule,
    ImageModule,
  ],
})
export class ImageUploaderModule {}
