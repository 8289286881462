<app-dialog-info-display [noOfColumn]="labels.length > 0 ? 2 : 3">
  <ng-container>
    <ng-template dialogInfoDisplayCell>
      <info-display-cell fieldName="MEMBER.LABEL.NAME">
        {{ memberData | guestFullName }}
      </info-display-cell>
    </ng-template>

    <ng-template dialogInfoDisplayCell *ngIf="labels.length > 0">
      <ng-container>
        <info-display-cell [fieldName]="labels[0]?.label">
          {{ memberData?.guestTierInfoVo[labels[0]?.field] | number }}
        </info-display-cell>
      </ng-container>
    </ng-template>

    <ng-template dialogInfoDisplayCell>
      <info-display-cell fieldName="MEMBER.LABEL.MEMBERSHIP_NUMBER">
        {{ memberData?.id }}
      </info-display-cell>
    </ng-template>

    <ng-template dialogInfoDisplayCell *ngIf="labels.length > 0">
      <ng-container>
        <info-display-cell [fieldName]="labels[1]?.label">
          <ng-container *ngIf="memberData?.guestTierInfoVo[labels[1]?.field]; else thenBlock">
            {{ memberData?.guestTierInfoVo[labels[1]?.field] | systemDateFormat }}
          </ng-container>
          <ng-template #thenBlock>
            <div class="col-value">-</div>
          </ng-template>
        </info-display-cell>
      </ng-container>
    </ng-template>

    <ng-template dialogInfoDisplayCell>
      <info-display-cell fieldName="MEMBER.LABEL.MEMBERSHIP_TIER">
        {{ memberData?.guestTierInfoVo.currentTierName || '-' }}
      </info-display-cell>
    </ng-template>
  </ng-container>

  <!-- <ng-container *ngIf="labels.length > 0">
    <ng-template dialogInfoDisplayCell *ngIf="labels.length > 0">
      <ng-container>
        <info-display-cell [fieldName]="labels[0]?.label">
          {{ memberData?.guestTierInfoVo[labels[0]?.field] | number }}
        </info-display-cell>
      </ng-container>
    </ng-template>

    <ng-template dialogInfoDisplayCell>
      <info-display-cell>
      </info-display-cell>
    </ng-template>
  
    <ng-template dialogInfoDisplayCell>
      <ng-container >
        <info-display-cell [fieldName]="labels[1]?.label">
          <ng-container *ngIf="memberData?.guestTierInfoVo[labels[1]?.field]; else thenBlock">
            {{ memberData?.guestTierInfoVo[labels[1]?.field] | systemDateFormat }}
          </ng-container>
          <ng-template #thenBlock>
            <div class="col-value">-</div>
          </ng-template>
        </info-display-cell>
      </ng-container>
    </ng-template>
  </ng-container> -->
</app-dialog-info-display>

<!-- <div class="display-inner">
  <div class="name-info info-col">
    <div class="col-name">
      {{ 'MEMBER.LABEL.NAME' | translate }}
    </div>
    <div class="col-value">
      {{ memberData | guestFullName }}
    </div>
  </div>

  <div class="member-number-info info-col">
    <div class="col-name">
      {{ 'MEMBER.LABEL.MEMBERSHIP_NUMBER' | translate }}
    </div>
    <div class="col-value">
      {{ memberData?.id }}
    </div>
  </div>

  <div class="member-tier-info info-col">
    <div class="col-name">
      {{ 'MEMBER.LABEL.MEMBERSHIP_TIER' | translate }}
    </div>
    <div class="col-value">
      {{ memberData?.guestTierInfoVo.currentTierName || '-' }}
    </div>
  </div>

  <div class="ava-redeem-points-info info-col">
    <ng-container *ngIf="labels.length > 0">
      <div class="col-name">
        {{ labels[0].label | translate }}
      </div>
      <div class="col-value">
        {{ memberData?.guestTierInfoVo[labels[0].field] | number }}
      </div>
    </ng-container>
  </div>

  <div class="redeem-point-exp-info info-col">
    <ng-container *ngIf="labels.length > 0">
      <div class="col-name">
        {{ labels[1].label | translate }}
      </div>
      <div *ngIf="memberData?.guestTierInfoVo[labels[1].field]; else thenBlock" class="col-value">
        {{ memberData?.guestTierInfoVo[labels[1].field] | systemDateFormat }}
      </div>
      <ng-template #thenBlock>
        <div class="col-value">-</div>
      </ng-template>
    </ng-container>
  </div>
</div> -->
