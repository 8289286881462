import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-multiple-input-field',
  templateUrl: './multiple-input-field.component.html',
  styleUrls: ['./multiple-input-field.component.scss'],
})
export class MultipleInputComponent extends FieldType implements OnInit {
  ngOnInit(): void {

  }
}
