import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaskedDatepickerComponent } from './masked-datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { IMaskModule } from 'angular-imask';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, MatDatepickerModule, MatIconModule, IMaskModule, MatInputModule, FormsModule, ReactiveFormsModule],
  exports: [MaskedDatepickerComponent],
  declarations: [MaskedDatepickerComponent],
})
export class MaskedDatepickerModule {}
