<span class="transaction-status-badge" [ngClass]="{'grey':!redColor.includes(status), 'red': redColor.includes(status)}" *ngIf="rptStatus.includes(status)">
 {{status | transactionStatusPipe}}
</span>
<div *ngIf="!rptStatus.includes(status)" [ngSwitch]="status">
  <!-- Grey -->
  <span class="transaction-status-badge grey" *ngSwitchCase="TransactionConstants.STATUS_SUBMITTED">
    {{ 'RECEIPT_TRANSACTIONS.LABEL.STATUS_SUBMITTED' | translate }}
  </span>
  <span class="transaction-status-badge grey" *ngSwitchCase="TransactionConstants.STATUS_NOT_ACTIVATED">
    {{ 'RECEIPT_TRANSACTIONS.LABEL.STATUS_NOT_ACTIVATED' | translate }}
  </span>
  <span class="transaction-status-badge grey" *ngSwitchCase="TransactionConstants.STATUS_EXPIRED">
    {{ 'RECEIPT_TRANSACTIONS.LABEL.STATUS_EXPIRED' | translate }}
  </span>
  <span class="transaction-status-badge green" *ngSwitchCase="TransactionConstants.STATUS_APPROVED">
    {{ 'RECEIPT_TRANSACTIONS.LABEL.STATUS_APPROVED' | translate }}
  </span>
  <span class="transaction-status-badge grey" *ngSwitchCase="TransactionConstants.STATUS_PENDING_FOR_APPROVAL">
    {{ 'RECEIPT_TRANSACTIONS.LABEL.PENDING_FOR_APPROVAL' | translate }}
  </span>

  <!-- Green -->
  <!-- <ng-container *ngSwitchCase="TransactionConstants.STATUS_APPROVED">
    <span class="transaction-status-badge green" *ngIf="pointEarned !== TransactionConstants.POINT_EARNED_PROCESSED">
      {{ 'RECEIPT_TRANSACTIONS.LABEL.STATUS_APPROVED' | translate }}
    </span>
    <span class="transaction-status-badge green" *ngIf="pointEarned === TransactionConstants.POINT_EARNED_PROCESSED">
      {{ 'RECEIPT_TRANSACTIONS.LABEL.STATUS_PROCESSED' | translate }}
    </span>
  </ng-container> -->
  <!-- Red -->
  <span class="transaction-status-badge red" *ngSwitchCase="TransactionConstants.STATUS_REJECTED">
    {{ 'RECEIPT_TRANSACTIONS.LABEL.STATUS_REJECTED' | translate }}
  </span>
  <span class="transaction-status-badge red" *ngSwitchCase="TransactionConstants.STATUS_VOIDED">
    {{ 'RECEIPT_TRANSACTIONS.LABEL.STATUS_VOIDED' | translate }}
  </span>
  <span class="transaction-status-badge red" *ngSwitchCase="TransactionConstants.STATUS_LOCKED">
    {{ 'RECEIPT_TRANSACTIONS.LABEL.STATUS_LOCKED' | translate }}
  </span>
  <span class="transaction-status-badge grey" *ngSwitchDefault>
    {{ status | uppercase }}
  </span>
</div>
