import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TransactionGuestInfoComponent } from './transaction-guest-info/transaction-guest-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { GuestPipeModule } from '@app/@shared/pipes/guest/guest-pipe.module';
import { ReceiptTransactionPipeModule } from '@app/@shared/pipes/receipt-transaction/receipt-transaction-pipe.module';

// Image Related Component
import { ReceiptImageListComponent } from './receipt-images/receipt-image-list/receipt-image-list.component';
import { ReceiptImageThumbnailComponent } from './receipt-images/receipt-image-thumbnail/receipt-image-thumbnail.component';
import { ReceiptImageViewerComponent } from './receipt-images/receipt-image-viewer/receipt-image-viewer.component';
import { IvyCarouselModule } from '../common-carousel';
import { DxDataGridModule, DxGalleryModule, DxPopoverModule } from 'devextreme-angular';
import { PinchZoomModule } from '@app/@shared/components/pinch-zoom/pinch-zoom.module';
import { ImageModule } from '@app/@shared/pipes/image/image.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TransactionDetailComponent } from './transaction-detail/transaction-detail.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MerchantPipeModule } from '@app/@shared/pipes/merchant/merchant-pipe.module';
import { SystemPipeModule } from '@app/@shared/pipes/system/system-pipe.module';
import { MatInputModule } from '@angular/material/input';
import { FormButtonModule } from '../form-button/form-button.module';
import { AssociatedMemberPickerComponent } from './associated-member-picker/associated-member-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { AttachmentsListModule } from '../attachments-list/attachments-list.module';
import { CompareReceiptsComponent } from './compare-receipts/compare-receipts.component';
import { DuplicatedReceiptsDialogComponent } from './dialogs/duplicated-receipts-dialog/duplicated-receipts-dialog.component';
import { DirectiveModule } from '@app/@shared/directives/directive.module';
import { ApprovalHistoryComponent } from './approval-history/approval-history.component';
import { RouterModule } from '@angular/router';
import { ChipInputModule } from '@app/@shared/components/chip-input';
import { VerifyTransactionDialogComponent } from './dialogs/verify-transaction-dialog/verify-transaction-dialog.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { IMaskModule } from 'angular-imask';
import { MaskedDatepickerModule } from '../masked-datepicker/masked-datepicker.module';
import { VerifyTransactionInfoDialogComponent } from './dialogs/verify-transaction-info-dialog/verify-transaction-info-dialog.component';
import { CalculationInputModule } from '../calculation-input/calculation-input.module';
import { OcrStatusBadgeComponent } from '@app/pages/receipt-transaction/components/ocr-status-badge/ocr-status-badge.component';
import { RelatedDepositTransactionComponent } from './related-deposit-transaction/related-deposit-transaction.component';
import { TransactionStatusComponent } from './transaction-status/transaction-status.component';
import { LinkDepositTransactionDialogComponent } from './dialogs/link-deposit-transaction-dialog/link-deposit-transaction-dialog.component';
import { DataTableServerSearchModule } from '@app/@shared/components/data-table-server-search/data-table-server-search.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TransactionAmountRangeInputComponent } from '@app/pages/receipt-transaction/components/transaction-amount-range-input/transaction-amount-range-input.component';
import { ReceiptAmountRangeInputComponent } from '@app/pages/receipt-transaction/components/receipt-amount-range-input/receipt-amount-range-input.component';
import { MatRadioModule } from '@angular/material/radio';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { isCheckedPipe } from '@app/@shared/pipes';
import { RPTFormransactionComponent } from './RPT-recepit/RPT-form/RPT-form.component';
import { RPTTransactionComponent } from './RPT-recepit/RPT-transaction/RPT-transaction.component';
import { RPTNoImgTransactionComponent } from './RPT-recepit/RPT-no-img/RPT-no-img.component';
import { RPTIconTransactionComponent } from './RPT-recepit/RPT-icon/RPT-icon.component';

import { TransactionStatusPipe } from './transaction-status/transaction-status.pipe';
import { CompareRptReceiptsDialogComponent } from './dialogs/compare-rpt-receipts-dialog/compare-rpt-receipts-dialog.component';
import { FormlyModule } from '@ngx-formly/core';

@NgModule({
  declarations: [
    TransactionGuestInfoComponent,
    ReceiptImageListComponent,
    ReceiptImageThumbnailComponent,
    ReceiptImageViewerComponent,
    TransactionDetailComponent,
    AssociatedMemberPickerComponent,
    CompareReceiptsComponent,
    DuplicatedReceiptsDialogComponent,
    ApprovalHistoryComponent,
    VerifyTransactionDialogComponent,
    VerifyTransactionInfoDialogComponent,
    OcrStatusBadgeComponent,
    RelatedDepositTransactionComponent,
    TransactionStatusComponent,
    LinkDepositTransactionDialogComponent,
    TransactionAmountRangeInputComponent,
    ReceiptAmountRangeInputComponent,
    isCheckedPipe,
    RPTFormransactionComponent,
    RPTTransactionComponent,
    RPTNoImgTransactionComponent,
    RPTIconTransactionComponent,
    TransactionStatusPipe,
    CompareRptReceiptsDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    GuestPipeModule,
    MatCheckboxModule,
    ReceiptTransactionPipeModule,
    IvyCarouselModule,
    DxGalleryModule,
    PinchZoomModule,
    ImageModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MerchantPipeModule,
    SystemPipeModule,
    MatInputModule,
    FormButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    AttachmentsListModule,
    DxDataGridModule,
    DirectiveModule,
    ChipInputModule,
    RouterModule,
    MatOptionModule,
    MatSelectModule,
    NgSelectModule,
    DragDropModule,
    MaskedDatepickerModule,
    IMaskModule,
    CalculationInputModule,
    DataTableServerSearchModule,
    MatDatepickerModule,
    MatRadioModule,
    AngularSvgIconModule,
    ImageModule,
    DxPopoverModule,
    FormlyModule
  ],
  exports: [
    TransactionGuestInfoComponent,
    ReceiptImageListComponent,
    ReceiptImageThumbnailComponent,
    ReceiptImageViewerComponent,
    TransactionDetailComponent,
    AssociatedMemberPickerComponent,
    CompareReceiptsComponent,
    ApprovalHistoryComponent,
    DragDropModule,
    MaskedDatepickerModule,
    MatInputModule,
    IMaskModule,
    SystemPipeModule,
    OcrStatusBadgeComponent,
    RelatedDepositTransactionComponent,
    TransactionStatusComponent,
    LinkDepositTransactionDialogComponent,
    TransactionAmountRangeInputComponent,
    ReceiptAmountRangeInputComponent,
    isCheckedPipe,
    RPTFormransactionComponent,
    RPTTransactionComponent,
    RPTNoImgTransactionComponent,
    RPTIconTransactionComponent,
    CompareRptReceiptsDialogComponent
  ],
  providers: [],
})
export class TransactionShareModule {}
