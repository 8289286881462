import { Injectable } from '@angular/core';

@Injectable()
export class QrCodeConstants {
  public PREFIX_ACTUALIZATION = 'ACTUALIZATION';
  public PREFIX_REDEMPTION_AND_ACTUALIZATION = 'REDEMPTIONACTUALIZATION';
  public TRIM_PREFIX_ACTUALIZATION = 'ACTUALIZATION-';
  public TRIM_PREFIX_REDEMPTION_AND_ACTUALIZATION = 'REDEMPTIONACTUALIZATION-';
  public REDEMPTION_AND_ACTUALIZATION_TYPE_BNP = 'BENEFITS_AND_PRIVILEGES';
  public REDEMPTION_AND_ACTUALIZATION_TYPE_YEAR_ROUND_OFFER = 'YEAR_ROUND_OFFER';
  public ACTION_ACTUALIZATION = 'ACTUALIZATION';
  public ACTION_REDEEM_AND_ACTUALIZATION = 'REDEMPTION_AND_ACTUALIZATION';
}
