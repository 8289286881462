import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { TransactionConstants } from '@app/@shared/components/receipt-transaction/receipt-transaction.constant';

@Component({
  selector: 'transaction-ocr-status-badge',
  templateUrl: './ocr-status-badge.component.html',
  styleUrls: ['./ocr-status-badge.component.scss'],
})
export class OcrStatusBadgeComponent implements OnInit, OnChanges {
  @Input() ocrRetCode: string;
  @Input() isCompleted: boolean;
  @Input() transactionDetail: any;
  @Input() isRpt = false;
  ocrStatus: string;
  isNoOrA = false;
  isOcrSuccessful = false;
  isOcrFailed = false;
  ocrStatusMessage: any[] = [];
  ocrMessage = '';
  errorMessage = {
    NA: {
      eligibleTransactionUploadSource: 'Not uploaded via eligible upload channel',
      receiptAmountUnderThreshold: 'Receipt Amount exceeds threshold',
      eligiblePaymentMethod: "Payment method is not eligible for instant earn",
      tenantEnableAutoApproveReceiptsForTouchpoint: '',
    },
    failed: {
      matchElectronicReceiptTransactionDate: 'Electronic Receipt Transaction Date does not match with user input',
      matchInvoiceNumbers: 'Invoice Number not match user input',
      matchReceiptAmount: 'Electronc Receipt Amount not match user input',
      matchSalesReceiptTransactionDate: 'Sales Receipt Transaction Date does not match with user input',
      matchTenant: 'Tenant not match',
      matchTransactionAmount: 'Sales Receipt Amount not match user input',
      duplicatedInvoiceNumbers: 'Duplicated invoice number(s)',
      matchPaymentMethod: 'Payment method does not match user input'

    },
  };
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
      const mainSplitpayment =  this.transactionDetail?.type == 'SPLIT_PAYMENT_MAIN_TRANSACTION';
      const nomarlTransaction = this.transactionDetail?.type == 'NORMAL_TRANSACTION';
      const consignmenChild = this.transactionDetail?.type ==  'CONSIGNMENT_CHILD_TRANSACTION';
      const mainConsignmenChild = this.transactionDetail?.type == 'CONSIGNMENT_MAIN_TRANSACTION';
      const splitPayment = this.transactionDetail?.type == 'SPLIT_PAYMENT_TRANSACTION' 
      let naKeyData:any[] = [] 
      if((nomarlTransaction || mainSplitpayment) && this.transactionDetail?.rptReceipt && this.transactionDetail?.rptReceiptInstantEarned) {
        this.ocrStatusInfo('Success');
        return
      }
      if((nomarlTransaction || mainSplitpayment) && this.transactionDetail?.rptReceipt && !this.transactionDetail?.rptReceiptInstantEarned) {
        this.ocrStatusInfo('N/A');
        naKeyData.push('Receipt amount exceed threshold')
      }
      if(splitPayment || consignmenChild) {
        this.ocrStatusInfo('N/A');
        naKeyData.push('Sub transaction / consignment not eligible to earn point')
      }
      if(mainConsignmenChild) {
        this.ocrStatusInfo('N/A');
        naKeyData.push('Consignment only earn point after day end grouping')
      }
      
    this.errorMessage.NA['tenantEnableAutoApproveReceiptsForTouchpoint'] = `Instant Earn is not enable for ${this.transactionDetail?.lookupTenantName}`;
    
    this.transactionDetail?.ocrElectronicReceiptTransactionDate && (this.errorMessage.failed['matchElectronicReceiptTransactionDate'] = `Electronic Receipt Transaction Date(${ moment(this.transactionDetail?.ocrElectronicReceiptTransactionDate).format('DD-MM-yyyy')}) does not match with user input`);
    
    if (this.transactionDetail?.approvedBy == 'OCR') {
      this.ocrStatusInfo('Success');
      return;
    }
    if (!this.transactionDetail?.hasOwnProperty('instantEarnResult') || this.transactionDetail?.instantEarnResult == null) {
      this.ocrStatusInfo('N/A');
      this.ocrStatusMessage = naKeyData;
      // this.ocrStatusMessage = []; //Object.values(this.errorMessage.NA);
      return;
    }
    if (this.transactionDetail?.hasOwnProperty('instantEarnResult')) {
      const naKey = Object.keys(this.errorMessage.NA);
      const failKey = Object.keys(this.errorMessage.failed);

      let failKeyData: any[] = [];
      for (const [key, value] of Object.entries(this.transactionDetail?.instantEarnResult)) {
        if (naKey.includes(key) && !value) {
          naKeyData.push(this.errorMessage.NA[key]);
        }
        if (key != 'duplicatedInvoiceNumbers' && failKey.includes(key) && !value) {
          failKeyData.push(this.errorMessage.failed[key]);
        }

        if(key == 'duplicatedInvoiceNumbers' && value) {
          failKeyData.push(this.errorMessage.failed[key]);
        }
      }
      if (naKeyData.length) {
        this.ocrStatusInfo('N/A');
        this.ocrStatusMessage = naKeyData;
        return;
      }

      if (failKeyData.length) {
        this.ocrStatusInfo('Failed');
        this.ocrStatusMessage = failKeyData;
      }
    }
  }
  ocrStatusInfo(value: string) {
    this.isOcrSuccessful = false;
    this.isNoOrA = false;
    this.isOcrFailed = false;
    this.ocrMessage = `Instant Earn ${value}`;
    this.ocrStatusMessage = [];
    switch (value) {
      case 'Success':
        this.isOcrSuccessful = true;
        break;
      case 'Failed':
        this.isOcrFailed = true;
        break;
      case 'N/A':
        this.isNoOrA = true;
        break;
    }
  }

  // get isShowBlock(): boolean {
  //   return this.ocrRetCode ? true : false;
  // }

  // get isOcrSuccessful(): boolean {
  //   return this.ocrRetCode === '0';
  // }

  // get isOcrFailed(): boolean {
  //   return this.ocrRetCode !== '0';
  // }

  // get ocrStatusMessage(): string {
  //   return this.isOcrSuccessful ? (this.isCompleted ? 'OCR Completed' : 'OCR Success') : 'OCR Failed';
  // }
}
