import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatDatepicker } from '@angular/material/datepicker';
import  {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'formly-year-month-picker',
  templateUrl: './year-month-picker.component.html',
  styleUrls: ['./year-month-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class YearMonthPickerComponent extends FieldType implements OnInit {
  startDateField: any = null;
  endDateField: any = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.startDateField = this.form.get(this.to.start);
    this.endDateField = this.form.get(this.to.end);
  }

  setMonthAndYear(normalizedMonthAndYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    if (this.to.startOfMonth) {
      this.formControl.setValue(ctrlValue.startOf('month'));
    } else if (this.to.endOfMonth) {
      this.formControl.setValue(ctrlValue.endOf('month'));
    } else {
      this.formControl.setValue(ctrlValue);
    }
    datepicker.close();
  }

  get maxDate() {
    const endDate = this.endDateField ? this.endDateField.value : null;
    const defaultMaxDate = this.to.maxDate ? moment(this.to.maxDate).endOf('month') : null;
    return this.to.end == this.field.key || !endDate ? defaultMaxDate : endDate;
  }

  get minDate() {
    const startDate = this.startDateField ? this.startDateField.value : null;
    const defaultMinDate = this.to.minDate ? moment(this.to.minDate).startOf('month') : null;
    return this.to.start == this.field.key || !startDate ? defaultMinDate : startDate;
  }
}
