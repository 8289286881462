import { Component, OnInit, ViewChild, AfterContentInit, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreadcrumbService, SidenavService } from '@core/services';
import { animate, style, transition, trigger, state } from '@angular/animations';
import { untilDestroyed } from '@app/@core/until-destroyed';

@Component({
  selector: 'app-config-shell',
  templateUrl: './config-shell.component.html',
  styleUrls: ['./config-shell.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          width: '66px',
        })
      ),
      state(
        'out',
        style({
          width: '260px',
        })
      ),
      transition('in => out', animate('150ms ease-in-out')),
      transition('out => in', animate('150ms ease-in-out')),
    ]),

    trigger('marginLeftInOut', [
      state(
        'in',
        style({
          marginLeft: '66px',
        })
      ),
      state(
        'out',
        style({
          marginLeft: '260px',
        })
      ),
      transition('in => out', animate('150ms ease-in-out')),
      transition('out => in', animate('150ms ease-in-out')),
    ]),
  ],
})
export class ConfigShellComponent implements AfterContentInit, OnDestroy {
  public sidenav: MatSidenav;
  isMenuOpened = false;
  menuState = 'in';
  isSetAutoOverflow = true;
  constructor(private sidenavService: SidenavService, private breadcrumbService: BreadcrumbService) {}

  ngAfterContentInit() {
    this.sidenavService.isMenuOpened$.subscribe((isOpen) => {
      this.isMenuOpened = isOpen;
      this.menuState = this.isMenuOpened ? 'out' : 'in';
    });

    this.breadcrumbService.onBreadcrumbUpdate$.pipe(untilDestroyed(this)).subscribe((breadcrumbs) => {
      if (breadcrumbs) {
        if (breadcrumbs[breadcrumbs.length - 1]?.isListingPage) {
          this.isSetAutoOverflow = false;
        } else {
          this.isSetAutoOverflow = true;
        }
      }
    });
  }

  ngOnDestroy(): void {}
}
