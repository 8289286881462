import IMask from 'imask';
import * as moment from 'moment';

export abstract class DateMaskConstants {
  public static DateMask = {
    mask: Date,
    pattern: 'd-`m-`Y', // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      Y: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
      },
    },
    // just make sure format ( parse (yourInputDateString) ) === yourInputDateString
    // define date -> str convertion
    format(date: any) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return [day, month, year].join('-');
    },
    // define str -> date convertion
    parse(str: any) {
      return moment(str, 'DD-MM-YYYY').toDate();
    },
    min: new Date(1900, 0, 1),
    max: new Date(),
    autofix: false,
    lazy: true,
    overwrite: true,
  };

  public static yearAndMonthMask = {
    mask: 'YYMM',
    blocks: {
      YY: {
        mask: IMask.MaskedRange,
        from: 1970,
        to: 9999,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
    },
    parse(str: any) {
      return moment(str, 'DD-MM-YYYY').toDate();
    },
  };

  public static ExpiryDateMask = {
    mask: Date,
    pattern: 'd-`m-`Y', // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      Y: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
      },
    },
    // just make sure format ( parse (yourInputDateString) ) === yourInputDateString
    // define date -> str conversion
    format(date: any) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return [day, month, year].join('-');
    },
    // define str -> date conversion
    parse(str: any) {
      return moment(str, 'DD-MM-YYYY').toDate();
    },
    min: new Date(1900, 0, 1),
    autofix: false,
    lazy: true,
    overwrite: true,
  };

  // Day of Birth Mask
  public static DAY_OF_BIRTH_LONG = {
    mask: /^([1-9]|[12][0-9]|3[01])$/,
    maxLength: 2,
    format(day: string) {
      const dayNum = +day;
      if (dayNum < 10) {
        day = '0' + day;
      }
      return day;
    },
  };

  // Day of Birth Mask
  public static DAY_OF_BIRTH_SHORT = {
    mask: /^([1-9]|[12][0-9]|3[0])$/,
    from: 1,
    to: 30,
    maxLength: 2,
    format(day: string) {
      const dayNum = +day;
      if (dayNum < 10) {
        day = '0' + day;
      }
      return day;
    },
  };
  // Day of Birth Mask
  public static DAY_OF_BIRTH_FEB = {
    mask: /^([1-9]|[12][0-9]|2[09])$/,
    maxLength: 2,
    format(day: string) {
      const dayNum = +day;
      if (dayNum < 10) {
        day = '0' + day;
      }
      return day;
    },
  };
}
