import { AbstractControl } from '@angular/forms';
import { environment } from '@env/environment';

export function maxAmountValidator(control: AbstractControl) {
  const MAX_AMOUNT = environment.APP_CONFIG.MAX_AMOUNT;
  const amount = control?.value;
  if (!amount) {
    return null;
  }
  return amount > MAX_AMOUNT ? { outOfMaxAmount: true } : null;
}
