import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { publishReplay, refCount, catchError, map, take } from 'rxjs/operators';
import { GenericCategory, DropdownDictionary, DropdownOption } from '@core/models/application/generic-category';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  public dropdown: Observable<DropdownDictionary> = null;

  constructor(private httpClient: HttpClient) {
    this.getDropdown().pipe(take(1));
  }

  public getDropdown(key: string = null, isShowError: boolean = false) {
    if (!this.dropdown) {
      this.dropdown = this.httpClient.get(`/admin/genericCategory`).pipe(
        map((res: any) => {
          const data = res.data as GenericCategory[];
          const dropdownDictionary: DropdownDictionary = {};
          data.forEach((option) => {
            if (!dropdownDictionary[option.type]) dropdownDictionary[option.type] = {};
            if (!dropdownDictionary[option.type][option.category]) dropdownDictionary[option.type][option.category] = [] as DropdownOption[];

            dropdownDictionary[option.type][option.category].push({
              value: option.code,
              label: option.name,
              parent: option.parent,
              displayOrder: option.displayOrder,
              filter: option.filter,
              disabled: option?.disabled
            });
          });

          Object.keys(dropdownDictionary).forEach((type) => {
            Object.keys(dropdownDictionary[type]).forEach((category) => {
              dropdownDictionary[type][category].sort((option1, option2) => {
                return option1.displayOrder - option2.displayOrder;
              });
            });
          });
          return dropdownDictionary;
        }),
        publishReplay(1),
        refCount(),
        catchError(() => (this.dropdown = null))
      ) as Observable<DropdownDictionary>;
    }
    return this.dropdown;
  }

  translateOptionCode(value: string, listName: string): Observable<string> {
    const splitListName = listName.split('.', 2);
    if (this.dropdown && splitListName.length === 2) {
      return this.dropdown.pipe(
        map((dropdownItem) => {
          const selectedValue = dropdownItem[splitListName[0]][splitListName[1]].find((item: any) => item.value === value);
          return selectedValue ? selectedValue.label : null;
        })
      );
    } else {
      return EMPTY;
    }
  }
}
