import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { LoyaltyService } from '../loyalty/loyalty.service';
import { BusinessUnitService } from './business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessUnitResolverService {
  constructor(private router: Router, private loyaltyService: LoyaltyService, private businessUnitService: BusinessUnitService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.businessUnitService.getBusinessUnitByCode(this.loyaltyService.currentProgramme.businessUnit).pipe(catchError((err) => this.router.navigateByUrl('/')));
  }
}
