import { Pipe, PipeTransform } from '@angular/core';
import { MembersConstants } from '@app/pages/members/members.constants';

@Pipe({
  name: 'memberStatusFormat',
})
export class MemberStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return MembersConstants.MEMBER_STATUS_MAPPING[value] ? MembersConstants.MEMBER_STATUS_MAPPING[value] : value;
  }
}
