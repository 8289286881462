import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatTabBody } from '@angular/material/tabs';

import {
  ApiResponse,
  AssociationHistory,
  CarParkInfo,
  CarPlate,
  CarStatusInfo,
  GuestC360Info,
  GuestProfile,
  PaginatedResponse,
  ParkingHistory,
  RealTimeParking,
  ValetParkingHistory,
  ValetRealTimeParking,
  wechatGuest,
} from '@core/models';
import { DkshStatus, MgmReferrerDetail } from '../../models/guest/member-profile';

export class Employee {
  id: number;

  firstName: string;

  lastName: string;

  gender: string;

  birthDate: Date;
}

const employees: Employee[] = [];

const surnames: string[] = ['Smith', 'Johnson', 'Brown', 'Taylor', 'Anderson', 'Harris', 'Clark', 'Allen', 'Scott', 'Carter'];

const names: string[] = ['James', 'John', 'Robert', 'Christopher', 'George', 'Mary', 'Nancy', 'Sandra', 'Michelle', 'Betty'];

const gender: string[] = ['Male', 'Female'];

let s = 123456789;
@Injectable({
  providedIn: 'root',
})
export class MemberService {
  rbsTargetSpendingDate: string = null;
  constructor(private httpClient: HttpClient) {}

  isOpenRedeemDialog: boolean = false;
  private _isFromValetParking: boolean = false;
  public get isFromValetParking(): boolean {
    return this._isFromValetParking;
  }
  public set isFromValetParking(value: boolean) {
    this._isFromValetParking = value;
  }

  random() {
    s = (1103515245 * s + 12345) % 2147483647;
    return s % (10 - 1);
  }
  isRrefreshValetParking: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  generateData(count: number) {
    let i: number;
    const startBirthDate = Date.parse('1/1/1975');
    const endBirthDate = Date.parse('1/1/1992');

    for (i = 0; i < count; i++) {
      const birthDate = new Date(startBirthDate + Math.floor((this.random() * (endBirthDate - startBirthDate)) / 10));
      birthDate.setHours(12);

      const nameIndex = this.random();
      const item = {
        id: i + 1,
        firstName: names[nameIndex],
        lastName: surnames[this.random()],
        gender: gender[Math.floor(nameIndex / 5)],
        birthDate,
      };
      employees.push(item);
    }

    return employees;
  }

  getMemberList(params?: any): Observable<any> {
    return this.httpClient.get(`/guest/search`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getInvitationLinkList(params?: any): Observable<any> {
    return this.httpClient.get(`/guest/invitation/search`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  revokeInvitationLink(params?: any): Observable<any> {
    return this.httpClient.put(`/guest/invitation/revoke/?id=${params.linkId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getMemberDetail(memberId: string): Observable<GuestProfile> {
    return this.httpClient.get(`/guest/id?id=${memberId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getIdFromQrCode(qrCodeString: string): Observable<any> {
    return this.httpClient.get(`/guest/getIdFromQrCode?qrCodeString=${qrCodeString}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  createMember(formData: any): Observable<any> {
    return this.httpClient.post(`/guest`, formData).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  updateMember(formData: any): Observable<any> {
    return this.httpClient.put(`/guest`, formData).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  searchMember(params?: any): Observable<any> {
    return this.httpClient.get(`/guest/search`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  checkDuplicatedEmail(emailAddress: string) {
    return this.httpClient.get(`/guest/validate_email?email=${emailAddress}`).pipe(
      map((response: ApiResponse) => {
        if (response.data) {
          return true;
        } else {
          throw new Error('Duplicated email');
        }
      })
    );
  }

  deleteRemarks(guestRemarksDeleteRequest: any): Observable<any> {
    return this.httpClient.delete(`/guest/remark`, { params: { ...guestRemarksDeleteRequest } }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  updateRemarks(guestRemarksUpdateRequest: any): Observable<any> {
    return this.httpClient.put(`/guest/remark`, guestRemarksUpdateRequest).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  addRemarks(guestRemarksCreateRequest: any): Observable<any> {
    return this.httpClient.post(`/guest/remark`, guestRemarksCreateRequest).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getRemarksByGuestId(params?: any): Observable<any> {
    return this.httpClient.get(`/guest/remark`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  retrieveAssociateMemberByGuestId(params?: any): Observable<any> {
    return this.httpClient.get(`/guest/associateMember`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  retrieveRemovedAssociateMemberByGuestId(params?: any): Observable<any> {
    return this.httpClient.get(`/guest/removedAssociateMember`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  searchForAssociateMember(params?: any): Observable<any> {
    return this.httpClient.get(`/guest/searchForAssociateMember`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  addAssociateMember(addAssociateMemberRequest: any, isEdit: boolean): Observable<any> {
    if (isEdit) {
      // update associated member
      return this.httpClient.put(`/guest/associateMember`, addAssociateMemberRequest).pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      );
    } else {
      // add associated member
      return this.httpClient.post(`/guest/associateMember`, addAssociateMemberRequest).pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      );
    }
  }

  // remove associated member
  removeAssociateMember(params?: any): Observable<any> {
    return this.httpClient.delete(`/guest/associateMember`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getAssociatedMemberByGuestId(memberId?: string): Observable<any> {
    return this.httpClient.get(`/guest/associateMember?guestId=${memberId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  retrieveAssociateMemberHistory(memberId?: string): Observable<AssociationHistory[]> {
    return this.httpClient.get(`/guest/associationHistory?guestId=${memberId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  resendActivationEmail(params?: any): Observable<any> {
    return this.httpClient.get(`/guest/resendActivationEmail?guestId=${params.guestId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  resendVerificationEmail(params?: any): Observable<any> {
    return this.httpClient.post(`/guest/resendEmailVerification?guestId=${params.guestId}`, {}).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  resendVerificationSMS(params?: any): Observable<any> {
    return this.httpClient.post(`/guest/resendMobileVerification?guestId=${params.guestId}`, {}).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  postInvitationLink(params?: any): Observable<any> {
    return this.httpClient.post(`/guest/invitation`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getGuestNameOnCreditCard(guestId: string) {
    return this.httpClient.get(`/guest/remark/nameOnCard?guestId=${guestId}`).pipe(
      map((response: ApiResponse) => {
        return response.data?.map((e: any) => e.remarks).join(', ');
      })
    );
  }

  getGuest360Display(guestId: string) {
    return this.httpClient.get(`/guest/get360Display?id=${guestId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<GuestC360Info>;
  }

  uploadOfficeWorkerCard(file: File, params: { imgSize: number; imgType: string }) {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpClient.post(`/guest/officeWorkerCard`, formData, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<string>;
  }

  resendOfficeWorkerVerificationEmail(guestId: string) {
    return this.httpClient.get(`/guest/officeWorker/resendEmailVerification`, { params: { guestId } }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  //Contactless Car Park
  getCarParkInfo(guestId?: string): Observable<CarParkInfo> {
    return this.httpClient.get(`/guest/contactlessCarParking/info?guestId=${guestId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getCarPlateList(guestId?: string): Observable<CarPlate[]> {
    return this.httpClient.get(`/guest/contactlessCarParking/carPlateList?guestId=${guestId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  checkValetParkingStatus(plateNumber: any): Observable<CarStatusInfo> {
    return this.httpClient.get(`/guest/contactlessCarParking/valet/status?plateNumber=${plateNumber}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getRealTimeParking(guestId?: string): Observable<RealTimeParking[]> {
    return this.httpClient.get(`/guest/contactlessCarParking/realTimeParking?guestId=${guestId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getParkingHistoryPage(params?: any): Observable<ParkingHistory[]> {
    return this.httpClient.get(`/guest/contactlessCarParking/parkingHistoryPage`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getParkingHistory(params?: any): Observable<ParkingHistory[]> {
    var url = `/guest/contactlessCarParking/parkingHistory?guestId=${params.guestId}`;
    if (params.enterStartDate) {
      url = url + '&enterStartDate=' + params.enterStartDate;
    }

    if (params.exitStartDate) {
      url = url + '&exitStartDate=' + params.exitStartDate;
    }

    if (params.carPlateNumber) {
      url = url + '&plateNumber=' + params.carPlateNumber;
    }
    return this.httpClient.get(url).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getHistoryCarPlates(guestId: string): Observable<CarPlate[]> {
    return this.httpClient.get(`/guest/contactlessCarParking/parkingHistory/carPlates?guestId=${guestId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  addCarPlate(params: any) {
    let body: HttpParams = new HttpParams();
    body = body.append('guestId', params.guestId);
    body = body.append('octopusNumber', params.octopusNumber);
    body = body.append('plateNumber', params.plateNumber);
    return this.httpClient.post(`/guest/contactlessCarParking/carplate`, body).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  deleteCarPlate(params: any): Observable<any> {
    let body: HttpParams = new HttpParams();
    body = body.append('guestId', params.guestId);
    body = body.append('octopusNumber', params.octopusNumber);
    body = body.append('plateNumber', params.plateNumber);
    return this.httpClient.post(`/guest/contactlessCarParking/carplate/delete`, body).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  submitRegisterCarParking(params: any): Observable<any> {
    return this.httpClient.post(`/guest/taikooplus/registerAndLinkTKPByLpAccount`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  saveOctopus(params: any): Observable<any> {
    return this.httpClient.post(`/guest/contactlessCarParking/octopus`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  enableContactlessCarParking(guestId: string): Observable<any> {
    let body: HttpParams = new HttpParams();
    body = body.append('guestId', guestId);
    return this.httpClient.post(`/guest/contactlessCarParking/enable`, body).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  enableValetParking(params: any): Observable<CarStatusInfo> {
    return this.httpClient.post(`/guest/contactlessCarParking/valet/enable`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  disableValetParking(params: any): Observable<CarStatusInfo> {
    return this.httpClient.post(`/guest/contactlessCarParking/valet/disable`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  searchMemberByCarplate(params: any): Observable<any> {
    return this.httpClient.get(`/guest/contactlessCarParking/searchByCarplate`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getWechatData(params: any): Observable<wechatGuest> {
    return this.httpClient.get(`/guest/wechat/getGuestWechat`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  weChatDeLink(params: any): Observable<any> {
    let body: HttpParams = new HttpParams();
    body = body.append('guestId', params.guestId);
    return this.httpClient.post(`/guest/wechat/delink`, body).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getValetCarPlateList(guestId: string, params: any): Observable<CarPlate[]> {
    return this.httpClient.get(`/valetParking/carplates?memberId=${guestId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getValetCarPlateListForSearch(guestId: string, params: any): Observable<any> {
    return this.httpClient.get(`/valetParking/carplates?memberId=${guestId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data.content;
      })
    );
  }
  getValetParkingRealTime(params: any): Observable<ValetRealTimeParking[]> {
    return this.httpClient.get(`/valetParking/realtime`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  addValetParkingCarPlate(params: any) {
    let body: HttpParams = new HttpParams();
    body = body.append('guestId', params.guestId);
    body = body.append('octopusNumber', params.octopusNumber);
    body = body.append('plateNumber', params.plateNumber);
    return this.httpClient.post(`/guest/contactlessCarParking/carplate`, body).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  valetParkingCheckIn(params: any): Observable<any> {
    return this.httpClient.post(`/valetParking/checkin`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getValetParkingHistory(guestId: string, params: any): Observable<ValetParkingHistory[]> {
    return this.httpClient.get(`/valetParking/history?memberId=${guestId}`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getMgmRefereeHistory(params: any): Observable<PaginatedResponse> {
    return this.httpClient.get(`/guest/referees`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getMgmHistoryAsReferee(refereeId: string) {
    return this.httpClient.get(`/guest/referrer?refereeId=${refereeId}`).pipe(
      map((response: ApiResponse<MgmReferrerDetail>) => {
        return response.data;
      })
    );
  }

  getDkshStatus(): Observable<DkshStatus> {
    return this.httpClient.get(`/guest/contactlessCarParking/checkDKSH`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getMemberInterestList(params: any) {
    return this.httpClient.get(`/campaign/promotion/rewardByActivities/memberInterest/list`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  submitMemberInterest(params: any) {
    return this.httpClient.post(`/campaign/promotion/rewardByActivities/memberInterest/submit`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
}
