import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SystemDatePipe } from '@app/@shared';
import { environment } from '@env/environment';
import * as moment from 'moment';

@Pipe({
  name: 'workflowSystemDateFormat',
})
export class WorkflowSystemDatePipe extends DatePipe implements PipeTransform {
  transform(value: string | number | Date, ...args: any): any {
    if (this.checkIsValidDate(value)) {
      if (Object.keys(environment.SYSTEM_DATE_FORMAT).indexOf('SYSTEM_DATE_FORMAT') >= 0) {
        return super.transform(value, environment.SYSTEM_DATE_FORMAT);
      }
      return super.transform(value, 'dd-MM-yyyy');
    } else return value;
  }

  private checkIsValidDate(value: any) {
    const regex = /[$-/:-?{-~!"^_`\[\]]/;
    if (!regex.test(value)) return false;
    if (typeof value === 'number') return false;
    else return moment(value).isValid();
  }
}
