import { Component, OnInit, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-html-tooltip',
  templateUrl: './html-tooltip.component.html',
  styleUrls: ['./html-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlTooltipComponent implements OnInit {
  @Input() icon: string;
  @Input() text: string;
  @Input() minWidth = 400;

  public isHover = false;
  constructor() {}

  ngOnInit(): void {}

  @HostListener('mouseenter', ['$event'])
  onMouseenter(event: MouseEvent) {
    this.isHover = true;
  }

  @HostListener('mouseleave', ['$event'])
  onMouseleave(event: MouseEvent) {
    this.isHover = false;
  }
}
