export abstract class GiftConstants {
  public static GIFT_FOOTER_ACTION_EDIT = 'EDIT';
  public static GIFT_FOOTER_ACTION_CRUD = 'CRUD';
  public static GIFT_TYPE_SEARCH_ARRAY = 'fullName,code,type';
  public static GIFT_TYPE_MERCHANT = 'MERCHANT'; // TODO: Change from E_VOUCHER
  public static GIFT_TYPE_LOYALTY_CURRENCY = 'LOYALTY_CURRENCY';
  public static GIFT_TYPE_EVOUCHER = 'E_VOUCHER';
  public static GIFT_TYPE_LOCKER_E_VOUCHER = 'LOCKER_E_VOUCHER';
  public static GIFT_TYPE_EV_CHARGER_VOUCHER = 'EV_CHARGER_VOUCHER';
  public static GIFT_TYPE_POINT_CONVERSION = 'POINT_CONVERSION';
  public static GIFT_TYPE_FREE_PARKING = 'FREE_PARKING';
  public static GIFT_TYPE_VALET_PARKING = 'VALET_PARKING';
  public static GIFT_TYPE_PONT_CONVERSION = 'POINT_CONVERSION';
  public static GIFT_TYPE_POINT_CONVERSION_ASIAMILES = 'POINT_CONVERSION_ASIAMILES';
  public static GIFT_TYPE_EVENT = 'EVENT';
  public static GIFT_TYPE_VENDING_MACHINE_ITEM = 'VENDING_MACHINE_ITEM';
  public static SERIAL_NUMBER_SEARCH_ARRAY = ['serialNumber', 'status', 'obsoleteReason'];
  public static SERIAL_NUMBER_STATUS_OBSOLETE = 'OBSOLETE';
  public static SERIAL_NUMBER_STATUS_ACTUALIZED = 'ACTUALIZED';
  public static COLLECTION_METHOD_COLLECT_AT_CONCIERGE = 'COLLECT_AT_CONCIERGE';
  public static COLLECTION_METHOD_COLLECT_AT_TENANT = 'COLLECT_AT_TENANT';
  public static COLLECTION_METHOD_COLLECT_AT_TENANT_APP = 'COLLECT_AT_TENANT_APP';
  public static COLLECTION_PROCESS_SCAN_QR_CODE = 'SCAN_QR_CODE';
  public static COLLECTION_METHOD_COLLECT_AT_CARPARK = 'COLLECT_AT_CARPARK';
  public static COLLECTION_PROCESS_NO_ACTION_REQUIRED = 'NO_ACTION_REQUIRED';
  public static PARKING_GIFT_TYPE_COLLECTION = [this.GIFT_TYPE_FREE_PARKING, this.GIFT_TYPE_VALET_PARKING];
  public static GIFT_UPLOAD_FILE_TYPE = [
    'text/plain',
    'application/vnd.ms-excel',
    'text/x-csv',
    'application/csv',
    'application/x-csv',
    'text/csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
    'text/tab-separated-values',
  ];
  public static GIFT_FIELD_NAME_MAPPING = {
    displayNameTc: 'Gift Full Name (TC)',
    displayNameSc: 'Gift Full Name (SC)',
    descriptionTc: 'Description (TC)',
    descriptionSc: 'Description (SC)',
    discountType: 'Discount Type',
    discountValue: 'Voucher Value',
    couponScope: 'Charger Type',
    fullName: 'Gift Full Name (EN)',
    fullNameZhHk: 'Gift Full Name (TC)',
    fullNameZhSc: 'Gift Full Name (SC)',
    shortNameZhHk: 'Gift Short Name (TC)',
    shortNameZhSc: 'Gift Short Name (SC)',
    shortName: 'Gift Short Name (EN)',
    type: 'Gift Type',
    category: 'Gift Category',
    subCategory: 'Gift Sub Category',
    giftClass: 'Gift Class',
    code: 'Gift Code',
    providerId: 'Gift Provider',
    description: 'Description (EN)',
    collectionMethod: 'Actualization Process',
    collectionProcess: 'Actualization at',
    requireSerialNumber: 'Required Serial Number',
    inventory: 'Inventory Total',
    giftAvailableActualisationMerchants: 'Available actualisation tenant',
    eVoucherValue: 'E-Voucher Value',
    pointConversionTo: 'Point Conversion To',
    barcodeFormat: 'Barcode format'
  };
  public static SERIAL_NUMBER_STATUS = {
    AVAILABLE: 'AVAILABLE',
    ACTUALIZED: 'ACTUALIZED',
    REDEEMED: 'REDEEMED',
    VOIDED: 'VOIDED',
    OBSOLETE: 'OBSOLETE',
  };
}
