import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { DialogService } from '../application/dialog/dialog.service';
import { ApiResponse, ImageListItem, NewReceiptImage } from '@core/models';
import ImageOcrStatus from '@app/@shared/components/image-uploader/model/image-ocr-status';

@Injectable({
  providedIn: 'root',
})
export class ReceiptImageService {
  private selectedImage$ = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectedImage = this.selectedImage$.asObservable().pipe(distinctUntilChanged());

  private ocrResult$ = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public ocrResult = this.ocrResult$.asObservable();

  private imageProcessingStatus$ = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public imageProcessingStatus = this.imageProcessingStatus$.asObservable();

  protected ocrUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private httpClient: HttpClient, private dialogService: DialogService) {}

  /**
   * Upload Single Receipt Image with Metadata
   * @params receipt
   */
  uploadReceiptImage(receipt: NewReceiptImage): Observable<any> {
    // Put Image Source to FromData
    const formData = new FormData();
    if (Array.isArray(receipt.sourceFile)) {
      formData.append('receipt', receipt.sourceFile[0], receipt.sourceFile[0].name);
      formData.append('originalImgPath', receipt.sourceFile[1], receipt.sourceFile[1].name);
    } else {
      formData.append('receipt', receipt.sourceFile, receipt.sourceFile.name);
    }
    return this.httpClient.post(`/transaction/receipt?receiptType=${receipt.receiptType}&primaryReceipt=${receipt.isPrimaryReceipt.toString()}`, formData).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  deleteReceiptImage(id: string): Observable<any> {
    return this.httpClient.delete(`/transaction/receipt?receiptId=${id}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  retrieveOcrResult(receiptId: string, fileCategoryName: string, transactionIndex: number, isPrimaryReceipt: boolean = false): Observable<any> {
    return this.httpClient.get(`/ocr/retrieveOcrResult`, { params: { receiptId } }).pipe(
      takeUntil(
        this.ocrUnsubscribe.pipe(
          map(() => {
            throw { ocrStatus: ImageOcrStatus.Interrupted };
          })
        )
      ),
      map((response: ApiResponse) => {
        const ocrData = response.data;
        // TODO: To ENUM
        if (ocrData.ocrRetCode === '0') {
          this.setReceiptImageOcrResult(response.data, fileCategoryName, transactionIndex, isPrimaryReceipt);
          return response.data;
        } else {
          throw { ocrStatus: ImageOcrStatus.Failed };
        }
      })
    );
  }

  setSelectedViewerImage(image: ImageListItem, imageViewerId: string): void {
    this.selectedImage$.next({
      imageSrc: image,
      imageViewerId,
    });
  }

  resetSelectedViewerImage(imageViewerId: string): void {
    this.selectedImage$.next({
      imageSrc: null,
      imageViewerId,
    });
  }

  /**
   * Open Receipt Image Dialog
   */
  showUploadedReceiptDialog(data: any, component: any): Observable<MatDialogRef<any>> {
    const dialogId = 'upload-reject-dialog';
    const dialogData = {
      id: dialogId,
      width: '580px',
      height: '70vh',
      panelClass: '',
      data,
      disableClose: true,
    };
    return this.dialogService.showDialog(dialogData, component);
  }

  setImageProcessingStatus(response: any, transactionIndex: number): void {
    this.imageProcessingStatus$.next({ ...response, transactionIndex });
  }

  interruptOcrRequest(): void {
    this.ocrUnsubscribe.next();
    // this.ocrUnsubscribe.complete();
  }

  private setReceiptImageOcrResult(response: any, fileCategoryName: string, transactionIndex: number, isPrimaryReceipt: boolean = false) {
    this.ocrResult$.next({ ...response, fileCategoryName, transactionIndex, isPrimaryReceipt });
  }
}
