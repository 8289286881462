<ng-container *ngIf="searchCardTitle && !customTitleTpl">
  <div class="d-flex">
    <div class="search-card-title">{{ searchCardTitle | translate }}</div>
    <app-search-tooltip *ngIf="freeTextSearchFields" [searchFields]="freeTextSearchFields"></app-search-tooltip>
  </div>
</ng-container>
<ng-container *ngIf="customTitleTpl" [ngTemplateOutlet]="customTitleTpl"></ng-container>

<div class="search-container">
  <form class="row basic-search-input no-gutters">
    <div class="col text-col">
      <div class="form-group">
        <div class="icon-addon addon-lg">
          <input #searchInput type="text" [placeholder]="placeholder" (keydown.enter)="onEnter($event)" [(ngModel)]="keywords" [ngModelOptions]="{ standalone: true }" class="form-control" />
          <label class="search-icon glyphicon glyphicon-searc">
            <mat-icon> search </mat-icon>
          </label>
          <label *ngIf="isExpandable" class="expand-arrow">
            <div class="expandable-icon" (click)="togglePanel()">
              <mat-icon [style.transform]="isExpended ? 'rotate(180deg)' : 'rotate(0deg)'"> keyboard_arrow_down </mat-icon>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="col button-col">
      <button mat-flat-button [appLoadingButton]="isLoading | async" type="button" class="reset-btn" (click)="onReset()">RESET</button>
      <button mat-flat-button [appLoadingButton]="isLoading | async" type="submit" class="search-btn" color="primary" (click)="onSearch()">SEARCH</button>
    </div>
  </form>
  <div class="d-flex flex-wrap pt-2" *ngIf="!isExpended">
    <mat-chip-list #chipList>
      <mat-chip class="param-chip" *ngFor="let param of advancedSearchParams" [disableRipple]="true" [selected]="false" [removable]="true" (removed)="removeParams(param)">
        <a>
          <mat-icon matChipRemove class="mr-1">close</mat-icon>
        </a>
        {{ param.controlLabel }}{{ param.controlValue !== null ? ': ' + param.controlValue : param.controlValue }}
      </mat-chip>
    </mat-chip-list>
  </div>
  <div [@inOutAnimation] class="pt-2" *ngIf="isExpended && isExpandable && !hasLightAdvancedSearch">
    <div class="row">
      <div class="col-12">
        <span class="input-desc">{{ 'COMMON.LABEL.NARROW_SEARCH_HELPER_TEXT' | translate }}</span>
      </div>
    </div>
    <div class="row pt-3 advanced-search-filter-container">
      <div class="col-12">
        <ng-container [ngTemplateOutlet]="formTpl"></ng-container>
      </div>
    </div>
  </div>
  <div class="pt-2" *ngIf="isExpandable && hasLightAdvancedSearch">
    <div class="row">
      <div class="col-12">
        <span class="input-desc">{{ 'COMMON.LABEL.NARROW_SEARCH_HELPER_TEXT' | translate }}</span>
      </div>
    </div>
    <div class="row pt-3 advanced-search-filter-container">
      <div class="col-12">
        <ng-container [ngTemplateOutlet]="formTpl"></ng-container>
      </div>
    </div>
  </div>
  <div class="pt-2" *ngIf="!isExpandable && hasLightAdvancedSearch">
    <div class="row">
      <div class="col-12">
        <span class="input-desc">{{ 'COMMON.LABEL.NARROW_SEARCH_HELPER_TEXT' | translate }}</span>
      </div>
    </div>
    <div class="row pt-3 advanced-search-filter-container">
      <div class="col-12">
        <ng-container [ngTemplateOutlet]="formTpl"></ng-container>
      </div>
    </div>
  </div>
</div>
