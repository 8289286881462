import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MembershipTierService {
  constructor(private httpClient: HttpClient) {}

  // TODO: Set model
  getTierList(params: any): Observable<any> {
    return this.httpClient.get(`/tier?size=100`, { params }).pipe(
      map((response: any) => {
        return response.data.content;
      })
    );
  }
}
