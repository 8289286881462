import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'guestFullName',
})
export class GuestFullNamePipe implements PipeTransform {
  transform(value: any, isSkipSalutation: boolean = false): string {
    if (isSkipSalutation) {
      return `${value.firstName ?? ''} ${value.lastName ?? ''}`;
    } else {
      return `${value.salutation ?? ''} ${value.firstName ?? ''} ${value.lastName ?? ''}`;
    }
  }
}
