import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { UserService } from '@core/services/auth/user.service';

@Directive({
  selector: '[appCanAccess]',
})
export class CanAccessDirective implements OnInit {
  @Input('appCanAccess') permission: any;
  @Input('isAny') isAny: boolean;

  constructor(private el: ElementRef, private userService: UserService) {}
  ngOnInit() {
    if (this.permission instanceof Array) {
      if (this.isAny) {
        if (!this.permission.some((val) => this.userService.permissions.includes(val))) {
          this.el.nativeElement.style.display = 'none';
        }
      } else {
        if (!this.permission.every((val) => this.userService.permissions.includes(val))) {
          this.el.nativeElement.style.display = 'none';
        }
      }
    } else if (!this.userService.permissions.includes(this.permission)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
