<div class="selection-info-display">
  <ng-container *ngFor="let row of transformedTmpls">
    <div class="row section-row">
      <ng-container *ngFor="let tmpl of row; let i = index">
        <div [ngClass]="'col-' + (noOfColumn !== 1 ? 12 / noOfColumn : 6)" [class.has-border]="i !== noOfColumn - 1">
          <ng-container *ngTemplateOutlet="tmpl"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
