<div class="d-flex justify-content-between align-items-center">
  <h3 class="sub-section">{{ 'RECEIPT_TRANSACTIONS.LABEL.RELATED_DEPOSIT_TRANSACTION' | translate }} {{ relatedDepositTransactionList?.length > 0 ? '(' + relatedDepositTransactionList?.length + ')' : '' }}</h3>
  <button *ngIf="!readonly" [disabled]="disabled" mat-flat-button class="link-deposit-btn label-uppercase" (click)="onOpenLinkedDepositTransactionDialog()">
    {{ 'RECEIPT_TRANSACTIONS.LABEL.LINK_DEPOSIT_TRANSACTION' | translate }}
  </button>
</div>
<div class="my-3 related-deposit-table">
  <dx-data-grid
    #dataGrid
    appDatagridSort
    [wordWrapEnabled]="true"
    [dataSource]="relatedDepositTransactionList"
    [remoteOperations]="{ paging: true }"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
    [noDataText]="'There is no deposit record yet'"
  >
    <dxo-remote-operations [filtering]="false" [sorting]="true" [grouping]="false" [paging]="true" [summary]="false"> </dxo-remote-operations>
    <!-- <dxo-scrolling [useNative]="true"></dxo-scrolling> -->
    <dxo-paging [pageSize]="20"></dxo-paging>
    <dxi-column [width]="140" dataField="status" caption="Status" cellTemplate="statusCell"></dxi-column>
    <div *dxTemplate="let cellData of 'statusCell'" class="cell-icon-row">
      <share-transaction-status [status]="cellData.value"></share-transaction-status>
      <div *ngIf="cellData.data.depositType == 'FULL'"><svg-icon src="/assets/images/icons/transactions/shape-full.svg" alt=""></svg-icon></div>
      <div *ngIf="cellData.data.depositType == 'PARTIAL'"><svg-icon src="/assets/images/icons/transactions/shape-partial.svg" alt=""></svg-icon></div>
    </div>
    <dxi-column [width]="110" dataField="id" caption="Transaction ID" cellTemplate="linkCell"></dxi-column>
    <div *dxTemplate="let data of 'linkCell'">
      <a [routerLink]="['/admin/receipt-transaction/view/', data.data.id]">{{ data.value }}</a>
    </div>
    <dxi-column [width]="160" dataField="lookupTenantName" dataType="string" cellTemplate="tenantCell" [caption]="'RECEIPT_TRANSACTIONS.LABEL.TENANT' | translate"></dxi-column>
    <div *dxTemplate="let cellData of 'tenantCell'">
      {{ cellData.value }}
    </div>
    <dxi-column [width]="150" dataField="transactionDate" caption="Transaction Date" cellTemplate="transactionDateCell"></dxi-column>
    <div *dxTemplate="let cellData of 'transactionDateCell'">
      {{ cellData.data.withTransactionTime ? (cellData.value | systemDateTimeFormat) : (cellData.value | systemDateFormat) }}
    </div>
    <dxi-column [width]="170" dataField="transactionAmount" dataType="string" cellTemplate="transactionAmountCell" caption="Transaction Amount"></dxi-column>
    <div *dxTemplate="let cellData of 'transactionAmountCell'">
      <span *ngIf="cellData.value"> {{ cellData.value | systemCurrency }} </span>
    </div>
    <dxi-column [width]="170" dataField="receiptAmount" dataType="string" cellTemplate="receiptAmountCell" caption="Electronic Receipt Amount"></dxi-column>
    <div *dxTemplate="let cellData of 'receiptAmountCell'">
      <span *ngIf="cellData.value"> {{ cellData.value | systemCurrency }} </span>
    </div>
    <dxi-column [width]="150" dataField="transactionUploadSource" caption="Upload Channel" cellTemplate="transactionUploadSourceCell"></dxi-column>
    <div *dxTemplate="let cellData of 'transactionUploadSourceCell'">
      <span *ngIf="cellData.value"> {{ cellData.value | genericCategory: 'PROMOTION.CHANNEL' | async }} </span>
    </div>
    <dxi-column [width]="180" dataField="paymentMethod" caption="Payment Method" cellTemplate="paymentMethodCell"> </dxi-column>
    <div *dxTemplate="let cellData of 'paymentMethodCell'">
      <span *ngIf="cellData.value"> {{ cellData.value | genericCategory: 'RECEIPT_TRANSACTION.PAYMENT_METHOD' | async }} </span>
    </div>
    <dxi-column [calculateCellValue]="calculateExpired" dataField="createdDate" sortOrder="desc" [width]="180" dataType="date" cellTemplate="dateCell" caption="Uploaded Date & Time"></dxi-column>
    <div *dxTemplate="let cellData of 'dateCell'">
      <div class="uploaded-time">
        {{ cellData.value.createdDate | systemDateTimeFormat }}
        <span *ngIf="cellData.value.soonToBeExpired === true" class="material-icons material-icons-blue"> timer </span>
        <span *ngIf="cellData.value.overdueTransaction === true" class="material-icons material-icons-red"> timer </span>
      </div>
    </div>
    <dxi-column [width]="200" dataField="invoiceNumbers" caption="Invoice Number"></dxi-column>
    <dxi-column [minWidth]="200" dataField="uploadedBy" dataType="string" cellTemplate="uploadedByCell" caption="Created By (Email/ Member ID)"> </dxi-column>
    <div *dxTemplate="let cellData of 'uploadedByCell'">
      <span> {{ cellData.data.createdByDisplay }} </span>
    </div>
    <dxi-column
      [cssClass]="!isAllowRemoveDeposit ? 'hide-col related-deposit' : null"
      [width]="isAllowRemoveDeposit ? 80 : 0"
      caption="Action"
      [fixed]="isAllowRemoveDeposit"
      fixedPosition="right"
      [allowSorting]="false"
      cellTemplate="actionCell"
    >
      <div *dxTemplate="let cell of 'actionCell'" class="action-col">
        <a class="remove-button-wrapper" (click)="onRemoveDepositClick(cell)">
          <mat-icon>clear</mat-icon>
        </a>
      </div>
    </dxi-column>
  </dx-data-grid>
</div>
