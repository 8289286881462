import { Directive, OnDestroy, OnInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DeviceDetectorService } from 'ngx-device-detector';

@Directive({
  selector: 'dx-data-grid',
})
export class DatagridUseNativeDirective implements OnInit, OnDestroy {
  constructor(private datagrid: DxDataGridComponent, private deviceDetectorService: DeviceDetectorService) {}

  ngOnInit() {
    this.datagrid.scrolling.useNative = this.deviceDetectorService.isDesktop();
  }

  ngOnDestroy() {}
}
