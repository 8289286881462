import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StackViewerComponent } from './stack-viewer.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SystemPipeModule } from '@app/@shared/pipes/system/system-pipe.module';
import { MerchantPipeModule } from '@app/@shared/pipes/merchant/merchant-pipe.module';
import { DirectiveModule } from '@app/@shared/directives/directive.module';

@NgModule({
  declarations: [StackViewerComponent],
  imports: [CommonModule, MatIconModule, TranslateModule, DirectiveModule, SystemPipeModule, MerchantPipeModule],
  exports: [StackViewerComponent],
})
export class StackViewerModule {}
