/**
 *
 * @param obj
 */
export function setAllValueToNull(obj: Object): Object {
  if (!obj) {
    return null;
  }
  return Object.keys(obj).reduce((reduced, key) => ({ ...reduced, [key]: null }), {});
}
