import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlTooltipModule } from '../html-tooltip/html-tooltip.module';
import { SearchTooltipComponent } from './search-tooltip.component';

@NgModule({
  declarations: [SearchTooltipComponent],
  imports: [CommonModule, HtmlTooltipModule],
  exports: [SearchTooltipComponent],
})
export class SearchTooltipModule {}
