<header>
  <nav>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <span class="flex-spacer"></span>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="user-avatar"></button>
        <mat-menu #menu="matMenu">
          <button (click)="onClickLogout()" mat-menu-item>
            <mat-icon>login</mat-icon>
            <span translate>COMMON.LABEL.LOGOUT</span>
          </button>
        </mat-menu>
        <span class="user-info">
          {{ this.username }}
        </span>
      </mat-toolbar-row>
    </mat-toolbar>
  </nav>
</header>
