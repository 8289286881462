import CustomStore from 'devextreme/data/custom_store';
import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LoadOptions } from 'devextreme/data/load_options';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DataTableService implements Resolve<any> {
  private tableState: any = { url: '', state: {} };
  private renderer: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get() {
    return this.tableState;
  }

  set(url: string, state: object) {
    this.tableState.url = url;
    this.tableState.state = { ...state };
  }

  clear() {
    this.tableState.url = '';
    this.tableState.state = {};
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (state.url === this.tableState.url) {
      const currentState = { ...this.tableState.state };
      this.clear();
      return currentState;
    }
  }

  /**
   * Return custom store object
   */
  getPaginationObject(objectKey: string, dataSource: any, params: any = {}, onLoadedFunction?: (result: any) => void): any {
    const storeObj: CustomStore = new CustomStore({
      // key: objectKey,
      load(loadOptions: any) {
        if (loadOptions.take) {
          params.size = loadOptions.take;
        }
        params.page = Math.ceil(loadOptions.skip / loadOptions.take) || 0;
        if (loadOptions.sort && loadOptions.sort.length > 0) {
          params.orderBy = loadOptions.sort[0].selector || objectKey;
          params.order = loadOptions.sort[0].desc ? 'DESC' : 'ASC';
        }
        return dataSource(params)
          .toPromise()
          .then((response: any) => {
            return {
              data: response.content,
              totalCount: response.totalElements,
            };
          })
          .catch((error: any) => {
            throw new Error('Data Loading Error');
          });
      },
      onLoaded: (value: any) => {
        if (onLoadedFunction) {
          onLoadedFunction(value);
        }
      },
    });
    return storeObj;
  }
  addLegend(htmlEL: HTMLElement, icons: Array<string> = [], textList: Array<string> = []) {
    let domhidden = htmlEL.querySelector('.dx-datagrid-pager.dx-pager.dx-hidden');
    let pager = htmlEL.querySelector('.dx-pages');

    if (domhidden) {
      this.renderer.removeClass(domhidden, 'dx-hidden');
      this.renderer.addClass(pager, 'dx-hidden');
    }
    if (!htmlEL.querySelector('.icons')) {
      let dom = htmlEL.querySelector('.dx-datagrid-pager.dx-pager');
      //hidden pagenation

      const div = this.renderer.createElement('div');
      if (!pager) {
          pager = htmlEL.querySelector('.dx-widget.dx-datagrid-pager.dx-pager');
      }
      if (!pager) {
        dom = htmlEL.querySelector('.dx-datagrid.dx-gridbase-container.dx-datagrid-borders');
        this.renderer.setStyle(div, 'margin-top', '10px');
      }
      this.renderer.addClass(div, 'icons');
      this.renderer.addClass(div, 'icons-table-bottom');
      icons.forEach((src, index) => {
        const lineDiv = this.renderer.createElement('div');
        this.renderer.addClass(lineDiv, 'line');
        if (index > 0) this.renderer.addClass(lineDiv, 'more-margin-left');
        const span01 = this.renderer.createElement('span');
        var icon = this.renderer.createElement('img');
        this.renderer.setAttribute(icon, 'src', src);
        this.renderer.appendChild(span01, icon);
        this.renderer.appendChild(lineDiv, span01);

        const span02 = this.renderer.createElement('span');
        const text = this.renderer.createText(textList[index]);
        this.renderer.addClass(span02, 'word');
        this.renderer.appendChild(span02, text);
        this.renderer.appendChild(lineDiv, span02);
        this.renderer.appendChild(div, lineDiv);
      });
      this.renderer.insertBefore(dom, div, pager, true);
    }
  }
}
