import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormButtonComponent } from './form-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DirectiveModule } from '@shared/directives/directive.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FormButtonComponent],
  imports: [CommonModule, TranslateModule, MatButtonModule, MatIconModule, DirectiveModule, RouterModule],
  exports: [FormButtonComponent],
})
export class FormButtonModule {}
