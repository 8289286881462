import { Directive, OnInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Directive({
  selector: '[appDatagridSort]',
})
export class DatagridSortDirective implements OnInit {
  private justSorted = false;

  constructor(public dataGrid: DxDataGridComponent) {
    this.setDataTableStyle();
  }

  ngOnInit() {
    this.dataGrid.instance.on('contentReady', () => this.onContentReady());
    this.dataGrid.instance.on('optionChanged', (event: any) => this.onSortingChanged(event));
  }

  OnDestroy() {
    this.dataGrid.instance.off('contentReady');
    this.dataGrid.instance.off('optionChanged');
  }

  onContentReady() {
    if (this.justSorted) {
      this.justSorted = false;
      this.dataGrid.instance.pageIndex(0).then(() => {
        this.dataGrid.instance.getScrollable()?.scrollTo(0);
      });
    }
  }

  onSortingChanged(event: any) {
    const sortOrderOptName = 'sortOrder';
    if (event.fullName.endsWith(sortOrderOptName)) {
      this.justSorted = true;
    }
  }

  private setDataTableStyle(): void {
    this.dataGrid.showBorders = true;
    this.dataGrid.showColumnLines = true;
    this.dataGrid.showRowLines = false;
  }
}
