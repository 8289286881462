import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Campaign, Promotion, PaginatedResponse, ApiResponse, QRCodeData } from '@core/models';
import { PromotionConstants } from '@app/@shared/components/promotion/promotion-constant';
import { PromotionEligibleQueryType } from '@app/pages/campaign/edit-promotion/query-builder/models/promotion-eligible-query-type';
import { setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';
import { GiftService } from './gift.service';

interface CampaignResponse extends PaginatedResponse {
  content: Campaign[];
}

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  public cloneFromPromotionId: number = null;
  public newPromotionId: number = null;
  public queryBuilderConfig$: Observable<any>;
  public addGiftAllotmentSubject: Subject<any> = new Subject<any>();
  
  constructor(private httpService: HttpClient, private giftService: GiftService) {}

  addGiftAllotmentSuccessSubject(val: string) {
    this.addGiftAllotmentSubject.next(val);
  }

  // 获取仓库gift数据
  getAllotmentData(giftId:any){
     this.giftService.retrieveGiftListWithAllotmentCount(giftId).subscribe((res)=> {
        this.addGiftAllotmentSuccessSubject(res)
     })
  }

  /**
   * Get Campaign List
   * @param params - custom params
   */
  getCampaigns(params: any) {
    return this.httpService.get(`/campaign`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    ) as Observable<CampaignResponse>;
  }

  searchCampaigns(params: any) {
    return this.httpService.get(`/campaign/search`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    ) as Observable<CampaignResponse>;
  }

  /**
   *
   * @param campaign Campaign
   */
  createCampaign(campaign: Campaign) {
    return this.httpService.post(`/campaign`, campaign);
  }

  updateCampaign(campaign: Campaign) {
    return this.httpService.put(`/campaign`, campaign);
  }

  deleteCampaign(params: any) {
    return this.httpService.delete('/campaign', { params });
  }

  getPromotions(campaignId?: any) {
    return this.httpService.get(`/campaign/promotion/list`, { params: campaignId }).pipe(
      map((res: ApiResponse) => {
        const data = res.data.map((d: Promotion) => {
          d.compositeStatus = this.getPromotionCompositeStatus(d);
          return d;
        });
        return data;
      })
    ) as Observable<Promotion[]>;
  }
  getPromotionsActive(params: any) {
    return this.httpService.get(`/campaign/promotion/list`, { params }).pipe(
      map((res: any) => {
        return res;
      })
    ) as Observable<ApiResponse<Promotion[]>>;
  }

  getSinglePromotion(promotionId: any) {
    return this.httpService.get(`/campaign/promotion`, { params: promotionId }).pipe(
      map((res: ApiResponse) => {
        const promotion = res.data;
        promotion.compositeStatus = this.getPromotionCompositeStatus(promotion);
        return promotion;
      })
    ) as Observable<Promotion>;
  }

  // 根据promotion获取alloment
   async getSinglePromotionAndAlloment (promotionId: any) {
    const promotion:Promotion = await this.getSinglePromotion(promotionId).toPromise();
    let giftIds:any[] = [];
    promotion.promotionEarningCriteria.forEach((item:any) => {
      item?.giftPackages?.forEach((gift:any) => {
        giftIds.push(gift.giftId)
      })
    });
    let giftWithAllotmentCount:any[] = []
    if(giftIds.length) {
      giftWithAllotmentCount = await this.giftService.retrieveGiftListWithAllotmentCount(giftIds).toPromise()
    }
    return (
      {
        giftCount: giftWithAllotmentCount,
        promotionData: promotion,
      }
    );
  }


  createPromotion(promotion: Promotion) {
    return this.httpService.post(`/campaign/promotion`, promotion);
  }

  updatePromotion(promotion: Promotion) {
    return this.httpService.put(`/campaign/promotion`, promotion);
  }

  deletePromotion(params: any) {
    return this.httpService.delete('/campaign/promotion', { params });
  }

  deactivatePromotion(id: number | string) {
    return this.httpService.put(`/campaign/promotion/deactivate/${id}`, {});
  }

  activatePromotion(id: number | string) {
    return this.httpService.put(`/campaign/promotion/activate/${id}`, {});
  }

  returnPromotionToDraft(id: number | string) {
    return this.httpService.put(`/campaign/promotion/draft/${id}`, {});
  }

  getPromotionCompositeStatus(promotion: Promotion) {
    const currentDate = setHours(setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0), 0);
    const startDate = new Date(promotion.startDate);
    const endDate = promotion.endDate ? new Date(promotion.endDate) : null;

    switch (promotion.status) {
      case PromotionConstants.STATUS_DRAFT:
      case PromotionConstants.STATUS_INACTIVE:
        return promotion.status;
      case PromotionConstants.STATUS_ACTIVE:
        if (startDate > currentDate) return PromotionConstants.STATUS_ACTIVE_PENDING;
        else if (startDate <= currentDate && (endDate === null || endDate >= currentDate)) return PromotionConstants.STATUS_ACTIVE_IN_PROGRESS;
        else if (endDate < currentDate) return PromotionConstants.STATUS_ACTIVE_COMPLETED;
        else return PromotionConstants.STATUS_INACTIVE;
    }
  }

  downloadMemberListTemplate() {
    return this.httpService.get(`/campaign/promotion/eligibilityMember/template`, { responseType: 'blob' as 'json' }).pipe(
      map((response: any) => {
        return response;
      })
    ) as Observable<Blob>;
  }

  getEligibleMember(params: any) {
    return this.httpService.get(`/campaign/promotion/eligibilityMember`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    ) as Observable<any[]>;
  }

  deleteEligibleMember(promotionId: string) {
    return this.httpService.delete(`/campaign/promotion/eligibilityMember`, { params: { id: promotionId } }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    ) as Observable<any[]>;
  }

  getInstantEarnPromotionList(guestId: string) {
    // manually add type: REDEMPTION, request by B/E
    return this.httpService.get(`/campaign/promotion/bonusPointReward/instantBonusPoint/promotionList`, { params: { guestId } }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    ) as Observable<any[]>;
  }

  /**
   *
   * @param csv - CSV File
   * @param params - custom params
   */
  uploadEligibleMember(csv: { data: File }, params?: any) {
    const formData = new FormData();
    formData.append('file', csv.data, csv.data.name);
    return this.httpService.post(`/campaign/promotion/eligibilityMember`, formData, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getEligibleMemberState(params: any) {
    return this.httpService.get(`/campaign/promotion/eligibilityMemberState`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }

  getQueryBuilderConfig() {
    this.queryBuilderConfig$ ??= this.httpService.get(`/admin/genericCategory/retrievePromotionEligibleQueryType`).pipe(
      map((res: ApiResponse) => {
        return <PromotionEligibleQueryType>res.data;
      }),
      publishReplay(1),
      refCount()
    );

    return this.queryBuilderConfig$;
  }

  getMatchByPromotionEligibleQuery(query: any) {
    return this.httpService.post(`/campaign/promotion/redemption/countMatchByPromotionEligibleQuery`, query).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }

  countMatchByPromotionEligibleQueryAsync(params: { promotionId: number; activate?: boolean; refresh?: boolean }) {
    return this.httpService.post(`/campaign/promotion/redemption/countMatchByPromotionEligibleQueryAsync`, null, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }

  countMatchByPromotionEligibleQueryAsyncStatus(params: any) {
    return this.httpService.get(`/campaign/promotion/redemption/countMatchByPromotionEligibleQueryAsyncStatus`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }

  getGuestsMatchByPromotionEligibleQueryCsv(params: { promotionId: number; activationList: boolean }) {
    return this.httpService.get(`/campaign/promotion/redemption/getGuestsMatchByPromotionEligibleQueryCsv`, { params, responseType: 'blob' as 'json' }).pipe(
      map((response: any) => {
        return response;
      })
    ) as Observable<Blob>;
  }

  retrieveEligibilityMemberAsyncStatus(params: any) {
    return this.httpService.get(`/campaign/promotion/retrieveEligibilityMemberAsyncStatus`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }

  uploadEligibleMemberAsync(csv: { data: File }, params?: any) {
    const formData = new FormData();
    formData.append('file', csv.data, csv.data.name);
    return this.httpService.post(`/campaign/promotion/eligibilityMemberAsync`, formData, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  deleteCheckInQrCode(params: any) {
    return this.httpService.delete('/campaign/promotion/deleteCheckInQrCode', { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  editCheckInQrCode(qrcodeData: QRCodeData) {
    return this.httpService.put(`/campaign/promotion/editCheckInQrCode`, qrcodeData).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  cloneCampaign(from: number, to: number, startDate: string, endDate: string) {
    const url = `/campaign/clone?from=${from}&to=${to}&promotionStartDate=${startDate}` + (endDate ? `&promotionEndDate=${endDate}` : '');
    return this.httpService.put(url, {}).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    );
  }
  getPICList(params: any): Observable<any> {
    return this.httpService.get(`/userAccess/users/listByRoles`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }
  getMiniGameList(): Observable<any> {
    return this.httpService.get(`/campaign/promotion/rewardByActivities/miniGameConfig`).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }

  // get EC
  getPromotionEarningCriteria(params: any) {
    return this.httpService.get(`/campaign/promotion/promotionEarningCriteria`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }

  // get promotion
  promotionByGiftId(params: any) {
    return this.httpService.get(`/campaign/promotion/promotionByGiftId`, { params }).pipe(
      map((res: ApiResponse) => {
        return res.data;
      })
    );
  }
}
