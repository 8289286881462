import { BehaviorSubject } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { cloneDeep, remove } from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';

import { MenuItem, MenuSection } from '@core/models/application/menu-item';
import { UserService } from '@core/services/auth/user.service';
import { ADMIN_MENU, CONFIG_MENU } from './menu.config';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public adminMenuSubject = new BehaviorSubject<any>({} as any);
  public adminMenu$ = this.adminMenuSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private userService: UserService) {
    this.userService.permission$.subscribe(() => {
      this.generateAdminMenuItems();
    });
  }

  generateAdminMenuItems() {
    const menu: MenuSection[] = [...cloneDeep(ADMIN_MENU)];
    menu.forEach((menuItem: MenuSection, menuItemIndex) => {
      menuItem.children = menuItem.children.filter((subMenuItem: MenuItem) => {
        return !subMenuItem.resource || this.userService.permissions.includes(subMenuItem.resource);
      });

      menuItem.children.forEach((subMenuItem: MenuItem, subMenuIndex) => {
        if (subMenuItem.children) {
          subMenuItem.children = subMenuItem.children.filter((subMenuChildItem: MenuItem) => {
            return !subMenuChildItem.resource || this.userService.permissions.includes(subMenuChildItem.resource);
          });
        }
      });

      menuItem.children = menuItem.children.filter((subMenuItem) => {
        return !subMenuItem.children || subMenuItem.children.length !== 0;
      });
    });
    // return menu;
    this.adminMenuSubject.next(menu);
  }

  getConfigMenuItems(): MenuSection[] {
    return CONFIG_MENU;
  }
}
