import { Directive, Host, Optional, Self } from '@angular/core';
import { NgxMatSelectDirective } from 'ngx-mat-select';

@Directive({
  selector: '[ngxMatSelect]',
})
export class MatSelectFixDirective {
  constructor(@Host() @Self() @Optional() public host: NgxMatSelectDirective) {
    host.deviceType = () => 'tablet'; // For some reason NgxMatSelectDirective ignores non-alphabetic search input on desktop
  }
}
