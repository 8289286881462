import { ChangeDetectionStrategy, Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { DialogData } from '@core/models/application/dialog-data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-success',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessDialogComponent implements OnInit {
  isShowContentTpl = false;
  stringContent: string;
  templateRefContent: TemplateRef<any>;
  isLoading = false;

  constructor(public dialogRef: MatDialogRef<SuccessDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    this.data.closeBtn = this.data.closeBtn ?? false;
    this.data.highlightedButtonTwoCallback = this.data.highlightedButtonTwoCallback ?? this.noop;
    this.data.buttonOneCallback = this.data.buttonOneCallback ?? this.noop;
    this.data.buttonTwoCallback = this.data.buttonTwoCallback ?? this.noop;
    this.data.strokedButtonCallback = this.data.strokedButtonCallback ?? this.noop;
    this.data.strokedButtonTwoCallback = this.data.strokedButtonTwoCallback ?? this.noop;

    if (this.data.content instanceof TemplateRef) {
      this.isShowContentTpl = true;
      this.templateRefContent = this.data.content;
    } else {
      this.stringContent = this.data.content.toString();
    }
  }

  noop = () => {};

  onCloseButtonClick() {
    this.dialogRef.close();
  }
}
