<p class="head-title">Are you sure you want to reject the transaction?</p>
<form [formGroup]="rejectFormGroup">
  <div class="row">
    <!-- * Reject reason dropdown -->
    <div class="col-12">
      <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
        <mat-label>{{ 'RECEIPT_TRANSACTIONS.REJECT.LABEL.REJECT_REASON' | translate }}</mat-label>
        <ng-select formControlName="rejectReason" appendTo="body" ngSelectMat [items]="dropdownData.RECEIPT_TRANSACTION.TRANSACTION_REJECTED_REASON" bindLabel="label" bindValue="value"> </ng-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <!-- * Remarks -->
    <div class="col-12">
      <mat-form-field class="full-width">
        <mat-label>{{ 'RECEIPT_TRANSACTIONS.REJECT.LABEL.REMARKS' | translate }}</mat-label>
        <!-- <input matInput name="rejectedRemarks" autocomplete="off" formControlName="rejectedRemarks" [placeholder]="'Input remarks'" /> -->
        <textarea rows="5" matInput name="rejectedRemarks" autocomplete="off" formControlName="rejectedRemarks" [placeholder]="'Input remarks'" maxlength="65535"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="isModified">
    <!-- * Modify Reason -->
    <div class="col-12 head-title">
      {{ 'RECEIPT_TRANSACTIONS.REJECT.LABEL.MODIFY_TITLE' | translate }}
    </div>
    <div class="col-12">
      <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
        <mat-label>{{ 'RECEIPT_TRANSACTIONS.REJECT.LABEL.MODIFY_REASON' | translate }}</mat-label>
        <ng-select formControlName="transactionModifyReason" ngSelectMat [items]="dropdownData.RECEIPT_TRANSACTION.TRANSACTION_MODIFY_REASON" appendTo="body" bindLabel="label" bindValue="value"></ng-select>
      </mat-form-field>
    </div>
  </div>
</form>

<div class="d-flex justify-content-end button-container bottom-button">
  <!-- * Cancel button -->
  <button mat-flat-button type="button" class="cancel-btn" (click)="onCancelClick()">CANCEL</button>
  <!-- * Submit button -->
  <c-form-button [label]="'Confirm'" [upperCaseLabel]="true" (click)="onSubmitClick()"></c-form-button>
</div>
