import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Dialog Components
import { GeneralDialogComponent } from './general-dialog/general-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { ChangeRequestDialogComponent } from './change-request-dialog/change-request-dialog.component';
import { RequestMemberInfoComponent } from './change-request-dialog/request-member-info/request-member-info.component';
import { FormButtonModule } from '../form-button/form-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { SystemPipeModule } from '@app/@shared/pipes/system/system-pipe.module';
import { MaskedDatepickerModule } from '../masked-datepicker/masked-datepicker.module';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IMaskModule } from 'angular-imask';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { DirectiveModule } from '@shared/directives/directive.module';
import { MatButtonModule } from '@angular/material/button';
import { AttachmentsListModule } from '../attachments-list/attachments-list.module';
import { MatDialogModule } from '@angular/material/dialog';
import { GuestPipeModule } from '@app/@shared/pipes/guest/guest-pipe.module';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogInfoDisplayModule } from '../dialog-info-display/dialog-info-display.module';
import { SyncFormUploaderModule } from '../sync-form-uploader/sync-form-uploader.module';

@NgModule({
  declarations: [GeneralDialogComponent, ErrorDialogComponent, ConfirmationDialogComponent, SuccessDialogComponent, ChangeRequestDialogComponent, RequestMemberInfoComponent],
  imports: [
    CommonModule,
    FormButtonModule,
    TranslateModule,
    SystemPipeModule,
    MaskedDatepickerModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatIconModule,
    IMaskModule,
    DirectiveModule,
    MatButtonModule,
    AttachmentsListModule,
    MatDialogModule,
    GuestPipeModule,
    RouterModule,
    NgSelectModule,
    DialogInfoDisplayModule,
    SyncFormUploaderModule,
  ],
  exports: [RequestMemberInfoComponent],
})
export class DialogModule {}
