import { Component, ElementRef, Input, OnInit, Renderer2, AfterViewInit, ViewChild } from '@angular/core';
import { DataTableService } from '@app/@core';
import { debug } from 'console';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';

@Component({
  selector: 'receipt-transaction-approval-history',
  templateUrl: './approval-history.component.html',
  styleUrls: ['./approval-history.component.scss'],
})
export class ApprovalHistoryComponent implements OnInit, AfterViewInit {
  @Input() approvalHistoryList: any;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  constructor(private renderer: Renderer2, private dataTableService: DataTableService, private el: ElementRef) {}
  ngAfterViewInit(): void {
    this.insertIcons();
  }

  ngOnInit(): void {}

  sortDateWithID(rowData: any) {
    return moment(rowData.modifiedDate).unix().toString() + rowData.id;
  }
  insertIcons() {
    this.dataGrid.onContentReady.subscribe(async (res: any) => {
      await this.checkElement();
      const icons = ['/assets/images/icons/transactions/shape-full.svg', '/assets/images/icons/transactions/shape-partial.svg'];
      const textlist = ['Full deposit', 'Partial deposit'];
      var historyGridElement = document.getElementById('depositTypeGrid');
      this.dataTableService.addLegend(historyGridElement, icons, textlist);
    });
  }
  displayPager() {
    var historyGridElement = document.getElementById('historyGrid');
    const pager = historyGridElement.querySelector('.dx-pages.dx-hidden');
    if (pager) {
      this.renderer.removeClass(pager, 'dx-hidden');
    }
  }
  async sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('');
      }, ms);
    });
  }
  async checkElement() {
    var historyGridElement = document.getElementById('depositTypeGrid');
    return new Promise(async (resolve) => {
      var time = 8;
      while (time > 0) {
        const pager = historyGridElement.querySelector('.dx-pages');
        if (pager) {
          resolve('');
          break;
        } else {
          time--;
          await this.sleep(1000);
        }
      }
      resolve('');
    });
  }
}
