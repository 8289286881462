import { Pipe, PipeTransform } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface ISelectOption {
  labelProp: string;
  valueProp: string;
}

@Pipe({
  name: 'formlySelectOptions',
})
export class FormlySelectOptionsPipe implements PipeTransform {
  transform(options: any, field?: FormlyFieldConfig): Observable<any> {
    if (!(options instanceof Observable)) {
      options = of(options);
    }
    const selectProps = {
      labelProp: field.templateOptions.labelProp,
      valueProp: field.templateOptions.valueProp,
    };
    return (options as Observable<any>).pipe(map((value) => this.transformOptions(value, selectProps)));
  }

  private transformOptions(options: any[], field?: ISelectOption) {
    return options ? options.map((option) => {
      return {
        label: option[field.labelProp],
        value: option[field.valueProp],
      };
    }) : [];
  }
}
