import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData, DialogService, ReceiptTransactionService, StackViewerService, untilDestroyed } from '@app/@core';
import { ReferenceTransaction } from '@app/@core/models/receipt-transaction/transaction';
import { AttachmentRequest } from '@core/models';
import { ChangeRequestService } from '@core/services';
import { CompareReceiptsComponent } from '../../compare-receipts/compare-receipts.component';
import { FeatureFlagService } from '@core/services/feature-flag.service';

@Component({
  selector: 'receipt-transaction-duplicated-receipts-dialog',
  templateUrl: './duplicated-receipts-dialog.component.html',
  styleUrls: ['./duplicated-receipts-dialog.component.scss'],
})
export class DuplicatedReceiptsDialogComponent implements OnInit, OnDestroy {
  customData: any;
  attachments: AttachmentRequest[] = [];
  constructor(
    public dialogRef: MatDialogRef<DuplicatedReceiptsDialogComponent>,
    private receiptTransactionService: ReceiptTransactionService,
    private stackViewerService: StackViewerService,
    private dialogService: DialogService,
    private changeRequestService: ChangeRequestService,
    public featureFlagService: FeatureFlagService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.customData = data.customData;
    data.yesCallback = data.yesCallback || this.noop;
    data.noCallback = data.noCallback || this.noop;
    data.yesLabel = data.yesLabel || 'RECEIPT_TRANSACTIONS.LABEL.CONTINUE_TO_APPROVE'; // TODO
  }

  isRpt(val: any) {
    return this.receiptTransactionService.isRptReceipt(val, this.customData.tenantList);
  }

  ngOnInit(): void {
    this.receiptTransactionService.closeCompareReceiptesSubject$.subscribe((res: any) => {
      if (res) {
        const getIndex = this.customData.duplicatedTransactionList.findIndex((item: any) => item.id == res);
        getIndex >= 0 && (this.customData.duplicatedTransactionList[getIndex].status = 'REJECTED');
      }
    });
  }

  ngOnDestroy(): void {
    this.stackViewerService.closePanel();
  }

  noop = () => {};

  openCompareView(transactionId: string) {
    this.receiptTransactionService.getTransactionDetailById(transactionId).subscribe((referencedTransactionDetail: ReferenceTransaction) => {
      // Set reference detail to the compare view
      const transactionData = referencedTransactionDetail[0].transaction;
      if (transactionData?.workflowRequestTransactions) {
        this.attachments = this.changeRequestService.convertWorkflowAttachment(transactionData.receiptTransactionAttachments);
      }
      this.openViewerCompare(referencedTransactionDetail);
    });
  }
  openViewerCompare(referencedTransactionDetail: any) {
    const stackViewerData = {
      panelTitle: 'RECEIPT_TRANSACTIONS.LABEL.COMPARE_RECEIPT',
      preOpenEvent: () => {
        this.dialogService.hideDialog('duplicated-receipts-confirmation-dialog');
        // for RPT QR Code scan feature
        this.dialogService.hideDialog('paymentQrCodeDialogComponent');
      },
      closeEvent: (data: any) => {
        this.dialogService.resumeDialog('duplicated-receipts-confirmation-dialog');
        this.dialogService.resumeDialog('paymentQrCodeDialogComponent');
      },
      customData: {
        referencedTransactionData: {
          memberProfile: referencedTransactionDetail[0].memberDetail,
          transactionDetail: referencedTransactionDetail[0].transaction,
        },
        originalTransactionData: this.customData.originalTransactionData,
        attachments: this.attachments,
        tenantList: this.customData.tenantList,
      },
    };
    this.stackViewerService.openViewer(stackViewerData, CompareReceiptsComponent);
  }
}
