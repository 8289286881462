import { CommonModule } from '@angular/common';
import { I18nModule } from '@app/i18n';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { QrCodeConstants } from '@core/services/application/barcode-scanner/qr-code-prefix.constants';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { AdminShellComponent } from './admin-shell.component';
import { SidenavComponent, FilterCurrentProgrammePipe } from './sidenav/sidenav.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [SharedModule, CommonModule, TranslateModule, I18nModule, RouterModule, NgxSpinnerModule],
  declarations: [HeaderComponent, AdminShellComponent, SidenavComponent, FooterComponent, SideMenuComponent, FilterCurrentProgrammePipe],
  providers: [QrCodeConstants],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminShellModule {}
