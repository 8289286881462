import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ReceiptTransactionService } from '../receipt-transaction/receipt-transaction.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowConfigResolver implements Resolve<any> {
  constructor(private router: Router, private receiptTransactionService: ReceiptTransactionService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.receiptTransactionService.getReceiptTransactionWorkflowConfig().pipe(catchError((err) => this.router.navigateByUrl('/')));
  }
}
