<div class="row">
  <div class="col-md-4" *ngFor="let item of shortcutItems$ | async">
    <div class="shortcut-card card" [routerLink]="item.link ? item.link : []" (click)="onCardClick(item.action)">
      <div class="card-body">
        <span class="material-icons">
          {{ item.icon }}
        </span>
        <p translate class="card-text shortcut-title">
          {{ item.title }}
        </p>
      </div>
    </div>
  </div>
</div>
