<div class="row uploader-container">
  <div class="col-auto col-uploader">
    <div class="image-uploader-wrapper" [class.is-disabled]="isOptional">
      <!-- Drag and Drop Zone-->
      <div
        #uploaderWrapper
        class="border-wrapper file-uploader-container"
        (click)="onClickUploaderWrapper($event)"
        (drop)="uploaderWrapperDrop($event, uploaderWrapper)"
        (dragover)="uploaderWrapperDragOver($event, uploaderWrapper)"
        (dragleave)="uploaderWrapperDragLeave($event, uploaderWrapper)"
        (dragend)="uploaderWrapperDragEnd($event, uploaderWrapper)"
      >
        <div class="row no-gutters">
          <span>{{ uploaderLabel }}</span>
        </div>
        <div class="row no-gutters icon-row">
          <div>
            <mat-icon class="uploader-icon">camera_alt</mat-icon>
          </div>
          <div>
            <mat-icon class="uploader-icon">collections</mat-icon>
          </div>
        </div>
        <!-- Hidden Uploader for tablet device     -->
        <input #fileInputRef style="display: none" type="file" accept="image/*" [attr.capture]="isEnableAlbumPicker ? null : 'environment'" (change)="onSelectFile($event)" />
      </div>
    </div>
    <!-- <div *ngIf="isShowCapture" class="button-bar">
      <button #captureWrapper mat-flat-button type="button" color="grey" class="border-wrapper file-uploader-container" (click)="onClickReceiptCapture($event)">Receipt capture</button>
      <input #captureFileInputRef style="display: none" type="file" accept="image/*" capture="environment" (change)="onSelectCaptureFile($event)" />
    </div> -->
    <div class="upload-type-title">
      <span requiredInput [isRequired]="isRequired && !isOptional"> {{ fileCategoryLabel | translate }} </span>
      <span>{{ uploadStatus }}</span>
      <div *ngIf="isAllowSkip">
        <dx-check-box class="skip-checkbox" [(ngModel)]="isOptional" [disabled]="isDisableOptionalCheckbox" [ngModelOptions]="{ standalone: true }" (onValueChanged)="onChangeSkipOption($event)"></dx-check-box>
        {{ 'RECEIPT_TRANSACTIONS.LABEL.SKIP_RECEIPT' | translate }}
      </div>
    </div>
  </div>
  <div class="col">
    <div class="thumbnails-section" *ngIf="receiptImages.length > 0">
      <carousel #thumbnailListRef [height]="225" [cellWidth]="170" [margin]="15" [cellCount]="receiptImages.length">
        <div class="carousel-cell thumbnail-card" *ngFor="let thumbnail of receiptImages; let i = index; trackBy: trackByFn">
          <image-thumbnail #imageThumbnail [source]="thumbnail" (clickDelete)="onClickDeleteImage(i)" (clickThumbnail)="onOpenImageViewer(i)"></image-thumbnail>
        </div>
      </carousel>
    </div>
  </div>
</div>
