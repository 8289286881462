import { Component, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@core/models/application/dialog-data';

@Component({
  selector: 'dialog-confirmation',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  isShowContentTpl = false;

  isLoading = false;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    data.yesCallback = data.yesCallback || this.noop;
    data.noCallback = data.noCallback || this.noop;
    if (data.content instanceof TemplateRef) this.isShowContentTpl = true;
  }

  noop = () => {};

  onNoClick(): void {
    this.dialogRef.close();
  }
}
