import { Directive, AfterContentChecked, OnChanges, SimpleChanges, OnInit, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

@Directive({
  selector: 'mat-form-field',
})
export class RequiredFormFieldDirective implements OnInit, OnChanges, AfterContentChecked {
  @Input() requiredOverrideValue: boolean = null;
  @HostBinding('class.required-form-field') requiredInput: boolean;

  constructor(private matFormField: MatFormField) {}

  ngOnInit(): void {}

  ngAfterContentChecked() {
    // this.applyRequiredMarker();
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.applyRequiredMarker();
  }

  private applyRequiredMarker() {
    const ctrl = this.matFormField._control;
    const required = ctrl.ngControl?.control?.validator?.({} as AbstractControl)?.required && !ctrl.disabled;

    this.requiredInput = this.requiredOverrideValue === null ? required : this.requiredOverrideValue;
  }
}
