export abstract class PhoneMask {
  public static DIGITS_8 = {
    mask: '0000 0000',
  };

  public static DIGITS_9 = {
    mask: '00 000 0000',
  };

  public static DIGITS_11 = {
    mask: '0000 0000 000',
  };
}
