import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, ElementRef, HostBinding, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Self, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { CurrencyMaskConstants, GeneralConstants } from '@app/@shared/masks';
import { Subject } from 'rxjs';

@Component({
  selector: 'calculation-input',
  templateUrl: './calculation-input.component.html',
  styleUrls: ['./calculation-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CalculationInputComponent }],
})
export class CalculationInputComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy, AfterViewInit {
  stateChanges = new Subject<void>();
  inputsGroup: UntypedFormGroup;
  focused = false;
  hasError = false;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input() allowNegativeAmount = false;
  @Input() readOnly = false;
  @Input() placeholder =''
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    return this.calculatedResult.value;
  }
  set value(value: string | null) {
    if (value === null) {
      this.calculatedResult.setValue(value, { emitEvent: false });
      this.equation.setValue(null, { emitEvent: false });
      this.hasError = false;
    } else {
      this.calculatedResult.setValue(String(value), { emitEvent: false });
      if (!this.equation.value) this.equation.setValue(this.calculatedResult.value, { emitEvent: false });
    }

    this.stateChanges.next();
  }

  get empty() {
    return !this.calculatedResult.value;
  }

  get errorState(): boolean {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  get calculatedResult() {
    return this.inputsGroup.get('calculatedResult');
  }

  get equation() {
    return this.inputsGroup.get('equationInput');
  }

  HKD_MASK = CurrencyMaskConstants.HKD;
  CALCULATION_MASK = GeneralConstants.POSITIVE_CALCULATION_MASK;
  isFocus = false;
  private _required = false;
  // calculatedResult: string;
  // equation: string;

  @ViewChild('maskInput') maskInput: ElementRef;
  @ViewChild('calculationInput') calculationInputElementRef: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef.nativeElement, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }

      this.focused = !!origin && !this.disabled;
      this.stateChanges.next();
    });

    this.inputsGroup = this.fb.group({
      calculatedResult: [null],
      equationInput: [null],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange = (_: any) => {};
  onTouched = () => {};
  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.stateChanges.next();
    this.onChange(this.value);
  }

  onContainerClick() {}

  setDescribedByIds(ids: string[]) {}

  validate({ value }: UntypedFormControl): ValidationErrors | null {
    return (
      this.hasError && {
        invalidInput: true,
      }
    );
  }

  checkCalculationInput: ValidatorFn = (fg: UntypedFormControl) => {
    return (
      this.hasError && {
        invalidInput: true,
      }
    );
  };

  ngOnInit(): void {
    this.ngControl.control.addValidators([this.validate.bind(this)]);
    this.ngControl.control.updateValueAndValidity();
    this.equation.valueChanges.subscribe((value) => {
      const calculatedResult = this.calculate(value);
      this.value = calculatedResult ? calculatedResult.match(/^-?\d+(?:\.\d{0,2})?/)[0] : '';
      this.stateChanges.next();
      this.onChange(this.value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allowNegativeAmount) {
      if (changes.allowNegativeAmount.currentValue) {
        this.CALCULATION_MASK = GeneralConstants.CALCULATION_MASK;
        this.HKD_MASK = CurrencyMaskConstants.HKD_WITH_NEGATIVE;
      } else {
        if (typeof this.equation.value === 'string') {
          if (this.equation.value?.includes('-') && Number(this.calculatedResult.value) >= 0) {
            this.equation.setValue(null, { emitEvent: false });
            this.value = this.calculatedResult.value;
            this.onChange(this.value);
          }
          if (this.equation.value?.includes('-') && Number(this.calculatedResult.value) < 0) {
            this.value = null;
            this.onChange(this.value);
          }
        }
        this.CALCULATION_MASK = GeneralConstants.POSITIVE_CALCULATION_MASK;
        this.HKD_MASK = CurrencyMaskConstants.HKD;
      }
    }
  }

  ngAfterViewInit() {
    if (this.disabled) {
      this.calculatedResult.disable();
      this.equation.disable();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusChange(e: any) {
    if (!this.disabled) {
      this.isFocus = !this.isFocus;
      if (this.isFocus) {
        setTimeout((_: any) => {
          this.calculationInputElementRef.nativeElement.focus();
        });
        this.maskInput.nativeElement.blur();
      } else {
        this.maskInput.nativeElement.blur();
        this.calculationInputElementRef.nativeElement.blur();
        const calculatedResult = this.calculate(this.equation.value);
        // this.value = this.hasError ? calculatedResult : calculatedResult ? calculatedResult.match(/^-?\d+(?:\.\d{0,2})?/)[0] : '';
        this.value = calculatedResult ? calculatedResult.match(/^-?\d+(?:\.\d{0,2})?/)[0] : '';
        this.stateChanges.next();
        this.onChange(this.value);
      }
    }
  }

  inputEquationKeyPress(e: any) {}

  calculate(equation: string | undefined | null) {
    if (equation) {
      try {
        const result = Number(eval(equation)).toFixed(2).toString();
        this.hasError = false;
        const isResultValid = this.allowNegativeAmount ? typeof Number(result) === 'number' : typeof Number(result) === 'number' && Number(result) >= 0;
        return isResultValid ? result : '0';
      } catch (e) {
        this.hasError = true;
        return null;
        // return equation;
      }
    } else {
      this.hasError = false;
      return null;
    }
  }
}
