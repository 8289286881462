/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from '@app/app.module';
import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
}

var dynatraceScript = document.createElement('script');
dynatraceScript.setAttribute('type', 'text/javascript');
dynatraceScript.setAttribute('src', environment.DYNATRACE_SCRIPT_URL);
dynatraceScript.setAttribute('crossorigin', 'anonymous');
document.head.appendChild(dynatraceScript);

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  hmrBootstrap(module, bootstrap);
} else {
  bootstrap().catch((err) => console.error(err));
}
