import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

/**
 * A route strategy allowing for explicit route reuse.
 * Used as a workaround for https://github.com/angular/angular/issues/18374
 * To reuse a given route, add `data: { reuse: true }` to the route definition.
 */
@Injectable()
export class RouteReusableStrategy extends RouteReuseStrategy {
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle | null): void {}

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    let reuse = false;
    if (future.data.extraParams && future.data.extraParams.reuse) {
      reuse = true;
      delete future.data.extraParams.reuse;
      delete curr.data.extraParams.reuse;
    }
    if (curr.data.metadata === 'MEMBER_PROFILE.VIEW_MEMBER' && future.data.metadata === 'MEMBER_PROFILE.VIEW_MEMBER') {
      return curr.params?.membershipNumber === future.params?.membershipNumber ? true : false;
    } else if (curr.data.metadata === 'CAMPAIGN.PROMOTION.EDIT' && future.data.metadata === 'CAMPAIGN.PROMOTION.EDIT') {
      return future.fragment === curr.fragment ? false : true;
    } else {
      return future.data.extraParams && typeof future.data.extraParams.reuse !== 'undefined' ? future.data.extraParams.reuse : future.routeConfig === curr.routeConfig;
    }
  }
}
