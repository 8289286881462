import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges, AfterViewInit, TemplateRef } from '@angular/core';
import { ReceiptTransactionService } from '@core/services';
import { Image, ImageListItem } from '@core/models';
import { ReceiptImageService } from '@app/@core/services/receipt-transaction/receipt-image.service';
import { SimpleChanges } from '@angular/core';
@Component({
  selector: 'c-receipt-image-list',
  templateUrl: './receipt-image-list.component.html',
  styleUrls: ['./receipt-image-list.component.scss'],
})
export class ReceiptImageListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() images: Image[];
  @Input() isBottomThumbnail = false;
  @Input() imageViewerId: string;
  @Output() selectImageEvent: EventEmitter<any> = new EventEmitter();
  @Input() isRpt = false;
  transformedImages: ImageListItem[] = [];
  selectedImage: ImageListItem;

  constructor(private receiptTransactionService: ReceiptTransactionService, private viewTransactionImageService: ReceiptImageService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.viewTransactionImageService.selectedImage.subscribe((selectedImage) => {
      if (selectedImage && this.imageViewerId === selectedImage.imageViewerId) {
        this.selectedImage = selectedImage.imageSrc;
      }
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.images) {
      this.transformImagesUrl();
    }
  }

  async transformImagesUrl() {
    this.transformedImages = [];
    for await (const image of this.images) {
      this.transformedImages.push({
        thumbnailUrl: this.receiptTransactionService.getReceiptImageUrl(image.id, 'thumbnail'),
        originalUrl: this.receiptTransactionService.getReceiptImageUrl(image.id, 'original'),
        caption: image.caption,
        id: image.id,
      });
    }
  }

  getThumbnailLink(id: string) {
    return this.receiptTransactionService.getReceiptThumbnail(id, 'thumbnail');
  }

  onSelectThumbnail(item: ImageListItem) {
    this.viewTransactionImageService.setSelectedViewerImage(item, this.imageViewerId);
  }
}
