import { Directive, Renderer2, ElementRef, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef, DoCheck, ChangeDetectorRef } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
@Directive({
  selector: 'dx-data-grid',
})
export class TableNoDataButtonDirective implements OnInit, AfterViewInit, OnChanges {
  parentRef: Node & ParentNode;
  noDataText: Element;
  assignUserBtn: any;
  buttonContainerRef: any;
  @Input() appTableNoDataButton: boolean;
  @Input() buttonTpl: TemplateRef<any>;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  constructor(private render: Renderer2, private cd: ChangeDetectorRef, private datagrid: DxDataGridComponent, private viewContainerRef: ViewContainerRef) {
    this.cd.detach();
  }

  ngOnInit() {
    this.setNoDataText();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.appTableNoDataButton = changes.appTableNoDataButton.currentValue;
    if (this.buttonTpl) {
      if (!this.appTableNoDataButton) {
        this.removeButton();
      } else {
        this.setButton();
      }
    }
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    if (this.appTableNoDataButton) {
      this.setButton();
      this.cd.detectChanges();
    }
  }

  setButton() {
    this.noDataText = this.datagrid.instance.element().querySelector('.dx-datagrid-nodata');
    this.setNoDataText(true);
    if (this.noDataText) {
      this.parentRef = this.noDataText.parentNode;
      if (this.parentRef) this.render.removeChild(this.parentRef, this.noDataText);
      this.buttonContainerRef = this.viewContainerRef.createEmbeddedView(this.buttonTpl);
      for (let node of this.buttonContainerRef.rootNodes) {
        this.render.appendChild(this.parentRef, node);
      }
    }
  }

  removeButton() {
    this.noDataText = this.datagrid.instance.element().querySelector('.dx-datagrid-nodata');
    if (this.noDataText) {
      this.render.setStyle(this.noDataText, 'top', '0');
      for (let node of this.buttonContainerRef.rootNodes) {
        this.render.removeChild(this.parentRef, node);
      }
    }
    this.setNoDataText();
  }

  setNoDataText(isEmpty?: boolean) {
    this.datagrid.noDataText = isEmpty ? '' : 'No records found';
  }
}
