import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// Shell Related
import { CsShell } from '@app/shells/cs-shell/cs-shell.service';
import { AdminShell } from '@app/shells/admin-shell/admin-shell.service';
import { ConfigShell } from '@app/shells/config-shell/config-shell.service';

// System Pages
import { ForbiddenComponent } from './pages/system-error-pages/forbidden/forbidden.component';
import { PageNotFoundComponent } from './pages/system-error-pages/page-not-found/page-not-found.component';

// Guards
import { PermissionGuard } from '@core/guards/permission.guard';
import { AuthGuard } from './@core/services';
import { SystemAdminGuard } from './@core';
import { environment } from '@env/environment';

/**
 * @description Admin Shell Routes (with URL prefix /admin)
 */
const routes: Routes = [
  {
    path: 'admin',
    children: [
      AdminShell.childRoutes([
        {
          path: 'home',
          loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
          // canActivate: [PermissionGuard],
          data: {
            module: 'home',
          },
        },
        {
          path: 'tenant',
          loadChildren: () => import('./pages/tenants/tenants.module').then((m) => m.TenantsModule),
          // canActivate: [PermissionGuard],
          data: {
            module: 'tenants',
          },
        },
        {
          path: 'partner',
          loadChildren: () => import('./pages/partners/partners.module').then((m) => m.PartnersModule),
          // canActivate: [PermissionGuard],
          data: {
            module: 'partners',
          },
        },
        {
          path: 'currency-conversion',
          loadChildren: () => import('./pages/currency-conversion/currency-conversion.module').then((m) => m.CurrencyConversionModule),
          canActivate: [PermissionGuard],
          data: {
            module: 'currency-conversion',
          },
        },
        {
          path: 'white-list',
          loadChildren: () => import('./pages/white-list/white-list.module').then((m) => m.WhiteListModule),
          canActivate: [PermissionGuard],
          data: {
            module: 'white-list',
          },
        },
        {
          path: 'loyalty',
          loadChildren: () => import('./pages/loyalty/loyalty.module').then((m) => m.LoyaltyModule),
          // canActivate: [PermissionGuard],
          data: {
            module: 'loyalty',
          },
        },
        {
          path: 'members',
          loadChildren: () => import('./pages/members/members.module').then((m) => m.MembersModule),
          // canActivate: [PermissionGuard],
          data: {
            module: 'members',
          },
        },
        {
          path: 'profile',
          loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule),
          data: {
            module: 'profile',
          },
        },
        {
          path: 'receipt-transaction',
          loadChildren: () => import('./pages/receipt-transaction/receipt-transaction.module').then((m) => m.ReceiptTransactionModule),
          data: {
            module: 'receipt-transaction',
          },
        },
        {
          path: 'reimbursement',
          loadChildren: () => import('./pages/reimbursement/reimbursement.module').then((m) => m.ReimbursementModule),
          data: {
            module: 'reimbursement',
          },
        },
        {
          path: 'campaign',
          loadChildren: () => import('./pages/campaign/campaign.module').then((m) => m.CampaignModule),
          data: {
            module: 'campaign',
          },
        },
        {
          path: 'gift',
          loadChildren: () => import('./pages/gift/gift.module').then((m) => m.GiftModule),
          data: {
            module: 'gift',
          },
        },
        {
          path: 'workflow',
          loadChildren: () => import('./pages/workflow/workflow.module').then((m) => m.WorkflowModule),
          canActivate: [PermissionGuard],
          data: {
            module: 'workflow',
          },
        },
        {
          path: 'report',
          loadChildren: () => import('./pages/report/report.module').then((m) => m.ReportModule),
          data: {
            module: 'report',
          },
        },
        {
          path: 'otp',
          loadChildren: () => import('./pages/otp/otp.module').then((m) => m.OtpModule),
          data: {
            module: 'otp',
          },
        },
        {
          path: 'approval-matrix-review',
          loadChildren: () => import('./pages/approval-matrix-review/approval-matrix-review.module').then((m) => m.ApprovalMatrixReviewModule),
          data: {
            module: 'approval-matrix-review',
          },
        },
        {
          path: 'account',
          loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
          data: {
            module: 'account',
          },
        },
      ]),
    ],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'config',
    children: [
      { path: '', redirectTo: 'business-unit', pathMatch: 'full' },
      ConfigShell.childRoutes([
        {
          path: 'business-unit',
          loadChildren: () => import('./pages/business-unit/business-unit.module').then((m) => m.BusinessUnitModule),
          // canActivate: [PermissionGuard],
          data: {
            module: 'business-unit',
          },
        },
        {
          path: 'programme',
          loadChildren: () => import('./pages/programme/programme.module').then((m) => m.ProgrammeModule),
          // canActivate: [PermissionGuard],
          data: {
            module: 'programme',
          },
        },
        {
          path: 'user-role',
          loadChildren: () => import('./pages/user-role/user-role.module').then((m) => m.UserRoleModule),
          data: {
            module: 'user-role',
          },
        },
        {
          path: 'approval-matrix',
          loadChildren: () => import('./pages/approval-matrix/approval-matrix.module').then((m) => m.ApprovalMatrixModule),
          data: {
            module: 'approval-matrix',
          },
        },
      ]),
    ],
    canActivateChild: [AuthGuard, SystemAdminGuard],
  },
  {
    path: 'cs',
    children: [
      { path: '', redirectTo: 'forbidden', pathMatch: 'full' },
      CsShell.childRoutes([
        {
          path: 'receipt-transaction',
          loadChildren: () => import('./pages/receipt-transaction/receipt-transaction.module').then((m) => m.ReceiptTransactionModule),
          data: {
            module: 'receipt-transaction',
          },
        },
      ]),
    ],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'playground',
    loadChildren: () => import('./pages/playground/playground.module').then((m) => m.PlaygroundModule),
  },
  {
    path: 'demo',
    loadChildren: () => import('./pages/demo/demo.module').then((m) => m.DemoModule),
    canActivate: ['canActivateDemoPage'],
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  // Fallback when no prior route is matched
  { path: 'forbidden', component: ForbiddenComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    TranslateModule.forRoot(),
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule, TranslateModule],
  providers: [
    {
      provide: 'canActivateDemoPage',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        switch (environment.showDemoPage) {
          case 'y':
            return true;
          case 'pw':
            return localStorage.getItem('demoPassword') === 'acnDemo';
          case 'n':
            return false;
          default:
            return false;
        }
      },
    },
  ],
})
export class AppRoutingModule {}
