import { Component, Input, OnInit} from '@angular/core';
import { TransactionConstants } from '../../receipt-transaction.constant';


@Component({
  selector: 'RPT-icon',
  templateUrl: './RPT-icon.component.html',
  styleUrls: ['./RPT-icon.component.scss'],
})
export class RPTIconTransactionComponent implements OnInit {
  @Input() isRpt = false;
  @Input() ocrTransactionDetail:any = null;
  TransactionConstants = TransactionConstants;
  constructor() {}
  ngOnInit(): void {
      
  }
}
