import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { DataTableService } from '@core/services/application/data-table';
import { DxDataGridComponent } from 'devextreme-angular';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataTableGuard implements CanDeactivate<DxDataGridComponent>, CanActivate {
  constructor(private dataTableService: DataTableService) {}
  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const state = component.getTableState();
    this.dataTableService.set(currentState.url, state);
    return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.dataTableService.get().url === state.url) {
      return true;
    }
  }
}
