import { ReceiptTransactionService } from '@app/@core/services';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageMode, ProcessedReceiptImage, ImageState, ReceiptImageProcessingStatus } from '../model/receipt-image-processing-status';
import { DialogService, DialogData, ReceiptImageService } from '@core';
import { FormService } from '@core/services/application/form';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import ImageProcessingStatus from '../model/image-processing-status';
import ImageOcrStatus from '../model/image-ocr-status';

@Component({
  selector: 'image-thumbnail',
  templateUrl: './image-thumbnail.component.html',
  styleUrls: ['./image-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageThumbnailComponent implements OnInit, OnChanges {
  @Input() source: ReceiptImageProcessingStatus;
  @Output() clickDelete = new EventEmitter<any>();
  @Output() clickThumbnail = new EventEmitter<any>();
  isSubmitting: boolean;
  isPendingOcrResult: boolean = false;
  thumbnail: any;

  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private formService: FormService,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    // ref isSubmitting in form service
    this.formService.currentIsSubmitting.subscribe((isSubmitting: boolean) => (this.isSubmitting = isSubmitting));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source) {
      this.isPendingOcrResult = this.source.ocrStatus === ImageOcrStatus.Pending || this.source.remoteState === ImageProcessingStatus.New; // Show Loading Spinner only when OCR State = PENDING
      if (this.source.thumbnail) {
        this.thumbnail = this.source.thumbnail;
      } else {
        const sourceBlob = this.source.src;
        const objectURL = URL.createObjectURL(sourceBlob);
        this.thumbnail = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
      }
    }
  }

  /**
   * Open modal when clicking thumbnail
   */
  onClickThumbnail() {
    if (this.isSubmitting || this.isPendingOcrResult) {
      return;
    } else {
      this.clickThumbnail.emit();
    }
  }

  onClickDeleteImage() {
    if (!this.isSubmitting) {
      const dialogData: DialogData = {
        content: `Are you sure want to delete this photo?`,
        id: 'confirm-delete-photo-dialog',
        title: 'Delete',
        isErrorDialogStyle: true,
        yesLabel: 'Confirm',
        noLabel: 'Cancel',
        yesCallback: (dialogRef) => {
          this.clickDelete.emit();
          dialogRef.close();
        },
        noCallback: (dialogRef) => {
          dialogRef.close();
        },
      };
      this.dialogService.showConfirmationDialog(dialogData).subscribe();
    }
  }
}
