export const ErrorExclusion = {
  AD_USER_NOT_FOUND: 'E103003',
  OCR_ERROR: 'E152000',
  IMAGE_NOT_FOUND: 'E150014',
  IMAGE_FAILED_TO_LOAD: 'E105000',
};

export const MallDollarVoidError = {
  MALLDOLLARVOID: 'E190090',
}

export const TokenFailureError = {
  INVALID_TOKEN: 'E100002',
  USERINFO_COGNITO_ERROR: 'E103004',
};

export const CarParkMiddlewareFailureError = {
  MIDDLEWARE_FAILURE: 'E240016',
};
