import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount, catchError, map, find } from 'rxjs/operators';
import { CurrencyConversion } from '../models/business-unit/currency-conversion';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CurrencyConversionService {
  public conversions: Observable<CurrencyConversion[]> = null;

  constructor(private httpService: HttpClient) {}

  /**
   * Get all currency conversion rate
   * @param params Params
   */
  public getConversionRates() {
    if (!this.conversions) {
      this.conversions = this.httpService.get(`/admin/currencyConversionRate`).pipe(
        map((res: any) => {
          return res.data;
        }),
        publishReplay(1),
        refCount(),
        catchError((err, caught) => {
          this.conversions = null;
          return caught;
        })
      ) as Observable<CurrencyConversion[]>;
    }
    return this.conversions;
  }

  /**
   *
   * @param id
   */
  public getConversion(id: string) {
    const conversion: Observable<CurrencyConversion> = this.httpService.get(`/admin/currencyConversionRate/${id}`).pipe(map((res: any) => res.data)) as Observable<CurrencyConversion>;
    return conversion;
  }

  /**
   *
   * @param conversion
   */
  createConversion(conversion: CurrencyConversion) {
    this.conversions = null;
    return this.httpService.post(`/admin/currencyConversionRate`, conversion);
  }

  /**
   *
   * @param conversion
   */
  updateConversion(conversion: CurrencyConversion) {
    this.conversions = null;
    return this.httpService.put(`/admin/currencyConversionRate`, conversion);
  }

  deleteConversion() {}
}
