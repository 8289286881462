import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DropdownService } from './dropdown.service';

@Injectable({
  providedIn: 'root',
})
export class DropdownResolver implements Resolve<any> {
  constructor(private router: Router, private dropdownService: DropdownService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.dropdownService.getDropdown().pipe(catchError((err) => this.router.navigateByUrl('/')));
  }
}
