/**
 * Image Status Code
 */
enum ImageOcrStatus {
  Pending,
  Failed,
  Successful,
  Unnecessary,
  Interrupted,
}

export default ImageOcrStatus;
