<!-- Normal Member Info Display -->
<div *ngIf="!isAssociatedMember" class="display-inner" [class.default-background]="isDefaultBackground" [class.with-image-background]="!isDefaultBackground" [style.backgroundImage]="backgroundImage">
  <div class="basic-info">
    <div class="name-info">{{ memberData | guestFullName }} {{ memberData.nickName && '(' + memberData.nickName + ')' }}</div>
    <div class="other-basic-info">{{ memberData.id }} - {{ memberData.guestTierInfoVo.currentTierName.toLocaleUpperCase() }}</div>
  </div>
  <div class="redemption-pts-info">
    <div class="info-title">
      {{ 'MEMBER.LABEL.AVAILABLE_REDEMPTION_POINTS' | translate }}
    </div>
    <div class="info-value">
      {{ memberData.guestTierInfoVo.availableRedemptionPoints | number }}
    </div>
  </div>
  <div class="redemption-pts-info">
    <div class="info-title">
      {{ 'MEMBER.LABEL.REDEMPTION POINT EXPIRY DATE' | translate }}
    </div>
    <div class="info-value">
      {{ memberData.guestTierInfoVo.redemptionPointExpiryDate | systemDateFormat }}
    </div>
  </div>
</div>

<!-- AssociatedMember Info Display -->

<div *ngIf="isAssociatedMember" class="display-inner associate-member-display-inner display-inner-active">
  <div class="basic-info">
    <div class="name-info">{{ memberData | guestFullName }} {{ memberData.nickName && '(' + memberData.nickName + ')' }}</div>
    <div class="other-basic-info">{{ memberData.guestId }} - {{ memberData.tierName }}</div>
  </div>
</div>
