import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@env/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Start
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
// Angular Material End

// Devextreme Libs
import { DxDataGridModule, DxFileUploaderModule, DxLoadPanelModule, DxAccordionModule, DxTemplateModule, DxGalleryModule, DxPopoverModule } from 'devextreme-angular';

// Third parties libs

// Global Pages
import { TranslateModule } from '@ngx-translate/core';
import { IMaskModule } from 'angular-imask';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CommonContainerComponent, DataDescriptionListComponent, BreadcrumbComponent, IvyCarouselModule, FormBottomContainerComponent } from './components';
import { DynamicBreadcrumbPipe, MerchantPipe, ActualizationLocationPipe, SystemCurrencyPipe } from '@shared/pipes';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { RouterModule } from '@angular/router';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { AngularDualListBoxModule } from 'angular-dual-listbox';

// Custom modules
import { ImageUploaderModule } from './components/image-uploader/image-uploader.module';
import { SystemPipeModule } from '@app/@shared/pipes/system/system-pipe.module';
import { GuestPipeModule } from '@app/@shared/pipes/guest/guest-pipe.module';
import { ImageModule } from '@app/@shared/pipes/image/image.module';
import { DirectiveModule } from '@shared/directives/directive.module';
import { ReceiptTransactionPipeModule } from '@app/@shared/pipes/receipt-transaction/receipt-transaction-pipe.module';
import { MerchantPipeModule } from '@app/@shared/pipes/merchant/merchant-pipe.module';
import { BuPipeModule } from '@app/@shared/pipes/bu/bu-pipe.module';

// Custom Components
import { CalculationInputModule } from './components/calculation-input/calculation-input.module';
import { AttachmentsListModule } from './components/attachments-list/attachments-list.module';
import { CardListingComponent } from './components/card-listing/card-listing.component';
import { CollapsiblePanelComponent } from './components/collapsible-panel/collapsible-panel.component';
import { CustomListBoxComponent } from './components/custom-list-box/custom-list-box.component';
import { DataTableAdvancedSearchComponent } from './components/data-table-advanced-search/data-table-advanced-search.component';
import { DataTableClientSearchComponent } from './components/data-table-client-search/data-table-client-search.component';
import { DialogModule } from './components/dialog/dialog.module';
import { FormButtonModule } from './components/form-button/form-button.module';
import { FormDividerComponent } from '@shared/components/form-divider/form-divider.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { LoaderComponent } from './loader/loader.component';
import { MaskedDatepickerModule } from './components/masked-datepicker/masked-datepicker.module';
import { MatSelectWithSelectAllComponent } from './components/mat-select-with-select-all/mat-select-with-select-all.component';
import { MemberInfoDisplayComponent } from './components/member-info-display';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { PinchZoomModule } from '@app/@shared/components/pinch-zoom/pinch-zoom.module';
import { PromotionStatusComponent } from './components/promotion/promotion-status/promotion-status.component';
import { QrCodeScannerComponent } from './components/qrcode-scanner/qrcode-scanner.component';
import { ShortcutCardComponent } from './components/shortcut-card/shortcut-card.component';
import { SimpleUploaderComponent } from './components/simple-uploader/simple-uploader.component';
import { TenantsSelectorComponent } from './components/tenants-selector/tenants-selector.component';
import { PageNotFoundComponent } from '@app/pages/system-error-pages/page-not-found/page-not-found.component';
import { WorkflowSystemDatePipe } from './pipes/workflow/workflow-system-date.pipe';
import { WorkflowNumberPipe } from './pipes/workflow/workflow-number.pipe';
import { HtmlTooltipComponent } from './components/html-tooltip/html-tooltip.component';
import { FormFieldModule } from './components/form-field/form-field.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TransactionShareModule } from './components/receipt-transaction/receipt-transaction.module';
import { TierPipe } from './pipes/tier.pipe';
import { SerialNumberStatusPipe } from './pipes/gift/serial-number-status.pipe';
import { ChipInputModule } from './components/chip-input';
import { StackViewerModule } from './components/stack-viewer/stack-viewer.module';
import { ReminderComponent } from './components/reminder/reminder.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SearchTooltipModule } from './components/search-tooltip/search-tooltip.module';
import { HtmlTooltipModule } from './components/html-tooltip/html-tooltip.module';
import { DialogInfoDisplayModule } from './components/dialog-info-display/dialog-info-display.module';
import { DataTableServerSearchModule } from './components/data-table-server-search/data-table-server-search.module';
import { SyncFormUploaderModule } from './components/sync-form-uploader/sync-form-uploader.module';
import { LocationPipe } from './pipes/location.pipe';
import { ReimbursementImageListComponent } from './components/reimbursement/reimbursement-image-list/reimbursement-image-list.component';
import { ReimbursementImageViewerComponent } from './components/reimbursement/reimbursement-image-viewer/reimbursement-image-viewer.component';
import { ReimbursementImageUploadComponent } from './components/reimbursement/reimbursement-image-upload/reimbursement-image-upload.component';
import { DateTimePickerModule } from './components/date-time/date-time.module';
import { CommonPopoverComponent } from './components/common-popover/common-popover.component';
import { NgxMatSelectModule } from 'ngx-mat-select';

const MAT_DATEPICKER_FORMAT = {
  parse: {
    dateInput: environment.datepicker_date_format,
  },
  display: {
    dateInput: environment.datepicker_date_format,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: environment.datepicker_date_format,
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    // TODO: Add to sub module
    DataTableServerSearchModule,
    AngularDualListBoxModule,
    CommonModule,
    DialogModule,
    DxAccordionModule,
    DxFileUploaderModule,
    DxGalleryModule,
    DxLoadPanelModule,
    DxTemplateModule,
    FormButtonModule,
    FormsModule,
    IMaskModule,
    IvyCarouselModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatCardModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgxCsvParserModule,
    PinchZoomModule,
    ReactiveFormsModule,
    RouterModule,
    ZXingScannerModule,
    VirtualScrollerModule,
    MatButtonModule,
    MatDialogModule,
    SystemPipeModule,
    DirectiveModule,
    FormFieldModule,
    TranslateModule.forChild(),
    NgSelectModule,
    GuestPipeModule,
    ReceiptTransactionPipeModule,
    TransactionShareModule,
    MerchantPipeModule,
    StackViewerModule,
    BuPipeModule,
    CalculationInputModule,
    AngularSvgIconModule.forRoot(),
    HtmlTooltipModule,
    SearchTooltipModule,
    DialogInfoDisplayModule,
    SyncFormUploaderModule,
    ImageModule,
    DxPopoverModule,
    NgxMatSelectModule.forRoot(),
  ],
  declarations: [
    ActualizationLocationPipe,
    BreadcrumbComponent,
    CardListingComponent,
    CollapsiblePanelComponent,
    CommonContainerComponent,
    CustomListBoxComponent,
    DataDescriptionListComponent,
    DataTableAdvancedSearchComponent,
    DataTableClientSearchComponent,
    DynamicBreadcrumbPipe,
    FormBottomContainerComponent,
    FormDividerComponent,
    ImageModalComponent,
    LoaderComponent,
    MatSelectWithSelectAllComponent,
    MemberInfoDisplayComponent,
    PageContainerComponent,
    PageNotFoundComponent,
    PromotionStatusComponent,
    QrCodeScannerComponent,
    ShortcutCardComponent,
    SimpleUploaderComponent,
    TenantsSelectorComponent,
    WorkflowSystemDatePipe,
    WorkflowNumberPipe,
    SerialNumberStatusPipe,
    TierPipe,
    ReminderComponent,
    LocationPipe,
    ReimbursementImageListComponent,
    ReimbursementImageViewerComponent,
    ReimbursementImageUploadComponent,
    CommonPopoverComponent
  ],
  exports: [
    ActualizationLocationPipe,
    AngularDualListBoxModule,
    AngularDualListBoxModule,
    AttachmentsListModule,
    BreadcrumbComponent,
    BuPipeModule,
    CalculationInputModule,
    CardListingComponent,
    ChipInputModule,
    CollapsiblePanelComponent,
    DataDescriptionListComponent,
    DataTableAdvancedSearchComponent,
    DataTableClientSearchComponent,
    DialogModule,
    DirectiveModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxLoadPanelModule,
    DynamicBreadcrumbPipe,
    FormBottomContainerComponent,
    FormButtonModule,
    FormDividerComponent,
    FormFieldModule,
    FormsModule,
    GuestPipeModule,
    HtmlTooltipComponent,
    IMaskModule,
    ImageModule,
    ImageUploaderModule,
    IvyCarouselModule,
    LoaderComponent,
    MaskedDatepickerModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatSelectWithSelectAllComponent,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MemberInfoDisplayComponent,
    MerchantPipeModule,
    // MerchantSelectionDialogComponent,
    NgSelectModule,
    NgxCsvParserModule,
    PromotionStatusComponent,
    PromotionStatusComponent,
    QrCodeScannerComponent,
    ReactiveFormsModule,
    ReceiptTransactionPipeModule,
    ReminderComponent,
    SerialNumberStatusPipe,
    ShortcutCardComponent,
    SimpleUploaderComponent,
    StackViewerModule,
    SystemPipeModule,
    TenantsSelectorComponent,
    TenantsSelectorComponent,
    TierPipe,
    TransactionShareModule,
    TranslateModule,
    VirtualScrollerModule,
    WorkflowNumberPipe,
    FormFieldModule,
    NgSelectModule,
    GuestPipeModule,
    ReceiptTransactionPipeModule,
    IvyCarouselModule,
    MerchantPipeModule,
    BuPipeModule,
    TierPipe,
    ReminderComponent,
    SerialNumberStatusPipe,
    CalculationInputModule,
    WorkflowSystemDatePipe,
    ZXingScannerModule,
    AngularSvgIconModule,
    HtmlTooltipModule,
    SearchTooltipModule,
    DialogInfoDisplayModule,
    DataTableServerSearchModule,
    SyncFormUploaderModule,
    LocationPipe,
    ReimbursementImageListComponent,
    ReimbursementImageViewerComponent,
    ReimbursementImageUploadComponent,
    DateTimePickerModule,
    CommonPopoverComponent
  ],
  providers: [
    // Material components global configs
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATEPICKER_FORMAT },
    MerchantPipe,
    SystemCurrencyPipe,
  ],
})
export class SharedModule {}
