import IMask from 'imask';
export abstract class DateTimeMaskConstants {
  public static TIME = {
    overwrite: true,
    autofix: true,
    mask: 'HH:MM',
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
        minLength: 2,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        minLength: 2,
        maxLength: 2,
      },
    },
  };
}
