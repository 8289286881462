import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { DialogData } from '@core/models/application/dialog-data';
import { DialogService } from '@core/services/application/dialog';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Campaign, DropdownDictionary, FieldOptionDataSource, Promotion, ReportConfig, Tenant } from '@app/@core/models';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay, startWith, tap } from 'rxjs/operators';
import { ApiResponse } from '@app/@core/models';
import { isObject } from 'lodash';
import { LoyaltyService } from '@app/@core/services/loyalty/loyalty.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  isSubmittingSource = new BehaviorSubject<boolean>(false);
  currentIsSubmitting = this.isSubmittingSource.asObservable();
  formlyObservables: Subscription[] = [];

  constructor(private dialogService: DialogService, private httpService: HttpClient, private loyaltyService: LoyaltyService) {}

  changeIsSubmitting(isSubmitting: boolean) {
    this.isSubmittingSource.next(isSubmitting);
  }

  checkFormValidity(form: UntypedFormGroup | UntypedFormGroup[]) {
    const errorFields: any = [];
    const forms: UntypedFormGroup[] = [];
    if (form instanceof UntypedFormGroup) {
      forms.push(form);
    }
    forms.forEach((formGroup: UntypedFormGroup, index: number) => {
      if (!formGroup.valid) {
        Object.keys(formGroup.controls).map((key) => {
          const controlErrors: ValidationErrors = formGroup.get(key).errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach((keyError) => {
              const fieldName = `${key}`;
              const errorLabel = `Error`;
              errorFields.push(`Transaction ${index + 1}: ${fieldName} ${errorLabel}`);
            });
          }
        });
      }
    });
    const dialogData: DialogData = {
      title: 'Error',
      errorList: errorFields,
      width: '370px',
      yesCallback: (dialogRef) => {
        dialogRef.close();
      },
    };
    this.dialogService.showErrorDialog(dialogData).subscribe();
  }

  onYearMonthPickerInput(datePicker: MatDatepicker<any>, datePickerInput: HTMLInputElement) {
    // masked value will sometime return with random '_' attached, use below function to solve the problem
    const handledMaskedDate = datePickerInput.value;
    if (moment(handledMaskedDate, 'YYYYMM', true).isValid()) {
      datePicker?.close();
      datePicker?.select(moment(handledMaskedDate, 'YYYYMM'));
    }

    if (!handledMaskedDate) {
      datePicker?.select(null);
    }
  }

  onYearMonthPickerInputBlur(datePicker: MatDatepicker<any>, datePickerInput: HTMLInputElement, datePickerHiddenInput: any) {
    if (!moment(datePickerInput.value, 'YYYYMM', true).isValid()) {
      if (datePickerHiddenInput.value) {
        datePicker?.select(moment(datePickerHiddenInput.value, 'YYYYMM'));
      } else {
        datePicker?.select(null);
      }
    }
  }

  // once the value of the hidden input is changed, change also the visible input
  onHiddenInputChanged(datePickerInput: HTMLInputElement, datePickerHiddenInput: any) {
    datePickerInput.value = datePickerHiddenInput?.value;
  }

  // errorMapping is the 'validator error key - error message group' pair
  // labelMapping is the 'field key - field label' pair
  getFormError(
    formGroup: UntypedFormGroup | UntypedFormArray,
    errorMapping: { [field: string]: { errorMsg: string; showFieldList?: boolean } },
    labelMapping?: { [field: string]: string },
    customErrorObj?: { [errorMsg: string]: any }
  ) {
    const errorObj: { [errorMsg: string]: any } = customErrorObj ? customErrorObj : {};
    !customErrorObj && this.consolidateFormErrorObj(formGroup, errorObj, labelMapping);
    return this.generateErrorDialogContent(errorObj, errorMapping);
  }

  getOptionListForDropdown(reportConfig: ReportConfig) {
    if (reportConfig?.options?.formState?.fieldOptionDataSources) {
      const { fieldOptionDataSources } = reportConfig.options.formState;
      if (fieldOptionDataSources.length > 0) {
        fieldOptionDataSources.forEach((fieldOptionDataSource: FieldOptionDataSource) => {
          const key = fieldOptionDataSource.key;
          const selectOptionsDataKey = fieldOptionDataSource.selectOptionsDataKey;
          const url = fieldOptionDataSource.apiSource?.url;
          const params = fieldOptionDataSource.apiSource?.params;
          if (key) {
            reportConfig.fields.forEach((parentField) => {
              if (parentField.fieldGroup) {
                parentField.fieldGroup.forEach((childField) => {
                  if ((childField.type === 'select' || childField.type === 'select-all' || childField.type === 'ng-select') && childField.key === key) {
                    childField.templateOptions.options = url && params ? this.retrieveOptionList(url, params) : [];
                    childField.templateOptions.options$ = childField.templateOptions.options;
                  }
                });
              }
            });
          }
          if (selectOptionsDataKey) {
            reportConfig.options.formState.selectOptionsData[selectOptionsDataKey] = url && params ? this.retrieveOptionList(url, params) : [];
          }
        });
      }
    }
    return reportConfig;
  }

  flattenFormData(data: any, res: any = {}) {
    Object.keys(data).forEach((key) => {
      if (!Array.isArray(data[key]) && isObject(data[key]) && !moment.isMoment(data[key])) {
        this.flattenFormData(data[key], res);
      } else {
        if (moment.isMoment(data[key])) {
          res[key] = data[key].format();
        } else {
          res[key] = data[key];
        }
      }
    });
    return res;
  }

  retrieveOptionList(url: string, params?: any) {
    return this.httpService.get(url, { params }).pipe(
      map((response: ApiResponse) => {
        if (response.data) {
          return response.data.content || response.data;
        }
      }),
      shareReplay(1)
    ) as Observable<any>;
  }

  consolidateFormErrorObj(formGroup: UntypedFormGroup | UntypedFormArray, errorObj: { [errorMsg: string]: any }, labelMapping: { [field: string]: {} }) {
    if (formGroup.errors) {
      Object.keys(formGroup.errors).forEach((error) => {
        errorObj[error] = errorObj[error];
      });
    }
    Object.keys(formGroup.controls).forEach((fieldName) => {
      if (formGroup.controls[fieldName].errors) {
        Object.keys(formGroup.controls[fieldName].errors).forEach((error) => {
          errorObj[error] = (errorObj[error] || []).concat(labelMapping[fieldName] || fieldName);
          errorObj[error] = Array.from(new Set(errorObj[error]));
        });
      }

      if (!(formGroup.controls[fieldName] instanceof UntypedFormControl)) {
        this.consolidateFormErrorObj(formGroup.controls[fieldName], errorObj, labelMapping);
      }
    });
  }

  unsubscribeFormlyObservables(field: FormlyFieldConfig) {
    this.formlyObservables.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.formlyObservables = [];
  }

  generateErrorDialogContent(errorObj: { [errorType: string]: string[] }, errorMapping: { [errorType: string]: { [options: string]: any } }) {
    const processedErrorObj = {};
    Object.keys(errorObj).forEach((errorKey) => {
      if (errorMapping[errorKey]) {
        processedErrorObj[errorMapping[errorKey].errorMsg || errorKey] = errorMapping[errorKey].showFieldList ? errorObj[errorKey] : true;
      }
    });
    return Object.keys(processedErrorObj).length > 0 ? processedErrorObj : null;
  }

  showErrorMessage(errObj: any) {
    if (!errObj) return;

    const dialogData: DialogData = {
      title: 'Error',
      errorList: errObj,
      width: '370px',
      yesCallback: (dialogRef) => {
        dialogRef.close();
      },
    };
    this.dialogService.showErrorDialog(dialogData).subscribe();
  }
}
