import { ApiResponse } from '@core/models/application/api-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { LoyaltyService } from '@core/services/loyalty';
import { OAuthService, OAuthStorage, TokenResponse } from 'angular-oauth2-oidc';
import { Observable, ReplaySubject, BehaviorSubject, of, interval, Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { map, distinctUntilChanged, filter, catchError, take } from 'rxjs/operators';
import { DialogService } from '@core/services/application/dialog';
import { DialogData } from '@core/models/application/dialog-data';
import { v4 as uuidv4 } from 'uuid';
import { GuestProfile, UserProfile } from '@app/@core/models';
import * as moment from 'moment-timezone';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  public currentUserSubject = new BehaviorSubject<any>({} as any);

  public isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  public permissionSubject = new BehaviorSubject<any>({} as any);
  public permission$ = this.permissionSubject.asObservable().pipe(distinctUntilChanged());

  public userRoleSubject = new BehaviorSubject<any>({} as any);
  public userRole$ = this.userRoleSubject.asObservable().pipe(distinctUntilChanged());

  public systemAdminSubject = new BehaviorSubject<any>({} as any);
  public systemAdmin$ = this.systemAdminSubject.asObservable().pipe(distinctUntilChanged());

  public refreshTimerSubscription: Subscription = null;
  public refreshTimer$ = timer(600000, 600000);

  private PERMISSIONS: string[] = [];
  private USER_ACCESS: UserProfile = null;

  private refreshProgramme$: Subscription = null;

  get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  get currentUserInfo(): GuestProfile {
    return this.currentUserSubject.value;
  }

  get currentUserRole(): string {
    return this.userRoleSubject.value;
  }

  get permissions(): string[] {
    return this.PERMISSIONS;
  }

  set permissions(permissions: string[]) {
    this.PERMISSIONS = permissions;
  }

  get userAccess() {
    return this.USER_ACCESS;
  }

  set userAccess(userAccess: UserProfile) {
    this.USER_ACCESS = userAccess;
  }

  get router(): Router {
    return this.injector.get(Router);
  }

  constructor(
    private oauthService: OAuthService,
    private httpClient: HttpClient,
    private injector: Injector,
    private oAuthStorage: OAuthStorage,
    private loyaltyService: LoyaltyService,
    private dialogService: DialogService,
    private ngZone: NgZone
  ) {
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events.pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type))).subscribe((e) => this.login());

    this.ngZone.run(() => {
      this.oauthService.setupAutomaticSilentRefresh();
    });
  }

  runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG & SET SESSION ID
    this.initAuthSessionId();
    this.compareTime();
    return this.oauthService
      .loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin())
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          // CRM-9266 + /userLogin
          return this.logUserLogin().then(() => {
            // return Promise.resolve();
            this.refreshTimerSubscription = this.refreshTimer$.subscribe(() => this.oauthService.refreshToken());

            if (Object.keys(this.currentUserInfo).length === 0) {
              return this.loadAppUserProfile().then(() => {
                return Promise.resolve();
              });
            } else {
              return Promise.resolve();
            }
          });
        }
        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
        return this.oauthService
          .silentRefresh()
          .then(() => {
            return Promise.resolve();
          })
          .catch((result) => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = ['interaction_required', 'login_required', 'account_selection_required', 'consent_required'];
            if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.login();
              //
              // Instead, we'll now do this:
              console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })
      .then(() => {
        return this.getUserAccess()
          .then((userAccess) => {
            if (userAccess) {
              if (this.refreshProgramme$) this.refreshProgramme$.unsubscribe();
              this.refreshProgramme$ = interval(5 * 60 * 1000).subscribe(() => {
                this.loyaltyService.refreshProgrammeList(userAccess.email).pipe(take(1)).subscribe();
              });
              return this.loyaltyService.initProgrammeList(userAccess.email).then(async () => {
                this.userAccess = userAccess;
                const preferredProgramme = JSON.parse(localStorage.getItem('CURRENT_LOYALTY_PROGRAMME'));
                if (preferredProgramme) {
                  await this.loyaltyService.switchLoyaltyProgramme(preferredProgramme.businessUnit, preferredProgramme.code);
                  this.userAccess = await this.getUserAccess(); // override user access matrix if preferred LP !== default LP
                  // TODO:
                  this.setUserRole(preferredProgramme.businessUnit, preferredProgramme.code);
                  this.setSystemAdmin();
                } else {
                  await this.loyaltyService.switchLoyaltyProgramme(this.userAccess.currentBu, this.userAccess.currentLp);
                  // TODO:
                  this.setUserRole(this.userAccess.currentBu, this.userAccess.currentLp);
                  this.setSystemAdmin();
                }
                this.permissions = this.userAccess.accesses;
                this.permissionSubject.next(this.permissions);
                // Set User Role
              });
            }
          })
          .catch((result) => {
            this.navigateToLoginPage();
            return Promise.reject(result);
          });
      })
      .then(() => {
        const state = decodeURIComponent(this.oauthService.state);
        if (state && state.trim() !== '') {
          this.router.navigateByUrl(state);
        }
        return Promise.resolve();
      })
      .catch((error) => {
        // return Promise.reject();
      });
  }

  /**
   * Login
   * @param targetUrl OAuth Redirect URL
   */
  login(targetUrl?: string, isDirectLogin: boolean = true) {
    let loginParams;
    if (isDirectLogin) {
      loginParams = { identity_provider: environment.identity_provider };
    }
    this.oauthService.initLoginFlow(targetUrl || this.router.url, loginParams);
  }

  // CRM-9266 split use case to only trigger when user login manually
  manualLogout(isTimeout: boolean = false) {
    // CRM-9266 + /userLogout
    this.logUserLogout().subscribe();

    this.logout(isTimeout);
  }

  /**
   * Revoke token in redis and logout from IDP
   * @param isTimeout - Set true if the flow is session timeout
   */
  logout(isTimeout: boolean = false) {
    if (isTimeout) {
      // Read this item after redirect back to login page - if true: Show timeout message
      this.oAuthStorage.setItem('spl.timeout', 'true');
    }

    if (this.refreshTimerSubscription) {
      this.refreshTimerSubscription.unsubscribe();
      this.refreshTimerSubscription = null;
    }

    this.dialogService.closeAllDialogs();
    this.revokeToken().subscribe(() => {
      if (this.refreshProgramme$) {
        this.refreshProgramme$.unsubscribe();
        this.refreshProgramme$ = null;
      }
      // Params for cognito callback
      this.oauthService.logOut({ client_id: environment.OAUTH_CLIENT_ID, logout_uri: environment.APP_BASE_URL });
    });
  }

  /**
   * Refresh user profile and permission resources
   */
  refreshUserAccess() {
    return this.getUserAccess().then((userAccess) => {
      this.permissions = userAccess.accesses;
      this.permissionSubject.next(this.permissions);

      this.userAccess = userAccess;
      this.setUserRole(this.userAccess.currentBu, this.userAccess.currentLp);
      this.setSystemAdmin();
    });
  }

  /**
   * Redirect to Login Page
   */
  navigateToLoginPage(preserveStateUrl?: string) {
    preserveStateUrl ? this.router.navigate([`/login`], { state: { targetUrl: preserveStateUrl } }) : this.router.navigate(['/login']);
  }

  /**
   *  Used in Playground - Testing Purpose
   */
  forceRefreshToken(): Promise<TokenResponse> {
    return this.oauthService.refreshToken();
  }

  hasPermission(permissionId: string): boolean {
    return this.permissions.includes(permissionId);
  }

  // CRM-9266 Application Log-in Log
  logUserLogin(): Promise<any> {
    return this.httpClient.post('/userAccess/userLogin', null).toPromise();
  }

  // CRM-9266 Application Log-out Log
  logUserLogout(): Observable<any> {
    return this.httpClient.post('/userAccess/userLogout', null);
  }

  refreshAllUserTitle(): Observable<any> {
    return this.httpClient.post('/cognito/refreshADTitle', {}).pipe(
      map((data) => {
        return data;
      })
    );
  }

  private setUserProfile(user: any) {
    this.currentUserSubject.next(user);
  }

  /**
   * Load user profile from application (DB)
   */
  private loadAppUserProfile(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.oauthService.loadUserProfile().then(
        () => {
          return this.getUserAccess().then(
            (userProfile) => {
              // Set user profile to subject
              this.setUserProfile(userProfile);
              resolve(userProfile);
            },
            (error) => {
              reject(error);
            }
          );
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  private setUserRole(businessUnit: string, loyaltyProgram: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const currentRole = this.userAccess.roles.find((role: any) => {
        return role.bu === businessUnit && role.program === loyaltyProgram;
      });
      this.userRoleSubject.next(currentRole?.role);
      resolve(currentRole);
    });
  }

  private setSystemAdmin() {
    const systemAdminRole = this.userAccess.roles.find((role: any) => {
      return role.role === 'System Administrator';
    });
    this.systemAdminSubject.next(!!systemAdminRole);
  }

  /**
   * Revoke token from Redis
   */
  private revokeToken(): Observable<any> {
    // Revoke token from both server side and client side
    this.clearLocalToken();
    return this.httpClient.get(`/cognito/revokeToken`).pipe(
      map((data) => {
        return data;
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
   * Set up session id (UUID) for cross browser access control
   */
  private initAuthSessionId() {
    // UUID To Identify SAME USER in Different Devices
    if (!this.oAuthStorage.hasOwnProperty('spl.sid')) {
      this.oAuthStorage.setItem('spl.sid', uuidv4());
    }
    if (this.oAuthStorage.getItem('spl.timeout')) {
      this.oAuthStorage.removeItem('spl.timeout');

      const dialogData: DialogData = {
        content: 'COMMON.MESSAGE.SESSION_TIMEOUT',
        buttonOneLabel: 'AUTH.LABEL.RE_LOGIN',
        buttonOneCallback: (dialogRef) => {
          dialogRef.close();
          this.login();
        },
      };
      this.dialogService.showSuccessDialog(dialogData).subscribe();
    }
  }

  /**
   *
   */
  private clearLocalToken() {
    this.oAuthStorage.removeItem('access_token');
    this.oAuthStorage.removeItem('spl.sid');
    this.isAuthenticatedSubject.next(false);
    this.currentUserSubject.next({} as any);
  }

  /**
   * Get user's permission/resources
   */
  private getUserAccess(): Promise<UserProfile> {
    return this.httpClient
      .get(`/userAccess`)
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      )
      .toPromise();
  }
   
  // 当前时间和系统时间做对比
  compareTime(dialogRef?:any) {
    this.currentDateTime().subscribe(res=> {
       dialogRef && (dialogRef.componentInstance.isLoading = false);
        
        const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')
        
        const dateTime = new Date(res);
        // 设置时区为中国上海
        dateTime.setTime(dateTime.getTime()); // 将时间调整为东八区
        // 格式化成中国地区时间字符串
        const targetDate = dateTime.toLocaleString('zh-CN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: 'Asia/Shanghai'
        });
        const timeDifferenceInMinutes = moment(targetDate, 'YYYY-MM-DD HH:mm:ss').diff(moment(currentDate, 'YYYY-MM-DD HH:mm:ss'), 'hours');
        const targetDateFormat = moment(targetDate).format('YYYY-MM-DD HH:mm:ss');
        if(Math.abs(timeDifferenceInMinutes) > 1) {
          !dialogRef && this.showTimeDialog(targetDateFormat);
        }else{
          dialogRef && dialogRef.close()
        }
    })
  }
  showTimeDialog(time:any) {
    const dialogData: DialogData = {
      content: `Time discrepancy with server is detected. Please check and adjust your device’s time zone setting/time setting. <p>Current server time: ${time}</p>`,
      id: 'confirm-delete-photo-dialog',
      title: 'Warn',
      yesLabel: 'Retry',
      isHideNoButton: true,
      isHtml: true,
      yesCallback: (dialogRef) => {
        this.compareTime(dialogRef)
      },
    };
    this.dialogService.showConfirmationDialog(dialogData).subscribe();
  }

   // 获取当前时间
   currentDateTime() {
    return this.httpClient.get(`/system/currentDateTime`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
}
