/**
 * Image Status Code
 */
enum ImageProcessingStatus {
  New,
  Pending,
  Failed,
  Successful,
  Oversize,
}

export default ImageProcessingStatus;
