<header>
  <nav>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-icon-button (click)="toggleMenu()">
          <mat-icon>menu</mat-icon>
        </button>
        <div class="header-left">
          <img src="/assets/images/logo-white.svg" alt="logo" />
          <span class="project-title title-divider"></span>
          <!-- <span class="project-title title app-name">
            {{ 'PROJECT.TITLE' | translate }}
          </span> -->
          <img class="title" src="/assets/images/hk1crm-app-logo.svg" alt="logo" />
          <span *ngIf="environment.showBuildVersion" class="project-title app-name version-label"> Version {{ envVersion }} </span>
        </div>
        <span class="flex-spacer"></span>
        <div class="right-action">
          <button mat-icon-button class="system-icon" (click)="onClickScanQRCode()">
            <mat-icon>qr_code_scanner</mat-icon>
          </button>
          <button mat-icon-button class="system-icon" (click)="onClickSetting()" [hidden]="!(userService.systemAdmin$ | async)">
            <mat-icon>settings</mat-icon>
          </button>
          <div id="avatar">
            <span>
              {{ usernameInitial }}
            </span>
          </div>
          <div class="user-info">
            <div class="label">
              {{ username }}
            </div>
            <div class="label title" title="{{ currentUser.title }}">
              {{ currentUser.title }}
            </div>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="onClickLogout()" mat-menu-item>
              <mat-icon>login</mat-icon>
              <span translate>COMMON.LABEL.LOGOUT</span>
            </button>
            <div mat-menu-item>
              <mat-checkbox *ngIf="environment.debug" class="debug-checkbox" color="primary" [checked]="debugService.debug" (change)="debugService.setDebugMode($event.checked)">Debug Mode </mat-checkbox>
            </div>
          </mat-menu>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </nav>
</header>
