import { ValidationErrors } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';

export function emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }
  const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEXP.test(control.value) ? null : { invalidFormat: true };
}

export function phoneValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }
  const PHONE_REGEXP = /^[0-9]*$/;
  return PHONE_REGEXP.test(control.value) ? null : { invalidPhoneFormat: true };
}

/**
 * validator by reg and length
 */
export function phoneValidatorWithParam(legalLength: number, existingPhoneNumber?: string) {
  return function phoneValidator(control: AbstractControl): ValidationErrors | null {
    let val = control.value;
    const PHONE_REGEXP = /^[0-9]*$/;
    if (!val) {
      return !existingPhoneNumber ? null : PHONE_REGEXP.test(existingPhoneNumber) && existingPhoneNumber.length === legalLength ? null : { invalidPhoneFormat: true };
    }

    let len = val.length;
    return PHONE_REGEXP.test(val) && len === legalLength ? null : { invalidPhoneFormat: true };
  };
}

export function passwordValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }
  const trimmedPassword = control.value.trim();
  const hasNumber = /\d/.test(trimmedPassword);
  const hasUpper = /[A-Z]/.test(trimmedPassword);
  const hasLower = /[a-z]/.test(trimmedPassword);
  const hasSpecialChar = /\W|_/.test(trimmedPassword);
  const matchAtLeast3 = [hasNumber, hasUpper, hasLower, hasSpecialChar].filter((v) => v).length >= 3;
  const valid = matchAtLeast3;
  if (trimmedPassword.length < 8) {
    return { minLength: true };
  }
  if (!valid) {
    return { mismatchPolicy: true };
  }
  return null;
}
