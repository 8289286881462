<div class="row">
  <div class="col">
    <mat-form-field class="full-width multiple-select">
      <mat-label translate>{{ promotionMerchantType === 'MERCHANT' ? 'Tenants' : promotionMerchantType === 'PARTNER' ? 'Partners' : 'Tenants & Partners' }} Category</mat-label>
      <mat-select multiple [disabled]="disabled" [(ngModel)]="category" (selectionChange)="onCategoryChange($event)" [placeholder]="'PROMOTION.EDIT.LABEL.SELECT_TENANT_CATEGORY.PLACEHOLDER' | translate">
        <mat-option *ngFor="let item of dropdownData?.MERCHANT.CATEGORY" [value]="item.value">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col">
    <mat-form-field class="full-width multiple-select">
      <mat-label translate>{{ promotionMerchantType === 'MERCHANT' ? 'Tenants' : promotionMerchantType === 'PARTNER' ? 'Partners' : 'Tenants & Partners' }} Subcategory</mat-label>
      <mat-select multiple [disabled]="disabled" [(ngModel)]="subcategory" (selectionChange)="onSubcategoryChange($event)" [placeholder]="'PROMOTION.EDIT.LABEL.SELECT_TENANT_SUBCATEGORY.PLACEHOLDER' | translate">
        <mat-option *ngFor="let item of filteredSubcategoryList" [value]="item.value" [disabled]="subcategory && subcategory.length === 3 && !subcategory.includes(item.value)">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col">
    <app-custom-list-box
      [source]="filteredTenantList"
      [(destination)]="selectedTenants"
      [disabled]="disabled"
      key="id"
      display="name"
      [filter]="true"
      [sort]="false"
      [format]="format"
      (destinationChange)="onDestinationChange($event)"
    ></app-custom-list-box>
  </div>
</div>
