<div class="confirmation-dialog-wrapper">
  <div *ngIf="data.title" class="dialog-title" [ngClass]="{ red: data.isErrorDialogStyle }" mat-dialog-title>
    {{ data.title | translate }}
  </div>
  <div *ngIf="data.content && !isShowContentTpl && !data.isHtml" mat-dialog-content>
    {{ data.content.toString() | translate }}
  </div>
  <div *ngIf="data.content && !isShowContentTpl && data.isHtml" mat-dialog-content innerHTML="{{data.content}}"></div>

  <!-- For Custom Content -->
  <div *ngIf="data.content && isShowContentTpl" mat-dialog-content>
    <ng-container *ngTemplateOutlet="data.content"></ng-container>
  </div>
  <div mat-dialog-actions class="action-wrapper" [class.has-sticky-action]="data.hasStickyActionBar">
    <c-form-button [isLoading]="isLoading" *ngIf="!data.isHideNoButton" color="basic" class="grey" [label]="data.noLabel || 'No'" (click)="isLoading = true; data.noCallback(dialogRef)" [upperCaseLabel]="true">
    </c-form-button>
    <c-form-button
      [isLoading]="isLoading"
      [ngClass]="{ red: data.isErrorDialogStyle }"
      [buttonType]="data.isErrorDialogStyle ? 'stroke' : 'flat'"
      [color]="data.isErrorDialogStyle ? 'warning' : 'primary'"
      [label]="data.yesLabel || 'Yes'"
      (click)="isLoading = true; data.yesCallback(dialogRef)"
      [upperCaseLabel]="true"
    ></c-form-button>
  </div>
</div>
