import { SafeUrl } from '@angular/platform-browser';
import ImageOcrStatus from './image-ocr-status';
import ImageProcessingStatus from './image-processing-status';

export class ImageInput {
  mode: ImageMode;
  image: File;
}

export enum ImageMode {
  display,
  edit,
  capture,
}
export enum ImageState {
  New,
  Unchanged,
  Updated,
  Display,
}

export class ProcessedReceiptImage {
  caption?: string;
  id?: string;
  name: string;
  oversized?: boolean;
  ocrProcessed?: boolean;
  processing?: boolean;
  src: SafeUrl | ArrayBuffer;
  state?: ImageState = ImageState.New;
  ocrState?: string; // TODO: should not be string
  remoteState?: ImageProcessingStatus;
  thumbnail?: SafeUrl | ArrayBuffer;
  uploadSessionId: string;
  fileCategoryName?: string;
}

export class ReceiptImageProcessingStatus {
  id?: string;
  name?: string;
  ocrStatus?: ImageOcrStatus;
  remoteState?: ImageProcessingStatus;
  thumbnail?: SafeUrl | ArrayBuffer;
  src?: File;
  uploadSessionId?: string;
  transactionIndex?: number;
  caption?: string;
}
