<div class="breadcrumb-container" [class.has-breadcrumb]="isBreadcrumbVisible" [class.has-breadcrumb1]="!isBreadcrumbVisible">
  <div class="breadcrumb-row">
    <div *ngIf="isBreadcrumbVisible" class="left-nav-container">
      <div *ngIf="!isOverrideLeftSection" class="breadcrumb-nav">
        <ng-container *ngFor="let breadcrumb of breadcrumbArr; let last = last; let first = first">
          <ng-container *ngIf="breadcrumb.label !== ''">
            <mat-icon class="arrow-icon" *ngIf="!first">keyboard_arrow_right</mat-icon>
            <mat-icon class="page-icon" *ngIf="breadcrumb.icon !== ''">{{ breadcrumb.icon }}</mat-icon>
            <a *ngIf="breadcrumb.link" class="breadcrumb-label" routerLink="{{ breadcrumb.link | dynamicBreadcrumb }}" translate>{{ breadcrumb.label | dynamicBreadcrumb }}</a>
            <a *ngIf="!breadcrumb.link" class="breadcrumb-label" translate>{{ breadcrumb.label | dynamicBreadcrumb }}</a>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="isOverrideLeftSection" class="breadcrumb-nav">
        <ng-container *ngTemplateOutlet="leftContainerTemplate"></ng-container>
      </div>
    </div>
    <div *ngIf="isRightSectionVisible && currentTemplate" class="right-nav-container">
      <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
    </div>
  </div>
</div>
<div *ngIf="isShowExtraRowContainer">
  <ng-container *ngTemplateOutlet="extraRowTemplate"></ng-container>
</div>
