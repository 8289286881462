import { Pipe, PipeTransform } from '@angular/core';
import { LoyaltyService } from '@core/services/loyalty';
import { BreadcrumbService } from '@core/services/application/breadcrumb.service';

@Pipe({
  name: 'dynamicBreadcrumb',
})
export class DynamicBreadcrumbPipe implements PipeTransform {
  currentPageParams: {};
  defaultMapping: {};

  constructor(private breadcrumbService: BreadcrumbService, private loyaltyService: LoyaltyService) {
    this.breadcrumbService.onBreadcrumbParamsUpdate$.subscribe((params) => {
      this.currentPageParams = params;
    });
    this.defaultMapping = {
      BUSINESS_UNIT_NAME: this.loyaltyService.currentProgramme.businessUnit,
      PROGRAMME_CODE: this.loyaltyService.currentProgramme.code,
      PROGRAMME_NAME: this.loyaltyService.currentProgramme.name,
    };
  }

  transform(value: string, args?: any): string {
    const combinedMap = { ...this.currentPageParams, ...this.defaultMapping };
    return this.replaceAllLabelValue(value, combinedMap);
  }

  private replaceAllLabelValue(str: string, mapObj: {}): string {
    const replacedMap = Object.keys(mapObj).map((key) => {
      const newKey = `{{${key}}}` || key;
      return {
        key: newKey,
        value: mapObj[key],
      };
    });
    const regexPattern = new RegExp(replacedMap.map((e) => e.key).join('|'), 'gi');
    return str.replace(regexPattern, (matched) => {
      return replacedMap.find((obj) => {
        return obj.key === matched;
      }).value;
    });
  }
}
