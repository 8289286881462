import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MembersConstants } from '@app/pages/members/members.constants';
import { GuestProfile } from '@core';

@Component({
  selector: 'member-info-display',
  templateUrl: './member-info-display.component.html',
  styleUrls: ['./member-info-display.component.scss'],
  host: {
    class: 'member-info-display',
  },
})
export class MemberInfoDisplayComponent implements OnChanges {
  isDefaultBackground = false;
  backgroundImage: string;
  @Input() memberData: GuestProfile;
  @Input() isAssociatedMember: boolean;

  constructor() {
    this.isAssociatedMember = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.memberData.currentValue) {
      this.isDefaultBackground = this.memberData.businessUnit !== 'PP' ? true : !MembersConstants.MEMBER_INFO_BAR_TIER_IMAGE_MAPPING[this.memberData.businessUnit][this.memberData.guestTierInfoVo.currentTier];
      this.backgroundImage =
        this.memberData.businessUnit === 'PP'
          ? `url(/assets/images/member-info-bar/${this.memberData.businessUnit}/${MembersConstants.MEMBER_INFO_BAR_TIER_IMAGE_MAPPING[this.memberData.businessUnit][this.memberData.guestTierInfoVo.currentTier]}.png)`
          : 'unset';
    }
  }
}
