import { Routes, Route } from '@angular/router';

import { ConfigShellComponent } from './config-shell.component';
import { MetadataResolver } from '@core/services/application/metadata-resolver.service';

/**
 * Provides helper methods to create routes.
 */
export class ConfigShell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ConfigShellComponent,
      children: routes,
      // Reuse ShellComponent instance when navigating between child views
      data: {
        extraParams: {
          reuse: true,
        },
      },
      resolve: {
        extraParams: MetadataResolver,
      },
      runGuardsAndResolvers: 'always',
    };
  }
}
