import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '@app/@core/services/application/dialog/dialog.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionDialogService extends DialogService {
  constructor(private matDialog: MatDialog, private dialogService: DialogService) {
    super(matDialog);
  }

  showFurtherApprovalDialog(data: any, component: any): Observable<MatDialogRef<any>> {
    const dialogId = 'transaction-further-approval-dialog';
    const dialogData = {
      id: dialogId,
      width: '800px',
      panelClass: 'p-0-container',
      data,
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
    };
    return this.dialogService.showDialog(dialogData, component);
  }

  showVerifyTransactionInfoDialog(data: any, component: any): Observable<MatDialogRef<any>> {
    const dialogId = 'transaction-verify-transaction-info-dialog';
    const dialogData = {
      id: dialogId,
      width: '500px',
      panelClass: 'p-md-container',
      disableClose: true,
      hasBackdrop: false,
      autoFocus: false,
      data,
    };
    return this.dialogService.showDialog(dialogData, component);
  }

  showRejectDialog(data: any, component: any): Observable<MatDialogRef<any>> {
    const dialogId = 'transaction-reject-dialog';
    const dialogData = {
      id: dialogId,
      width: '370px',
      panelClass: '',
      data,
      disableClose: true,
    };
    // this.openDialog(dialogId, dialogData, RejectTransactionDialogComponent, afterCloseFunction);
    return this.dialogService.showDialog(dialogData, component);
  }

  openVoidTransactionDialog(data: any, component: any): Observable<MatDialogRef<any>> {
    const dialogId = 'void-transaction-dialog';
    const dialogData = {
      id: dialogId,
      data,
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
    };
    return this.dialogService.showDialog(dialogData, component);
  }

  /**
   * Verify Transaction Dialog
   */
  openVerifyTransactionDialog(data: any, component: any): Observable<MatDialogRef<any>> {
    const dialogId = 'verify-transaction-dialog';
    const dialogData = {
      id: dialogId,
      width: '450px',
      data,
      disableClose: true,
      hasBackdrop: false,
      autoFocus: false,
      panelClass: 'p-md-container',
    };
    return this.dialogService.showDialog(dialogData, component);
  }

  /** Dialogs Related */
  openDuplicatedReceiptsConfirmationDialog(data: any, component: any): Observable<MatDialogRef<any>> {
    const dialogId = 'duplicated-receipts-confirmation-dialog';
    const dialogData = {
      id: dialogId,
      data,
      width: '700px',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
    };
    return this.dialogService.showDialog(dialogData, component);
  }

  closeDuplicatedReceiptsConfirmationDialog() {
    this.dialogService.closeExistingDialog('duplicated-receipts-confirmation-dialog');
  }

  closeVerifyTransactionInfoDialog() {
    this.dialogService.closeExistingDialog('verify-transaction-dialog');
  }
  closeTransactionVerifyTransactionInfoDialog() {
    this.dialogService.closeExistingDialog('transaction-verify-transaction-info-dialog');
  }
}
