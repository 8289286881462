import { Component, OnInit, AfterContentInit, AfterViewInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { MenuService, SidenavService, LoyaltyService, RequestService } from '@core/services';
import { Router } from '@angular/router';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-admin-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @ViewChildren(MatExpansionPanel) sidenav: QueryList<MatExpansionPanel>;
  @ViewChildren(MatAccordion) accordion: QueryList<MatAccordion>;

  @ViewChildren('menuItems') menuItems: QueryList<MatExpansionPanel>;

  public window = window;

  public forceRouterLinkExactURLs = ['/admin/members/invitation-link', '/admin/receipt-transaction/spotcheck'];

  isMenuOpened = false;
  inputBoxNotification: Observable<any>;
  inputBoxNumberIsLoading = true;
  triggerRetrieveTotalRequest: BehaviorSubject<number>;

  initialViewChecked = false;

  observer: MutationObserver;

  private resizeEvent = new Event('resize');

  constructor(public menuService: MenuService, private sidenavService: SidenavService, private router: Router, public loyaltyService: LoyaltyService, private requestService: RequestService) {}

  ngOnInit(): void {
    this.triggerRetrieveTotalRequest = this.requestService.triggerRetrieveTotalRequest;
  }

  ngAfterContentInit() {
    this.sidenavService.isMenuOpened$.subscribe((isOpen) => {
      this.isMenuOpened = isOpen;
    });
  }

  ngAfterViewInit() {
    this.sidenavService.isMenuOpened$.subscribe((isOpen) => {
      if (!isOpen) {
        this.sidenav.map((panel) => {
          panel.close();
        });
      }
      window.dispatchEvent(this.resizeEvent);
    });

    this.initExpandPanel();
  }

  initExpandPanel() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        let ele = <HTMLElement>mutation.target;
        if (ele.nodeName === 'MAT-EXPANSION-PANEL-HEADER' && ele.classList.contains('is-active')) {
          this.menuItems.forEach((menuItem) => {
            if (menuItem._body.nativeElement.querySelector('.is-active')) {
              if (!menuItem.expanded) {
                setTimeout(() => {
                  menuItem.open();
                }, 100);
              }
            }
          });
        }
      });
      this.observer.disconnect();
    });

    this.observer.observe(document.querySelector('.side-menu'), {
      subtree: true,
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }

  returnRouteLink(event: Event, route: string) {
    event.preventDefault();
    if (!this.isMenuOpened) {
      this.router.navigate([`/admin/${route}`]);
    }
  }

  menuActionOnClick(event: Event, action?: () => void) {
    event.preventDefault();
    if(action) {
      action()
    }
    // action ? action() : null;
  }
}
