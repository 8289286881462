import { Injectable } from '@angular/core';
import { UploadDetail } from '@core/models/application/upload-detail';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/application/api-response';

@Injectable({
  providedIn: 'root',
})
export class S3FileManagementService {
  constructor(private httpClient: HttpClient) {}

  uploadFile(uploadDetail: UploadDetail) {
    const formData = new FormData();

    if (uploadDetail.base64) formData.append('base64', uploadDetail.base64);
    if (uploadDetail.multipartFile) formData.append('multipartFile', uploadDetail.multipartFile);
    if (uploadDetail.businessUnit) formData.append('businessUnit', uploadDetail.businessUnit);
    if (uploadDetail.loyaltyProgram) formData.append('loyaltyProgram', uploadDetail.loyaltyProgram);

    return this.httpClient.post(`/storage/uploadFile/${uploadDetail.type}`, formData).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    );
  }

  deleteFile(params: any) {
    return this.httpClient.delete(`/storage/delete`, { params }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  downloadFile(fileBinary: Blob, fileName: string, mimeType?: string) {
    const binaryData = [];
    binaryData.push(fileBinary);
    const downloadLink = document.createElement('a');
    downloadLink.href = mimeType ? window.URL.createObjectURL(new Blob(binaryData, { type: mimeType })) : window.URL.createObjectURL(new Blob(binaryData));
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
