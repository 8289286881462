import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, SimpleChanges, OnChanges, ViewChild, TemplateRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ImageListItem, Image, ReceiptTransactionService, DialogData, DialogService } from '@core';
import { ReceiptImageService } from '@app/@core/services/receipt-transaction/receipt-image.service';
import { DxGalleryComponent } from 'devextreme-angular';

import { DomSanitizer } from '@angular/platform-browser';
import { PinchZoomComponent } from '@shared/components/pinch-zoom/pinch-zoom.component';

@Component({
  selector: 'receipt-image-thumbnail',
  templateUrl: './receipt-image-thumbnail.component.html',
  styleUrls: ['./receipt-image-thumbnail.component.scss'],
})
export class ReceiptImageThumbnailComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() images: Image[] = [];
  @Input() isLocalImage: boolean = false;
  @Input() isRpt:boolean = false;
  @Output() selectImageEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('imageViewerRef') imageViewerRef: TemplateRef<any>;
  @ViewChild('imageSliderRef') imageSliderRef: DxGalleryComponent;

  @ViewChildren('pinchViewer') pinchViewerRef: QueryList<PinchZoomComponent>;
  imageRotateDeg = 0;
  imageRotateStyle = {};

  transformedImages: ImageListItem[] = [];
  selectedImage: ImageListItem;
  selectedIndex: number;
  constructor(private receiptTransactionService: ReceiptTransactionService, private viewTransactionImageService: ReceiptImageService, private dialogService: DialogService, private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.viewTransactionImageService.selectedImage.subscribe((selectedImage) => {
      this.selectedImage = selectedImage;
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.images) {
      this.transformImagesUrl();
    }
  }

  transformImagesUrl() {
    if (this.images) {
      for (const image of this.images) {
        if (image instanceof File) {
          const objectURL = URL.createObjectURL(image);
          this.transformedImages.push({
            thumbnailUrl: this.domSanitizer.bypassSecurityTrustUrl(objectURL),
            originalUrl: this.domSanitizer.bypassSecurityTrustUrl(objectURL),
            caption: image.caption,
            id: image.id,
          });
        } else {
          this.transformedImages.push({
            thumbnailUrl: this.receiptTransactionService.getReceiptImageUrl(image.id, 'thumbnail'),
            originalUrl: this.receiptTransactionService.getReceiptImageUrl(image.id, 'original'),
            caption: image.caption,
            id: image.id,
          });
        }
      }
    }
  }

  getThumbnailLink(id: string) {
    return this.receiptTransactionService.getReceiptThumbnail(id, 'thumbnail');
  }

  async onSelectThumbnail(index: number) {
    this.selectedIndex = index;
    const dialogData: DialogData = {
      id: 'image-zoom-dialog',
      content: this.imageViewerRef,
      // position: {
      //   left: '0',
      // },
      height: 'auto',
    };
    this.dialogService.showDialog(dialogData).subscribe();
  }

  onCurrentViewChanged() {
    this.resetImageRotation();
  }

  // Image viewer Control
  onClickZoomIn() {
    const currentPinchViewerRef = this.pinchViewerRef.filter((element, index) => index === this.imageSliderRef.selectedIndex)[0];

    if (currentPinchViewerRef) {
      let newScale = currentPinchViewerRef.scale + currentPinchViewerRef.properties.zoomControlScale;
      currentPinchViewerRef.pinchZoom.setZoom({ scale: newScale > currentPinchViewerRef.maxScale ? currentPinchViewerRef.maxScale : newScale });
    }
  }

  onClickZoomOut() {
    const currentPinchViewerRef = this.pinchViewerRef.filter((element, index) => index === this.imageSliderRef.selectedIndex)[0];

    if (currentPinchViewerRef) {
      const newScale = currentPinchViewerRef.scale - currentPinchViewerRef.properties.zoomControlScale;
      currentPinchViewerRef.pinchZoom.setZoom({ scale: newScale > 1 ? newScale : 1 });
    }
  }

  onClickRotateLeft() {
    this.imageRotateDeg -= 90;
    this.imageRotateStyle = { transform: `rotate(${this.imageRotateDeg}deg)` };
  }

  onClickRotateRight() {
    this.imageRotateDeg += 90;
    this.imageRotateStyle = { transform: `rotate(${this.imageRotateDeg}deg)` };
  }

  resetImageRotation() {
    this.imageRotateDeg = 0;
    this.imageRotateStyle = { transform: `rotate(${this.imageRotateDeg}deg)` };
  }
}
