//XXX: To be housekeep
export abstract class MembersConstants {
  // Area
  public static AREA_HONG_KONG = 'HONG_KONG';
  public static AREA_MAINLAND = 'OTHER_PROVINCE_IN_MAINLAND_CHINA';
  public static AREA_ASIA_PACIFIC = 'ASIA_PACIFIC';
  // Phone Code
  public static PHONE_CODE_852 = '+852';
  public static PHONE_CODE_853 = '+853';
  public static PHONE_CODE_86 = '+86';
  public static PHONE_CODE_886 = '+886';
  public static PHONE_CODE_OTHERS = 'Others';

  // Member Status
  public static MEMBER_STATUS_ACTIVATED = 'ACTIVATED';
  public static MEMBER_STATUS_PENDING_ACTIVE = 'PENDING-ACTIVE';
  public static MEMBER_STATUS_TERMINATED = 'TERMINATED';
  public static MEMBER_STATUS_APPROVED = 'APPROVED';
  public static ACTUALIZE_GIFT_FIELD_NAME_MAPPING = {
    name: 'Gift Name',
    expiryDate: 'Expiry Date',
    serialNumber: 'Serial Number',
  };
  public static MEMBER_STATUS_MAPPING = {
    'PENDING-ACTIVE': 'Non-Activated',
    ACTIVATED: 'Activated',
    TERMINATED: 'Terminated',
    INACTIVE: 'Inactivated',
    BACKLISTED: 'Blacklisted',
    VOID: 'Void',
  };

  public static CHANGE_REQUEST_BTN_STYLE = {
    border: 'solid 1px #1b4266',
  };

  // ASSOCIATED Status
  public static ASSOCIATED_STATUS_ACTIVE = 'ACTIVE';
  public static ASSOCIATED_STATUS_SUSPENDED = 'SUSPENDED';
  public static ASSOCIATED_STATUS_TERMINATED = 'TERMINATED';
  public static ASSOCIATED_STATUS_INACTIVE = 'INACTIVE';
  public static BONUS_POINT_CALCULATION_BTN_STYLE = {
    color: '#1b4266',
    border: '1px solid #1b4266',
  };

  //
  public static INVITATION_LINK_INVALID = 'INVALID';
  public static INVITATION_LINK_REGISTERED = 'REGISTERED';
  public static INVITATION_LINK_EXPIRED = 'EXPIRED';
  public static INVITATION_LINK_GENERATED = 'GENERATED';
  public static INVITATION_LINK_STATUS_MAPPING = {
    INVALID: 'Invalid',
    REGISTERED: 'Registered',
    EXPIRED: 'Expired',
    GENERATED: 'Generated',
  };
  public static INVITATION_LINK_REFERRAL_TYPE = {
    REFERRAL_BY_MANAGEMENT: 'REFERRAL_BY_MANAGEMENT',
    REFERRAL_BY_MEMBER: 'REFERRAL_BY_MEMBER',
    REFERRAL_BY_TENANT: 'REFERRAL_BY_TENANT',
    SPL_MANAGEMENT: 'SPL_MANAGEMENT',
  };

  public static MEMBER_INFO_BAR_TIER_IMAGE_MAPPING = {
    PP: {
      PREMEMBER: 'pre-member',
      BLUE: 'blue',
      BLACK: 'black',
      PLATINUM: 'platinum',
      GOLD: 'gold',
    },
  };
  public static CONTACTLESS_CAR_PARK_STATUS = {
    //Enable/Disable/Locked (
    ENABLE: 'ENABLE',
    DISABLE: 'DISABLE',
    LOCKED: 'LOCKED',
  };

  public static PP_SAVE_AND_APPROVE_UPPER_LIMIT = 5000;
  public static CP_SAVE_AND_APPROVE_UPPER_LIMIT: number = null;
  public static CG_SAVE_AND_APPROVE_UPPER_LIMIT = 5000;
}
