import { Component, OnInit, AfterContentInit } from '@angular/core';
import { MenuService, SidenavService } from '@core/services/application';
import { MenuItem } from '@core/models/application/menu-item';

@Component({
  selector: 'app-config-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, AfterContentInit {
  menuItemList: MenuItem[];
  isMenuOpened = false;

  constructor(private menuService: MenuService, private sidenavService: SidenavService) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.sidenavService.isMenuOpened$.subscribe((isOpen: boolean) => {
      this.isMenuOpened = isOpen;
    });
  }
}
