<!-- <div *ngIf="isShowBlock" class="float-right status-content-container" [ngClass]="{ 'success-ocr': isOcrSuccessful, 'failed-ocr': isOcrFailed }">
  <div class="ocr-message">
    {{ ocrStatusMessage }}
    <mat-icon *ngIf="isOcrSuccessful">done</mat-icon>
    <mat-icon *ngIf="isOcrFailed">priority_high</mat-icon>
  </div>
</div> -->
<div *ngIf="isOcrSuccessful || isNoOrA || isOcrFailed" class="float-right ocr-status-content-container" [ngClass]="{ 'success-ocr': isOcrSuccessful, 'failed-ocr': isOcrFailed, 'na-ocr': isNoOrA }">
  <mat-icon id="ocrInfo" class="ocr-icon" *ngIf="!isOcrSuccessful"> info </mat-icon>
  <div class="ocr-message">{{ ocrMessage }}</div>
  <dx-popover target="#ocrInfo" hideEvent="mouseleave" showEvent="click" position="top" [width]="300">
    <div class="popoverTip" *dxTemplate="let data = model; of: 'content'">
      <p class="ocrStatus-popoverTip-title">Remarks:</p>
      <div class="ocrStatus-popoverTip-con">
        <div *ngFor="let item of ocrStatusMessage">
          <i>•</i>
          <p>{{ item }}</p>
        </div>
      </div>
    </div>
  </dx-popover>
</div>
