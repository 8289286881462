import { Component, OnDestroy, OnInit } from '@angular/core';
import { StackViewerService } from '@app/@core/services';

@Component({
  selector: 'app-cs-shell',
  templateUrl: './cs-shell.component.html',
  styleUrls: ['./cs-shell.component.scss'],
})
export class CsShellComponent implements OnInit, OnDestroy {
  isShowStackViewer = false;
  constructor(private stackViewerService: StackViewerService) {
    this.stackViewerService.isShowStackViewer$.subscribe((isShowPanel) => {
      this.isShowStackViewer = isShowPanel;
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {}
}
