import { Component, DoCheck, Input, IterableChanges, IterableDiffer, IterableDiffers, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GuestProfile } from '@core/models/guest/member-profile';

@Component({
  selector: 'request-member-info',
  templateUrl: './request-member-info.component.html',
  styleUrls: ['./request-member-info.component.scss'],
})
export class RequestMemberInfoComponent implements OnInit, DoCheck {
  @Input() memberData: GuestProfile;
  @Input() labels: any[] = [];

  constructor(private _iterableDiffers: IterableDiffers) {}

  ngOnInit(): void {}

  ngDoCheck() {}

  get memberFullNameWithTitle() {
    return `${this.memberData?.salutation} ${this.memberData?.firstName} ${this.memberData?.lastName}`;
  }
}
