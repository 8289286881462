import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { untilDestroyed } from '@core';
import { DropdownDictionary, DropdownOption, Tenant } from '@core/models';
import { DropdownService } from '@core/services/application/generic-category';
import { TenantService } from '@core/services/tenant.service';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-tenants-selector',
  templateUrl: './tenants-selector.component.html',
  styleUrls: ['./tenants-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TenantsSelectorComponent),
      multi: true,
    },
  ],
})
export class TenantsSelectorComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() promotionStartDate: any;
  @Input() promotionEndDate: any;
  @Input() promotionMerchantType: any;

  public tenantSubject = new BehaviorSubject<Tenant[]>([] as Tenant[]);

  public filteredTenantList: Tenant[] = [];
  public selectedTenants: Tenant[] = [];

  public category: string[] = [];
  public subcategory: string[] = [];

  public dropdownData: DropdownDictionary;
  public filteredSubcategoryList: DropdownOption[] = [];

  public touched = false;
  public disabled = false;

  public format = { add: 'Add', remove: 'Remove', all: 'Select All', none: 'Clear' };

  constructor(private tenantService: TenantService, private dropdownService: DropdownService) {}
  ngOnDestroy(): void {}

  onChange = (delta: any) => {};
  onTouched = () => {
    this.touched = true;
  };

  writeValue(obj: any): void {
    const idList = Array.isArray(obj) ? obj : [];
    this.tenantSubject.pipe(untilDestroyed(this)).subscribe((tenantList) => {
      const selectedTenantList = tenantList.filter((tenant) => {
        return idList.includes(tenant.id);
      });

      this.selectedTenants = selectedTenantList;
    });
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    let observable;
    let searchParams = {
      listType: 'ACTIVE'
    };
    switch (this.promotionMerchantType) {
      case 'MERCHANT':
        observable = this.tenantService.getTenants(searchParams).pipe(map((response) => response.content));
        break;
      case 'PARTNER':
        observable = this.tenantService.getPartners(searchParams).pipe(map((response) => response.content));
        break;
      case null:
        observable = this.tenantService.getAllRecords(searchParams);
        break;
    }

    observable.pipe(take(1)).subscribe((res) => {
      let selectedTenantList = res
        .filter((tenant) => {
          if (!this.promotionStartDate && !this.promotionEndDate) return true;

          if (!this.promotionEndDate && tenant.endOn && moment(this.promotionStartDate).isAfter(moment(tenant.endOn))) {
            return false;
          }

          if (this.promotionEndDate && ((tenant.endOn && moment(this.promotionStartDate).isAfter(moment(tenant.endOn))) || moment(this.promotionEndDate).isBefore(moment(tenant.startOn)))) {
            return false;
          }

          return true;
        })
        .sort((a: Tenant, b: Tenant) => {
          return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
        });

      this.tenantSubject.next(selectedTenantList);
      this.filteredTenantList = selectedTenantList;
    });

    this.dropdownService
      .getDropdown()
      .pipe(take(1))
      .subscribe((res: DropdownDictionary) => {
        this.dropdownData = res;
      });
  }

  onCategoryChange(event: MatSelectChange) {
    this.subcategory = [];
    this.filteredSubcategoryList = this.dropdownData.MERCHANT.SUB_CATEGORY.filter((subcategory) => {
      return this.category.indexOf(subcategory.parent) !== -1;
    });
    this.filterTenants();
  }

  onSubcategoryChange(event: MatSelectChange) {
    this.filterTenants();
  }

  filterTenants() {
    this.tenantSubject.subscribe((tenantList) => {
      this.filteredTenantList = tenantList.filter((tenant) => {
        return (this.category.length === 0 || this.category.indexOf(tenant.category) !== -1) && (this.subcategory.length === 0 || this.subcategory.indexOf(tenant.subCategory) !== -1);
      });
    });
  }

  onDestinationChange(event: Tenant[]) {
    const idList = event.map((tenant) => {
      return tenant.id;
    });

    this.onChange(idList);
  }
}
