import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from '@app/@core';
import { LoyaltyService, ShortcutService } from '@core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'c-shortcut-card',
  templateUrl: './shortcut-card.component.html',
  styleUrls: ['./shortcut-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortcutCardComponent implements OnInit, OnDestroy {
  shortcutItems$: Observable<any>;

  constructor(private shortcutService: ShortcutService, private cd: ChangeDetectorRef, private loyaltyService: LoyaltyService) {}

  ngOnInit(): void {
    this.shortcutItems$ = this.loyaltyService.currentProgramme$.pipe(
      untilDestroyed(this),
      map((lp) => {
        return this.shortcutService.getListItems();
      })
    );
    this.cd.markForCheck();
  }

  ngOnDestroy() {}

  onCardClick(action?: () => void): void {
    if(action) {
      action()
    }
  }
}
