export abstract class ChangeRequestConstants {
  public static MOBILE_UPDATE = 'MOBILE_UPDATE';
  public static EMAIL_UPDATE = 'EMAIL_UPDATE';
  public static BIRTHDAY_UPDATE = 'BIRTHDAY_UPDATE';
  public static REDEMPTION_POINT_EXPIRY_DATE_EXTENSION = 'REDEMPTION_POINT_EXPIRY_DATE_EXTENSION';
  public static TIER_EXPIRY_DATE_EXTENSION = 'TIER_EXPIRY_DATE_EXTENSION';
  public static MANUAL_POINT_ADJUSTMENT = 'MANUAL_POINT_ADJUSTMENT';
  public static MEMBER_POINTS = 'MEMBER_POINTS';
  public static MEMBER_INFO = 'MEMBER_INFO';
  public static RECEIPT_APPROVAL = 'RECEIPT_APPROVAL';
  public static OFFICE_WORKER = 'OFFICE_WORKER_APPROVAL';
  public static RECEIPT_VOID = 'RECEIPT_VOID';
  public static MANUAL_TIER_ADJUSTMENT = 'MANUAL_TIER_ADJUSTMENT';
  public static MEMBER_STATUS_UPDATE = 'MEMBER_STATUS_UPDATE';
  public static BLACKLIST_MEMBER = 'BLACKLIST_MEMBER';
  public static UNBLACKLIST_MEMBER = 'UNBLACKLIST_MEMBER';
  public static EXPIRY_LOGIC_QUARTERLY = 'QUARTERLY';
  public static OFFICE_WORKER_MANUAL_VERIFICATION = 'OFFICE_WORKER_MANUAL_VERIFICATION';
  public static OFFICE_WORKER_TERMINATION = 'OFFICE_WORKER_TERMINATION';
  public static REOMVE_CAR_PLATE="REMOVE_CARPLATE";

  public static BLACK_TIER = ['BLACK'];
  public static NON_BLACK_TIER = ['PREMEMBER', 'BLUE', 'GOLD', 'PLATINUM'];
  public static TIER_EXPIRY_DATE_EXTENSION_NON_BL = 'TIER_EXPIRY_DATE_EXTENSION_NON_BL';
  public static TIER_EXPIRY_DATE_EXTENSION_BL = 'TIER_EXPIRY_DATE_EXTENSION_BL';
  public static DAY_OF_MONTH: String[] = [...Array(31).keys()].map((x) => String(++x));
  public static MEMBER_REQUEST_TYPE = 'MEMBER_REQUEST_TYPE';
  public static MONTHS = [
    { label: 'Jan', value: '1' },
    { label: 'Feb', value: '2' },
    { label: 'Mar', value: '3' },
    { label: 'Apr', value: '4' },
    { label: 'May', value: '5' },
    { label: 'Jun', value: '6' },
    { label: 'Jul', value: '7' },
    { label: 'Aug', value: '8' },
    { label: 'Sep', value: '9' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' },
  ];

  public static MEMBER_PROFILE_REQUEST = [
    ChangeRequestConstants.EMAIL_UPDATE,
    ChangeRequestConstants.MOBILE_UPDATE,
    ChangeRequestConstants.BIRTHDAY_UPDATE,
    ChangeRequestConstants.MEMBER_STATUS_UPDATE,
    ChangeRequestConstants.BLACKLIST_MEMBER,
    ChangeRequestConstants.UNBLACKLIST_MEMBER,
    ChangeRequestConstants.OFFICE_WORKER,
    ChangeRequestConstants.OFFICE_WORKER_MANUAL_VERIFICATION,
    ChangeRequestConstants.OFFICE_WORKER_TERMINATION,
  ];
  public static NON_ACTIVATED_MEMBER_REQUEST_TYPE = [
    ChangeRequestConstants.EMAIL_UPDATE,
    ChangeRequestConstants.MOBILE_UPDATE,
    ChangeRequestConstants.BIRTHDAY_UPDATE,
    ChangeRequestConstants.MEMBER_STATUS_UPDATE,
    ChangeRequestConstants.BLACKLIST_MEMBER,
  ];
  public static NON_ACTIVATED = 'MEMBER.LABEL.STATUS_NOT_ACTIVATED';
  public static POINT_TYPE_TIER = 'TIER';
  public static POINT_TYPE_REDEMPTION = 'REDEMPTION';
}
