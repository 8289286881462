<div class="guest-info-box spl-card has-border grey">
  <table aria-describedby="guest transaction information table">
    <tbody>
      <tr>
        <th class="label" width="180px" scope="col">{{ 'MEMBER.LABEL.NAME' | translate }}</th>
        <td>{{ guestData | guestFullName }}</td>
      </tr>
      <tr>
        <th class="label" scope="col">{{ 'MEMBER.LABEL.NAME_ON_CREDIT_CARD' | translate }}</th>
        <td>{{ guestNameOnCreditCard | replaceEmptyValue }}</td>
      </tr>
      <tr>
        <th class="label" scope="col">{{ 'MEMBER.LABEL.CURRENT_TIER' | translate }}</th>
        <td>{{ guestData?.guestTierInfoVo?.currentTierName }}</td>
      </tr>
      <tr>
        <th class="label" scope="col">{{ 'MEMBER.LABEL.MEMBERSHIP_NUMBER' | translate }}</th>
        <td>
          <a [routerLink]="['/admin/members/view', guestData?.id]" (click)="onClickGuestId($event)"> {{ guestData?.id }}</a>
        </td>
      </tr>
      <tr>
        <th class="label" scope="col">{{ 'MEMBER.LABEL.REDEMPTION_POINTS' | translate }}</th>
        <td>
          {{ guestData.guestTierInfoVo.availableRedemptionPoints | number }}
        </td>
      </tr>
      <tr>
        <th class="label" scope="col">{{ 'MEMBER.LABEL.REDEMPTION_POINTS_EXP_DATE' | translate }}</th>
        <td>
          {{ guestData.guestTierInfoVo.redemptionPointExpiryDate | systemDateFormat | replaceEmptyValue }}
        </td>
      </tr>
      <tr *ngIf="transactionData">
        <th class="label" scope="col">{{ 'RECEIPT_TRANSACTIONS.LABEL.RECEIPT_STATUS' | translate }}</th>
        <td>{{ transactionData?.status | receiptTransactionStatus | translate }}</td>
      </tr>
    </tbody>
  </table>
</div>
