import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemDatePipe } from './system-date.pipe';
import { SystemDateTimePipe } from './system-date-time.pipe';
import { SystemCurrencyPipe } from './system-currency.pipe';
import { AbsoluteNumberPipe } from './number/absolute-number.pipe';
import { ReplaceEmptyValuePipe } from './text/replace-empty-value.pipe';
import { GenericCategoryPipe } from './generic-category.pipe';
import { VrmUserPipe } from './vrm-user.pipe';
import { FilterDuplicatedItemPipe } from './filter-duplicated-item.pipe';

@NgModule({
  declarations: [SystemDatePipe, SystemDateTimePipe, SystemCurrencyPipe, AbsoluteNumberPipe, ReplaceEmptyValuePipe, GenericCategoryPipe, VrmUserPipe, FilterDuplicatedItemPipe],
  exports: [SystemDatePipe, SystemDateTimePipe, SystemCurrencyPipe, AbsoluteNumberPipe, ReplaceEmptyValuePipe, GenericCategoryPipe, VrmUserPipe, FilterDuplicatedItemPipe],
  imports: [CommonModule],
})
export class SystemPipeModule {}
