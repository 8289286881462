import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'receipt-transaction-associated-member-picker',
  templateUrl: './associated-member-picker.component.html',
  styleUrls: ['./associated-member-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociatedMemberPickerComponent implements OnInit {
  @Input() dataSource: Array<any>;
  @Input() selectedIndex: number;
  @Input() transactionIndex: number;
  @Input() disabled = false;
  @Input() isFromCsUpload = false;

  @Output() onChange: EventEmitter<any>;

  constructor(private cd: ChangeDetectorRef) {
    this.onChange = new EventEmitter<any>();
  }

  ngOnInit(): void {}

  onClick(e: any) {
    e.stopPropagation();
    if (this.dataSource.length < 2 || this.disabled) {
      return;
    }
    this.selectedIndex = this.selectedIndex === 0 ? 1 : 0;
    this.onChange.emit({
      transactionIndex: this.transactionIndex,
      selectedIndex: this.selectedIndex,
    });
    this.cd.markForCheck();
  }
}
