import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DepositTransactionDialogData, DialogData, DialogService, DropdownDictionary, ReceiptTransaction, Tenant, untilDestroyed } from '@app/@core';
import { Subject } from 'rxjs';
import { LinkDepositTransactionDialogComponent } from '../dialogs/link-deposit-transaction-dialog/link-deposit-transaction-dialog.component';

@Component({
  selector: 'related-deposit-transaction',
  templateUrl: './related-deposit-transaction.component.html',
  styleUrls: ['./related-deposit-transaction.component.scss'],
})
export class RelatedDepositTransactionComponent implements OnInit, ControlValueAccessor {
  relatedDepositTransactionList: ReceiptTransaction[] | null;
  stateChanges = new Subject<void>();
  @Input() tenantId: string;
  @Input() id: string;
  @Input() dropdownData: DropdownDictionary;
  @Input() readonly = false;
  @Input() guestId: string;
  @Input() linkedPickupSlipTransactionId: string;
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;
  private _required = false;

  @Input()
  get value(): ReceiptTransaction[] | null {
    return this.relatedDepositTransactionList;
  }
  set value(transactions: ReceiptTransaction[] | null) {
    let updatedTransactions: ReceiptTransaction[] = [];
    if (transactions !== null) {
      updatedTransactions = transactions.map((transaction) => {
        const updatedTransaction = { ...transaction };
        if (typeof transaction.invoiceNumbers === 'object' && transaction.invoiceNumbers !== null) {
          updatedTransaction.invoiceNumbers = (updatedTransaction.invoiceNumbers as any[]).map((invoiceNumberObj) => {
            return invoiceNumberObj.invoiceNumber;
          });
        }
        updatedTransaction.createdByDisplay = updatedTransaction.createdBy === updatedTransaction.uploadedBy ? `${updatedTransaction.createdBy}` : `${updatedTransaction.uploadedBy} (${updatedTransaction.createdBy})`;
        return updatedTransaction;
      });
    } else {
      updatedTransactions = transactions;
    }

    this.relatedDepositTransactionList = updatedTransactions;
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  get isAllowRemoveDeposit(): boolean {
    return !this.disabled && !this.readonly;
  }
  constructor(@Optional() @Self() public ngControl: NgControl, private dialogService: DialogService) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange = (_: any) => {};
  onTouched = () => {};
  writeValue(value: any[] | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onOpenLinkedDepositTransactionDialog() {
    const data: DepositTransactionDialogData = {
      selectedList: this.relatedDepositTransactionList || [],
      tenantId: this.tenantId,
      dropdownData: this.dropdownData,
      guestId: this.guestId,
    };
    if (this.linkedPickupSlipTransactionId) data.linkedPickupSlipTransactionId = this.linkedPickupSlipTransactionId;
    const dialogData = {
      id: 'link-related-transaction',
      width: '1258px',
      maxWidth: '90vw',
      maxHeight: '823.67px',
      panelClass: 'outer-dialog-container',
      data,
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
    };
    this.dialogService
      .showDialog(dialogData, LinkDepositTransactionDialogComponent)
      .pipe(untilDestroyed(this))
      .subscribe((dialogRef) => {
        dialogRef.afterClosed().subscribe((data) => {
          if (data) {
            this.relatedDepositTransactionList = [...data];
            this.onChange(this.relatedDepositTransactionList);
          }
        });
      });
  }

  calculateExpired(rowData: any) {
    return {
      createdDate: rowData.createdDate,
      soonToBeExpired: rowData.soonToBeExpired,
      overdueTransaction: rowData.overdueTransaction,
    };
  }

  onRemoveDepositClick(e: any) {
    this.relatedDepositTransactionList = this.relatedDepositTransactionList.filter((transaction) => transaction.id !== e.data.id);
    this.onChange(this.relatedDepositTransactionList);
  }
}
