<!-- <div *ngIf="data.closeBtn" class="close-button">
  <button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div> -->
<div *ngIf="data.closeBtn" class="close-button">
  <span (click)="onCloseButtonClick()">
    <mat-icon>close</mat-icon>
  </span>
</div>
<div class="dialog-inner-container px-md" [ngClass]="{ 'pt-4': !data.closeBtn }">
  <h2 *ngIf="data.title" mat-dialog-title>{{ data.title | translate }}</h2>

  <div *ngIf="data.content && !isShowContentTpl" mat-dialog-content innerHTML="{{ stringContent | translate : data.contentParam }}"></div>

  <div *ngIf="data.content && isShowContentTpl" mat-dialog-content>
    <ng-container *ngTemplateOutlet="templateRefContent"></ng-container>
  </div>

  <!-- TODO: Move to dynamic button rendering -->
  <div mat-dialog-actions class="action-wrapper" [ngClass]="{ 'form-overlay': isLoading }">
    <span class="spinner-container-span" *ngIf="isLoading">
      <mat-spinner mode="indeterminate" diameter="25"></mat-spinner>
    </span>

    <button *ngIf="data.buttonOneLabelIcon" mat-flat-button color="primary" class="label-uppercase iconButton" (click)="data.buttonOneCallback(dialogRef)">
      <mat-icon style="font-size: 18px; margin-top: 4px">{{ data.buttonOneLabelIcon.icon }} </mat-icon> {{ data.buttonOneLabelIcon.text | translate }}
    </button>

    <button *ngIf="data.buttonOneLabel" mat-flat-button color="primary" class="label-uppercase" (click)="data.buttonOneCallback(dialogRef)">
      {{ data.buttonOneLabel | translate }}
    </button>
    <button *ngIf="data.primaryButtonLabel" mat-flat-button color="primary" class="label-uppercase" (click)="data.primaryButtonCallback(dialogRef)">
      {{ data.primaryButtonLabel | translate }}
    </button>
    <button *ngIf="data.highlightedButtonLabel" mat-flat-button class="label-uppercase highlighted-button" (click)="data.highlightedButtonTwoCallback(dialogRef)" cdkFocusInitial>
      {{ data.highlightedButtonLabel | translate }}
    </button>
    <button *ngIf="data.buttonTwoLabel" mat-stroked-button color="primary" class="label-uppercase stroke-button" (click)="data.buttonTwoCallback(dialogRef)" cdkFocusInitial>{{ data.buttonTwoLabel | translate }}</button>
    <button *ngIf="data.strokedButtonLabel" mat-stroked-button color="primary" class="label-uppercase stroke-button" (click)="data.strokedButtonCallback(dialogRef)" cdkFocusInitial>
      {{ data.strokedButtonLabel | translate }}
    </button>
    <button *ngIf="data.strokedButtonTwoLabel" mat-stroked-button color="primary" class="label-uppercase stroke-button" (click)="data.strokedButtonTwoCallback(dialogRef)" cdkFocusInitial>
      {{ data.strokedButtonTwoLabel | translate }}
    </button>

    <button *ngIf="data.strokedButtonThreeLabel" mat-stroked-button color="primary" class="label-uppercase stroke-button" (click)="data.strokedButtonThreeCallback(dialogRef)" cdkFocusInitial>
      {{ data.strokedButtonThreeLabel | translate }}
    </button>
  </div>
</div>
