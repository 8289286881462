import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[invalidInput]',
})
export class InvalidInputDirective {
  @Input() invalidInput = false;
  @HostBinding('class.required-input-invalid') isInvalid: boolean;

  constructor() {}

  ngOnInit() {
    this.isInvalid = this.invalidInput;
  }

  ngOnChanges() {
    this.isInvalid = this.invalidInput;
  }
}
