import { Observable, of } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { DropdownService } from '@core/services/application/generic-category';
import { keyBy } from 'lodash';

@Pipe({
  name: 'transactionStatusPipe',
})
export class TransactionStatusPipe implements PipeTransform {
  constructor(private dropdownService: DropdownService) {}

  transform(status:any){
     let dataStatus = status
     this.dropdownService.getDropdown().subscribe((res:any) => {
      const data = keyBy(res?.RECEIPT_TRANSACTION.TRANSACTION_STATUS,'value') 
      dataStatus =  data[status]?.label
     })
     return dataStatus ?? status
  }
}
