<div class="side-menu">
  <div *ngFor="let item of menuService.adminMenu$ | async">
    <ul [style.marginBottom.px]="!isMenuOpened ? 0 : null">
      <div *ngIf="isMenuOpened && item.sectionLabelId" class="parent-title">
        <span translate>
          {{ item.sectionLabelId | dynamicBreadcrumb }}
        </span>
      </div>
      <mat-accordion>
        <ng-container *ngFor="let menuItem of item.children">
          <ng-container *ngTemplateOutlet="!isMenuOpened ? linkedSubMenuTmpl : subMenuTmpl"></ng-container>

          <ng-template #linkedSubMenuTmpl>
            <a [routerLink]="menuItem.children && menuItem.children[0] ? menuItem.children[0].route : menuItem.route" (click)="$event.preventDefault()">
              <ng-container *ngTemplateOutlet="subMenuTmpl"></ng-container>
            </a>
          </ng-template>

          <ng-template #subMenuTmpl>
            <ng-container *ngTemplateOutlet="menuItem.children && menuItem.children.length > 0 ? expansionPanelTmpl : buttonTmpl"></ng-container>
          </ng-template>

          <ng-template #expansionPanelTmpl>
            <mat-expansion-panel #menuItems [class.large-menu-item]="!isMenuOpened" [hideToggle]="!isMenuOpened" [disabled]="!isMenuOpened">
              <mat-expansion-panel-header
                [routerLinkActive]="['is-active']"
                [routerLinkActiveOptions]="{ exact: false }"
                [class.panel-expand]="isMenuOpened"
                [class.panel-collapsed]="!isMenuOpened"
                [class.admin-panel-expand-arrow]="menuItems.expanded"
                [class.admin-panel-collapsed-arrow]="!menuItems.expanded"
              >
                <mat-panel-title [class.collapsed-large-icon]="!isMenuOpened">
                  <!-- Temp Solution for skip navigation -->
                  <a class="parent-menu" (click)="returnRouteLink($event, menuItem.route[0])">
                    <span *ngFor="let hiddenMenuItem of menuItem.route" style="display: none" [routerLink]="hiddenMenuItem"></span>
                    <span class="material-icons" [class.large-icon]="!isMenuOpened">
                      {{ menuItem.iconName }}
                    </span>
                    <span *ngIf="isMenuOpened" class="menu-title" translate>
                      {{ menuItem.labelId | dynamicBreadcrumb }}
                    </span>
                  </a>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul *ngIf="isMenuOpened && menuItem.children && menuItem.children.length > 0" class="sub-menu">
                <li
                  *ngFor="let subMenuItem of menuItem.children"
                  [routerLinkActive]="subMenuItem.route ? ['is-active'] : ''"
                  [routerLinkActiveOptions]="{ exact: forceRouterLinkExactURLs.includes(window.location.pathname) }"
                >
                  <a [ngClass]="{'is-a-active':(window.location.pathname === '/admin/' + subMenuItem.route)}" [routerLink]="subMenuItem.route ? subMenuItem.route : '#'" (click)="menuActionOnClick($event, subMenuItem.action)">
                    <span *ngIf="subMenuItem.iconName && subMenuItem.iconName !== ''" class="material-icons" [class.large-icon]="!isMenuOpened">
                      {{ subMenuItem.iconName }}
                    </span>
                    <span class="menu-title" translate>
                      {{ subMenuItem.labelId | dynamicBreadcrumb }}
                    </span>
                  </a>
                </li>
              </ul>
            </mat-expansion-panel>
          </ng-template>

          <ng-template #buttonTmpl>
            <a [routerLink]="menuItem.route" (click)="$event.preventDefault()">
              <mat-expansion-panel [disabled]="true" [hideToggle]="true" [class.large-menu-item]="!isMenuOpened">
                <mat-expansion-panel-header [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{ exact: false }" [class.panel-expand]="isMenuOpened" [class.panel-collapsed]="!isMenuOpened">
                  <mat-panel-title [class.collapsed-large-icon]="!isMenuOpened">
                    <span class="material-icons" [class.large-icon]="!isMenuOpened">
                      {{ menuItem.iconName }}
                    </span>
                    <span *ngIf="isMenuOpened" class="menu-title" translate>
                      {{ menuItem.labelId | dynamicBreadcrumb }}
                    </span>
                    <div class="badge" [class.little-badge]="!isMenuOpened" *ngIf="menuItem.isBadgeVisible && (triggerRetrieveTotalRequest | async) > 0">
                      <span class="badge-number">{{ triggerRetrieveTotalRequest | async }}</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
            </a>
          </ng-template>
        </ng-container>
      </mat-accordion>
    </ul>
  </div>
</div>
