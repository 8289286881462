import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { isValidJSON } from '@app/@core/utils/object/is-valid-json';
import { CurrencyMaskConstants } from '@app/@shared/masks/currency.mask';
import { Subject } from 'rxjs';

@Component({
  selector: 'receipt-amount-range-input',
  templateUrl: './receipt-amount-range-input.component.html',
  styleUrls: ['./receipt-amount-range-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ReceiptAmountRangeInputComponent }],
  // host: {
  //   '[class.example-floating]': 'shouldLabelFloat',
  //   '[id]': 'id',
  // }
})
export class ReceiptAmountRangeInputComponent implements ControlValueAccessor, MatFormFieldControl<ReceiptAmountRange>, OnDestroy {
  static nextId = 0;

  static ngAcceptInputType_disabled: BooleanInput;
  static ngAcceptInputType_required: BooleanInput;

  @ViewChild('receiptAmountFrom') receiptAmountFrom: HTMLInputElement;
  @ViewChild('receiptAmountTo') receiptAmountTo: HTMLInputElement;

  // HKD Currency Mask
  HKD_MASK = CurrencyMaskConstants.HKD;

  receiptAmountRangeForm: UntypedFormGroup;
  stateChanges = new Subject<void>();
  focused = false;

  id = `receipt-amount-range-input-${ReceiptAmountRangeInputComponent.nextId++}`;

  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const {
      value: { receiptAmountFrom, receiptAmountTo },
    } = this.receiptAmountRangeForm;

    return !receiptAmountFrom && !receiptAmountTo;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.receiptAmountRangeForm.disable() : this.receiptAmountRangeForm.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input() get value(): ReceiptAmountRange | null {
    if (this.receiptAmountRangeForm.valid) {
      const {
        value: { receiptAmountFrom, receiptAmountTo },
      } = this.receiptAmountRangeForm;
      return new ReceiptAmountRange(receiptAmountFrom, receiptAmountTo);
    }
    return null;
  }

  set value(value: ReceiptAmountRange | null) {
    const { receiptAmountFrom, receiptAmountTo } = value || new ReceiptAmountRange('', '');
    this.receiptAmountRangeForm.setValue({ receiptAmountFrom, receiptAmountTo });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.receiptAmountRangeForm.invalid && this.receiptAmountRangeForm.dirty;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    formBuilder: UntypedFormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.receiptAmountRangeForm = formBuilder.group({
      receiptAmountFrom: [null],
      receiptAmountTo: [null],
    });

    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.transaction-amount-range-input-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    // if (this.parts.controls.subscriber.valid) {
    //   this._focusMonitor.focusVia(this.subscriberInput, 'program');
    // } else if (this.parts.controls.exchange.valid) {
    //   this._focusMonitor.focusVia(this.subscriberInput, 'program');
    // } else if (this.parts.controls.area.valid) {
    //   this._focusMonitor.focusVia(this.exchangeInput, 'program');
    // } else {
    //   this._focusMonitor.focusVia(this.areaInput, 'program');
    // }
  }

  writeValue(value: any): void {
    if (isValidJSON(value)) {
      this.value = JSON.parse(value);
    } else {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    // this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
    // this.stateChanges.next();
  }
}

/** Data structure for holding telephone number. */
export class ReceiptAmountRange {
  constructor(public receiptAmountFrom: any, public receiptAmountTo: any) {}
}
