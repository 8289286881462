import { Component, OnInit } from '@angular/core';
import { UserService } from '@core/services/auth/user.service';

@Component({
  selector: 'app-cs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser: any = {} as any;
  username: string;

  constructor(private userService: UserService) {}

  ngOnInit() {
    Object.assign(this.currentUser, this.userService.currentUserInfo);
    this.username = this.currentUser.given_name ? `${this.currentUser.given_name || ''} ${this.currentUser.family_name || ''}` : '';
  }

  onClickLogout() {
    this.userService.manualLogout();
  }
}
