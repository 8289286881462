import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Role, userAccessRequest, User, CheckEmailResponse } from '@core/models/user-role';
import { HttpClient } from '@angular/common/http';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { ApiResponse } from '@core/models/application/api-response';
import { PaginatedResponse } from '@app/@core';

@Injectable({
  providedIn: 'root',
})

/**
 *
 */
export class UserRoleService {
  public user: Observable<User> = null;
  public users: Observable<User[]> = null;
  public roles: Observable<Role[]> = null;
  public userAccess: Observable<any> = null;
  public roleAccessList: Observable<Role[]> = null;
  public roleAccess: Observable<Role> = null;
  public checkUserResponse: { [email: string]: Observable<CheckEmailResponse> } = {};
  public managementReferral: Observable<any[]>;

  constructor(private httpClient: HttpClient) {}

  getUsers(params?: any) {
    this.users = this.httpClient.get('/userAccess/users', { params }).pipe(
      map((res: any) => {
        res.data.content.forEach((d: any) => {
          d.status === 'ACTIVE' ? (d.isActive = true) : (d.isActive = false);
        });
        return res.data;
      })
    ) as Observable<User[]>;
    return this.users;
  }

  getUser(params?: any) {
    this.user = this.httpClient.get('/userAccess/user', { params }).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<User>;
    return this.user;
  }

  getRoles(params?: any) {
    this.roles = this.httpClient.get('/userAccess/roles', { params }).pipe(
      map((res: any) => {
        res.data.content.forEach((d: any) => {
          d.status === 'ACTIVE' ? (d.isActive = true) : (d.isActive = false);
        });
        return res.data;
      })
    ) as Observable<Role[]>;
    return this.roles;
  }

  updateUserAccess(userAccess: userAccessRequest) {
    this.userAccess = this.httpClient.put('/userAccess/userRole', userAccess).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<any>;
    return this.userAccess;
  }

  createUserAccess(userAccess: userAccessRequest) {
    this.userAccess = this.httpClient.post('/userAccess/userRole', userAccess).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<any>;
    return this.userAccess;
  }

  getRoleAccessList(params?: any) {
    this.roleAccessList = this.httpClient.get('/userAccess/permissions', { params }).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<Role[]>;
    return this.roleAccessList;
  }

  createRoleAccess(params?: any) {
    this.roleAccess = this.httpClient.post('/userAccess/role', params).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<Role>;
    return this.roleAccess;
  }

  updateRoleAccess(params?: any) {
    this.roleAccess = this.httpClient.put('/userAccess/role', params).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<Role>;
    return this.roleAccess;
  }

  deactivateRoleAccess(params?: any) {
    return this.httpClient.put(`/userAccess/deactivateRole?id=${params}`, {}).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<Role>;
  }

  getRoleAccessById(params?: any) {
    this.userAccess = this.httpClient.get('/userAccess/roleAccesses', { params }).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<any>;
    return this.userAccess;
  }

  getRoleById(params?: any) {
    return this.httpClient.get(`/userAccess/role?id=${params}`).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<Role>;
  }

  deactivateUser(params?: any) {
    return this.httpClient.put(`/userAccess/deactivateUser?id=${params}`, {}).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<User>;
  }

  activateUser(params?: any) {
    return this.httpClient.put(`/userAccess/activateUser?id=${params}`, {}).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<User>;
  }

  activateRoleAccess(params?: any) {
    return this.httpClient.put(`/userAccess/activateRole?id=${params}`, {}).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<User>;
  }

  searchUser(params?: any) {
    return this.httpClient.get(`/userAccess/users/search`, { params }).pipe(
      map((res: any) => {
        res.data.content.forEach((d: any) => {
          d.status === 'ACTIVE' ? (d.isActive = true) : (d.isActive = false);
        });
        return res.data;
      })
    ) as Observable<PaginatedResponse<User[]>>;
  }

  findUserInfoByEmail(emailAddress: string) {
    if (!this.checkUserResponse[emailAddress]) {
      this.checkUserResponse[emailAddress] = this.httpClient.get(`/cognito/user?email=${emailAddress}`).pipe(
        map((res: any) => {
          return res.data;
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.checkUserResponse[emailAddress];
  }

  searchUsersByRole(bu: string, lp: string, role: string, searchFields?: Array<string>, searchValue?: string, params?: any) {
    return this.httpClient.get(`/userAccess/users/searchByRole?bu=${bu}&lp=${lp}&role=${role}&searchFields=${searchFields || ''}&searchValue=${searchValue || ''}`, { params }).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<User[]>;
  }

  listUserByRole(role: string, params?: any) {
    return this.httpClient.get(`/userAccess/users/listByRole?role=${role}`, { params }).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<User[]>;
  }

  assignUserToRole(params: any) {
    return this.httpClient.put('/userAccess/assignUsers', params).pipe(
      map((res: any) => {
        const data = res.data;
        data.message = res.message;
        return data;
      })
    ) as Observable<User>;
  }

  revokeUserRole(bu: string, lp: string, role: string, user: string) {
    return this.httpClient.put(`/userAccess/revokeUserRole?bu=${bu}&lp=${lp}&role=${role}&user=${user}`, {}).pipe(
      map((res: any) => {
        return res.data;
      })
    ) as Observable<Role>;
  }

  getVrmUserList(isShowAllUser: boolean = false): Observable<any> {
    const status = isShowAllUser ? null : 'ACTIVE';
    return this.httpClient.get(`/userAccess/vrm?status=${status}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getManagementReferral(): Observable<any> {
    return this.httpClient.get(`/userAccess/managementReferral?status=ACTIVE`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      }),
      publishReplay(1),
      refCount()
    );
  }
}
