import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData, DialogService, DropdownDictionary, DropdownService, ReceiptTransactionService, untilDestroyed, VoidTransactionRequest } from '@app/@core';
import { RedemptionDetailComponent } from '@app/pages/members/view-member/rewards-and-gifts/redemption-activities/redemption-detail/redemption-detail.component';

@Component({
  selector: 'app-void-transaction-dialog',
  templateUrl: './void-transaction-dialog.component.html',
  styleUrls: ['./void-transaction-dialog.component.scss'],
})
export class VoidTransactionDialogComponent implements OnInit, OnDestroy {
  static REQUEST_REASON = 'RECEIPT_VOID';
  voidReasonsList: any[];
  voidRequestForm: UntypedFormGroup;

  public isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { guestId: string; receiptTransactionId: string },
    public dialogRef: MatDialogRef<RedemptionDetailComponent>,
    private receiptTransactionService: ReceiptTransactionService,
    private dropdownService: DropdownService,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.dropdownService
      .getDropdown()
      .pipe(untilDestroyed(this))
      .subscribe((dropdownData) => {
        this.voidReasonsList = dropdownData?.WORKFLOW.REQUEST_REASON.filter((option) => option.parent === VoidTransactionDialogComponent.REQUEST_REASON);
      });
  }

  ngOnDestroy(): void {}

  initForm() {
    this.voidRequestForm = this.fb.group({
      reason: [null, Validators.required],
      remark: [null, Validators.required],
    });
  }

  onCancelClick() {}

  onConfirmClick() {
    if (this.voidRequestForm.valid) {
      this.isLoading = true;

      const voidTransactionRequest: VoidTransactionRequest = {
        guestId: this.dialogData.guestId,
        reason: this.voidRequestForm.get('reason').value,
        receiptTransactionId: this.dialogData.receiptTransactionId,
        remark: this.voidRequestForm.get('remark').value,
      };

      this.receiptTransactionService
        .voidReceiptTransactionRequest(voidTransactionRequest)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => this.dialogRef.close({ isVoided: true }),
          error: () => (this.isLoading = false),
          complete: () => (this.isLoading = false),
        });
    } else {
      this.showFormErrors();
    }
  }

  // TODO: Move to common service
  private showFormErrors() {
    const errorObj = {};
    const errorFields = Object.keys(this.voidRequestForm.controls)
      .map((key) => {
        const controlErrors: ValidationErrors = this.voidRequestForm.get(key).errors;
        if (controlErrors != null) {
          if (Object.keys(controlErrors).includes('required')) {
            const fieldLabel = key === 'reason' ? 'Void Reason' : 'Remarks'; // Dirty
            const errorLabel = 'required';
            return `${fieldLabel} ${errorLabel}`;
          }
        }
      })
      .filter((x) => x);
    if (errorFields.length > 0) {
      errorObj['COMMON.MESSAGE.INVALID_FIELD'] = errorFields;
      const dialogData: DialogData = {
        title: 'Missing field!',
        errorList: errorObj,
        width: '370px',
        yesCallback: (dialogRef) => {
          dialogRef.close();
        },
      };
      this.dialogService.showErrorDialog(dialogData).subscribe();
    }
  }
}
