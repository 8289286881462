import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-select-all-form-field',
  templateUrl: './select-all-form-field.component.html',
  styleUrls: ['./select-all-form-field.component.scss'],
})
export class SelectAllFormFieldComponent extends FieldType implements OnInit {
  ngOnInit() {}
}
