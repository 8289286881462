import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GuestProfile, ApiResponse, PointAdjustmentRequest, GetPointHistoryRequest, InstantBonusPointRequest, RedemptionPointCycle, TierMovementHistory } from '@core/models';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '@core/services/application/dialog';

@Injectable({
  providedIn: 'root',
})
export class PointService {
  constructor(private httpClient: HttpClient, private dialogService: DialogService) {}

  // submit instant bonus point request
  earnInstantBonusPoint(formattedInstantBonusPointRequest: InstantBonusPointRequest) {
    return this.httpClient.post(`/campaign/promotion/bonusPointReward/instantBonusPoint`, formattedInstantBonusPointRequest).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  // submit manual-point-adjustment request
  createManualPointAdjustment(pointAdjustmentRequest?: PointAdjustmentRequest): Observable<any> {
    return this.httpClient.post(`/point/manualPointAdjustment/REDEMPTION`, pointAdjustmentRequest).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  // retrieve redemption-point's point history
  getPointHistory(getPointHistoryRequest: GetPointHistoryRequest): Observable<any> {
    // manually add type: REDEMPTION
    return this.httpClient.get(`/point/transactionHistory/redemption`, { params: { ...getPointHistoryRequest, type: 'REDEMPTION' } }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getRedemptionPointCycle(params?: any): Observable<RedemptionPointCycle[]> {
    return this.httpClient.get('/point/cycleHistory/redemption', { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getPointConversionBalance(params?: any): Observable<RedemptionPointCycle[]> {
    return this.httpClient.get('/campaign/promotion/redemption/pointConversion/balance', { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getTierMovementHistory(params?: any): Observable<TierMovementHistory[]> {
    return this.httpClient.get('/point/transactionHistory/tier', { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getMallDollarConversionDetails(guestId: string, pointTransactionId: number) {
    return this.httpClient.get('/campaign/promotion/redemption/mallDollar/transaction', { params: { guestId: guestId, pointTransactionId: pointTransactionId } }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  voidMallDollarConversion(params?: any) {
    return this.httpClient.post(`/campaign/promotion/redemption/mallDollar/transaction/void`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getMallDollarHistory(pointTransactionId: number) {
    return this.httpClient.get('/campaign/promotion/redemption/mallDollar/history', { params: { pointTransactionId: pointTransactionId } }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  showBonusPointCalculation(memberId: string, component: any) {
    const dialogData = {
      id: 'mat-bonus-pt-calculation',
      width: '800px',
      panelClass: 'p-0-container',
      data: { guestId: memberId },
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
    };
    return this.dialogService.showDialog(dialogData, component);
  }

  eligibleConversion(params:any) {
    return this.httpClient.get('/campaign/promotion/redemption/mallDollar/eligibleConversion', { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  actualizeMallDollar(params:any) {
    return this.httpClient.post(`/campaign/promotion/redemption/mallDollar/actualize`, params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    );
  }


}
