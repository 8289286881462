import { AfterContentInit, AfterViewInit, Component, ContentChildren, Directive, Input, OnDestroy, OnInit, QueryList, TemplateRef } from '@angular/core';
import { untilDestroyed } from '@app/@core';
import { DialogInfoDisplayCellDirective } from '@app/@shared/directives/application';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-info-display',
  templateUrl: './dialog-info-display.component.html',
  styleUrls: ['./dialog-info-display.component.scss'],
})
export class DialogInfoDisplayComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() noOfColumn = 3;
  @Input() labelWidth = this.noOfColumn === 2 ? 5 : 6;
  @Input() valueWidth = this.noOfColumn === 2 ? 7 : 6;
  noOfRow = 0;
  @ContentChildren(DialogInfoDisplayCellDirective, { read: TemplateRef }) templates: QueryList<any>;

  transformedTmpls: TemplateRef<any>[][] = [];
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngAfterContentInit() {
    this.templates.changes.pipe(startWith(this.templates), untilDestroyed(this)).subscribe((templates) => {
      this.noOfRow = Math.floor(templates.length / this.noOfColumn);
      this.transformDisplayDataByRow();
    });
  }

  transformDisplayDataByRow() {
    this.transformedTmpls = [];
    this.templates.toArray().forEach((template, index) => {
      index % this.noOfColumn === 0 || index === 0 ? this.transformedTmpls.push([template]) : this.transformedTmpls[this.transformedTmpls.length - 1].push(template);
    });
  }
}
