<div role="group" [formGroup]="transactionAmountRangeForm" class="transaction-amount-range-input-container" [attr.aria-labelledby]="_formField?.getLabelId()">
  <input
    class="transaction-amount-input"
    formControlName="transactionAmountFrom"
    (keyup)="_handleInput(transactionAmountRangeForm.controls.transactionAmountFrom)"
    [imask]="HKD_MASK"
    [unmask]="true"
    aria-label="Transaction Amount From"
    placeholder="From"
    #transactionAmountFrom
  />
  <span class="transaction-amount-input-spacer">&ndash;</span>
  <input
    class="transaction-amount-input text-middle"
    formControlName="transactionAmountTo"
    (keyup)="_handleInput(transactionAmountRangeForm.controls.transactionAmountTo)"
    [imask]="HKD_MASK"
    [unmask]="true"
    aria-label="Transaction Amount To"
    placeholder="To"
    #transactionAmountTo
  />
</div>
