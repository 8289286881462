import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
/**
 * deepTrim the body every post request
 */
@Injectable({
  providedIn: 'root',
})
export class ApiDateTimezoneInterceptor implements HttpInterceptor {
  public DateTime = DateTime;
  // deepTrim function
  castTimezone(object: any) {
    if (typeof object === 'string') {
      if (Number.isNaN(Number(object)) && this.DateTime.fromISO(object).toFormat("yyyy-MM-dd'T'HH:mm:ssZZ") === object) {
        // we only handle date time string
        return this.DateTime.fromISO(object).setZone('Asia/Hong_Kong', { keepLocalTime: true }).toFormat("yyyy-MM-dd'T'HH:mm:ssZZ");
      }
      return object;
    } else if (typeof object === 'object') {
      for (var key in object) {
        object[key] = this.castTimezone(object[key]);
      }
    }
    return object;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * for post handling
     */
    if (request.body !== null && (request.method === 'POST' || request.method === 'PUT')) {
      // replace body
      request.clone({ body: this.castTimezone(request.body) });
    }

    return next.handle(request);
  }
}
