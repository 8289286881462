<p mat-dialog-title>
  {{ 'RECEIPT_TRANSACTIONS.VOID_TRANSACTION.LABEL.HELPER_TEXT' | translate }}
</p>
<div mat-dialog-content>
  <form [formGroup]="voidRequestForm">
    <div class="row">
      <!-- Void Reason -->
      <div class="col-12">
        <mat-form-field class="full-width">
          <mat-label>
            {{ 'RECEIPT_TRANSACTIONS.VOID_TRANSACTION.LABEL.REASON' | translate }}
          </mat-label>
          <mat-select formControlName="reason" [placeholder]="'Please select void reason'">
            <mat-option *ngFor="let reason of voidReasonsList" [value]="reason.value">
              {{ reason.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <!-- Remarks -->
      <div class="col-12">
        <mat-form-field class="full-width">
          <mat-label>
            {{ 'RECEIPT_TRANSACTIONS.VOID_TRANSACTION.LABEL.REMARKS' | translate }}
          </mat-label>
          <!-- <input matInput name="remark" autocomplete="off" formControlName="remark" [placeholder]="'Input remarks'" /> -->
          <textarea rows="5" matInput name="remark" autocomplete="off" formControlName="remark" [placeholder]="'Input remarks'"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <c-form-button mat-dialog-close color="basic" class="grey" [label]="'COMMON.LABEL.CANCEL' | translate" [upperCaseLabel]="true" type=""></c-form-button>
  <c-form-button color="primary" [isLoading]="isLoading" [label]="'COMMON.LABEL.CONFIRM' | translate" (click)="onConfirmClick()" [upperCaseLabel]="true"></c-form-button>
</div>
