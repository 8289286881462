import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LoyaltyService } from '@core';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private loyaltyService: LoyaltyService) {}

  getFeatureFlag(featureName: string): boolean {
    let bu = this.loyaltyService.currentProgramme?.businessUnit;
    if (!environment.FEATURE_FLAGS.hasOwnProperty(featureName)) return false;
    if (!environment.FEATURE_FLAGS[featureName].hasOwnProperty(bu)) return false;
    return environment.FEATURE_FLAGS[featureName][bu];
  }
}
