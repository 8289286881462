import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinBySeparator',
})
export class JoinBySeparatorPipe implements PipeTransform {
  currentPageParams: {};
  defaultMapping: {};

  constructor(){}
  transform(value: any[], key: string, separator: string = ', '): string {
    if (!Array.isArray(value) || !key) {
      return '';
    }
    return value.map(item => item[key]).join(separator);
  }
}
