<div class="dual-list">
  <div class="listbox" [ngStyle]="{ order: direction() ? 1 : 2, 'margin-left': direction() ? 0 : '10px' }">
    <form *ngIf="filter" class="filter">
      <input class="form-control" name="filterSource" placeholder="Search" [(ngModel)]="available.picker" (ngModelChange)="onFilter(available)" />
    </form>

    <div class="record-picker">
      <ul [ngStyle]="{ 'max-height': height, 'min-height': height }" [ngClass]="{ over: available.dragOver }" (drop)="drop($event, confirmed)" (dragover)="allowDrop($event, available)" (dragleave)="dragLeave()">
        <li
          matRipple
          *ngFor="let item of available.sift; let idx = index"
          (click)="disabled ? null : selectItem(available.pick, item); shiftClick($event, idx, available, item)"
          [ngClass]="{ selected: isItemSelected(available.pick, item), disabled: disabled }"
          [draggable]="!disabled && format.draggable"
          (dragstart)="drag($event, item, available)"
          (dragend)="dragEnd(available)"
        >
          <label>{{ item._name }}</label>
        </li>
      </ul>
    </div>

    <div class="button-bar">
      <button mat-flat-button type="button" color="primary" name="addBtn" (click)="moveItem(available, confirmed)" [ngClass]="direction() ? 'point-right' : 'point-left'" [disabled]="available.pick.length === 0">
        {{ format.add }}
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>

      <button mat-stroked-button type="button" class="move-all-button" color="primary" name="addAllBtn" [disabled]="available.sift.length === 0" (click)="addAll()" [ngClass]="direction() ? 'point-right' : 'point-left'">
        ADD ALL
        <mat-icon>double_arrow</mat-icon>
      </button>

      <!-- <button mat-flat-button type="button" color="primary" (click)="selectAll(available)" [disabled]="disabled || isAllSelected(available)">{{ format.all }}</button> -->
      <!-- <button mat-stroked-button type="button" color="primary" (click)="selectNone(available)" [disabled]="!isAnySelected(available)">{{ format.none }}</button> -->
    </div>
  </div>

  <div class="listbox" [ngStyle]="{ order: direction() ? 2 : 1, 'margin-left': direction() ? '10px' : 0 }">
    <form *ngIf="filter" class="filter">
      <input class="form-control" name="filterDestination" placeholder="Search" [(ngModel)]="confirmed.picker" (ngModelChange)="onFilter(confirmed)" />
    </form>

    <div class="record-picker">
      <ul [ngStyle]="{ 'max-height': height, 'min-height': height }" [ngClass]="{ over: confirmed.dragOver }" (drop)="drop($event, available)" (dragover)="allowDrop($event, confirmed)" (dragleave)="dragLeave()">
        <li
          matRipple
          #itmConf
          *ngFor="let item of confirmed.sift; let idx = index"
          (click)="disabled ? null : selectItem(confirmed.pick, item); shiftClick($event, idx, confirmed, item)"
          [ngClass]="{ selected: isItemSelected(confirmed.pick, item), disabled: disabled }"
          [draggable]="!disabled && format.draggable"
          (dragstart)="drag($event, item, confirmed)"
          (dragend)="dragEnd(confirmed)"
        >
          <label>{{ item._name }}</label>
        </li>
      </ul>
    </div>

    <div class="button-bar">
      <button mat-flat-button type="button" color="primary" name="removeBtn" (click)="moveItem(confirmed, available)" [ngClass]="direction() ? 'point-left' : 'point-right'" [disabled]="confirmed.pick.length === 0">
        <mat-icon>keyboard_arrow_left</mat-icon>
        DELETE
      </button>
      <button
        mat-stroked-button
        class="move-all-button"
        type="button"
        color="primary"
        name="removeAllBtn"
        [disabled]="confirmed.sift.length === 0"
        (click)="removeAll()"
        [ngClass]="direction() ? 'point-left' : 'point-right'"
      >
        <mat-icon class="mirror">double_arrow</mat-icon>
        DELETE ALL
      </button>
      <!-- <button mat-flat-button type="button" color="primary" (click)="selectAll(confirmed)" [disabled]="disabled || isAllSelected(confirmed)">{{ format.all }}</button>
      <button mat-stroked-button type="button" color="primary" (click)="selectNone(confirmed)" [disabled]="!isAnySelected(confirmed)">{{ format.none }}</button> -->
    </div>
  </div>
</div>
