import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableService } from '@core/services/application/data-table';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { ApiAuthInterceptor, ApiPrefixInterceptor, ApiTrimInterceptor, ErrorHandlerInterceptor, ApiDateTimezoneInterceptor } from './http';

import {
  SidenavService,
  ConfigSidenavService,
  MenuService,
  ErrorService,
  MemberService,
  AuthGuard,
  TimeService,
  ReceiptTransactionService,
  MembershipTierResolverService,
  TenantService,
  DropdownService,
  CurrencyConversionService,
  UserRoleService,
  BusinessUnitService,
  LoyaltyService,
  S3FileManagementService,
  QrCodeScannerService,
  FormGeneratorService,
  CampaignService,
  ImageService,
  FormService,
  StackViewerService,
  GiftService,
  RequestService,
  WorkflowConfigResolver,
  ReportService,
  VrmUserResolverService,
  PreserveTabService,
  BusinessUnitResolverService,
  CurrentLoyaltyProgrammeResolver,
  CurrencyConversionRateResolverService,
  ExternalLinkService,
} from '@core/services';
import { OAUTH_CONFIG } from '@core/services/auth/auth.config';
import { FormGuard } from './guards/form.guard';
import { NoAuthGuard, SystemAdminGuard } from './guards';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { AUTH_MODULE_CONFIG } from './auth-module-config';
import { GenericCategoryPipe } from '@app/@shared/pipes/system/generic-category.pipe';
import { IMAGE_COMPRESSOR_CONFIG, ImageCompressorConfig } from './services/application/image/image-compressor.config';
import { ApiTracerInterceptor } from './http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AccountCodeService } from './services/account-code.service';
import { FeatureFlagService } from '@core/services/feature-flag.service';

// Use local storage instead of session storage for oauth
export function storageFactory(): OAuthStorage {
  return localStorage;
}
@NgModule({
  imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule, OAuthModule.forRoot(), AngularSvgIconModule.forRoot()],
  providers: [
    AuthGuard,
    BusinessUnitService,
    CampaignService,
    ConfigSidenavService,
    CurrencyConversionService,
    AccountCodeService,
    DataTableService,
    DataTableService,
    DropdownService,
    ErrorService,
    FormGeneratorService,
    FormGuard,
    ImageService,
    LoyaltyService,
    MemberService,
    MembershipTierResolverService,
    MenuService,
    PreserveTabService,
    NoAuthGuard,
    SystemAdminGuard,
    QrCodeScannerService,
    ReceiptTransactionService,
    S3FileManagementService,
    SidenavService,
    TenantService,
    TimeService,
    UserRoleService,
    VrmUserResolverService,
    FormService,
    StackViewerService,
    GiftService,
    RequestService,
    WorkflowConfigResolver,
    ReportService,
    GenericCategoryPipe,
    BusinessUnitResolverService,
    CurrentLoyaltyProgrammeResolver,
    CurrencyConversionRateResolverService,
    ExternalLinkService,
    FeatureFlagService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTrimInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiDateTimezoneInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTracerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: AuthConfig, useValue: OAUTH_CONFIG },
        { provide: OAuthModuleConfig, useValue: AUTH_MODULE_CONFIG },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: ImageCompressorConfig, useValue: IMAGE_COMPRESSOR_CONFIG },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
