import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, forwardRef, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Self, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, FormControl, UntypedFormGroup, NgControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { GeneralConstants } from '@app/@shared/masks';
import { Subject } from 'rxjs';

export class PointRange {
  constructor(public pointFrom: string, public pointTo: string) {}
}

export function pointRangeValidator(fg: UntypedFormGroup) {
  const { pointFrom, pointTo } = fg.value;
  return (!pointFrom && !pointTo) || (pointFrom && pointTo && Number(pointTo) > Number(pointFrom)) ? null : { invalidPointRange: true };
}
@Component({
  selector: 'point-range-input',
  templateUrl: './point-range-input.component.html',
  styleUrls: ['./point-range-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: PointRangeInputComponent }],
  host: {
    '[class.input-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class PointRangeInputComponent implements ControlValueAccessor, MatFormFieldControl<PointRange>, OnDestroy, OnChanges {
  static nextId = 0;
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
  NUMBER_MASK: any;
  pointRange: UntypedFormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'point-range-input';
  id = `input-${PointRangeInputComponent.nextId++}`;
  @ViewChild('fromInput') fromInput: HTMLInputElement;
  @ViewChild('toInput') toInput: HTMLInputElement;

  @Input('aria-describedby') userAriaDescribedBy: string;
  @Input() formlyAttributes: any;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.pointRange.disable() : this.pointRange.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): PointRange | null {
    const {
      value: { pointFrom, pointTo },
    } = this.pointRange;
    return new PointRange(pointFrom, pointTo);
  }
  set value(range: PointRange | null) {
    const { pointFrom, pointTo } = range || new PointRange('', '');
    this.pointRange.setValue({ pointFrom, pointTo });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.pointRange.invalid && this.pointRange.dirty;
  }

  constructor(
    fb: UntypedFormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.pointRange = fb.group(
      {
        pointTo: [null, { updateOn: 'change' }],
        pointFrom: [null, { updateOn: 'change' }],
      },
      { validators: [this.pointRangeValidator] }
    );

    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.formlyAttributes?.currentValue?.templateOptions?.isFloatNum) {
      this.NUMBER_MASK = GeneralConstants.ONLYTWODOTS;
    } else {
      this.NUMBER_MASK = GeneralConstants.INTEGER;
    }
  }
  onChange = (_: any) => {};
  onTouched = () => {};
  writeValue(range: PointRange | null): void {
    this.value = range;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.input-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (!this.pointRange.controls.pointFrom.value && !this.pointRange.controls.pointTo.value) {
      this._focusMonitor.focusVia(this.fromInput, 'program');
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.stateChanges.next();
    this.onChange(this.value);
  }

  pointRangeValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const { pointFrom, pointTo } = fg.value;
    if (this?.formlyAttributes && this?.formlyAttributes?.templateOptions?.isNoVerifyEnd) {
      return (!pointFrom && !pointTo) || (pointFrom && !pointTo) || (pointFrom && pointTo && Number(pointTo) >= Number(pointFrom)) ? null : { invalidPointRange: true };
    }
    return (!pointFrom && !pointTo) || (pointFrom && pointTo && Number(pointTo) > Number(pointFrom)) ? null : { invalidPointRange: true };
  };

  get empty() {
    const {
      value: { pointFrom, pointTo },
    } = this.pointRange;
    return !pointFrom && !pointTo;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
}
