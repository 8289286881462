import { Component, OnInit, Inject, EventEmitter, Input, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@core/models/application/dialog-data';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import {
  ReceiptTransactionService,
} from '@core/services';

@Component({
  selector: 'c-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss'],
})
export class QrCodeScannerComponent implements OnInit {
  afterSave: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('scanner', { static: false }) scanner: ZXingScannerComponent;
  constructor(
    public receiptTransactionService: ReceiptTransactionService,   
    public dialogRef: MatDialogRef<QrCodeScannerComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  ngOnInit(): void {}

  /**
   *
   * @param result QR Code String
   */
  onScanSuccess(result: string) {
    if (this.data?.isRPT) {
      this.afterSave.emit(result);
      // this.dialogRef.close(result);
    } else {
      this.dialogRef.close(result);
    }
  }

  onClickCloseButton() {
    if (this.scanner) {
      this.scanner.reset(); // 停止扫描
      console.log('this.scanner', this.scanner)
    }
    this.dialogRef.close();
  }
}
