import { Directive, HostBinding, ElementRef, Input, OnChanges, OnInit, DoCheck, Renderer2 } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Directive({
  selector: 'dx-data-grid',
})
export class DatagridLoadingPanelDirective implements OnInit, DoCheck {
  constructor(private datagrid: DxDataGridComponent, private renderer: Renderer2) {}

  ngDoCheck(): void {
    const datagridInnerBox = this.datagrid.instance.element().querySelector('.dx-datagrid-rowsview');
    const datagridLoadingPanel = this.datagrid.instance.element().querySelector('.dx-loadpanel-content');

    const datagridBar = this.datagrid.instance.element().querySelector('.dx-datagrid-header-panel');
    const datagridHeader = this.datagrid.instance.element().querySelector('.dx-datagrid-headers');

    if (datagridInnerBox && datagridLoadingPanel) {
      this.renderer.setStyle(datagridLoadingPanel, 'height', datagridInnerBox.clientHeight + 'px');

      const barHeight = datagridBar?.clientHeight || 0;
      const headerHeight = datagridHeader?.clientHeight || 0;
      const transformString = `translate(5px, ${barHeight + headerHeight + 3}px)`;
      this.renderer.setStyle(datagridLoadingPanel, 'transform', transformString);

      this.renderer.setStyle(datagridLoadingPanel, 'width', datagridInnerBox.clientWidth - 4 + 'px');
    }
  }

  ngOnInit() {}
}
