import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoyaltyService } from '@core/services/loyalty/loyalty.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentLoyaltyProgrammeResolver implements Resolve<any> {
  constructor(private loyaltyService: LoyaltyService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.loyaltyService.currentProgramme.businessUnit) {
      this.router.navigate(['/admin/home']);
    }

    return this.loyaltyService.getLoyaltyProgrammes({ id: this.loyaltyService.currentProgramme.id }).pipe(
      map((programmes) => {
        return programmes.content[0];
      }),
      catchError((err) => this.router.navigateByUrl(`/`))
    );
  }
}
