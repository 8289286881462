import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FormCanDeactivate } from './formCanDeactivate.guard';
import { DialogService } from '@core/services/application/dialog';
import { DialogData } from '@core/models/application/dialog-data';

@Injectable({
  providedIn: 'root',
})
export class FormGuard implements CanDeactivate<FormCanDeactivate> {
  constructor(private dialogService: DialogService) {}
  canDeactivate(component: FormCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (component.canDeactivate(currentState, nextState)) {
      this.dialogService.navigateAwaySelection$.next(true);
      return of(true);
    }

    if (!component.canDeactivate(currentState, nextState)) {
      const dialogData: DialogData = {
        content: 'Confirm to leave this page? Any unsaved data will be lost.',
        title: '',
        id: 'mat-form-guard-dialog',
        yesCallback: (dialogRef) => {
          dialogRef.close();
          this.dialogService.navigateAwaySelection$.next(true);
        },
        noCallback: (dialogRef) => {
          dialogRef.close();
          this.dialogService.navigateAwaySelection$.next(false);
        },
        hasBackdrop: true,
      };
      this.dialogService.showConfirmationDialog(dialogData).subscribe();
    }

    return this.dialogService.navigateAwaySelection$;
  }
}
