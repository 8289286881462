import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@core/models/application/dialog-data';

@Component({
  selector: 'dialog-error',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  public Object = Object;
  public Array = Array;
  public customData: {
    dateTime: string;
    traceId: string;
    [key: string]: any;
  };

  constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    data.yesCallback = data.yesCallback || this.noop;
    data.noCallback = data.noCallback || this.noop;

    this.customData = data.customData;
  }

  noop = () => {
    this.dialogRef.close();
  };

  onNoClick(): void {
    this.dialogRef.close();
  }
}
