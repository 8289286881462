<div>
  <div class="close-button">
    <span (click)="onClickCloseButton()">
      <mat-icon>close</mat-icon>
    </span>
  </div>
  <div class="row no-gutters image-action-bar">
    <div class="mr-auto">
      <button mat-raised-button class="action-button" (click)="onClickZoomIn()">
        <mat-icon>zoom_in</mat-icon>
      </button>
      <button mat-raised-button class="action-button" (click)="onClickZoomOut()">
        <mat-icon>zoom_out</mat-icon>
      </button>
    </div>
  </div>
  <dx-gallery
    #imageSlider
    [dataSource]="processedImages"
    [loop]="false"
    [showNavButtons]="true"
    [swipeEnabled]="false"
    [showIndicator]="false"
    [selectedIndex]="selectedIndex"
    (onSelectionChanged)="onCurrentViewChanged()"
  >
    <div *dxTemplate="let galleryItem of 'item'">
      <div class="image-viewer-container">
        <div class="primary-image-viewer">
          <pinch-zoom #pinchViewer [limit-zoom]="10" [autoHeight]="true" [disableZoomControl]="'disable'" [backgroundColor]="'#fff'">
            <img [ngStyle]="imageRotateStyle" [src]="showBlobImage(galleryItem.src)" alt="receipt image" />
          </pinch-zoom>
        </div>
      </div>
    </div>
  </dx-gallery>
  <div class="rotate-action">
    <button class="edit-button" mat-button (click)="onClickEditImage()"><mat-icon>edit</mat-icon><span>Edit Image</span></button>
  </div>
</div>
