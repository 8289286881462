<header>
  <nav>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-icon-button (click)="closeConfigView()">
          <mat-icon>close</mat-icon>
        </button>
        <div class="header-left"></div>
        <span class="flex-spacer"></span>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="user-avatar">
          <!-- <ngx-avatar [name]="username[0] || 'U'" size="22" bgColor="#d8d8d8" fgColor="#1b4266" textSizeRatio="2.3"></ngx-avatar> -->
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="onClickLogout()" mat-menu-item>
            <mat-icon>login</mat-icon>
            <span translate>COMMON.LABEL.LOGOUT</span>
          </button>
        </mat-menu>
        <span class="user-info">
          {{ username }}
        </span>
      </mat-toolbar-row>
    </mat-toolbar>
  </nav>
</header>
