import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MerchantPipe } from './merchant.pipe';

@NgModule({
  declarations: [MerchantPipe],
  imports: [CommonModule],
  exports: [MerchantPipe],
})
export class MerchantPipeModule {}
