import { ValidationErrors } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';

export function checkDepositTransactionTenant(control: UntypedFormControl): ValidationErrors {
  if (control?.parent?.get('linkDepositTransactionId')?.value?.length && control?.value) {
    const existingLinkedDepositTransactionTenant = control?.parent.get('linkDepositTransactionId').value.map((value: any) => value.tenantId)[0];
    return control?.value !== existingLinkedDepositTransactionTenant ? { depositTransactionTenantInvalid: true } : null;
  }
  return null;
}
